@font-face {
	font-family: "Open Sans Condensed Bold";
	src: url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.eot");
	src: url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.eot?#iefix")format("embedded-opentype"),
	url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.woff2")format("woff2"),
	url("/fonts/condensed/OpenSans-ExtraBold.woff2")format("woff2"),
	url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.woff")format("woff"),
	url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.ttf")format("truetype"),
	url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.svg#Open Sans Condensed Bold")format("svg");
}

// @mixin font-get($font) {
// 	@if map-has-key($fonts, $font) {
// 		font-family: map-get($fonts, $font), 'Condensedbold';
// 	}
// }



