.level1{
	.uk-accordion-title{
		background-color:map-get($colors, 'sc-grey-8');
		border-bottom:1px solid;
		border-radius: 0;
		font-weight: 400;
		padding-left: 0px;
	}
	svg{
		color:map-get($colors, 'pc-pink');
	}

	>:nth-child(n+2){
    margin-top: 2px;
	}

}


.level2{
	.uk-accordion-title{
		background-color:map-get($colors, 'pc-white');
		border-bottom:0px;
		border-radius: 30px;
		font-weight: 700 ;
		padding-left: 30px;
	}
		svg{
			color: map-get($colors, 'sc-grey-4');
		}

}

