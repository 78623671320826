.titulo__modoneh2{
	font-size:24px;
	font-weight:map-get($weights, 'extrabold') ;
	text-align: left;
  padding-left: 45px;
	color:map-get($colors, 'pc-navy');
	font-family: map-get($fonts,'condensedextrabold');
	&:after{
		background-color: map-get($colors, 'pc-navy');
		content:'';
    width: 95%;
    height: 3px;
    display: block;
		margin-top: 15px;
		position: relative;
    left: -45px;
	}
}

.titulo__modtwoh2{
	font-size:24px;
	font-weight:map-get($weights, 'extrabold') ;
	text-align: left;
  padding-left: 45px;
	text-align: center;
	font-family: map-get($fonts,'condensedextrabold');
	color:map-get($colors, 'pc-navy');
}

.titulo__modtreeh2{
	font-size:24px;
	font-weight:map-get($weights, 'extrabold') ;
	text-align: left;
  padding-left: 45px;


	&:after{
		background-color: map-get($colors, 'pc-navy');
		content:'';
    width: 460px;
		max-width: 100%;
    height: 3px;
    display: block;
		margin-top: 15px;
		position: relative;
    left: -45px;
	}
}







.remove__border_bottom:last-child{
	border-bottom:0px;
}

.mejorespracticas--title:before{
	content:'';
}



.remove_bottom-20{

	margin-bottom: -20px;

}

.white_deg{
	border-color: #FFFFFF61;

}


