:root {
	@each $name, $color in $colors {
		$opacity: 10;
		--#{$name}: #{rgba($color, $opacity/10)};
	}

	@each $name, $color in $colors {

		@for $opacity from 1 through 9 {
			$name_color: $name;
			@if $opacity != 10 {
				$name_color: $name + "-opacity-" + $opacity;
			}

			--#{$name_color}: #{rgba($color, $opacity/10)};
		}
	}
}

:focus,
:focus-visible {
	outline: solid 2px map-get($colors, "pc-pink") !important;
	// outline-offset: 4px;
}

input::placeholder {
	color: map-get($colors, 'pc-gray-1');
	font-weight: map-get($weights, "medium")
}

html,
body {
  color: map-get($colors, 'pc-gray-1');
}

.body {
	background-color: map-get($colors, 'sc-grey-7')
}

.footer {
	background-color: map-get($colors, 'sc-navy-2');
}

em {
  color: map-get($colors, 'sc-negro-1');
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5 {
	color: map-get($colors, "pc-navy");
}

.uk-link, a {
	color:  #ffffff;
	color:  map-get($colors, "pc-navy");
	// border-bottom: 2px solid map-get($colors, 'pc-navy');
	transition: border-bottom 200ms ease-in-out;

	&:hover,
	&:focus {
		color:  #ffffff;
		// border-bottom: 2px solid map-get($colors, 'pc-pink');
	}
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
	color:  map-get($colors, "pc-white");
  text-decoration: none;
}

.uk-subnav > * > :first-child {
	border: 2px solid map-get($colors, 'pc-pink');
	border-radius: 5px;
	color: map-get($colors, "pc-navy");
}
.uk-subnav-pill > * > a:hover {
	color: map-get($colors, "pc-navy");
}
.uk-subnav-pill > .uk-active > a {
  background-color:  map-get($colors, "pc-pink");
  color: map-get($colors, "pc-white");
}

.uk-link-boder {
	border-bottom: 2px solid map-get($colors, 'pc-pink');
}

.uk-link-remove-boder,
.uk-link-remove-boder:hover,
.uk-link-remove-boder:focus {
	border-style: inherit;
	border-width: inherit;
}

@mixin set_color($name, $color, $opacity:1) {
	@if $opacity != 10 {
		$name: $name + "-opacity-" + $opacity;
	}

	.c-#{$name} {
		color: rgba($color, $opacity/10) !important;
		transition: color 300ms;
	}
	.c-#{$name}\:h:hover,
	.c-#{$name}\:h:focus,
	.c-#{$name}\:h:active { color: rgba($color, $opacity/10) !important; }
}

@mixin set_background($name, $color, $opacity:1) {
	@if $opacity != 10 {
		$name: $name + "-opacity-" + $opacity;
	}

	.bgc-#{$name} {
		background-color: rgba($color, $opacity/10) !important;
		transition: background-color 300ms;
	}
	.bgc-#{$name}\:h:hover,
	.bgc-#{$name}\:h:focus,
	.bgc-#{$name}\:h:active { background-color: rgba($color, $opacity/10) !important; }
}

@mixin set_boder($name, $color, $opacity:1) {
	@if $opacity != 10 {
		$name: $name + "-opacity-" + $opacity;
	}

	.bc-#{$name} { border-color: rgba($color, $opacity/10); }
}

@each $name, $color in $colors {

	@for $i from 1 through 10 {
		@include set_color($name, $color, $i);

		@include set_background($name, $color, $i);

		@include set_boder($name, $color, $i);
	}

}

@for $i from $minSize through $maxSize {
	.b-solid-#{$i} {
		border-style: solid;
		border-width: #{$i+'px'};
	}
	.b-radius-#{$i}{
		border-radius:#{$i+'px'};
	}
	@each $side in $sides {
		.b-solid-#{$i}-#{$side} {
			border-#{$side}-style: solid;
			border-#{$side}-width: #{$i+'px'};
		}

		.b-solid-#{$i}-#{$side}-remove {
			border-#{$side}-style: inherit;
			border-#{$side}-width: inherit;
		}
	}
	@each $border-side in $border-sides {
    .b-radius-#{$i}-#{$border-side} {
        border-#{$border-side}-radius:  #{$i+'px'};;
    }
	}
}

.b-radius-remove {
	border-radius:0px;
}
@each $border-side in $border-sides {
    .b-radius-#{$border-side}-remove {
        border-#{$border-side}-radius: 0;
    }
}

.b-solid-remove {
	border-style: inherit;
	border-width: inherit;
}

/* General Theme */
@import './general/header';
@import './general/footer';
// @import '.components/custom/Login.scss';



/* Pages Theme */
//@import './pages/name_page';
// @import './pages/metodologia_page';
// @import './pages/preguntas';
// @import './pages/ejes_ciudad';
 @import './pages/home';



/* Sections Theme */
//@import './sections/name_section';
@import './sections/preguntas_frecuentes';

//@import './pages/edit_proveedor';

