.uk-navbar-nav > li > a {
	color: map-get($colors, "pc-white");
	font-size: 16px;
	font-weight: map-get($weights, "bold");
	text-transform: initial;

	&:hover {
		color: map-get($colors, "pc-white");
	}

	&:active {
		color: map-get($colors, "pc-white");
	}
	&[aria-expanded=true]{

		color: map-get($colors, "pc-white");
	}
}
