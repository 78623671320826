.control-label-checkbox {
	display: grid;
	grid-template-columns: 1em auto;
	gap: .5em
}

input[type=checkbox] {
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	display: inline-block;
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 16px !important;
	height: 16px !important;
	border: .15em solid currentColor;
	border-radius: 5px;
	transform: translateY(-.075em);
	display: grid;
	place-content: center
}

input[type=checkbox].large {
	width: 1.15em;
	height: 1.15em;
}

input[type=checkbox]::before {
	content: "";
	width: .65em;
	height: .65em;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em map-get($colors, "pc-navy");
	background-color: CanvasText
}

input[type=checkbox].check-pc-navy::before {
	box-shadow: inset 1em 1em map-get($colors, "pc-navy");
}

input[type=checkbox].check-pc-pink::before {
	box-shadow: inset 1em 1em map-get($colors, "pc-pink");
}

input[type=checkbox]:checked::before {
	transform: scale(1)
}
