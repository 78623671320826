.titulo__h2__interna{
	font-size:28px;
	font-weight:map-get($weights, 'extrabold') ;
	text-align: left;
	color:map-get($colors, 'pc-navy');
	font-family: map-get($fonts,'condensedextrabold');

	&:after{
		background-color: map-get($colors, 'pc-pink');
		content:'';
    width: 188px;
    height: 6px;
    display: block;
		margin-top: 14px;
		position: relative;

	}
}





