@for $size from $minSize through $maxSize {

	.w-#{$size} {
		width: #{$size+'px'};
  }

	.maxw-#{$size} {
		max-width: #{$size+'px'};
  }

	.minw-#{$size} {
		min-width: #{$size+'px'};
  }

  @include mq-xs {
		.w-#{$size}\@sx {
			width: #{$size+'px'};
		}

		.maxw-#{$size}\@sx {
			max-width: #{$size+'px'};
		}

		.minw-#{$size}\@sx {
			min-width: #{$size+'px'};
		}
  }

  @include mq-s {
    .w-#{$size}\@s {
			width: #{$size+'px'};
		}

		.maxw-#{$size}\@s {
			max-width: #{$size+'px'};
		}

		.minw-#{$size}\@s {
			min-width: #{$size+'px'};
		}
  }

  @include mq-m {
    .w-#{$size}\@m {
			width: #{$size+'px'};
		}

		.maxw-#{$size}\@m {
			max-width: #{$size+'px'};
		}

		.minw-#{$size}\@m {
			min-width: #{$size+'px'};
		}
  }

  @include mq-l {
    .w-#{$size}\@l {
			width: #{$size+'px'};
		}

		.maxw-#{$size}\@l {
			max-width: #{$size+'px'};
		}

		.minw-#{$size}\@l {
			min-width: #{$size+'px'};
		}
  }

  @include mq-xl {
    .w-#{$size}\@xl {
			width: #{$size+'px'};
		}

		.maxw-#{$size}\@xl {
			max-width: #{$size+'px'};
		}

		.minw-#{$size}\@xl {
			min-width: #{$size+'px'};
		}
  }

}

@import 'pages/name_layout';
@import 'pages/mecanismos_layout';
@import 'pages/detailcomprador_layout';
@import 'pages/actas_layout';

