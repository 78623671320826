.userdata {
  background-color: #002a68;
  color: #fff;
  padding: 20px;
  // width: 280px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}
// .container {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 20px;
//   padding: 20px;
// }

.login-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.form-group {
  margin-bottom: 10px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.form-group i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: #ff1784;
  border: none;
}

.remember-me {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.forgot-questions {
  margin-top: 10px;
  text-align: left;
}

.forgot-questions p {
  margin: 5px 0;
}

.controls {
  background-color: #fff;
  color: #002A68;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  width: 100%;
}
a {
  color: white;

}

.forgot-questions a {
  text-decoration: none;
}
