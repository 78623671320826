@font-face {
	font-family: "Open Sans Condensed Light Regular";
    src: url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.eot");
    src: url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.svg#Open Sans Condensed Light Regular")format("svg");

}

// @mixin font-get($font) {
// 	@if map-has-key($fonts, $font) {
// 		font-family: map-get($fonts, $font), 'Condensedbold';
// 	}
// }



