@charset "UTF-8";
/* UIKIT */
/***************\
  Media Querys

ejemplo de como utilizar un mixin  
  @include mq-xs {

 }

\***************/
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
html {
  /* 1 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666;
}

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #0f6ecd;
  text-decoration: underline;
}

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted;
}

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap;
  padding: 2px 6px;
  background: #f8f8f8;
}

/*
 * Emphasize
 */
em {
  color: #f0506e;
}

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none;
}

/*
 * Mark
 */
mark {
  background: #ffd;
  color: #666;
}

/*
 * Quote
 */
q {
  font-style: italic;
}

/*
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px;
}

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0;
}

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

/* Headings
 ========================================================================== */
h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: #333;
  text-transform: none;
}

/* Add margin if adjacent element */
* + h1, * + .uk-h1,
* + h2, * + .uk-h2,
* + h3, * + .uk-h3,
* + h4, * + .uk-h4,
* + h5, * + .uk-h5,
* + h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px;
}

/*
 * Sizes
 */
h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2;
}

h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3;
}

h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4;
}

h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4;
}

h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .uk-h1 {
    font-size: 2.625rem;
  }
  h2, .uk-h2 {
    font-size: 2rem;
  }
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px;
}

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px;
}

/* Address
 ========================================================================== */
address {
  font-style: normal;
}

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic;
  color: #333;
}

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px;
}

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #666;
}
blockquote footer::before {
  content: "— ";
}

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background: #fff;
}

pre code {
  font-family: Consolas, monaco, monospace;
}

/* Focus
 ========================================================================== */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px dotted #ff1784;
}

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none;
}

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main { /* 2 */
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px;
}

/* ========================================================================
   Component: Link
 ========================================================================== */
/* Muted
 ========================================================================== */
a.uk-link-muted,
.uk-link-muted a,
.uk-link-toggle .uk-link-muted {
  color: #999;
}

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted {
  color: #666;
}

/* Text
 ========================================================================== */
a.uk-link-text,
.uk-link-text a,
.uk-link-toggle .uk-link-text {
  color: inherit;
}

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text {
  color: #999;
}

/* Heading
 ========================================================================== */
a.uk-link-heading,
.uk-link-heading a,
.uk-link-toggle .uk-link-heading {
  color: inherit;
}

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading {
  color: #1e87f0;
  text-decoration: none;
}

/* Reset
 ========================================================================== */
/*
 * `!important` needed to override inverse component
 */
a.uk-link-reset,
.uk-link-reset a {
  color: inherit !important;
  text-decoration: none !important;
}

/* Toggle
 ========================================================================== */
.uk-link-toggle {
  color: inherit !important;
  text-decoration: none !important;
}

/* ========================================================================
   Component: Heading
 ========================================================================== */
.uk-heading-small {
  font-size: 2.6rem;
  line-height: 1.2;
}

.uk-heading-medium {
  font-size: 2.8875rem;
  line-height: 1.1;
}

.uk-heading-large {
  font-size: 3.4rem;
  line-height: 1.1;
}

.uk-heading-xlarge {
  font-size: 4rem;
  line-height: 1;
}

.uk-heading-2xlarge {
  font-size: 6rem;
  line-height: 1;
}

/* Tablet Landscape and bigger */
@media (min-width: 960px) {
  .uk-heading-small {
    font-size: 3.25rem;
  }
  .uk-heading-medium {
    font-size: 3.5rem;
  }
  .uk-heading-large {
    font-size: 4rem;
  }
  .uk-heading-xlarge {
    font-size: 6rem;
  }
  .uk-heading-2xlarge {
    font-size: 8rem;
  }
}
/* Laptop and bigger */
@media (min-width: 1200px) {
  .uk-heading-medium {
    font-size: 4rem;
  }
  .uk-heading-large {
    font-size: 6rem;
  }
  .uk-heading-xlarge {
    font-size: 8rem;
  }
  .uk-heading-2xlarge {
    font-size: 11rem;
  }
}
/* Primary
   Deprecated: Use `uk-heading-medium` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Hero
   Deprecated: Use `uk-heading-xlarge` instead
 ========================================================================== */
/* Tablet landscape and bigger */
/* Desktop and bigger */
/* Divider
 ========================================================================== */
.uk-heading-divider {
  padding-bottom: calc(5px + 0.1em);
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5;
}

/* Bullet
 ========================================================================== */
.uk-heading-bullet {
  position: relative;
}

/*
 * 1. Using `inline-block` to make it work with text alignment
 * 2. Center vertically
 * 3. Style
 */
.uk-heading-bullet::before {
  content: "";
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  top: calc(-0.1 * 1em);
  vertical-align: middle;
  /* 3 */
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #e5e5e5;
}

/* Line
 ========================================================================== */
/*
 * Clip the child element
 */
.uk-heading-line {
  overflow: hidden;
}

/*
 * Extra markup is needed to make it work with text align
 */
.uk-heading-line > * {
  display: inline-block;
  position: relative;
}

/*
 * 1. Center vertically
 * 2. Make the element as large as possible. It's clipped by the container.
 * 3. Style
 */
.uk-heading-line > ::before,
.uk-heading-line > ::after {
  content: "";
  /* 1 */
  position: absolute;
  top: calc(50% - (calc(0.2px + 0.05em) / 2));
  /* 2 */
  width: 2000px;
  /* 3 */
  border-bottom: calc(0.2px + 0.05em) solid #e5e5e5;
}

.uk-heading-line > ::before {
  right: 100%;
  margin-right: calc(5px + 0.3em);
}

.uk-heading-line > ::after {
  left: 100%;
  margin-left: calc(5px + 0.3em);
}

/* ========================================================================
   Component: Divider
 ========================================================================== */
/*
 * 1. Reset default `hr`
 * 2. Set margin if a `div` is used for semantical reason
 */
[class*=uk-divider] {
  /* 1 */
  border: none;
  /* 2 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + [class*=uk-divider] {
  margin-top: 20px;
}

/* Icon
 ========================================================================== */
.uk-divider-icon {
  position: relative;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22%23e5e5e5%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.uk-divider-icon::before,
.uk-divider-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  max-width: calc(50% - (50px / 2));
  border-bottom: 1px solid #e5e5e5;
}

.uk-divider-icon::before {
  right: calc(50% + (50px / 2));
  width: 100%;
}

.uk-divider-icon::after {
  left: calc(50% + (50px / 2));
  width: 100%;
}

/* Small
 ========================================================================== */
/*
 * 1. Fix height because of `inline-block`
 * 2. Using ::after and inline-block to make `text-align` work
 */
/* 1 */
.uk-divider-small {
  line-height: 0;
}

/* 2 */
.uk-divider-small::after {
  content: "";
  display: inline-block;
  width: 100px;
  max-width: 100%;
  border-top: 1px solid #e5e5e5;
  vertical-align: top;
}

/* Vertical
 ========================================================================== */
.uk-divider-vertical {
  width: max-content;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}

/*
 * Avoid column break within the list item, when using `column-count`
 */
.uk-list > * {
  break-inside: avoid-column;
}

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0;
}

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px;
}

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px;
}

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal;
}

.uk-list-decimal > * {
  counter-increment: decimal;
}

.uk-list-disc > ::before,
.uk-list-circle > ::before,
.uk-list-square > ::before,
.uk-list-decimal > ::before,
.uk-list-hyphen > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right;
}

.uk-list-disc > ::before {
  list-style-type: disc;
}

.uk-list-circle > ::before {
  list-style-type: circle;
}

.uk-list-square > ::before {
  list-style-type: square;
}

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . ";
}

.uk-list-hyphen > ::before {
  content: "–  ";
}

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #999 !important;
}

.uk-list-emphasis > ::before {
  color: #333 !important;
}

.uk-list-primary > ::before {
  color: #1e87f0 !important;
}

.uk-list-secondary > ::before {
  color: #222 !important;
}

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px;
}

.uk-list-bullet > ::before {
  content: "";
  display: list-item;
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px;
}
.uk-list-striped > *:nth-of-type(odd) {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.uk-list-striped > :nth-of-type(odd) {
  background: #f8f8f8;
}

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px;
}

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0;
}

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
}

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0;
}

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px;
}

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0;
}

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0;
}

/* ========================================================================
   Component: Description list
 ========================================================================== */
/*
 * Term
 */
.uk-description-list > dt {
  color: #333;
  font-size: 0.875rem;
  font-weight: normal;
  text-transform: uppercase;
}

.uk-description-list > dt:nth-child(n+2) {
  margin-top: 20px;
}

/*
 * Description
 */
/* Style modifier
 ========================================================================== */
/*
 * Line
 */
.uk-description-list-divider > dt:nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Behave like a block element
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}

/* Add margin if adjacent element */
* + .uk-table {
  margin-top: 20px;
}

/* Header cell
 ========================================================================== */
/*
 * 1. Style
 */
.uk-table th {
  padding: 16px 12px;
  text-align: left;
  vertical-align: bottom;
  /* 1 */
  font-size: 0.875rem;
  font-weight: normal;
  color: #999;
  text-transform: uppercase;
}

/* Cell
 ========================================================================== */
.uk-table td {
  padding: 16px 12px;
  vertical-align: top;
}

/*
 * Remove margin from the last-child
 */
.uk-table td > :last-child {
  margin-bottom: 0;
}

/* Footer
 ========================================================================== */
.uk-table tfoot {
  font-size: 0.875rem;
}

/* Caption
 ========================================================================== */
.uk-table caption {
  font-size: 0.875rem;
  text-align: left;
  color: #999;
}

/* Alignment modifier
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-table-divider > tr:not(:first-child),
.uk-table-divider > :not(:first-child) > tr,
.uk-table-divider > :first-child > tr:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

/*
 * Striped
 */
.uk-table-striped > tr:nth-of-type(odd),
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #f8f8f8;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

/*
 * Hover
 */
.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
  background: #ffd;
}

/* Active state
 ========================================================================== */
.uk-table > tr.uk-active,
.uk-table tbody tr.uk-active {
  background: #ffd;
}

/* Size modifier
 ========================================================================== */
.uk-table-small th,
.uk-table-small td {
  padding: 10px 12px;
}

.uk-table-large th,
.uk-table-large td {
  padding: 22px 12px;
}

/* Justify modifier
 ========================================================================== */
.uk-table-justify th:first-child,
.uk-table-justify td:first-child {
  padding-left: 0;
}

.uk-table-justify th:last-child,
.uk-table-justify td:last-child {
  padding-right: 0;
}

/* Cell size modifier
 ========================================================================== */
.uk-table-shrink {
  width: 1px;
}

.uk-table-expand {
  min-width: 150px;
}

/* Cell link modifier
 ========================================================================== */
/*
 * Does not work with `uk-table-justify` at the moment
 */
.uk-table-link {
  padding: 0 !important;
}

.uk-table-link > a {
  display: block;
  padding: 16px 12px;
}

.uk-table-small .uk-table-link > a {
  padding: 10px 12px;
}

/* Responsive table
 ========================================================================== */
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-table-responsive,
.uk-table-responsive tbody,
.uk-table-responsive th,
.uk-table-responsive td,
.uk-table-responsive tr {
    display: block;
  }
  .uk-table-responsive thead {
    display: none;
  }
  .uk-table-responsive th,
.uk-table-responsive td {
    width: auto !important;
    max-width: none !important;
    min-width: 0 !important;
    overflow: visible !important;
    white-space: normal !important;
  }
  .uk-table-responsive th:not(:first-child):not(.uk-table-link),
.uk-table-responsive td:not(:first-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:first-child) > a {
    padding-top: 5px !important;
  }
  .uk-table-responsive th:not(:last-child):not(.uk-table-link),
.uk-table-responsive td:not(:last-child):not(.uk-table-link),
.uk-table-responsive .uk-table-link:not(:last-child) > a {
    padding-bottom: 5px !important;
  }
  .uk-table-justify.uk-table-responsive th,
.uk-table-justify.uk-table-responsive td {
    padding-left: 0;
    padding-right: 0;
  }
}
.uk-table tbody tr {
  transition: background-color 0.1s linear;
}

.uk-table-striped > tr:nth-of-type(even):last-child,
.uk-table-striped tbody tr:nth-of-type(even):last-child {
  border-bottom: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0;
}

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0);
}

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none;
}

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #999;
  /* 1 */
  text-decoration: none !important;
}

.uk-icon-link:hover {
  color: #666;
}

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959;
}

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666;
}

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666;
}

/* ========================================================================
   Component: Form Range
 ========================================================================== */
/*
 * 1. Remove default style.
 * 2. Define consistent box sizing.
 * 3. Remove `margin` in all browsers.
 * 4. Align to the center of the line box.
 * 5. Prevent content overflow if a fixed width is used.
 * 6. Take the full width.
 * 7. Remove white background in Chrome.
 */
.uk-range {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  max-width: 100%;
  /* 6 */
  width: 100%;
  /* 7 */
  background: transparent;
}

/* Focus */
.uk-range:focus {
  outline: none;
}

.uk-range::-moz-focus-outer {
  border: none;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-range:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer;
}

.uk-range:not(:disabled)::-moz-range-thumb {
  cursor: pointer;
}

/*
 * Track
 * 1. Safari doesn't have a focus state. Using active instead.
 */
/* Webkit */
.uk-range::-webkit-slider-runnable-track {
  height: 3px;
  background: #ebebeb;
  border-radius: 500px;
}

.uk-range:focus::-webkit-slider-runnable-track,
.uk-range:active::-webkit-slider-runnable-track {
  background: #dfdfdf;
}

/* Firefox */
.uk-range::-moz-range-track {
  height: 3px;
  background: #ebebeb;
  border-radius: 500px;
}

.uk-range:focus::-moz-range-track {
  background: #dfdfdf;
}

/*
 * Thumb
 * 1. Reset
 * 2. Style
 */
/* Webkit */
.uk-range::-webkit-slider-thumb {
  /* 1 */
  -webkit-appearance: none;
  margin-top: -7px;
  /* 2 */
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #fff;
  border: 1px solid #cccccc;
}

/* Firefox */
.uk-range::-moz-range-thumb {
  /* 1 */
  border: none;
  /* 2 */
  height: 15px;
  width: 15px;
  margin-top: -7px;
  border-radius: 500px;
  background: #fff;
  border: 1px solid #cccccc;
}

/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers.
 */
.uk-input,
.uk-select,
.uk-textarea,
.uk-radio,
.uk-checkbox {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
}

/*
 * Show the overflow in Edge.
 */
.uk-input {
  overflow: visible;
}

/*
 * Remove the inheritance of text transform in Firefox.
 */
.uk-select {
  text-transform: none;
}

/*
 * 1. Change font properties to `inherit` in all browsers
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-select optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}

/*
 * Remove the default vertical scrollbar in IE 10+.
 */
.uk-textarea {
  overflow: auto;
}

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-input[type=search]::-webkit-search-cancel-button,
.uk-input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
.uk-input[type=number]::-webkit-inner-spin-button,
.uk-input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-input::-moz-placeholder,
.uk-textarea::-moz-placeholder {
  opacity: 1;
}

/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-radio:not(:disabled),
.uk-checkbox:not(:disabled) {
  cursor: pointer;
}

/*
 * Define consistent border, margin, and padding.
 */
.uk-fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Input, select and textarea
 * Allowed: `text`, `password`, `datetime-local`, `date`,  `month`,
            `time`, `week`, `number`, `email`, `url`, `search`, `tel`, `color`
 * Disallowed: `range`, `radio`, `checkbox`, `file`, `submit`, `reset` and `image`
 ========================================================================== */
/*
 * Remove default style in iOS.
 */
.uk-input,
.uk-textarea {
  -webkit-appearance: none;
}

/*
 * 1. Prevent content overflow if a fixed width is used
 * 2. Take the full width
 * 3. Reset default
 * 4. Style
 */
.uk-input,
.uk-select,
.uk-textarea {
  /* 1 */
  max-width: 100%;
  /* 2 */
  width: 100%;
  /* 3 */
  border: 0 none;
  /* 4 */
  padding: 0 10px;
  background: #fff;
  color: #666;
  border: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

/*
 * Single-line
 * 1. Allow any element to look like an `input` or `select` element
 * 2. Make sure line-height is not larger than height
 *    Also needed to center the text vertically
 */
.uk-input,
.uk-select:not([multiple]):not([size]) {
  height: 40px;
  vertical-align: middle;
  /* 1 */
  display: inline-block;
}

/* 2 */
.uk-input:not(input),
.uk-select:not(select) {
  line-height: 38px;
}

/*
 * Multi-line
 */
.uk-select[multiple],
.uk-select[size],
.uk-textarea {
  padding-top: 6px;
  padding-bottom: 6px;
  vertical-align: top;
}

.uk-select[multiple],
.uk-select[size] {
  resize: vertical;
}

/* Focus */
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  outline: none;
  background-color: #fff;
  color: #666;
  border-color: #1e87f0;
}

/* Disabled */
.uk-input:disabled,
.uk-select:disabled,
.uk-textarea:disabled {
  background-color: #f8f8f8;
  color: #999;
  border-color: #e5e5e5;
}

/*
 * Placeholder
 */
.uk-input::placeholder {
  color: #999;
}

.uk-textarea::placeholder {
  color: #999;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Small
 */
.uk-form-small {
  font-size: 0.875rem;
}

/* Single-line */
.uk-form-small:not(textarea):not([multiple]):not([size]) {
  height: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

/* Multi-line */
textarea.uk-form-small,
[multiple].uk-form-small,
[size].uk-form-small {
  padding: 5px 8px;
}

.uk-form-small:not(select):not(input):not(textarea) {
  line-height: 28px;
}

/*
 * Large
 */
.uk-form-large {
  font-size: 1.25rem;
}

/* Single-line */
.uk-form-large:not(textarea):not([multiple]):not([size]) {
  height: 55px;
  padding-left: 12px;
  padding-right: 12px;
}

/* Multi-line */
textarea.uk-form-large,
[multiple].uk-form-large,
[size].uk-form-large {
  padding: 7px 12px;
}

.uk-form-large:not(select):not(input):not(textarea) {
  line-height: 53px;
}

/* Style modifier (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Error
 */
.uk-form-danger,
.uk-form-danger:focus {
  color: #f0506e;
  border-color: #f0506e;
}

/*
 * Success
 */
.uk-form-success,
.uk-form-success:focus {
  color: #32d296;
  border-color: #32d296;
}

/*
 * Blank
 */
.uk-form-blank {
  background: none;
  border-color: transparent;
}

.uk-form-blank:focus {
  border-color: #e5e5e5;
  border-style: solid;
}

/* Width modifiers (`uk-input`, `uk-select` and `uk-textarea`)
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-xsmall {
  width: 50px;
}

select.uk-form-width-xsmall {
  width: 75px;
}

.uk-form-width-small {
  width: 130px;
}

.uk-form-width-medium {
  width: 200px;
}

.uk-form-width-large {
  width: 500px;
}

/* Select
 ========================================================================== */
/*
 * 1. Remove default style. Also works in Firefox
 * 2. Style
 * 3. Set `color` for options in the select dropdown, because the inherited `color` might be too light.
 */
.uk-select:not([multiple]):not([size]) {
  /* 1 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 2 */
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

/* 3 */
.uk-select:not([multiple]):not([size]) option {
  color: #666;
}

/*
 * Disabled
 */
.uk-select:not([multiple]):not([size]):disabled {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* Datalist
 ========================================================================== */
/*
 * 1. Remove default style in Chrome
 */
.uk-input[list] {
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.uk-input[list]:hover,
.uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

/* 1 */
.uk-input[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

/* Radio and checkbox
 ========================================================================== */
/*
 * 1. Style
 * 2. Make box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 * 6. Center icons
 */
.uk-radio,
.uk-checkbox {
  /* 1 */
  display: inline-block;
  height: 16px;
  width: 16px;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* 5 */
  background-color: transparent;
  /* 6 */
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px solid #cccccc;
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
}

.uk-radio {
  border-radius: 50%;
}

/* Focus */
.uk-radio:focus,
.uk-checkbox:focus {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border-color: #1e87f0;
}

/*
 * Checked
 */
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #1e87f0;
  border-color: transparent;
}

/* Focus */
.uk-radio:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: #0e6ecd;
}

/*
 * Icons
 */
.uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23fff%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/*
 * Disabled
 */
.uk-radio:disabled,
.uk-checkbox:disabled {
  background-color: #f8f8f8;
  border-color: #e5e5e5;
}

.uk-radio:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23999%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-checkbox:disabled:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23999%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.uk-checkbox:disabled:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23999%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Legend
 ========================================================================== */
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove padding so people aren't caught out if they zero out fieldsets.
 * 4. Style
 */
.uk-legend {
  /* 1 */
  width: 100%;
  /* 2 */
  color: inherit;
  /* 3 */
  padding: 0;
  /* 4 */
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Custom controls
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 */
.uk-form-custom {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
}

/*
 * 1. Position and resize the form control to always cover its container
 * 2. Required for Firefox for positioning to the left
 * 3. Required for Webkit to make `height` work
 * 4. Hide controle and show cursor
 * 5. Needed for the cursor
 * 6. Clip height caused by 5. Needed for Webkit only
 */
.uk-form-custom select,
.uk-form-custom input[type=file] {
  /* 1 */
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* 2 */
  left: 0;
  /* 3 */
  -webkit-appearance: none;
  /* 4 */
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type=file] {
  /* 5 */
  font-size: 500px;
  /* 6 */
  overflow: hidden;
}

/* Label
 ========================================================================== */
.uk-form-label {
  color: #333;
  font-size: 0.875rem;
}

/* Layout
 ========================================================================== */
/*
 * Stacked
 */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 5px;
}

/*
 * Horizontal
 */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 5px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 7px;
    float: left;
  }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 7px;
  }
}
/* Icons
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set width
 * 3. Center icon vertically and horizontally
 * 4. Style
 */
.uk-form-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 40px;
  /* 3 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 4 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-form-icon:hover {
  color: #666;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-form-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Input padding
 */
.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
  padding-left: 40px !important;
}

/*
 * Position modifier
 */
.uk-form-icon-flip {
  right: 0;
  left: auto;
}

.uk-form-icon-flip ~ .uk-input {
  padding-right: 40px !important;
}

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 0.875rem;
  /* 8 */
  line-height: 38px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color, border-color;
}

.uk-button:not(:disabled) {
  cursor: pointer;
}

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none;
}

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: transparent;
  color: #333;
  border: 1px solid #e5e5e5;
}

/* Hover */
.uk-button-default:hover {
  background-color: transparent;
  color: #333;
  border-color: #b2b2b2;
}

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: transparent;
  color: #333;
  border-color: #999999;
}

/*
 * Primary
 */
.uk-button-primary {
  background-color: #1e87f0;
  color: #fff;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-primary:hover {
  background-color: #0f7ae5;
  color: #fff;
}

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6ecd;
  color: #fff;
}

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-secondary:hover {
  background-color: #151515;
  color: #fff;
}

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090909;
  color: #fff;
}

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #fff;
  border: 1px solid transparent;
}

/* Hover */
.uk-button-danger:hover {
  background-color: #ee395b;
  color: #fff;
}

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff;
}

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: transparent;
  color: #999;
  border-color: #e5e5e5;
}

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 15px;
  line-height: 28px;
  font-size: 0.875rem;
}

.uk-button-large {
  padding: 0 40px;
  line-height: 53px;
  font-size: 0.875rem;
}

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333;
  position: relative;
}
.uk-button-text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  border-bottom: 1px solid currentColor;
  transition: right 0.3s ease-out;
}

/* Hover */
.uk-button-text:hover {
  color: #333;
}
.uk-button-text:hover::before {
  right: 0;
}

/* Disabled */
.uk-button-text:disabled {
  color: #999;
}
.uk-button-text:disabled::before {
  display: none;
}

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333;
}

/* Hover */
.uk-button-link:hover {
  color: #999;
  text-decoration: none;
}

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none;
}

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative;
}

/* Group
 ========================================================================== */
/*
 * Collapse border
 */
.uk-button-group > .uk-button:nth-child(n+2),
.uk-button-group > div:nth-child(n+2) .uk-button {
  margin-left: -1px;
}

/*
 * Create position context to superimpose the successor elements border
 * Known issue: If you use an `a` element as button and an icon inside,
 * the active state will not work if you click the icon inside the button
 * Workaround: Just use a `button` or `input` element as button
 */
.uk-button-group .uk-button:hover,
.uk-button-group .uk-button:focus,
.uk-button-group .uk-button:active,
.uk-button-group .uk-button.uk-active {
  position: relative;
  z-index: 1;
}

/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Add the correct vertical alignment in all browsers.
 * 2. Behave like a block element.
 * 3. Remove borders in Firefox.
 * 4. Remove default style in Chrome, Safari and Edge.
 * 5. Style
 */
.uk-progress {
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  display: block;
  width: 100%;
  /* 3 */
  border: 0;
  /* 4 */
  background-color: #f8f8f8;
  /* 5 */
  margin-bottom: 20px;
  height: 15px;
  border-radius: 500px;
  overflow: hidden;
}

/* Add margin if adjacent element */
* + .uk-progress {
  margin-top: 20px;
}

/*
 * Show background color set on `uk-progress` in Chrome, Safari and Edge.
 */
.uk-progress::-webkit-progress-bar {
  background-color: transparent;
}

/*
 * Progress Bar
 * 1. Transitions don't work on `::-moz-progress-bar` pseudo element in Firefox yet.
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=662351
 */
.uk-progress::-webkit-progress-value {
  background-color: #1e87f0;
  transition: width 0.6s ease;
}

.uk-progress::-moz-progress-bar {
  background-color: #1e87f0;
  /* 1 */
  transition: width 0.6s ease;
}

/* ========================================================================
   Component: Section
 ========================================================================== */
/*
 * 1. Make it work with `100vh` and height in general
 */
.uk-section {
  display: flow-root;
  box-sizing: border-box; /* 1 */
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-section > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-section-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-section-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*
 * Large
 */
.uk-section-large {
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-section-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-section-xlarge {
    padding-top: 210px;
    padding-bottom: 210px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-section-default {
  background: #fff;
}

/*
 * Muted
 */
.uk-section-muted {
  background: #f8f8f8;
}

/*
 * Primary
 */
.uk-section-primary {
  background: #1e87f0;
}

/*
 * Secondary
 */
.uk-section-secondary {
  background: #222;
}

/* Overlap modifier
 ========================================================================== */
/*
 * Reserved modifier to make a section overlap another section with an border image
 * Implemented by the theme
 */
/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0;
}

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0;
}

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px;
}

.uk-container-small {
  max-width: 900px;
}

.uk-container-large {
  max-width: 1400px;
}

.uk-container-xlarge {
  max-width: 1600px;
}

.uk-container-expand {
  max-width: none;
}

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0;
}

.uk-container-expand-right {
  margin-right: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px);
  }
  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
.uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-xsmall,
.uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-small,
.uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-large,
.uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1400px / 2) - 40px);
  }
  .uk-container-expand-left.uk-container-xlarge,
.uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px);
  }
}
/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px);
}

.uk-container-item-padding-remove-left {
  margin-left: -15px;
}

.uk-container-item-padding-remove-right {
  margin-right: -15px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 30px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -30px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
    width: calc(100% + 40px);
  }
  .uk-container-item-padding-remove-left {
    margin-left: -40px;
  }
  .uk-container-item-padding-remove-right {
    margin-right: -40px;
  }
}
/* ========================================================================
   Component: Tile
 ========================================================================== */
.uk-tile {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-tile {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-tile > :last-child {
  margin-bottom: 0;
}

/* Size modifiers
 ========================================================================== */
/*
 * XSmall
 */
.uk-tile-xsmall {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
 * Small
 */
.uk-tile-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

/*
 * Large
 */
.uk-tile-large {
  padding-top: 70px;
  padding-bottom: 70px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-large {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
/*
 * XLarge
 */
.uk-tile-xlarge {
  padding-top: 140px;
  padding-bottom: 140px;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-tile-xlarge {
    padding-top: 210px;
    padding-bottom: 210px;
  }
}
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-tile-default {
  background-color: #fff;
}

/*
 * Muted
 */
.uk-tile-muted {
  background-color: #f8f8f8;
}

/*
 * Primary
 */
.uk-tile-primary {
  background-color: #1e87f0;
}

/*
 * Secondary
 */
.uk-tile-secondary {
  background-color: #222;
}

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box;
  transition: box-shadow 0.1s ease-in-out;
}

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 30px 30px;
}

.uk-card-header {
  display: flow-root;
  padding: 15px 30px;
}

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px;
  }
  .uk-card-header {
    padding: 20px 40px;
  }
  .uk-card-footer {
    padding: 20px 40px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0;
}

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  border-radius: 2px;
  text-transform: uppercase;
}

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0;
}

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #fff;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #fff;
  color: #666;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-card-default .uk-card-title {
  color: #333;
}

.uk-card-default.uk-card-hover:hover {
  background-color: #fff;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-card-default .uk-card-header {
  border-bottom: 1px solid #e5e5e5;
}

.uk-card-default .uk-card-footer {
  border-top: 1px solid #e5e5e5;
}

/*
 * Primary
 */
.uk-card-primary {
  background-color: #1e87f0;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-card-primary .uk-card-title {
  color: #fff;
}

.uk-card-primary.uk-card-hover:hover {
  background-color: #1e87f0;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #222;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-card-secondary .uk-card-title {
  color: #fff;
}

.uk-card-secondary.uk-card-hover:hover {
  background-color: #222;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px;
}

.uk-card-small .uk-card-header {
  padding: 13px 20px;
}

.uk-card-small .uk-card-footer {
  padding: 13px 20px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
.uk-card-large .uk-card-body {
    padding: 70px 70px;
  }
  .uk-card-large .uk-card-header {
    padding: 35px 70px;
  }
  .uk-card-large .uk-card-footer {
    padding: 35px 70px;
  }
}
/*
 * Default
 */
.uk-card-body > .uk-nav-default {
  margin-left: -30px;
  margin-right: -30px;
}

.uk-card-body > .uk-nav-default:only-child {
  margin-top: -15px;
  margin-bottom: -15px;
}

.uk-card-body > .uk-nav-default > li > a,
.uk-card-body > .uk-nav-default .uk-nav-header,
.uk-card-body > .uk-nav-default .uk-nav-divider {
  padding-left: 30px;
  padding-right: 30px;
}

.uk-card-body > .uk-nav-default .uk-nav-sub {
  padding-left: 45px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body > .uk-nav-default {
    margin-left: -40px;
    margin-right: -40px;
  }
  .uk-card-body > .uk-nav-default:only-child {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .uk-card-body > .uk-nav-default > li > a,
.uk-card-body > .uk-nav-default .uk-nav-header,
.uk-card-body > .uk-nav-default .uk-nav-divider {
    padding-left: 40px;
    padding-right: 40px;
  }
  .uk-card-body > .uk-nav-default .uk-nav-sub {
    padding-left: 55px;
  }
}
/*
 * Small
 */
.uk-card-small > .uk-nav-default {
  margin-left: -20px;
  margin-right: -20px;
}

.uk-card-small > .uk-nav-default:only-child {
  margin-top: -5px;
  margin-bottom: -5px;
}

.uk-card-small > .uk-nav-default > li > a,
.uk-card-small > .uk-nav-default .uk-nav-header,
.uk-card-small > .uk-nav-default .uk-nav-divider {
  padding-left: 20px;
  padding-right: 20px;
}

.uk-card-small > .uk-nav-default .uk-nav-sub {
  padding-left: 35px;
}

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large > .uk-nav-default {
    margin: 0;
  }
  .uk-card-large > .uk-nav-default:only-child {
    margin: 0;
  }
  .uk-card-large > .uk-nav-default > li > a,
.uk-card-large > .uk-nav-default .uk-nav-header,
.uk-card-large > .uk-nav-default .uk-nav-divider {
    padding-left: 0;
    padding-right: 0;
  }
  .uk-card-large > .uk-nav-default .uk-nav-sub {
    padding-left: 15px;
  }
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999;
  transition: 0.1s ease-in-out;
  transition-property: color, opacity;
}

/* Hover */
.uk-close:hover {
  color: #666;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999;
  transition: color 0.1s ease-in-out;
}

/* Hover */
.uk-totop:hover {
  color: #666;
}

/* OnClick */
.uk-totop:active {
  color: #333;
}

/* ========================================================================
   Component: Marker
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-marker {
  padding: 5px;
  background: #222;
  color: #fff;
  border-radius: 500px;
}

/* Hover */
.uk-marker:hover {
  color: #fff;
}

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666;
}

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px;
  color: inherit;
  opacity: 0.4;
}

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
.uk-alert-close:hover {
  color: inherit;
  opacity: 0.8;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0;
}

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296;
}

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a;
}

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e;
}

/*
 * Content
 */
.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
  color: inherit;
}

.uk-alert a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

.uk-alert a:not([class]):hover {
  color: inherit;
  text-decoration: underline;
}

/* ========================================================================
   Component: Placeholder
 ========================================================================== */
.uk-placeholder {
  margin-bottom: 20px;
  padding: 30px 30px;
  background: transparent;
  border: 1px dashed #e5e5e5;
}

/* Add margin if adjacent element */
* + .uk-placeholder {
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-placeholder > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff !important;
  font-size: 11px;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
}

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none;
}

/* ========================================================================
   Component: Label
 ========================================================================== */
.uk-label {
  display: inline-block;
  padding: 0 10px;
  background: #1e87f0;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 2px;
  text-transform: uppercase;
}

/* Color modifiers
 ========================================================================== */
/*
 * Success
 */
.uk-label-success {
  background-color: #32d296;
  color: #fff;
}

/*
 * Warning
 */
.uk-label-warning {
  background-color: #faa05a;
  color: #fff;
}

/*
 * Danger
 */
.uk-label-danger {
  background-color: #f0506e;
  color: #fff;
}

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px;
}

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0;
}

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8);
}

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8);
}

/* ========================================================================
   Component: Article
 ========================================================================== */
.uk-article {
  display: flow-root;
}

/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0;
}

/* Adjacent sibling
 ========================================================================== */
.uk-article + .uk-article {
  margin-top: 70px;
}

/* Title
 ========================================================================== */
.uk-article-title {
  font-size: 2.23125rem;
  line-height: 1.2;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-article-title {
    font-size: 2.625rem;
  }
}
/* Meta
 ========================================================================== */
.uk-article-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}
.uk-article-meta a {
  color: #999;
}
.uk-article-meta a:hover {
  color: #666;
  text-decoration: none;
}

/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sections
 ========================================================================== */
.uk-comment-body {
  display: flow-root;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.uk-comment-header {
  display: flow-root;
  margin-bottom: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child,
.uk-comment-header > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-comment-title {
  font-size: 1.25rem;
  line-height: 1.4;
}

/* Meta
 ========================================================================== */
.uk-comment-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}

/* Avatar
 ========================================================================== */
/* List
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none;
}

/* Adjacent siblings */
.uk-comment-list > :nth-child(n+2) {
  margin-top: 70px;
}

/*
 * Sublists
 * Note: General sibling selector allows reply block between comment and sublist
 */
.uk-comment-list .uk-comment ~ ul {
  margin: 70px 0 0 0;
  padding-left: 30px;
  list-style: none;
}

/* Tablet and bigger */
@media (min-width: 960px) {
  .uk-comment-list .uk-comment ~ ul {
    padding-left: 100px;
  }
}
/* Adjacent siblings */
.uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 70px;
}

/* Style modifier
 ========================================================================== */
.uk-comment-primary {
  padding: 30px;
  background-color: #f8f8f8;
}

/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Reset `form`
 */
.uk-search {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  margin: 0;
}

/* Input
 ========================================================================== */
/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and Safari on OS X.
 */
.uk-search-input::-webkit-search-cancel-button,
.uk-search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-input::-moz-placeholder {
  opacity: 1;
}

/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Change font properties to `inherit` in all browsers
 * 5. Show the overflow in Edge.
 * 6. Remove default style in iOS.
 * 7. Vertical alignment
 * 8. Take the full container width
 * 9. Style
 */
.uk-search-input {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  /* 5 */
  overflow: visible;
  /* 6 */
  -webkit-appearance: none;
  /* 7 */
  vertical-align: middle;
  /* 8 */
  width: 100%;
  /* 9 */
  border: none;
  color: #666;
}

.uk-search-input:focus {
  outline: none;
}

/* Placeholder */
.uk-search-input::placeholder {
  color: #999;
}

/* Icon (Adopts `uk-icon`)
 ========================================================================== */
/*
 * Position above input
 * 1. Set position
 * 2. Center icon vertically and horizontally
 * 3. Style
 */
.uk-search .uk-search-icon {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: #999;
}

/*
 * Required for `a`.
 */
.uk-search .uk-search-icon:hover {
  color: #999;
}

/*
 * Make `input` element clickable through icon, e.g. if it's a `span`
 */
.uk-search .uk-search-icon:not(a):not(button):not(input) {
  pointer-events: none;
}

/*
 * Position modifier
 */
.uk-search .uk-search-icon-flip {
  right: 0;
  left: auto;
}

/* Default modifier
 ========================================================================== */
.uk-search-default {
  width: 240px;
}

/*
 * Input
 */
.uk-search-default .uk-search-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: transparent;
  border: 1px solid #e5e5e5;
}

/* Focus */
.uk-search-default .uk-search-input:focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #1e87f0;
}

/*
 * Icon
 */
.uk-search-default .uk-search-icon {
  width: 40px;
}

.uk-search-default .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-default .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Navbar modifier
 ========================================================================== */
.uk-search-navbar {
  width: 400px;
}

/*
 * Input
 */
.uk-search-navbar .uk-search-input {
  height: 40px;
  background: transparent;
  font-size: 1.5rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-navbar .uk-search-icon {
  width: 40px;
}

.uk-search-navbar .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 40px;
}

.uk-search-navbar .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 40px;
}

/* Large modifier
 ========================================================================== */
.uk-search-large {
  width: 500px;
}

/*
 * Input
 */
.uk-search-large .uk-search-input {
  height: 80px;
  background: transparent;
  font-size: 2.625rem;
}

/* Focus */
/*
 * Icon
 */
.uk-search-large .uk-search-icon {
  width: 80px;
}

.uk-search-large .uk-search-icon:not(.uk-search-icon-flip) ~ .uk-search-input {
  padding-left: 80px;
}

.uk-search-large .uk-search-icon-flip ~ .uk-search-input {
  padding-right: 80px;
}

/* Toggle
 ========================================================================== */
.uk-search-toggle {
  color: #999;
}

/* Hover */
.uk-search-toggle:hover {
  color: #666;
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none;
}

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px;
}

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333;
  overflow: hidden;
}
.uk-accordion-title::before {
  content: "";
  width: 1.4em;
  height: 1.4em;
  margin-left: 10px;
  float: right;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.uk-open > .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

/* Hover */
.uk-accordion-title:hover {
  color: #666;
  text-decoration: none;
}

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px;
}

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Drop
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */
.uk-drop {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 20px;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  width: 300px;
}

/* Show */
.uk-drop.uk-open {
  display: block;
}

/* Grid modifiers
 ========================================================================== */
.uk-drop-stack .uk-drop-grid > * {
  width: 100% !important;
}

/* Parent icon
 ========================================================================== */
.uk-drop-parent-icon {
  margin-left: 0.25em;
  transition: transform 0.3s ease-out;
}

[aria-expanded=true] > .uk-drop-parent-icon {
  transform: rotateX(180deg);
}

/* ========================================================================
   Component: Dropbar
 ========================================================================== */
/*
 * Adopts `uk-drop`
 * 1. Reset drop
 * 2. Style
 */
.uk-dropbar {
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 0;
  /* 1 */
  width: auto;
  /* 2 */
  padding: 25px 15px 25px 15px;
  background: #fff;
  color: #666;
}

/*
 * Remove margin from the last-child
 */
.uk-dropbar > :last-child {
  margin-bottom: 0;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-dropbar {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-dropbar {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.uk-dropbar :focus-visible {
  outline-color: #ff1784 !important;
}

/* Size modifier
 ========================================================================== */
.uk-dropbar-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Direction modifier
 ========================================================================== */
.uk-dropbar-top {
  box-shadow: 0 12px 7px -6px rgba(0, 0, 0, 0.05);
}

.uk-dropbar-bottom {
  box-shadow: 0 -12px 7px -6px rgba(0, 0, 0, 0.05);
}

.uk-dropbar-left {
  box-shadow: 12px 0 7px -6px rgba(0, 0, 0, 0.05);
}

.uk-dropbar-right {
  box-shadow: -12px 0 7px -6px rgba(0, 0, 0, 0.05);
}

/* ========================================================================
   Component: Dropnav
 ========================================================================== */
/*
 * 1. Position
 * 2. Reset dropbar
 * 3. Width
 */
.uk-dropnav-dropbar {
  /* 1 */
  position: absolute;
  z-index: 980;
  /* 2 */
  padding: 0;
  /* 3 */
  left: 0;
  right: 0;
}

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px;
  }
}
/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1;
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden;
}

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform;
}

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0);
}

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px;
}

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none;
}

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0);
}

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 20px 20px;
}

.uk-modal-header {
  display: flow-root;
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.uk-modal-footer {
  display: flow-root;
  padding: 10px 20px;
  background: #fff;
  border-top: 1px solid #e5e5e5;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal-body {
    padding: 30px 30px;
  }
  .uk-modal-header {
    padding: 15px 30px;
  }
  .uk-modal-footer {
    padding: 15px 30px;
  }
}
/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.3;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*=uk-modal-close-] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px;
}

/*
 * Remove margin from adjacent element
 */
[class*=uk-modal-close-]:first-child + * {
  margin-top: 0;
}

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white;
}

.uk-modal-close-outside:hover {
  color: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%);
  }
}
/*
 * Full
 */
.uk-modal-close-full {
  top: 0;
  right: 0;
  padding: 10px;
  background: #fff;
}

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal-close-full {
    padding: 20px;
  }
}
/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y;
}

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity;
}

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none;
}

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent;
}

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden;
}

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px;
}

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y;
}

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none;
}

.uk-slider-items.uk-grid {
  flex-wrap: nowrap;
}

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative;
}

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. Create stacking context already when not sticky to have the same context
*     for position set to `sticky` and `relative`
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 980;
  /* 3 */
  box-sizing: border-box;
}

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*
 * Faster animations
 */
.uk-sticky[class*=uk-animation-] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none;
}

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto;
}

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -270px;
  /* 2 */
  box-sizing: border-box;
  width: 270px;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 30px 30px;
  }
}
/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px;
  }
}
/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0;
}

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0;
}

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out;
}

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right;
}

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out;
}

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0;
}

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0;
}

.uk-open > .uk-offcanvas-reveal {
  width: 270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px;
  }
}
/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 5px;
  right: 5px;
  padding: 5px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-close {
    top: 10px;
    right: 10px;
  }
}
/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0;
}

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none;
}

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear;
}

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1;
}

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 * 1. `clip` is needed for `position: sticky` elements to keep their position
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden;
  /* 1 */
  overflow-x: clip;
}

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%;
}

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 270px;
}

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -270px;
}

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px;
  }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px;
  }
}
/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none;
}

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Leader
 ========================================================================== */
.uk-leader {
  overflow: hidden;
}

/*
 * 1. Place element in text flow
 * 2. Never break into a new line
 * 3. Get a string back with as many repeating characters to fill the container
 * 4. Prevent wrapping. Overflowing characters will be clipped by the container
 */
.uk-leader-fill::after {
  /* 1 */
  display: inline-block;
  margin-left: 15px;
  /* 2 */
  width: 0;
  /* 3 */
  content: attr(data-fill);
  /* 4 */
  white-space: nowrap;
}

/*
 * Hide if media does not match
 */
.uk-leader-fill.uk-leader-hide::after {
  display: none;
}

/*
 * Pass fill character to JS
 */
:root {
  --uk-leader-fill-content: .;
}

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px;
}

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px;
}

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px;
}

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px;
}

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}
/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer;
}

* + .uk-notification-message {
  margin-top: 10px;
}

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px;
}

.uk-notification-message:hover .uk-notification-close {
  display: block;
}

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0;
}

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296;
}

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a;
}

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e;
}

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
}

/* Show */
.uk-tooltip.uk-active {
  display: block;
}

/* ========================================================================
   Component: Sortable
 ========================================================================== */
.uk-sortable {
  position: relative;
}

/*
 * Remove margin from the last-child
 */
.uk-sortable > :last-child {
  margin-bottom: 0;
}

/* Drag
 ========================================================================== */
.uk-sortable-drag {
  position: fixed !important;
  z-index: 1050 !important;
  pointer-events: none;
}

/* Placeholder
 ========================================================================== */
.uk-sortable-placeholder {
  opacity: 0;
  pointer-events: none;
}

/* Empty modifier
 ========================================================================== */
.uk-sortable-empty {
  min-height: 50px;
}

/* Handle
 ========================================================================== */
/* Hover */
.uk-sortable-handle:hover {
  cursor: move;
}

/* ========================================================================
   Component: Countdown
 ========================================================================== */
/* Item
 ========================================================================== */
/* Number
 ========================================================================== */
/*
 * 1. Make numbers all of the same size to prevent jumping. Must be supported by the font.
 * 2. Style
 */
.uk-countdown-number {
  /* 1 */
  font-variant-numeric: tabular-nums;
  /* 2 */
  font-size: 2rem;
  line-height: 0.8;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-number {
    font-size: 4rem;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-number {
    font-size: 6rem;
  }
}
/* Separator
 ========================================================================== */
.uk-countdown-separator {
  font-size: 1rem;
  line-height: 1.6;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-countdown-separator {
    font-size: 2rem;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-countdown-separator {
    font-size: 3rem;
  }
}
/* Label
 ========================================================================== */
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0;
}

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0;
}

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px;
}

.uk-grid > * {
  padding-left: 30px;
}

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px;
  }
  .uk-grid > * {
    padding-left: 40px;
  }
  /* Vertical */
  .uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
    margin-top: 40px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px;
}

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px;
}

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px;
}

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px;
}

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px;
}

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px;
}

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
.uk-grid-column-large {
    margin-left: -70px;
  }
  .uk-grid-large > *,
.uk-grid-column-large > * {
    padding-left: 70px;
  }
  /* Vertical */
  .uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
    margin-top: 70px;
  }
}
/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0;
}

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0;
}

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0;
}

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
}

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px;
}

.uk-grid-divider > * {
  padding-left: 60px;
}

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px;
}

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px;
  }
  .uk-grid-divider > * {
    padding-left: 80px;
  }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px;
  }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px;
  }
}
/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px;
}

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px;
}

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px;
}

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px;
}

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px;
}

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
}

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px;
}

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px;
}

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px;
}

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px;
}

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px;
}

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px;
}

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
}

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px;
}

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px;
}

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px;
}

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px;
}

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px;
}

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px;
}

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
}

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
    margin-left: -140px;
  }
  .uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px;
  }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px;
  }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px;
  }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px;
  }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
  }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px;
  }
}
/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
}

.uk-grid-match > * > :not([class*=uk-width]),
.uk-grid-item-match > :not([class*=uk-width]) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto;
}

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none;
}

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0;
}

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px;
}

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px;
}

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0;
}

/* Parent icon
 ========================================================================== */
.uk-nav-parent-icon {
  margin-left: auto;
  transition: transform 0.3s ease-out;
}

.uk-nav > li.uk-open > a .uk-nav-parent-icon {
  transform: rotateX(180deg);
}

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.uk-nav-header:not(:first-child) {
  margin-top: 20px;
}

/* Divider
 ========================================================================== */
.uk-nav .uk-nav-divider {
  margin: 5px 0;
}

/* Default modifier
 ========================================================================== */
.uk-nav-default {
  font-size: 0.875rem;
  line-height: 1.5;
}

/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999;
}

/* Hover */
.uk-nav-default > li > a:hover {
  color: #666;
}

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333;
}

/*
 * Subtitle
 */
.uk-nav-default .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-nav-default .uk-nav-sub a {
  color: #999;
}

.uk-nav-default .uk-nav-sub a:hover {
  color: #666;
}

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Primary modifier
 ========================================================================== */
.uk-nav-primary {
  font-size: 1.5rem;
  line-height: 1.5;
}

/*
 * Items
 */
.uk-nav-primary > li > a {
  color: #999;
}

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #666;
}

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333;
}

/*
 * Subtitle
 */
.uk-nav-primary .uk-nav-subtitle {
  font-size: 1.25rem;
}

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub {
  font-size: 1.25rem;
  line-height: 1.5;
}

.uk-nav-primary .uk-nav-sub a {
  color: #999;
}

.uk-nav-primary .uk-nav-sub a:hover {
  color: #666;
}

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Secondary modifier
 ========================================================================== */
.uk-nav-secondary {
  font-size: 16px;
  line-height: 1.5;
}
.uk-nav-secondary > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 0;
}

/*
 * Items
 */
.uk-nav-secondary > li > a {
  color: #333;
  padding: 10px 10px;
}

/* Hover */
.uk-nav-secondary > li > a:hover {
  color: #333;
  background-color: #f8f8f8;
}

/* Active */
.uk-nav-secondary > li.uk-active > a {
  color: #333;
  background-color: #f8f8f8;
}

/*
 * Subtitle
 */
.uk-nav-secondary .uk-nav-subtitle {
  font-size: 0.875rem;
  color: #999;
}

/* Hover */
.uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: #666;
}

/* Active */
.uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #333;
}

/*
 * Header
 */
.uk-nav-secondary .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-nav-secondary .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-nav-secondary .uk-nav-sub {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-nav-secondary .uk-nav-sub a {
  color: #999;
}

.uk-nav-secondary .uk-nav-sub a:hover {
  color: #666;
}

.uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center;
}

/* 2 */
.uk-nav-center li > a {
  justify-content: center;
}

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0;
}

/* Parent icon  */
.uk-nav-center .uk-nav-parent-icon {
  margin-left: 0.25em;
}

/* Style modifier
 ========================================================================== */
/*
 * Divider
 * Naming is in plural to prevent conflicts with divider sub object.
 */
.uk-nav.uk-nav-divider > :not(.uk-nav-header, .uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #e5e5e5;
}

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative;
}

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8;
}

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 */
.uk-navbar-left,
.uk-navbar-right,
[class*=uk-navbar-center] {
  display: flex;
  gap: 15px;
  /* 1 */
  align-items: center;
}

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto;
}

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative;
}

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990;
}

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0;
}

.uk-navbar-center-left {
  right: calc(100% + 15px);
}

.uk-navbar-center-right {
  left: calc(100% + 15px);
}

[class*=uk-navbar-center-] {
  width: max-content;
  box-sizing: border-box;
}

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  gap: 15px;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap;
}

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: 80px;
  /* 4 */
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 5 */
  text-decoration: none;
}

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  padding: 0 0;
  color: #999;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #666;
}

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333;
}

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333;
}

/* Parent icon modifier
 ========================================================================== */
.uk-navbar-parent-icon {
  margin-left: 4px;
  transition: transform 0.3s ease-out;
}

.uk-navbar-nav > li > a[aria-expanded=true] .uk-navbar-parent-icon {
  transform: rotateX(180deg);
}

/* Item
 ========================================================================== */
.uk-navbar-item {
  padding: 0 0;
  color: #666;
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0;
}

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  padding: 0 0;
  color: #999;
}

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded=true] {
  color: #666;
  text-decoration: none;
}

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem;
}

/* Justify modifier
 ========================================================================== */
.uk-navbar-justify .uk-navbar-left,
.uk-navbar-justify .uk-navbar-right,
.uk-navbar-justify .uk-navbar-nav,
.uk-navbar-justify .uk-navbar-nav > li,
.uk-navbar-justify .uk-navbar-item,
.uk-navbar-justify .uk-navbar-toggle {
  flex-grow: 1;
}

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-drop`
 * 1. Set a default width
 * 2. Style
 */
.uk-navbar-dropdown {
  --uk-position-offset: 15px;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  /* 1 */
  width: 200px;
  /* 2 */
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

/*
 * Remove margin from the last-child
 */
.uk-navbar-dropdown > :last-child {
  margin-bottom: 0;
}

.uk-navbar-dropdown :focus-visible {
  outline-color: #ff1784 !important;
}

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown .uk-drop-grid {
  margin-left: -30px;
}

.uk-navbar-dropdown .uk-drop-grid > * {
  padding-left: 30px;
}

/* Gutter Vertical */
.uk-navbar-dropdown .uk-drop-grid > .uk-grid-margin {
  margin-top: 30px;
}

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-drop-stack) {
  width: 400px;
}

.uk-navbar-dropdown-width-3:not(.uk-drop-stack) {
  width: 600px;
}

.uk-navbar-dropdown-width-4:not(.uk-drop-stack) {
  width: 800px;
}

.uk-navbar-dropdown-width-5:not(.uk-drop-stack) {
  width: 1000px;
}

/*
 * Size modifier
 */
.uk-navbar-dropdown-large {
  --uk-position-shift-offset: 0;
  padding: 40px;
}

/*
 * Dropbar modifier
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-navbar-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 25px 0 25px 0;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  box-shadow: none;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 40px;
  }
}
.uk-navbar-dropdown-dropbar-large {
  --uk-position-shift-offset: 0;
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-navbar-dropdown-nav {
  font-size: 0.875rem;
}

/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999;
}

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #666;
}

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333;
}

/*
 * Subtitle
 */
.uk-navbar-dropdown-nav .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #666;
}

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333;
}

/* Dropbar
 ========================================================================== */
/*
 * Adopts `uk-dropnav-dropbar`
 */
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-left,
.uk-navbar-right,
[class*=uk-navbar-center] {
    gap: 30px;
  }
  .uk-navbar-center-left {
    right: calc(100% + 30px);
  }
  .uk-navbar-center-right {
    left: calc(100% + 30px);
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-nav {
    gap: 30px;
  }
}
/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #999;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/* Hover */
.uk-subnav > * > a:hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-subnav > .uk-active > a {
  color: #333;
}

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px;
}

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center;
}

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent;
}

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e5e5e5;
}

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999;
}

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: #f8f8f8;
  color: #666;
}

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #666;
}

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #1e87f0;
  color: #fff;
}

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999;
}

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none;
}

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents;
}

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.875rem;
  color: #999;
}

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #666;
  text-decoration: none;
}

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #666;
}

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "/";
  display: inline-block;
  /* 1 */
  margin: 0 20px 0 calc(20px - 4px);
  /* 2 */
  font-size: 0.875rem;
  color: #999;
}

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: 0;
  /* 3 */
  padding: 0;
  list-style: none;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  padding: 5px 10px;
  color: #999;
  transition: color 0.1s ease-in-out;
}

/* Hover */
.uk-pagination > * > :hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-pagination > .uk-active > * {
  color: #666;
}

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999;
}

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none;
  position: relative;
}
.uk-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  border-bottom: 1px solid #e5e5e5;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999;
  border-bottom: 1px solid transparent;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: color 0.1s ease-in-out;
}

/* Hover */
.uk-tab > * > a:hover {
  color: #666;
  text-decoration: none;
}

/* Active */
.uk-tab > .uk-active > a {
  color: #333;
  border-color: #1e87f0;
}

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999;
}

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
.uk-tab-bottom::before {
  top: 0;
  bottom: auto;
}

.uk-tab-bottom > * > a {
  border-top: 1px solid transparent;
  border-bottom: none;
}

/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0;
}

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0;
}

.uk-tab-left::before {
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-left: 1px solid #e5e5e5;
  border-bottom: none;
}

.uk-tab-right::before {
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
  border-left: 1px solid #e5e5e5;
  border-bottom: none;
}

.uk-tab-left > * > a {
  justify-content: left;
  border-right: 1px solid transparent;
  border-bottom: none;
}

.uk-tab-right > * > a {
  justify-content: left;
  border-left: 1px solid transparent;
  border-bottom: none;
}

.uk-tab .uk-dropdown {
  margin-left: 30px;
}

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5);
  transition: color 0.1s ease-in-out;
}

/* Hover */
.uk-slidenav:hover {
  color: rgba(102, 102, 102, 0.9);
}

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5);
}

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px;
}

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex;
}

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px;
}

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px;
}

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid rgba(102, 102, 102, 0.4);
  transition: 0.2s ease-in-out;
  transition-property: background-color, border-color;
}

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(102, 102, 102, 0.6);
  border-color: transparent;
}

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2);
  border-color: transparent;
}

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6);
  border-color: transparent;
}

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px;
}

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px;
}

/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-thumbnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -15px;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-thumbnav > * {
  /* 1 */
  padding-left: 15px;
}

/* Items
 ========================================================================== */
/*
 * Items
 */
.uk-thumbnav > * > * {
  display: inline-block;
  position: relative;
}
.uk-thumbnav > * > *::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4));
  transition: opacity 0.1s ease-in-out;
}

/* Hover */
.uk-thumbnav > * > :hover::after {
  opacity: 0;
}

/* Active */
.uk-thumbnav > .uk-active > *::after {
  opacity: 0;
}

/* Modifier: 'uk-thumbnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-thumbnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -15px;
}

/* 2 */
.uk-thumbnav-vertical > * {
  padding-left: 0;
  padding-top: 15px;
}

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px;
}

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px;
}

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */
.uk-iconnav > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  line-height: 0;
  /* 4 */
  color: #999;
  /* 5 */
  text-decoration: none;
  font-size: 0.875rem;
  transition: 0.1s ease-in-out;
  transition-property: color, background-color;
}

/* Hover */
.uk-iconnav > * > a:hover {
  color: #666;
}

/* Active */
.uk-iconnav > .uk-active > a {
  color: #666;
}

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px;
}

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px;
}

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * Adopts `uk-drop`
 * 1. Reset drop and let text expand the width instead of wrapping
 * 2. Set a default width
 * 3. Style
 */
.uk-dropdown {
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 15px;
  /* 1 */
  width: auto;
  /* 2 */
  min-width: 200px;
  /* 3 */
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

/*
 * Remove margin from the last-child
 */
.uk-dropdown > :last-child {
  margin-bottom: 0;
}

.uk-dropdown :focus-visible {
  outline-color: #ff1784 !important;
}

/* Size modifier
 ========================================================================== */
.uk-dropdown-large {
  padding: 40px;
}

/* Dropbar modifier
 ========================================================================== */
/*
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 5px 0 25px 0;
  --uk-position-viewport-offset: 15px;
  box-shadow: none;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-dropdown-dropbar {
    --uk-position-viewport-offset: 30px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-dropdown-dropbar {
    --uk-position-viewport-offset: 40px;
  }
}
.uk-dropdown-dropbar-large {
  padding-top: 40px;
  padding-bottom: 40px;
}

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
.uk-dropdown-nav {
  font-size: 0.875rem;
}

/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999;
}

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #666;
}

/*
 * Subtitle
 */
.uk-dropdown-nav .uk-nav-subtitle {
  font-size: 12px;
}

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333;
}

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5;
}

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999;
}

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666;
}

/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom;
}

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1;
}

/*
 * Focus
 */
.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7);
}

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden;
}

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity;
}

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh;
}

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto;
}

.uk-lightbox-items > .uk-active {
  display: flex;
}

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
}

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7);
}

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7);
}

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff;
}

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Hover */
.uk-lightbox-button:hover {
  color: #fff;
}

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none;
}

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*=uk-animation-] {
  animation: 0.5s ease-out both;
}

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up;
}

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke);
}

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*=uk-animation-] {
  animation-name: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes uk-scale-down {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*=uk-child-width] > * {
  box-sizing: border-box;
  width: 100%;
}

.uk-child-width-1-2 > * {
  width: 50%;
}

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001);
}

.uk-child-width-1-4 > * {
  width: 25%;
}

.uk-child-width-1-5 > * {
  width: 20%;
}

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001);
}

.uk-child-width-auto > * {
  width: auto;
}

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*=uk-width]) {
  flex: 1;
  /* 1 */
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%;
  }
  .uk-child-width-1-2\@s > * {
    width: 50%;
  }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@s > * {
    width: 25%;
  }
  .uk-child-width-1-5\@s > * {
    width: 20%;
  }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@s > * {
    width: auto;
  }
  .uk-child-width-expand\@s > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%;
  }
  .uk-child-width-1-2\@m > * {
    width: 50%;
  }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@m > * {
    width: 25%;
  }
  .uk-child-width-1-5\@m > * {
    width: 20%;
  }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@m > * {
    width: auto;
  }
  .uk-child-width-expand\@m > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%;
  }
  .uk-child-width-1-2\@l > * {
    width: 50%;
  }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@l > * {
    width: 25%;
  }
  .uk-child-width-1-5\@l > * {
    width: 20%;
  }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@l > * {
    width: auto;
  }
  .uk-child-width-expand\@l > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%;
  }
  .uk-child-width-1-2\@xl > * {
    width: 50%;
  }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001);
  }
  .uk-child-width-1-4\@xl > * {
    width: 25%;
  }
  .uk-child-width-1-5\@xl > * {
    width: 20%;
  }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001);
  }
  .uk-child-width-auto\@xl > * {
    width: auto;
  }
  .uk-child-width-expand\@xl > :not([class*=uk-width]) {
    flex: 1;
    min-width: 1px;
  }
}
/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*=uk-width] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%;
}

/* Halves */
.uk-width-1-2 {
  width: 50%;
}

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001);
}

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001);
}

/* Quarters */
.uk-width-1-4 {
  width: 25%;
}

.uk-width-3-4 {
  width: 75%;
}

/* Fifths */
.uk-width-1-5 {
  width: 20%;
}

.uk-width-2-5 {
  width: 40%;
}

.uk-width-3-5 {
  width: 60%;
}

.uk-width-4-5 {
  width: 80%;
}

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001);
}

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001);
}

/* Pixel */
.uk-width-small {
  width: 150px;
}

.uk-width-medium {
  width: 300px;
}

.uk-width-large {
  width: 450px;
}

.uk-width-xlarge {
  width: 600px;
}

.uk-width-2xlarge {
  width: 750px;
}

/* Auto */
.uk-width-auto {
  width: auto;
}

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%;
  }
  .uk-width-3-4\@s {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%;
  }
  .uk-width-2-5\@s {
    width: 40%;
  }
  .uk-width-3-5\@s {
    width: 60%;
  }
  .uk-width-4-5\@s {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px;
  }
  .uk-width-medium\@s {
    width: 300px;
  }
  .uk-width-large\@s {
    width: 450px;
  }
  .uk-width-xlarge\@s {
    width: 600px;
  }
  .uk-width-2xlarge\@s {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@s {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%;
  }
  .uk-width-3-4\@m {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%;
  }
  .uk-width-2-5\@m {
    width: 40%;
  }
  .uk-width-3-5\@m {
    width: 60%;
  }
  .uk-width-4-5\@m {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px;
  }
  .uk-width-medium\@m {
    width: 300px;
  }
  .uk-width-large\@m {
    width: 450px;
  }
  .uk-width-xlarge\@m {
    width: 600px;
  }
  .uk-width-2xlarge\@m {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@m {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%;
  }
  .uk-width-3-4\@l {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%;
  }
  .uk-width-2-5\@l {
    width: 40%;
  }
  .uk-width-3-5\@l {
    width: 60%;
  }
  .uk-width-4-5\@l {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px;
  }
  .uk-width-medium\@l {
    width: 300px;
  }
  .uk-width-large\@l {
    width: 450px;
  }
  .uk-width-xlarge\@l {
    width: 600px;
  }
  .uk-width-2xlarge\@l {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@l {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%;
  }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%;
  }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001);
  }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001);
  }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%;
  }
  .uk-width-3-4\@xl {
    width: 75%;
  }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%;
  }
  .uk-width-2-5\@xl {
    width: 40%;
  }
  .uk-width-3-5\@xl {
    width: 60%;
  }
  .uk-width-4-5\@xl {
    width: 80%;
  }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001);
  }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001);
  }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px;
  }
  .uk-width-medium\@xl {
    width: 300px;
  }
  .uk-width-large\@xl {
    width: 450px;
  }
  .uk-width-xlarge\@xl {
    width: 600px;
  }
  .uk-width-2xlarge\@xl {
    width: 750px;
  }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto;
  }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px;
  }
}
/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content;
}

.uk-width-min-content {
  width: min-content;
}

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*=uk-height] {
  box-sizing: border-box;
}

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%;
}

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh;
}

.uk-height-viewport-2 {
  min-height: 200vh;
}

.uk-height-viewport-3 {
  min-height: 300vh;
}

.uk-height-viewport-4 {
  min-height: 400vh;
}

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px;
}

.uk-height-medium {
  height: 300px;
}

.uk-height-large {
  height: 450px;
}

.uk-height-max-small {
  max-height: 150px;
}

.uk-height-max-medium {
  max-height: 300px;
}

.uk-height-max-large {
  max-height: 450px;
}

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
}

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999;
}
.uk-text-meta > a {
  color: #999;
}
.uk-text-meta > a:hover {
  color: #666;
  text-decoration: none;
}

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5;
}

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5;
}

.uk-text-default {
  font-size: 16px;
  line-height: 1.5;
}

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300;
}

.uk-text-normal {
  font-weight: 400;
}

.uk-text-bold {
  font-weight: 700;
}

.uk-text-lighter {
  font-weight: lighter;
}

.uk-text-bolder {
  font-weight: bolder;
}

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic;
}

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important;
}

.uk-text-uppercase {
  text-transform: uppercase !important;
}

.uk-text-lowercase {
  text-transform: lowercase !important;
}

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important;
}

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important;
}

.uk-text-emphasis {
  color: #333 !important;
}

.uk-text-primary {
  color: #1e87f0 !important;
}

.uk-text-secondary {
  color: #222 !important;
}

.uk-text-success {
  color: #32d296 !important;
}

.uk-text-warning {
  color: #faa05a !important;
}

.uk-text-danger {
  color: #f0506e !important;
}

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in all browsers.
 * 2. Default color is set to transparent.
 * 3. Container fits the text
 * 4. Style
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  color: transparent !important;
  /* 3 */
  display: inline-block;
  /* 4 */
  background-color: #1e87f0;
  background-image: linear-gradient(90deg, #1e87f0 0%, spin(#1e87f0, 40%) 100%);
}

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}

.uk-text-right {
  text-align: right !important;
}

.uk-text-center {
  text-align: center !important;
}

.uk-text-justify {
  text-align: justify !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important;
  }
  .uk-text-right\@s {
    text-align: right !important;
  }
  .uk-text-center\@s {
    text-align: center !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important;
  }
  .uk-text-right\@m {
    text-align: right !important;
  }
  .uk-text-center\@m {
    text-align: center !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important;
  }
  .uk-text-right\@l {
    text-align: right !important;
  }
  .uk-text-center\@l {
    text-align: center !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important;
  }
  .uk-text-right\@xl {
    text-align: right !important;
  }
  .uk-text-center\@xl {
    text-align: center !important;
  }
}
/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important;
}

.uk-text-middle {
  vertical-align: middle !important;
}

.uk-text-bottom {
  vertical-align: bottom !important;
}

.uk-text-baseline {
  vertical-align: baseline !important;
}

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0;
}

/*
 * Wrap long words onto the next line and break them if they are too long to fit.
 * 1. Make it work with table cells in all browsers.
 * Note: Not using `hyphens: auto` because it hyphenates text even if not needed.
 */
.uk-text-break {
  overflow-wrap: break-word;
}

/* 1 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-word;
}

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*=uk-column-] {
  column-gap: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*=uk-column-] {
    column-gap: 40px;
  }
}
/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*=uk-column-] img {
  transform: translate3d(0, 0, 0);
}

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px;
  }
}
/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2;
}

.uk-column-1-3 {
  column-count: 3;
}

.uk-column-1-4 {
  column-count: 4;
}

.uk-column-1-5 {
  column-count: 5;
}

.uk-column-1-6 {
  column-count: 6;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2;
  }
  .uk-column-1-3\@s {
    column-count: 3;
  }
  .uk-column-1-4\@s {
    column-count: 4;
  }
  .uk-column-1-5\@s {
    column-count: 5;
  }
  .uk-column-1-6\@s {
    column-count: 6;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2;
  }
  .uk-column-1-3\@m {
    column-count: 3;
  }
  .uk-column-1-4\@m {
    column-count: 4;
  }
  .uk-column-1-5\@m {
    column-count: 5;
  }
  .uk-column-1-6\@m {
    column-count: 6;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2;
  }
  .uk-column-1-3\@l {
    column-count: 3;
  }
  .uk-column-1-4\@l {
    column-count: 4;
  }
  .uk-column-1-5\@l {
    column-count: 5;
  }
  .uk-column-1-6\@l {
    column-count: 6;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2;
  }
  .uk-column-1-3\@xl {
    column-count: 3;
  }
  .uk-column-1-4\@xl {
    column-count: 4;
  }
  .uk-column-1-5\@xl {
    column-count: 5;
  }
  .uk-column-1-6\@xl {
    column-count: 6;
  }
}
/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all;
}

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover],
[data-uk-cover] {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
}

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none;
}

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative;
}

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff;
}

.uk-background-muted {
  background-color: #f8f8f8;
}

.uk-background-primary {
  background-color: #1e87f0;
}

.uk-background-secondary {
  background-color: #222;
}

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.uk-background-cover {
  background-size: cover;
}

.uk-background-contain {
  background-size: contain;
}

.uk-background-width-1-1 {
  background-size: 100%;
}

.uk-background-height-1-1 {
  background-size: auto 100%;
}

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0;
}

.uk-background-top-center {
  background-position: 50% 0;
}

.uk-background-top-right {
  background-position: 100% 0;
}

.uk-background-center-left {
  background-position: 0 50%;
}

.uk-background-center-center {
  background-position: 50% 50%;
}

.uk-background-center-right {
  background-position: 100% 50%;
}

.uk-background-bottom-left {
  background-position: 0 100%;
}

.uk-background-bottom-center {
  background-position: 50% 100%;
}

.uk-background-bottom-right {
  background-position: 100% 100%;
}

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat;
}

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden;
}

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll;
  }
}
/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important;
  }
}
/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply;
}

.uk-background-blend-screen {
  background-blend-mode: screen;
}

.uk-background-blend-overlay {
  background-blend-mode: overlay;
}

.uk-background-blend-darken {
  background-blend-mode: darken;
}

.uk-background-blend-lighten {
  background-blend-mode: lighten;
}

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge;
}

.uk-background-blend-color-burn {
  background-blend-mode: color-burn;
}

.uk-background-blend-hard-light {
  background-blend-mode: hard-light;
}

.uk-background-blend-soft-light {
  background-blend-mode: soft-light;
}

.uk-background-blend-difference {
  background-blend-mode: difference;
}

.uk-background-blend-exclusion {
  background-blend-mode: exclusion;
}

.uk-background-blend-hue {
  background-blend-mode: hue;
}

.uk-background-blend-saturation {
  background-blend-mode: saturation;
}

.uk-background-blend-color {
  background-blend-mode: color;
}

.uk-background-blend-luminosity {
  background-blend-mode: luminosity;
}

/* ========================================================================
   Component: Align
 ========================================================================== */
/*
 * Default
 */
[class*=uk-align] {
  display: block;
  margin-bottom: 30px;
}

* + [class*=uk-align] {
  margin-top: 30px;
}

/*
 * Center
 */
.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}

/*
 * Left/Right
 */
.uk-align-left {
  margin-top: 0;
  margin-right: 30px;
  float: left;
}

.uk-align-right {
  margin-top: 0;
  margin-left: 30px;
  float: right;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-align-left\@s {
    margin-top: 0;
    margin-right: 30px;
    float: left;
  }
  .uk-align-right\@s {
    margin-top: 0;
    margin-left: 30px;
    float: right;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-align-left\@m {
    margin-top: 0;
    margin-right: 30px;
    float: left;
  }
  .uk-align-right\@m {
    margin-top: 0;
    margin-left: 30px;
    float: right;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-align-left\@l {
    margin-top: 0;
    float: left;
  }
  .uk-align-right\@l {
    margin-top: 0;
    float: right;
  }
  .uk-align-left,
.uk-align-left\@s,
.uk-align-left\@m,
.uk-align-left\@l {
    margin-right: 40px;
  }
  .uk-align-right,
.uk-align-right\@s,
.uk-align-right\@m,
.uk-align-right\@l {
    margin-left: 40px;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-align-left\@xl {
    margin-top: 0;
    margin-right: 40px;
    float: left;
  }
  .uk-align-right\@xl {
    margin-top: 0;
    margin-left: 40px;
    float: right;
  }
}
/* ========================================================================
   Component: SVG
 ========================================================================== */
/*
 * 1. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 2. Set the fill and stroke color of all SVG elements to the current text color
 */
/* 1 */
.uk-svg,
.uk-svg:not(.uk-preserve) [fill*="#"]:not(.uk-preserve) {
  fill: currentcolor;
}

.uk-svg:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: currentcolor;
}

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-svg {
  transform: translate(0, 0);
}

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box;
}

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0;
}

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  resize: both;
}

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell;
}

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left;
}

.uk-float-right {
  float: right;
}

/* 1 */
[class*=uk-float-] {
  max-width: 100%;
}

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}

/*
 * Enable scrollbars if content is clipped
 */
.uk-overflow-auto {
  overflow: auto;
}

.uk-overflow-auto > :last-child {
  margin-bottom: 0;
}

/* Box Sizing
 ========================================================================== */
.uk-box-sizing-content {
  box-sizing: content-box;
}

.uk-box-sizing-border {
  box-sizing: border-box;
}

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both;
}

.uk-resize-horizontal {
  resize: horizontal;
}

.uk-resize-vertical {
  resize: vertical;
}

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important;
}

.uk-display-inline {
  display: inline !important;
}

.uk-display-inline-block {
  display: inline-block !important;
}

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*=uk-inline] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden;
}

.uk-inline-clip {
  /* 6 */
  overflow: hidden;
}

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none;
}

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto;
}

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none;
}

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%;
}

/* Object
 ========================================================================== */
.uk-object-cover {
  object-fit: cover;
}

.uk-object-contain {
  object-fit: contain;
}

.uk-object-fill {
  object-fit: fill;
}

.uk-object-none {
  object-fit: none;
}

.uk-object-scale-down {
  object-fit: scale-down;
}

/*
 * Position
 */
.uk-object-top-left {
  object-position: 0 0;
}

.uk-object-top-center {
  object-position: 50% 0;
}

.uk-object-top-right {
  object-position: 100% 0;
}

.uk-object-center-left {
  object-position: 0 50%;
}

.uk-object-center-center {
  object-position: 50% 50%;
}

.uk-object-center-right {
  object-position: 100% 50%;
}

.uk-object-bottom-left {
  object-position: 0 100%;
}

.uk-object-bottom-center {
  object-position: 50% 100%;
}

.uk-object-bottom-right {
  object-position: 100% 100%;
}

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}

.uk-border-pill {
  border-radius: 500px;
}

.uk-border-rounded {
  border-radius: 5px;
}

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*=uk-border-] {
  -webkit-transform: translateZ(0);
}

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/*
 * Hover
 */
[class*=uk-box-shadow-hover] {
  transition: box-shadow 0.1s ease-in-out;
}

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
}

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle;
  }
  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter;
  }
}
/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1;
  margin-bottom: -2px;
}

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%;
  }
}
/* Logo
 ========================================================================== */
/*
 * 1. Style
 * 2. Required for `a`
 * 3. Behave like image but can be overridden through flex utility classes
 */
.uk-logo {
  /* 1 */
  font-size: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #333;
  /* 2 */
  text-decoration: none;
}

/* 3 */
:where(.uk-logo) {
  display: inline-block;
  vertical-align: middle;
}

/* Hover */
.uk-logo:hover {
  color: #333;
  /* 1 */
  text-decoration: none;
}

.uk-logo :where(img, svg, video) {
  display: block;
}

.uk-logo-inverse {
  display: none;
}

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none;
}

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move;
}

/* 2 */
.uk-drag iframe {
  pointer-events: none;
}

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
}

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply;
}

.uk-blend-screen {
  mix-blend-mode: screen;
}

.uk-blend-overlay {
  mix-blend-mode: overlay;
}

.uk-blend-darken {
  mix-blend-mode: darken;
}

.uk-blend-lighten {
  mix-blend-mode: lighten;
}

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge;
}

.uk-blend-color-burn {
  mix-blend-mode: color-burn;
}

.uk-blend-hard-light {
  mix-blend-mode: hard-light;
}

.uk-blend-soft-light {
  mix-blend-mode: soft-light;
}

.uk-blend-difference {
  mix-blend-mode: difference;
}

.uk-blend-exclusion {
  mix-blend-mode: exclusion;
}

.uk-blend-hue {
  mix-blend-mode: hue;
}

.uk-blend-saturation {
  mix-blend-mode: saturation;
}

.uk-blend-color {
  mix-blend-mode: color;
}

.uk-blend-luminosity {
  mix-blend-mode: luminosity;
}

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%);
}

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0;
}

.uk-transform-origin-top-center {
  transform-origin: 50% 0;
}

.uk-transform-origin-top-right {
  transform-origin: 100% 0;
}

.uk-transform-origin-center-left {
  transform-origin: 0 50%;
}

.uk-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex;
}

.uk-flex-inline {
  display: inline-flex;
}

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start;
}

.uk-flex-center {
  justify-content: center;
}

.uk-flex-right {
  justify-content: flex-end;
}

.uk-flex-between {
  justify-content: space-between;
}

.uk-flex-around {
  justify-content: space-around;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start;
  }
  .uk-flex-center\@s {
    justify-content: center;
  }
  .uk-flex-right\@s {
    justify-content: flex-end;
  }
  .uk-flex-between\@s {
    justify-content: space-between;
  }
  .uk-flex-around\@s {
    justify-content: space-around;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start;
  }
  .uk-flex-center\@m {
    justify-content: center;
  }
  .uk-flex-right\@m {
    justify-content: flex-end;
  }
  .uk-flex-between\@m {
    justify-content: space-between;
  }
  .uk-flex-around\@m {
    justify-content: space-around;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start;
  }
  .uk-flex-center\@l {
    justify-content: center;
  }
  .uk-flex-right\@l {
    justify-content: flex-end;
  }
  .uk-flex-between\@l {
    justify-content: space-between;
  }
  .uk-flex-around\@l {
    justify-content: space-around;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start;
  }
  .uk-flex-center\@xl {
    justify-content: center;
  }
  .uk-flex-right\@xl {
    justify-content: flex-end;
  }
  .uk-flex-between\@xl {
    justify-content: space-between;
  }
  .uk-flex-around\@xl {
    justify-content: space-around;
  }
}
/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch;
}

.uk-flex-top {
  align-items: flex-start;
}

.uk-flex-middle {
  align-items: center;
}

.uk-flex-bottom {
  align-items: flex-end;
}

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row;
}

.uk-flex-row-reverse {
  flex-direction: row-reverse;
}

.uk-flex-column {
  flex-direction: column;
}

.uk-flex-column-reverse {
  flex-direction: column-reverse;
}

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap;
}

.uk-flex-wrap {
  flex-wrap: wrap;
}

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch;
}

.uk-flex-wrap-top {
  align-content: flex-start;
}

.uk-flex-wrap-middle {
  align-content: center;
}

.uk-flex-wrap-bottom {
  align-content: flex-end;
}

.uk-flex-wrap-between {
  align-content: space-between;
}

.uk-flex-wrap-around {
  align-content: space-around;
}

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1;
}

.uk-flex-last {
  order: 99;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1;
  }
  .uk-flex-last\@s {
    order: 99;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1;
  }
  .uk-flex-last\@m {
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1;
  }
  .uk-flex-last\@l {
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1;
  }
  .uk-flex-last\@xl {
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none;
}

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto;
}

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1;
}

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px;
}

* + .uk-margin {
  margin-top: 20px !important;
}

.uk-margin-top {
  margin-top: 20px !important;
}

.uk-margin-bottom {
  margin-bottom: 20px !important;
}

.uk-margin-left {
  margin-left: 20px !important;
}

.uk-margin-right {
  margin-right: 20px !important;
}

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px;
}

* + .uk-margin-small {
  margin-top: 10px !important;
}

.uk-margin-small-top {
  margin-top: 10px !important;
}

.uk-margin-small-bottom {
  margin-bottom: 10px !important;
}

.uk-margin-small-left {
  margin-left: 10px !important;
}

.uk-margin-small-right {
  margin-right: 10px !important;
}

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px;
}

* + .uk-margin-medium {
  margin-top: 40px !important;
}

.uk-margin-medium-top {
  margin-top: 40px !important;
}

.uk-margin-medium-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-medium-left {
  margin-left: 40px !important;
}

.uk-margin-medium-right {
  margin-right: 40px !important;
}

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px;
}

* + .uk-margin-large {
  margin-top: 40px !important;
}

.uk-margin-large-top {
  margin-top: 40px !important;
}

.uk-margin-large-bottom {
  margin-bottom: 40px !important;
}

.uk-margin-large-left {
  margin-left: 40px !important;
}

.uk-margin-large-right {
  margin-right: 40px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px;
  }
  * + .uk-margin-large {
    margin-top: 70px !important;
  }
  .uk-margin-large-top {
    margin-top: 70px !important;
  }
  .uk-margin-large-bottom {
    margin-bottom: 70px !important;
  }
  .uk-margin-large-left {
    margin-left: 70px !important;
  }
  .uk-margin-large-right {
    margin-right: 70px !important;
  }
}
/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px;
}

* + .uk-margin-xlarge {
  margin-top: 70px !important;
}

.uk-margin-xlarge-top {
  margin-top: 70px !important;
}

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important;
}

.uk-margin-xlarge-left {
  margin-left: 70px !important;
}

.uk-margin-xlarge-right {
  margin-right: 70px !important;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px;
  }
  * + .uk-margin-xlarge {
    margin-top: 140px !important;
  }
  .uk-margin-xlarge-top {
    margin-top: 140px !important;
  }
  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important;
  }
  .uk-margin-xlarge-left {
    margin-left: 140px !important;
  }
  .uk-margin-xlarge-right {
    margin-right: 140px !important;
  }
}
/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.uk-margin-auto-top {
  margin-top: auto !important;
}

.uk-margin-auto-bottom {
  margin-bottom: auto !important;
}

.uk-margin-auto-left {
  margin-left: auto !important;
}

.uk-margin-auto-right {
  margin-right: auto !important;
}

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@s {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@s {
    margin-right: auto !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@m {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@m {
    margin-right: auto !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@l {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@l {
    margin-right: auto !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important;
  }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important;
  }
}
/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important;
}

.uk-margin-remove-top {
  margin-top: 0 !important;
}

.uk-margin-remove-bottom {
  margin-bottom: 0 !important;
}

.uk-margin-remove-left {
  margin-left: 0 !important;
}

.uk-margin-remove-right {
  margin-right: 0 !important;
}

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important;
}

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important;
  }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important;
  }
}
/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px;
  }
}
/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 15px;
}

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 40px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px;
  }
}
/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}

.uk-padding-remove-top {
  padding-top: 0 !important;
}

.uk-padding-remove-bottom {
  padding-bottom: 0 !important;
}

.uk-padding-remove-left {
  padding-left: 0 !important;
}

.uk-padding-remove-right {
  padding-right: 0 !important;
}

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px;
}

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*=uk-position-top],
[class*=uk-position-bottom],
[class*=uk-position-left],
[class*=uk-position-right],
[class*=uk-position-center] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box;
}

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0;
}

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0;
}

.uk-position-top-right {
  top: 0;
  right: 0;
}

.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}

.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content;
}

/* Vertical */
[class*=uk-position-center-left],
[class*=uk-position-center-right] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y));
}

.uk-position-center-left {
  left: 0;
}

.uk-position-center-right {
  right: 0;
}

.uk-position-center-left-out {
  right: 100%;
  width: max-content;
}

.uk-position-center-right-out {
  left: 100%;
  width: max-content;
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content;
}

.uk-position-top-center {
  top: 0;
}

.uk-position-bottom-center {
  bottom: 0;
}

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 15px;
  --uk-position-margin-offset: 15px;
}

.uk-position-medium {
  margin: 30px;
  --uk-position-margin-offset: 30px;
}

.uk-position-large {
  margin: 30px;
  --uk-position-margin-offset: 30px;
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px;
  }
}
/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative !important;
}

.uk-position-absolute {
  position: absolute !important;
}

.uk-position-fixed {
  position: fixed !important;
}

.uk-position-sticky {
  position: sticky !important;
}

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1;
}

.uk-position-z-index-zero {
  z-index: 0;
}

.uk-position-z-index-negative {
  z-index: -1;
}

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*=uk-transition-scale]),
:where([class*=uk-transition-slide]) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0;
}

.uk-transition-fade,
[class*=uk-transition-scale],
[class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0;
}

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1;
}

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*=uk-transition-scale] {
  -webkit-backface-visibility: hidden;
}

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
}

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
}

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1;
}

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1;
}

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%;
}

.uk-transition-slide-bottom {
  --uk-translate-y: 100%;
}

.uk-transition-slide-left {
  --uk-translate-x: -100%;
}

.uk-transition-slide-right {
  --uk-translate-x: 100%;
}

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px);
}

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px;
}

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px);
}

.uk-transition-slide-right-small {
  --uk-translate-x: 10px;
}

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px);
}

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px;
}

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px);
}

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px;
}

/* Show */
.uk-transition-toggle:hover [class*=uk-transition-slide],
.uk-transition-toggle:focus [class*=uk-transition-slide],
.uk-transition-toggle [class*=uk-transition-slide]:focus-within,
.uk-transition-active.uk-active [class*=uk-transition-slide] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1;
}

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1;
}

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s;
}

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important;
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important;
  }
}
/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important;
  }
}
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important;
  }
}
/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important;
  }
}
/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important;
  }
}
/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important;
  }
}
/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important;
  }
}
/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important;
}

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Mind that `display: none`, `visibility: hidden` and `opacity: 0`
 * remove the element from the accessibility tree and that
 * `display: none` and `visibility: hidden` are not focusable.
 *
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Remove space when hidden.
 * 1. Remove from document flow.
 * 2. Hide element and shrink its dimension. Can't use zero dimensions together
 *    with `overflow: hidden` it would remove it from the accessibility tree.
 * 3. Hide the single rendered pixel.
 * 4. Prevent text wrapping caused by `width: 1px` because it has side effects on vocalisation
 *    by screen readers and the visual tracking indicator of other assistive technologies.
 */
.uk-hidden-visually:not(:focus):not(:active):not(:focus-within),
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  /* 1 */
  position: absolute !important;
  /* 2 */
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  /* 3 */
  clip-path: inset(50%) !important;
  /* 4 */
  white-space: nowrap !important;
}

/*
 * Keep space when hidden.
 * Hide element without shrinking its dimension.
 * Unfortunately, can't use `clip-path: inset(50%)` because hover won't work
 * if the element is positioned outside of the toggle box.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important;
}

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important;
  }
}
/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important;
  }
}
/* ========================================================================
   Component: Inverse
 ========================================================================== */
/*
 * Implemented class depends on the general theme color
 * `uk-light` is for light colors on dark backgrounds
 * `uk-dark` is or dark colors on light backgrounds
 */
.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-tile-primary:not(.uk-preserve-color), .uk-tile-secondary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*=uk-card-media]), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*=uk-card-media]), .uk-overlay-primary, .uk-offcanvas-bar {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light a, .uk-section-primary:not(.uk-preserve-color) a, .uk-section-secondary:not(.uk-preserve-color) a, .uk-tile-primary:not(.uk-preserve-color) a, .uk-tile-secondary:not(.uk-preserve-color) a, .uk-card-primary.uk-card-body a, .uk-card-primary > :not([class*=uk-card-media]) a, .uk-card-secondary.uk-card-body a, .uk-card-secondary > :not([class*=uk-card-media]) a, .uk-overlay-primary a, .uk-offcanvas-bar a,
.uk-light .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link,
.uk-card-primary.uk-card-body .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link,
.uk-card-secondary.uk-card-body .uk-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link,
.uk-overlay-primary .uk-link,
.uk-offcanvas-bar .uk-link {
  color: #fff;
}
.uk-light a:hover, .uk-section-primary:not(.uk-preserve-color) a:hover, .uk-section-secondary:not(.uk-preserve-color) a:hover, .uk-tile-primary:not(.uk-preserve-color) a:hover, .uk-tile-secondary:not(.uk-preserve-color) a:hover, .uk-card-primary.uk-card-body a:hover, .uk-card-primary > :not([class*=uk-card-media]) a:hover, .uk-card-secondary.uk-card-body a:hover, .uk-card-secondary > :not([class*=uk-card-media]) a:hover, .uk-overlay-primary a:hover, .uk-offcanvas-bar a:hover,
.uk-light .uk-link:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link:hover,
.uk-card-primary.uk-card-body .uk-link:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link:hover,
.uk-card-secondary.uk-card-body .uk-link:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link:hover,
.uk-overlay-primary .uk-link:hover,
.uk-offcanvas-bar .uk-link:hover,
.uk-light .uk-link-toggle:hover .uk-link,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link,
.uk-overlay-primary .uk-link-toggle:hover .uk-link,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link {
  color: #fff;
}
.uk-light :not(pre) > code, .uk-section-primary:not(.uk-preserve-color) :not(pre) > code, .uk-section-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-primary:not(.uk-preserve-color) :not(pre) > code, .uk-tile-secondary:not(.uk-preserve-color) :not(pre) > code, .uk-card-primary.uk-card-body :not(pre) > code, .uk-card-primary > :not([class*=uk-card-media]) :not(pre) > code, .uk-card-secondary.uk-card-body :not(pre) > code, .uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > code, .uk-overlay-primary :not(pre) > code, .uk-offcanvas-bar :not(pre) > code,
.uk-light :not(pre) > kbd,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-section-secondary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-tile-primary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre) > kbd,
.uk-card-primary.uk-card-body :not(pre) > kbd,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > kbd,
.uk-card-secondary.uk-card-body :not(pre) > kbd,
.uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > kbd,
.uk-overlay-primary :not(pre) > kbd,
.uk-offcanvas-bar :not(pre) > kbd,
.uk-light :not(pre) > samp,
.uk-section-primary:not(.uk-preserve-color) :not(pre) > samp,
.uk-section-secondary:not(.uk-preserve-color) :not(pre) > samp,
.uk-tile-primary:not(.uk-preserve-color) :not(pre) > samp,
.uk-tile-secondary:not(.uk-preserve-color) :not(pre) > samp,
.uk-card-primary.uk-card-body :not(pre) > samp,
.uk-card-primary > :not([class*=uk-card-media]) :not(pre) > samp,
.uk-card-secondary.uk-card-body :not(pre) > samp,
.uk-card-secondary > :not([class*=uk-card-media]) :not(pre) > samp,
.uk-overlay-primary :not(pre) > samp,
.uk-offcanvas-bar :not(pre) > samp {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light em, .uk-section-primary:not(.uk-preserve-color) em, .uk-section-secondary:not(.uk-preserve-color) em, .uk-tile-primary:not(.uk-preserve-color) em, .uk-tile-secondary:not(.uk-preserve-color) em, .uk-card-primary.uk-card-body em, .uk-card-primary > :not([class*=uk-card-media]) em, .uk-card-secondary.uk-card-body em, .uk-card-secondary > :not([class*=uk-card-media]) em, .uk-overlay-primary em, .uk-offcanvas-bar em {
  color: #fff;
}
.uk-light h1, .uk-section-primary:not(.uk-preserve-color) h1, .uk-section-secondary:not(.uk-preserve-color) h1, .uk-tile-primary:not(.uk-preserve-color) h1, .uk-tile-secondary:not(.uk-preserve-color) h1, .uk-card-primary.uk-card-body h1, .uk-card-primary > :not([class*=uk-card-media]) h1, .uk-card-secondary.uk-card-body h1, .uk-card-secondary > :not([class*=uk-card-media]) h1, .uk-overlay-primary h1, .uk-offcanvas-bar h1, .uk-light .uk-h1, .uk-section-primary:not(.uk-preserve-color) .uk-h1, .uk-section-secondary:not(.uk-preserve-color) .uk-h1, .uk-tile-primary:not(.uk-preserve-color) .uk-h1, .uk-tile-secondary:not(.uk-preserve-color) .uk-h1, .uk-card-primary.uk-card-body .uk-h1, .uk-card-primary > :not([class*=uk-card-media]) .uk-h1, .uk-card-secondary.uk-card-body .uk-h1, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h1, .uk-overlay-primary .uk-h1, .uk-offcanvas-bar .uk-h1,
.uk-light h2,
.uk-section-primary:not(.uk-preserve-color) h2,
.uk-section-secondary:not(.uk-preserve-color) h2,
.uk-tile-primary:not(.uk-preserve-color) h2,
.uk-tile-secondary:not(.uk-preserve-color) h2,
.uk-card-primary.uk-card-body h2,
.uk-card-primary > :not([class*=uk-card-media]) h2,
.uk-card-secondary.uk-card-body h2,
.uk-card-secondary > :not([class*=uk-card-media]) h2,
.uk-overlay-primary h2,
.uk-offcanvas-bar h2, .uk-light .uk-h2, .uk-section-primary:not(.uk-preserve-color) .uk-h2, .uk-section-secondary:not(.uk-preserve-color) .uk-h2, .uk-tile-primary:not(.uk-preserve-color) .uk-h2, .uk-tile-secondary:not(.uk-preserve-color) .uk-h2, .uk-card-primary.uk-card-body .uk-h2, .uk-card-primary > :not([class*=uk-card-media]) .uk-h2, .uk-card-secondary.uk-card-body .uk-h2, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h2, .uk-overlay-primary .uk-h2, .uk-offcanvas-bar .uk-h2,
.uk-light h3,
.uk-section-primary:not(.uk-preserve-color) h3,
.uk-section-secondary:not(.uk-preserve-color) h3,
.uk-tile-primary:not(.uk-preserve-color) h3,
.uk-tile-secondary:not(.uk-preserve-color) h3,
.uk-card-primary.uk-card-body h3,
.uk-card-primary > :not([class*=uk-card-media]) h3,
.uk-card-secondary.uk-card-body h3,
.uk-card-secondary > :not([class*=uk-card-media]) h3,
.uk-overlay-primary h3,
.uk-offcanvas-bar h3, .uk-light .uk-h3, .uk-section-primary:not(.uk-preserve-color) .uk-h3, .uk-section-secondary:not(.uk-preserve-color) .uk-h3, .uk-tile-primary:not(.uk-preserve-color) .uk-h3, .uk-tile-secondary:not(.uk-preserve-color) .uk-h3, .uk-card-primary.uk-card-body .uk-h3, .uk-card-primary > :not([class*=uk-card-media]) .uk-h3, .uk-card-secondary.uk-card-body .uk-h3, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h3, .uk-overlay-primary .uk-h3, .uk-offcanvas-bar .uk-h3,
.uk-light h4,
.uk-section-primary:not(.uk-preserve-color) h4,
.uk-section-secondary:not(.uk-preserve-color) h4,
.uk-tile-primary:not(.uk-preserve-color) h4,
.uk-tile-secondary:not(.uk-preserve-color) h4,
.uk-card-primary.uk-card-body h4,
.uk-card-primary > :not([class*=uk-card-media]) h4,
.uk-card-secondary.uk-card-body h4,
.uk-card-secondary > :not([class*=uk-card-media]) h4,
.uk-overlay-primary h4,
.uk-offcanvas-bar h4, .uk-light .uk-h4, .uk-section-primary:not(.uk-preserve-color) .uk-h4, .uk-section-secondary:not(.uk-preserve-color) .uk-h4, .uk-tile-primary:not(.uk-preserve-color) .uk-h4, .uk-tile-secondary:not(.uk-preserve-color) .uk-h4, .uk-card-primary.uk-card-body .uk-h4, .uk-card-primary > :not([class*=uk-card-media]) .uk-h4, .uk-card-secondary.uk-card-body .uk-h4, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h4, .uk-overlay-primary .uk-h4, .uk-offcanvas-bar .uk-h4,
.uk-light h5,
.uk-section-primary:not(.uk-preserve-color) h5,
.uk-section-secondary:not(.uk-preserve-color) h5,
.uk-tile-primary:not(.uk-preserve-color) h5,
.uk-tile-secondary:not(.uk-preserve-color) h5,
.uk-card-primary.uk-card-body h5,
.uk-card-primary > :not([class*=uk-card-media]) h5,
.uk-card-secondary.uk-card-body h5,
.uk-card-secondary > :not([class*=uk-card-media]) h5,
.uk-overlay-primary h5,
.uk-offcanvas-bar h5, .uk-light .uk-h5, .uk-section-primary:not(.uk-preserve-color) .uk-h5, .uk-section-secondary:not(.uk-preserve-color) .uk-h5, .uk-tile-primary:not(.uk-preserve-color) .uk-h5, .uk-tile-secondary:not(.uk-preserve-color) .uk-h5, .uk-card-primary.uk-card-body .uk-h5, .uk-card-primary > :not([class*=uk-card-media]) .uk-h5, .uk-card-secondary.uk-card-body .uk-h5, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h5, .uk-overlay-primary .uk-h5, .uk-offcanvas-bar .uk-h5,
.uk-light h6,
.uk-section-primary:not(.uk-preserve-color) h6,
.uk-section-secondary:not(.uk-preserve-color) h6,
.uk-tile-primary:not(.uk-preserve-color) h6,
.uk-tile-secondary:not(.uk-preserve-color) h6,
.uk-card-primary.uk-card-body h6,
.uk-card-primary > :not([class*=uk-card-media]) h6,
.uk-card-secondary.uk-card-body h6,
.uk-card-secondary > :not([class*=uk-card-media]) h6,
.uk-overlay-primary h6,
.uk-offcanvas-bar h6, .uk-light .uk-h6, .uk-section-primary:not(.uk-preserve-color) .uk-h6, .uk-section-secondary:not(.uk-preserve-color) .uk-h6, .uk-tile-primary:not(.uk-preserve-color) .uk-h6, .uk-tile-secondary:not(.uk-preserve-color) .uk-h6, .uk-card-primary.uk-card-body .uk-h6, .uk-card-primary > :not([class*=uk-card-media]) .uk-h6, .uk-card-secondary.uk-card-body .uk-h6, .uk-card-secondary > :not([class*=uk-card-media]) .uk-h6, .uk-overlay-primary .uk-h6, .uk-offcanvas-bar .uk-h6,
.uk-light .uk-heading-small,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-small,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-small,
.uk-card-primary.uk-card-body .uk-heading-small,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-small,
.uk-card-secondary.uk-card-body .uk-heading-small,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-small,
.uk-overlay-primary .uk-heading-small,
.uk-offcanvas-bar .uk-heading-small,
.uk-light .uk-heading-medium,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-medium,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-medium,
.uk-card-primary.uk-card-body .uk-heading-medium,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-medium,
.uk-card-secondary.uk-card-body .uk-heading-medium,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-medium,
.uk-overlay-primary .uk-heading-medium,
.uk-offcanvas-bar .uk-heading-medium,
.uk-light .uk-heading-large,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-large,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-large,
.uk-card-primary.uk-card-body .uk-heading-large,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-large,
.uk-card-secondary.uk-card-body .uk-heading-large,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-large,
.uk-overlay-primary .uk-heading-large,
.uk-offcanvas-bar .uk-heading-large,
.uk-light .uk-heading-xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-xlarge,
.uk-card-primary.uk-card-body .uk-heading-xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-card-secondary.uk-card-body .uk-heading-xlarge,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-xlarge,
.uk-overlay-primary .uk-heading-xlarge,
.uk-offcanvas-bar .uk-heading-xlarge,
.uk-light .uk-heading-2xlarge,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-2xlarge,
.uk-card-primary.uk-card-body .uk-heading-2xlarge,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-card-secondary.uk-card-body .uk-heading-2xlarge,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-2xlarge,
.uk-overlay-primary .uk-heading-2xlarge,
.uk-offcanvas-bar .uk-heading-2xlarge {
  color: #fff;
}
.uk-light blockquote, .uk-section-primary:not(.uk-preserve-color) blockquote, .uk-section-secondary:not(.uk-preserve-color) blockquote, .uk-tile-primary:not(.uk-preserve-color) blockquote, .uk-tile-secondary:not(.uk-preserve-color) blockquote, .uk-card-primary.uk-card-body blockquote, .uk-card-primary > :not([class*=uk-card-media]) blockquote, .uk-card-secondary.uk-card-body blockquote, .uk-card-secondary > :not([class*=uk-card-media]) blockquote, .uk-overlay-primary blockquote, .uk-offcanvas-bar blockquote {
  color: #fff;
}
.uk-light blockquote footer, .uk-section-primary:not(.uk-preserve-color) blockquote footer, .uk-section-secondary:not(.uk-preserve-color) blockquote footer, .uk-tile-primary:not(.uk-preserve-color) blockquote footer, .uk-tile-secondary:not(.uk-preserve-color) blockquote footer, .uk-card-primary.uk-card-body blockquote footer, .uk-card-primary > :not([class*=uk-card-media]) blockquote footer, .uk-card-secondary.uk-card-body blockquote footer, .uk-card-secondary > :not([class*=uk-card-media]) blockquote footer, .uk-overlay-primary blockquote footer, .uk-offcanvas-bar blockquote footer {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light hr, .uk-section-primary:not(.uk-preserve-color) hr, .uk-section-secondary:not(.uk-preserve-color) hr, .uk-tile-primary:not(.uk-preserve-color) hr, .uk-tile-secondary:not(.uk-preserve-color) hr, .uk-card-primary.uk-card-body hr, .uk-card-primary > :not([class*=uk-card-media]) hr, .uk-card-secondary.uk-card-body hr, .uk-card-secondary > :not([class*=uk-card-media]) hr, .uk-overlay-primary hr, .uk-offcanvas-bar hr, .uk-light .uk-hr, .uk-section-primary:not(.uk-preserve-color) .uk-hr, .uk-section-secondary:not(.uk-preserve-color) .uk-hr, .uk-tile-primary:not(.uk-preserve-color) .uk-hr, .uk-tile-secondary:not(.uk-preserve-color) .uk-hr, .uk-card-primary.uk-card-body .uk-hr, .uk-card-primary > :not([class*=uk-card-media]) .uk-hr, .uk-card-secondary.uk-card-body .uk-hr, .uk-card-secondary > :not([class*=uk-card-media]) .uk-hr, .uk-overlay-primary .uk-hr, .uk-offcanvas-bar .uk-hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light :focus-visible, .uk-section-primary:not(.uk-preserve-color) :focus-visible, .uk-section-secondary:not(.uk-preserve-color) :focus-visible, .uk-tile-primary:not(.uk-preserve-color) :focus-visible, .uk-tile-secondary:not(.uk-preserve-color) :focus-visible, .uk-card-primary.uk-card-body :focus-visible, .uk-card-primary > :not([class*=uk-card-media]) :focus-visible, .uk-card-secondary.uk-card-body :focus-visible, .uk-card-secondary > :not([class*=uk-card-media]) :focus-visible, .uk-overlay-primary :focus-visible, .uk-offcanvas-bar :focus-visible {
  outline-color: #fff;
}
.uk-light a.uk-link-muted, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted, .uk-card-primary.uk-card-body a.uk-link-muted, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted, .uk-card-secondary.uk-card-body a.uk-link-muted, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-muted, .uk-overlay-primary a.uk-link-muted, .uk-offcanvas-bar a.uk-link-muted,
.uk-light .uk-link-muted a,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a,
.uk-card-primary.uk-card-body .uk-link-muted a,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a,
.uk-card-secondary.uk-card-body .uk-link-muted a,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-muted a,
.uk-overlay-primary .uk-link-muted a,
.uk-offcanvas-bar .uk-link-muted a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light a.uk-link-muted:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-muted:hover, .uk-card-primary.uk-card-body a.uk-link-muted:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-muted:hover, .uk-card-secondary.uk-card-body a.uk-link-muted:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-muted:hover, .uk-overlay-primary a.uk-link-muted:hover, .uk-offcanvas-bar a.uk-link-muted:hover,
.uk-light .uk-link-muted a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-muted a:hover,
.uk-card-primary.uk-card-body .uk-link-muted a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-card-secondary.uk-card-body .uk-link-muted a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-muted a:hover,
.uk-overlay-primary .uk-link-muted a:hover,
.uk-offcanvas-bar .uk-link-muted a:hover,
.uk-light .uk-link-toggle:hover .uk-link-muted,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-muted,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-muted,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-muted,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-muted {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light a.uk-link-text:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-text:hover, .uk-card-primary.uk-card-body a.uk-link-text:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-text:hover, .uk-card-secondary.uk-card-body a.uk-link-text:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-text:hover, .uk-overlay-primary a.uk-link-text:hover, .uk-offcanvas-bar a.uk-link-text:hover,
.uk-light .uk-link-text a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-text a:hover,
.uk-card-primary.uk-card-body .uk-link-text a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-card-secondary.uk-card-body .uk-link-text a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-text a:hover,
.uk-overlay-primary .uk-link-text a:hover,
.uk-offcanvas-bar .uk-link-text a:hover,
.uk-light .uk-link-toggle:hover .uk-link-text,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-text,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-text,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-text,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-text,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-text {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light a.uk-link-heading:hover, .uk-section-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-section-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-primary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-tile-secondary:not(.uk-preserve-color) a.uk-link-heading:hover, .uk-card-primary.uk-card-body a.uk-link-heading:hover, .uk-card-primary > :not([class*=uk-card-media]) a.uk-link-heading:hover, .uk-card-secondary.uk-card-body a.uk-link-heading:hover, .uk-card-secondary > :not([class*=uk-card-media]) a.uk-link-heading:hover, .uk-overlay-primary a.uk-link-heading:hover, .uk-offcanvas-bar a.uk-link-heading:hover,
.uk-light .uk-link-heading a:hover,
.uk-section-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-heading a:hover,
.uk-card-primary.uk-card-body .uk-link-heading a:hover,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-card-secondary.uk-card-body .uk-link-heading a:hover,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-heading a:hover,
.uk-overlay-primary .uk-link-heading a:hover,
.uk-offcanvas-bar .uk-link-heading a:hover,
.uk-light .uk-link-toggle:hover .uk-link-heading,
.uk-section-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-section-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-primary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-tile-secondary:not(.uk-preserve-color) .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-primary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary.uk-card-body .uk-link-toggle:hover .uk-link-heading,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-link-toggle:hover .uk-link-heading,
.uk-overlay-primary .uk-link-toggle:hover .uk-link-heading,
.uk-offcanvas-bar .uk-link-toggle:hover .uk-link-heading {
  color: #fff;
}
.uk-light .uk-heading-divider, .uk-section-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-divider, .uk-card-primary.uk-card-body .uk-heading-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-divider, .uk-card-secondary.uk-card-body .uk-heading-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-divider, .uk-overlay-primary .uk-heading-divider, .uk-offcanvas-bar .uk-heading-divider {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-bullet::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-bullet::before, .uk-card-primary.uk-card-body .uk-heading-bullet::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-bullet::before, .uk-card-secondary.uk-card-body .uk-heading-bullet::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-bullet::before, .uk-overlay-primary .uk-heading-bullet::before, .uk-offcanvas-bar .uk-heading-bullet::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-heading-line > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::before, .uk-card-primary.uk-card-body .uk-heading-line > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::before, .uk-card-secondary.uk-card-body .uk-heading-line > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-line > ::before, .uk-overlay-primary .uk-heading-line > ::before, .uk-offcanvas-bar .uk-heading-line > ::before,
.uk-light .uk-heading-line > ::after,
.uk-section-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-heading-line > ::after,
.uk-card-primary.uk-card-body .uk-heading-line > ::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-heading-line > ::after,
.uk-card-secondary.uk-card-body .uk-heading-line > ::after,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-heading-line > ::after,
.uk-overlay-primary .uk-heading-line > ::after,
.uk-offcanvas-bar .uk-heading-line > ::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-icon, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon, .uk-card-primary.uk-card-body .uk-divider-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon, .uk-card-secondary.uk-card-body .uk-divider-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon, .uk-overlay-primary .uk-divider-icon, .uk-offcanvas-bar .uk-divider-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22rgba(255, 255, 255, 0.2)%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-divider-icon::before, .uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::before, .uk-card-primary.uk-card-body .uk-divider-icon::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::before, .uk-card-secondary.uk-card-body .uk-divider-icon::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon::before, .uk-overlay-primary .uk-divider-icon::before, .uk-offcanvas-bar .uk-divider-icon::before,
.uk-light .uk-divider-icon::after,
.uk-section-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-section-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-primary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-tile-secondary:not(.uk-preserve-color) .uk-divider-icon::after,
.uk-card-primary.uk-card-body .uk-divider-icon::after,
.uk-card-primary > :not([class*=uk-card-media]) .uk-divider-icon::after,
.uk-card-secondary.uk-card-body .uk-divider-icon::after,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-icon::after,
.uk-overlay-primary .uk-divider-icon::after,
.uk-offcanvas-bar .uk-divider-icon::after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-small::after, .uk-section-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-small::after, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-small::after, .uk-card-primary.uk-card-body .uk-divider-small::after, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-small::after, .uk-card-secondary.uk-card-body .uk-divider-small::after, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-small::after, .uk-overlay-primary .uk-divider-small::after, .uk-offcanvas-bar .uk-divider-small::after {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-divider-vertical, .uk-section-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-section-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-primary:not(.uk-preserve-color) .uk-divider-vertical, .uk-tile-secondary:not(.uk-preserve-color) .uk-divider-vertical, .uk-card-primary.uk-card-body .uk-divider-vertical, .uk-card-primary > :not([class*=uk-card-media]) .uk-divider-vertical, .uk-card-secondary.uk-card-body .uk-divider-vertical, .uk-card-secondary > :not([class*=uk-card-media]) .uk-divider-vertical, .uk-overlay-primary .uk-divider-vertical, .uk-offcanvas-bar .uk-divider-vertical {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-muted > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-muted > ::before, .uk-card-primary.uk-card-body .uk-list-muted > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-muted > ::before, .uk-card-secondary.uk-card-body .uk-list-muted > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-muted > ::before, .uk-overlay-primary .uk-list-muted > ::before, .uk-offcanvas-bar .uk-list-muted > ::before {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-list-emphasis > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-emphasis > ::before, .uk-card-primary.uk-card-body .uk-list-emphasis > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-emphasis > ::before, .uk-card-secondary.uk-card-body .uk-list-emphasis > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-emphasis > ::before, .uk-overlay-primary .uk-list-emphasis > ::before, .uk-offcanvas-bar .uk-list-emphasis > ::before {
  color: #fff !important;
}
.uk-light .uk-list-primary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-primary > ::before, .uk-card-primary.uk-card-body .uk-list-primary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-primary > ::before, .uk-card-secondary.uk-card-body .uk-list-primary > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-primary > ::before, .uk-overlay-primary .uk-list-primary > ::before, .uk-offcanvas-bar .uk-list-primary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-secondary > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-secondary > ::before, .uk-card-primary.uk-card-body .uk-list-secondary > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-secondary > ::before, .uk-card-secondary.uk-card-body .uk-list-secondary > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-secondary > ::before, .uk-overlay-primary .uk-list-secondary > ::before, .uk-offcanvas-bar .uk-list-secondary > ::before {
  color: #fff !important;
}
.uk-light .uk-list-bullet > ::before, .uk-section-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-section-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-primary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-list-bullet > ::before, .uk-card-primary.uk-card-body .uk-list-bullet > ::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-list-bullet > ::before, .uk-card-secondary.uk-card-body .uk-list-bullet > ::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-bullet > ::before, .uk-overlay-primary .uk-list-bullet > ::before, .uk-offcanvas-bar .uk-list-bullet > ::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-list-divider > :nth-child(n+2), .uk-section-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-section-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-primary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-divider > :nth-child(n+2), .uk-card-primary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-divider > :nth-child(n+2), .uk-card-secondary.uk-card-body .uk-list-divider > :nth-child(n+2), .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-divider > :nth-child(n+2), .uk-overlay-primary .uk-list-divider > :nth-child(n+2), .uk-offcanvas-bar .uk-list-divider > :nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-striped > *:nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > *:nth-of-type(odd), .uk-section-secondary:not(.uk-preserve-color) .uk-list-striped > *:nth-of-type(odd), .uk-tile-primary:not(.uk-preserve-color) .uk-list-striped > *:nth-of-type(odd), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped > *:nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > *:nth-of-type(odd), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-striped > *:nth-of-type(odd), .uk-card-secondary.uk-card-body .uk-list-striped > *:nth-of-type(odd), .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-striped > *:nth-of-type(odd), .uk-overlay-primary .uk-list-striped > *:nth-of-type(odd), .uk-offcanvas-bar .uk-list-striped > *:nth-of-type(odd) {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-list-striped > :nth-of-type(odd), .uk-section-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-section-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-primary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-tile-secondary:not(.uk-preserve-color) .uk-list-striped > :nth-of-type(odd), .uk-card-primary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-primary > :not([class*=uk-card-media]) .uk-list-striped > :nth-of-type(odd), .uk-card-secondary.uk-card-body .uk-list-striped > :nth-of-type(odd), .uk-card-secondary > :not([class*=uk-card-media]) .uk-list-striped > :nth-of-type(odd), .uk-overlay-primary .uk-list-striped > :nth-of-type(odd), .uk-offcanvas-bar .uk-list-striped > :nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-icon-link, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link, .uk-card-primary.uk-card-body .uk-icon-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link, .uk-card-secondary.uk-card-body .uk-icon-link, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link, .uk-overlay-primary .uk-icon-link, .uk-offcanvas-bar .uk-icon-link {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-icon-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:hover, .uk-card-primary.uk-card-body .uk-icon-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:hover, .uk-card-secondary.uk-card-body .uk-icon-link:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link:hover, .uk-overlay-primary .uk-icon-link:hover, .uk-offcanvas-bar .uk-icon-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-link:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-link:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-link:active, .uk-card-primary.uk-card-body .uk-icon-link:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-link:active, .uk-card-secondary.uk-card-body .uk-icon-link:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-link:active, .uk-overlay-primary .uk-icon-link:active, .uk-offcanvas-bar .uk-icon-link:active,
.uk-light .uk-active > .uk-icon-link,
.uk-section-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-section-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-tile-primary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-tile-secondary:not(.uk-preserve-color) .uk-active > .uk-icon-link,
.uk-card-primary.uk-card-body .uk-active > .uk-icon-link,
.uk-card-primary > :not([class*=uk-card-media]) .uk-active > .uk-icon-link,
.uk-card-secondary.uk-card-body .uk-active > .uk-icon-link,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-active > .uk-icon-link,
.uk-overlay-primary .uk-active > .uk-icon-link,
.uk-offcanvas-bar .uk-active > .uk-icon-link {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-button, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button, .uk-card-primary.uk-card-body .uk-icon-button, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button, .uk-card-secondary.uk-card-body .uk-icon-button, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button, .uk-overlay-primary .uk-icon-button, .uk-offcanvas-bar .uk-icon-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-icon-button:hover, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:hover, .uk-card-primary.uk-card-body .uk-icon-button:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:hover, .uk-card-secondary.uk-card-body .uk-icon-button:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button:hover, .uk-overlay-primary .uk-icon-button:hover, .uk-offcanvas-bar .uk-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-icon-button:active, .uk-section-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-section-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-primary:not(.uk-preserve-color) .uk-icon-button:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-icon-button:active, .uk-card-primary.uk-card-body .uk-icon-button:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-icon-button:active, .uk-card-secondary.uk-card-body .uk-icon-button:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-icon-button:active, .uk-overlay-primary .uk-icon-button:active, .uk-offcanvas-bar .uk-icon-button:active {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-input, .uk-section-primary:not(.uk-preserve-color) .uk-input, .uk-section-secondary:not(.uk-preserve-color) .uk-input, .uk-tile-primary:not(.uk-preserve-color) .uk-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-input, .uk-card-primary.uk-card-body .uk-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-input, .uk-card-secondary.uk-card-body .uk-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input, .uk-overlay-primary .uk-input, .uk-offcanvas-bar .uk-input,
.uk-light .uk-select,
.uk-section-primary:not(.uk-preserve-color) .uk-select,
.uk-section-secondary:not(.uk-preserve-color) .uk-select,
.uk-tile-primary:not(.uk-preserve-color) .uk-select,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select,
.uk-card-primary.uk-card-body .uk-select,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select,
.uk-card-secondary.uk-card-body .uk-select,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-select,
.uk-overlay-primary .uk-select,
.uk-offcanvas-bar .uk-select,
.uk-light .uk-textarea,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea,
.uk-card-primary.uk-card-body .uk-textarea,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea,
.uk-card-secondary.uk-card-body .uk-textarea,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea,
.uk-overlay-primary .uk-textarea,
.uk-offcanvas-bar .uk-textarea {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-input:focus, .uk-card-primary.uk-card-body .uk-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-input:focus, .uk-card-secondary.uk-card-body .uk-input:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input:focus, .uk-overlay-primary .uk-input:focus, .uk-offcanvas-bar .uk-input:focus,
.uk-light .uk-select:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-select:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-select:focus,
.uk-card-primary.uk-card-body .uk-select:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-select:focus,
.uk-card-secondary.uk-card-body .uk-select:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-select:focus,
.uk-overlay-primary .uk-select:focus,
.uk-offcanvas-bar .uk-select:focus,
.uk-light .uk-textarea:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-textarea:focus,
.uk-card-primary.uk-card-body .uk-textarea:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-textarea:focus,
.uk-card-secondary.uk-card-body .uk-textarea:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea:focus,
.uk-overlay-primary .uk-textarea:focus,
.uk-offcanvas-bar .uk-textarea:focus {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-input::placeholder, .uk-card-primary.uk-card-body .uk-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-input::placeholder, .uk-card-secondary.uk-card-body .uk-input::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input::placeholder, .uk-overlay-primary .uk-input::placeholder, .uk-offcanvas-bar .uk-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-textarea::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-textarea::placeholder, .uk-card-primary.uk-card-body .uk-textarea::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-textarea::placeholder, .uk-card-secondary.uk-card-body .uk-textarea::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-textarea::placeholder, .uk-overlay-primary .uk-textarea::placeholder, .uk-offcanvas-bar .uk-textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-select:not([multiple]):not([size]), .uk-section-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-section-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-primary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-tile-secondary:not(.uk-preserve-color) .uk-select:not([multiple]):not([size]), .uk-card-primary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-primary > :not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]), .uk-card-secondary.uk-card-body .uk-select:not([multiple]):not([size]), .uk-card-secondary > :not([class*=uk-card-media]) .uk-select:not([multiple]):not([size]), .uk-overlay-primary .uk-select:not([multiple]):not([size]), .uk-offcanvas-bar .uk-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-input[list]:hover, .uk-section-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:hover, .uk-card-primary.uk-card-body .uk-input[list]:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:hover, .uk-card-secondary.uk-card-body .uk-input[list]:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-input[list]:hover, .uk-overlay-primary .uk-input[list]:hover, .uk-offcanvas-bar .uk-input[list]:hover,
.uk-light .uk-input[list]:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-input[list]:focus,
.uk-card-primary.uk-card-body .uk-input[list]:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-card-secondary.uk-card-body .uk-input[list]:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-input[list]:focus,
.uk-overlay-primary .uk-input[list]:focus,
.uk-offcanvas-bar .uk-input[list]:focus {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-radio, .uk-section-primary:not(.uk-preserve-color) .uk-radio, .uk-section-secondary:not(.uk-preserve-color) .uk-radio, .uk-tile-primary:not(.uk-preserve-color) .uk-radio, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio, .uk-card-primary.uk-card-body .uk-radio, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio, .uk-card-secondary.uk-card-body .uk-radio, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio, .uk-overlay-primary .uk-radio, .uk-offcanvas-bar .uk-radio,
.uk-light .uk-checkbox,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox,
.uk-card-primary.uk-card-body .uk-checkbox,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox,
.uk-card-secondary.uk-card-body .uk-checkbox,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox,
.uk-overlay-primary .uk-checkbox,
.uk-offcanvas-bar .uk-checkbox {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-radio:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:focus, .uk-card-primary.uk-card-body .uk-radio:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:focus, .uk-card-secondary.uk-card-body .uk-radio:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:focus, .uk-overlay-primary .uk-radio:focus, .uk-offcanvas-bar .uk-radio:focus,
.uk-light .uk-checkbox:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:focus,
.uk-card-primary.uk-card-body .uk-checkbox:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:focus,
.uk-overlay-primary .uk-checkbox:focus,
.uk-offcanvas-bar .uk-checkbox:focus {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked,
.uk-light .uk-checkbox:checked,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked,
.uk-card-primary.uk-card-body .uk-checkbox:checked,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-card-secondary.uk-card-body .uk-checkbox:checked,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked,
.uk-overlay-primary .uk-checkbox:checked,
.uk-offcanvas-bar .uk-checkbox:checked,
.uk-light .uk-checkbox:indeterminate,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate,
.uk-overlay-primary .uk-checkbox:indeterminate,
.uk-offcanvas-bar .uk-checkbox:indeterminate {
  background-color: #fff;
  border-color: #fff;
}
.uk-light .uk-radio:checked:focus, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked:focus, .uk-card-primary.uk-card-body .uk-radio:checked:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked:focus, .uk-card-secondary.uk-card-body .uk-radio:checked:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked:focus, .uk-overlay-primary .uk-radio:checked:focus, .uk-offcanvas-bar .uk-radio:checked:focus,
.uk-light .uk-checkbox:checked:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked:focus,
.uk-card-primary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:checked:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked:focus,
.uk-overlay-primary .uk-checkbox:checked:focus,
.uk-offcanvas-bar .uk-checkbox:checked:focus,
.uk-light .uk-checkbox:indeterminate:focus,
.uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate:focus,
.uk-card-primary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-card-secondary.uk-card-body .uk-checkbox:indeterminate:focus,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate:focus,
.uk-overlay-primary .uk-checkbox:indeterminate:focus,
.uk-offcanvas-bar .uk-checkbox:indeterminate:focus {
  background-color: white;
}
.uk-light .uk-radio:checked, .uk-section-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-radio:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-radio:checked, .uk-card-primary.uk-card-body .uk-radio:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-card-secondary.uk-card-body .uk-radio:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-radio:checked, .uk-overlay-primary .uk-radio:checked, .uk-offcanvas-bar .uk-radio:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-checkbox:checked, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:checked, .uk-card-primary.uk-card-body .uk-checkbox:checked, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:checked, .uk-card-secondary.uk-card-body .uk-checkbox:checked, .uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:checked, .uk-overlay-primary .uk-checkbox:checked, .uk-offcanvas-bar .uk-checkbox:checked {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
}
.uk-light .uk-checkbox:indeterminate, .uk-section-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-section-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-primary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-tile-secondary:not(.uk-preserve-color) .uk-checkbox:indeterminate, .uk-card-primary.uk-card-body .uk-checkbox:indeterminate, .uk-card-primary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate, .uk-card-secondary.uk-card-body .uk-checkbox:indeterminate, .uk-card-secondary > :not([class*=uk-card-media]) .uk-checkbox:indeterminate, .uk-overlay-primary .uk-checkbox:indeterminate, .uk-offcanvas-bar .uk-checkbox:indeterminate {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-form-label, .uk-section-primary:not(.uk-preserve-color) .uk-form-label, .uk-section-secondary:not(.uk-preserve-color) .uk-form-label, .uk-tile-primary:not(.uk-preserve-color) .uk-form-label, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-label, .uk-card-primary.uk-card-body .uk-form-label, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-label, .uk-card-secondary.uk-card-body .uk-form-label, .uk-card-secondary > :not([class*=uk-card-media]) .uk-form-label, .uk-overlay-primary .uk-form-label, .uk-offcanvas-bar .uk-form-label {
  color: #fff;
}
.uk-light .uk-form-icon, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon, .uk-card-primary.uk-card-body .uk-form-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon, .uk-card-secondary.uk-card-body .uk-form-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-form-icon, .uk-overlay-primary .uk-form-icon, .uk-offcanvas-bar .uk-form-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-form-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-form-icon:hover, .uk-card-primary.uk-card-body .uk-form-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-form-icon:hover, .uk-card-secondary.uk-card-body .uk-form-icon:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-form-icon:hover, .uk-overlay-primary .uk-form-icon:hover, .uk-offcanvas-bar .uk-form-icon:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-button-default, .uk-section-primary:not(.uk-preserve-color) .uk-button-default, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default, .uk-card-primary.uk-card-body .uk-button-default, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default, .uk-card-secondary.uk-card-body .uk-button-default, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default, .uk-overlay-primary .uk-button-default, .uk-offcanvas-bar .uk-button-default {
  background-color: transparent;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-button-default:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:hover, .uk-card-primary.uk-card-body .uk-button-default:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:hover, .uk-card-secondary.uk-card-body .uk-button-default:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default:hover, .uk-overlay-primary .uk-button-default:hover, .uk-offcanvas-bar .uk-button-default:hover {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-button-default:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-default:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-default:active, .uk-card-primary.uk-card-body .uk-button-default:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-default:active, .uk-card-secondary.uk-card-body .uk-button-default:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default:active, .uk-overlay-primary .uk-button-default:active, .uk-offcanvas-bar .uk-button-default:active,
.uk-light .uk-button-default.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-default.uk-active,
.uk-card-primary.uk-card-body .uk-button-default.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-card-secondary.uk-card-body .uk-button-default.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-default.uk-active,
.uk-overlay-primary .uk-button-default.uk-active,
.uk-offcanvas-bar .uk-button-default.uk-active {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-button-primary, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary, .uk-card-primary.uk-card-body .uk-button-primary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary, .uk-card-secondary.uk-card-body .uk-button-primary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary, .uk-overlay-primary .uk-button-primary, .uk-offcanvas-bar .uk-button-primary {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-button-primary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:hover, .uk-card-primary.uk-card-body .uk-button-primary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:hover, .uk-card-secondary.uk-card-body .uk-button-primary:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary:hover, .uk-overlay-primary .uk-button-primary:hover, .uk-offcanvas-bar .uk-button-primary:hover {
  background-color: #f2f2f2;
  color: #666;
}
.uk-light .uk-button-primary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-primary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary:active, .uk-card-primary.uk-card-body .uk-button-primary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary:active, .uk-card-secondary.uk-card-body .uk-button-primary:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary:active, .uk-overlay-primary .uk-button-primary:active, .uk-offcanvas-bar .uk-button-primary:active,
.uk-light .uk-button-primary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-primary.uk-active,
.uk-card-primary.uk-card-body .uk-button-primary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-primary.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-primary.uk-active,
.uk-overlay-primary .uk-button-primary.uk-active,
.uk-offcanvas-bar .uk-button-primary.uk-active {
  background-color: #e6e6e6;
  color: #666;
}
.uk-light .uk-button-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary, .uk-card-primary.uk-card-body .uk-button-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary, .uk-card-secondary.uk-card-body .uk-button-secondary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary, .uk-overlay-primary .uk-button-secondary, .uk-offcanvas-bar .uk-button-secondary {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-button-secondary:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:hover, .uk-card-primary.uk-card-body .uk-button-secondary:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:hover, .uk-card-secondary.uk-card-body .uk-button-secondary:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary:hover, .uk-overlay-primary .uk-button-secondary:hover, .uk-offcanvas-bar .uk-button-secondary:hover {
  background-color: #f2f2f2;
  color: #666;
}
.uk-light .uk-button-secondary:active, .uk-section-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary:active, .uk-card-primary.uk-card-body .uk-button-secondary:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary:active, .uk-card-secondary.uk-card-body .uk-button-secondary:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary:active, .uk-overlay-primary .uk-button-secondary:active, .uk-offcanvas-bar .uk-button-secondary:active,
.uk-light .uk-button-secondary.uk-active,
.uk-section-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-section-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-primary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-tile-secondary:not(.uk-preserve-color) .uk-button-secondary.uk-active,
.uk-card-primary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-primary > :not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-card-secondary.uk-card-body .uk-button-secondary.uk-active,
.uk-card-secondary > :not([class*=uk-card-media]) .uk-button-secondary.uk-active,
.uk-overlay-primary .uk-button-secondary.uk-active,
.uk-offcanvas-bar .uk-button-secondary.uk-active {
  background-color: #e6e6e6;
  color: #666;
}
.uk-light .uk-button-text, .uk-section-primary:not(.uk-preserve-color) .uk-button-text, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text, .uk-card-primary.uk-card-body .uk-button-text, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text, .uk-card-secondary.uk-card-body .uk-button-text, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text, .uk-overlay-primary .uk-button-text, .uk-offcanvas-bar .uk-button-text {
  color: #fff;
}
.uk-light .uk-button-text::before, .uk-section-primary:not(.uk-preserve-color) .uk-button-text::before, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text::before, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text::before, .uk-card-primary.uk-card-body .uk-button-text::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text::before, .uk-card-secondary.uk-card-body .uk-button-text::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text::before, .uk-overlay-primary .uk-button-text::before, .uk-offcanvas-bar .uk-button-text::before {
  border-bottom-color: #fff;
}
.uk-light .uk-button-text:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:hover, .uk-card-primary.uk-card-body .uk-button-text:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:hover, .uk-card-secondary.uk-card-body .uk-button-text:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text:hover, .uk-overlay-primary .uk-button-text:hover, .uk-offcanvas-bar .uk-button-text:hover {
  color: #fff;
}
.uk-light .uk-button-text:disabled, .uk-section-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-section-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-primary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-text:disabled, .uk-card-primary.uk-card-body .uk-button-text:disabled, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-text:disabled, .uk-card-secondary.uk-card-body .uk-button-text:disabled, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-text:disabled, .uk-overlay-primary .uk-button-text:disabled, .uk-offcanvas-bar .uk-button-text:disabled {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-button-link, .uk-section-primary:not(.uk-preserve-color) .uk-button-link, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link, .uk-card-primary.uk-card-body .uk-button-link, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link, .uk-card-secondary.uk-card-body .uk-button-link, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-link, .uk-overlay-primary .uk-button-link, .uk-offcanvas-bar .uk-button-link {
  color: #fff;
}
.uk-light .uk-button-link:hover, .uk-section-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-button-link:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-button-link:hover, .uk-card-primary.uk-card-body .uk-button-link:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-button-link:hover, .uk-card-secondary.uk-card-body .uk-button-link:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-button-link:hover, .uk-overlay-primary .uk-button-link:hover, .uk-offcanvas-bar .uk-button-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-grid-divider > :not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-grid-divider > :not(.uk-first-column)::before, .uk-overlay-primary .uk-grid-divider > :not(.uk-first-column)::before, .uk-offcanvas-bar .uk-grid-divider > :not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-section-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-primary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary.uk-card-body .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-overlay-primary .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before, .uk-offcanvas-bar .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-close, .uk-section-primary:not(.uk-preserve-color) .uk-close, .uk-section-secondary:not(.uk-preserve-color) .uk-close, .uk-tile-primary:not(.uk-preserve-color) .uk-close, .uk-tile-secondary:not(.uk-preserve-color) .uk-close, .uk-card-primary.uk-card-body .uk-close, .uk-card-primary > :not([class*=uk-card-media]) .uk-close, .uk-card-secondary.uk-card-body .uk-close, .uk-card-secondary > :not([class*=uk-card-media]) .uk-close, .uk-overlay-primary .uk-close, .uk-offcanvas-bar .uk-close {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-close:hover, .uk-section-primary:not(.uk-preserve-color) .uk-close:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-close:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-close:hover, .uk-card-primary.uk-card-body .uk-close:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-close:hover, .uk-card-secondary.uk-card-body .uk-close:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-close:hover, .uk-overlay-primary .uk-close:hover, .uk-offcanvas-bar .uk-close:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-totop, .uk-section-primary:not(.uk-preserve-color) .uk-totop, .uk-section-secondary:not(.uk-preserve-color) .uk-totop, .uk-tile-primary:not(.uk-preserve-color) .uk-totop, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop, .uk-card-primary.uk-card-body .uk-totop, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop, .uk-card-secondary.uk-card-body .uk-totop, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop, .uk-overlay-primary .uk-totop, .uk-offcanvas-bar .uk-totop {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-totop:hover, .uk-section-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:hover, .uk-card-primary.uk-card-body .uk-totop:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:hover, .uk-card-secondary.uk-card-body .uk-totop:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop:hover, .uk-overlay-primary .uk-totop:hover, .uk-offcanvas-bar .uk-totop:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-totop:active, .uk-section-primary:not(.uk-preserve-color) .uk-totop:active, .uk-section-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-primary:not(.uk-preserve-color) .uk-totop:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-totop:active, .uk-card-primary.uk-card-body .uk-totop:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-totop:active, .uk-card-secondary.uk-card-body .uk-totop:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-totop:active, .uk-overlay-primary .uk-totop:active, .uk-offcanvas-bar .uk-totop:active {
  color: #fff;
}
.uk-light .uk-badge, .uk-section-primary:not(.uk-preserve-color) .uk-badge, .uk-section-secondary:not(.uk-preserve-color) .uk-badge, .uk-tile-primary:not(.uk-preserve-color) .uk-badge, .uk-tile-secondary:not(.uk-preserve-color) .uk-badge, .uk-card-primary.uk-card-body .uk-badge, .uk-card-primary > :not([class*=uk-card-media]) .uk-badge, .uk-card-secondary.uk-card-body .uk-badge, .uk-card-secondary > :not([class*=uk-card-media]) .uk-badge, .uk-overlay-primary .uk-badge, .uk-offcanvas-bar .uk-badge {
  background-color: #fff;
  color: #666 !important;
}
.uk-light .uk-label, .uk-section-primary:not(.uk-preserve-color) .uk-label, .uk-section-secondary:not(.uk-preserve-color) .uk-label, .uk-tile-primary:not(.uk-preserve-color) .uk-label, .uk-tile-secondary:not(.uk-preserve-color) .uk-label, .uk-card-primary.uk-card-body .uk-label, .uk-card-primary > :not([class*=uk-card-media]) .uk-label, .uk-card-secondary.uk-card-body .uk-label, .uk-card-secondary > :not([class*=uk-card-media]) .uk-label, .uk-overlay-primary .uk-label, .uk-offcanvas-bar .uk-label {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-article-meta, .uk-section-primary:not(.uk-preserve-color) .uk-article-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-article-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-article-meta, .uk-card-primary.uk-card-body .uk-article-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-article-meta, .uk-card-secondary.uk-card-body .uk-article-meta, .uk-card-secondary > :not([class*=uk-card-media]) .uk-article-meta, .uk-overlay-primary .uk-article-meta, .uk-offcanvas-bar .uk-article-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input, .uk-card-primary.uk-card-body .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-input, .uk-overlay-primary .uk-search-input, .uk-offcanvas-bar .uk-search-input {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-search-input::placeholder, .uk-section-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-section-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-primary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-input::placeholder, .uk-card-primary.uk-card-body .uk-search-input::placeholder, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-input::placeholder, .uk-card-secondary.uk-card-body .uk-search-input::placeholder, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-input::placeholder, .uk-overlay-primary .uk-search-input::placeholder, .uk-offcanvas-bar .uk-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon, .uk-card-primary.uk-card-body .uk-search .uk-search-icon, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search .uk-search-icon, .uk-overlay-primary .uk-search .uk-search-icon, .uk-offcanvas-bar .uk-search .uk-search-icon {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search .uk-search-icon:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search .uk-search-icon:hover, .uk-card-primary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search .uk-search-icon:hover, .uk-card-secondary.uk-card-body .uk-search .uk-search-icon:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search .uk-search-icon:hover, .uk-overlay-primary .uk-search .uk-search-icon:hover, .uk-offcanvas-bar .uk-search .uk-search-icon:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-default .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input, .uk-overlay-primary .uk-search-default .uk-search-input, .uk-offcanvas-bar .uk-search-default .uk-search-input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-search-default .uk-search-input:focus, .uk-section-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-section-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-primary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-default .uk-search-input:focus, .uk-card-primary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus, .uk-card-secondary.uk-card-body .uk-search-default .uk-search-input:focus, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-default .uk-search-input:focus, .uk-overlay-primary .uk-search-default .uk-search-input:focus, .uk-offcanvas-bar .uk-search-default .uk-search-input:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.uk-light .uk-search-navbar .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-navbar .uk-search-input, .uk-card-primary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-navbar .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-navbar .uk-search-input, .uk-overlay-primary .uk-search-navbar .uk-search-input, .uk-offcanvas-bar .uk-search-navbar .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-large .uk-search-input, .uk-section-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-section-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-primary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-large .uk-search-input, .uk-card-primary.uk-card-body .uk-search-large .uk-search-input, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-large .uk-search-input, .uk-card-secondary.uk-card-body .uk-search-large .uk-search-input, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-large .uk-search-input, .uk-overlay-primary .uk-search-large .uk-search-input, .uk-offcanvas-bar .uk-search-large .uk-search-input {
  background-color: transparent;
}
.uk-light .uk-search-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle, .uk-card-primary.uk-card-body .uk-search-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle, .uk-card-secondary.uk-card-body .uk-search-toggle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-toggle, .uk-overlay-primary .uk-search-toggle, .uk-offcanvas-bar .uk-search-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-search-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-search-toggle:hover, .uk-card-primary.uk-card-body .uk-search-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-search-toggle:hover, .uk-card-secondary.uk-card-body .uk-search-toggle:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-search-toggle:hover, .uk-overlay-primary .uk-search-toggle:hover, .uk-offcanvas-bar .uk-search-toggle:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-default > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a, .uk-card-primary.uk-card-body .uk-nav-default > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a, .uk-card-secondary.uk-card-body .uk-nav-default > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li > a, .uk-overlay-primary .uk-nav-default > li > a, .uk-offcanvas-bar .uk-nav-default > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-default > li > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li > a:hover, .uk-overlay-primary .uk-nav-default > li > a:hover, .uk-offcanvas-bar .uk-nav-default > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-default > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default > li.uk-active > a, .uk-overlay-primary .uk-nav-default > li.uk-active > a, .uk-offcanvas-bar .uk-nav-default > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-header, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-header, .uk-overlay-primary .uk-nav-default .uk-nav-header, .uk-offcanvas-bar .uk-nav-default .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-default .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-divider, .uk-overlay-primary .uk-nav-default .uk-nav-divider, .uk-offcanvas-bar .uk-nav-default .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-default .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a, .uk-overlay-primary .uk-nav-default .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-default .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-default .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-default .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a, .uk-card-primary.uk-card-body .uk-nav-primary > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li > a, .uk-overlay-primary .uk-nav-primary > li > a, .uk-offcanvas-bar .uk-nav-primary > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary > li > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li > a:hover, .uk-overlay-primary .uk-nav-primary > li > a:hover, .uk-offcanvas-bar .uk-nav-primary > li > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-primary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary > li.uk-active > a, .uk-overlay-primary .uk-nav-primary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-header, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-header, .uk-overlay-primary .uk-nav-primary .uk-nav-header, .uk-offcanvas-bar .uk-nav-primary .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-primary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-divider, .uk-overlay-primary .uk-nav-primary .uk-nav-divider, .uk-offcanvas-bar .uk-nav-primary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-primary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-primary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-primary .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-primary .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav-secondary > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a, .uk-overlay-primary .uk-nav-secondary > li > a, .uk-offcanvas-bar .uk-nav-secondary > li > a {
  color: #fff;
}
.uk-light .uk-nav-secondary > li > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover, .uk-overlay-primary .uk-nav-secondary > li > a:hover, .uk-offcanvas-bar .uk-nav-secondary > li > a:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-nav-secondary > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-secondary > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a, .uk-offcanvas-bar .uk-nav-secondary > li.uk-active > a {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.uk-light .uk-nav-secondary .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-subtitle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-subtitle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li > a:hover .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary.uk-card-body .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-secondary.uk-card-body .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-overlay-primary .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle, .uk-offcanvas-bar .uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #fff;
}
.uk-light .uk-nav-secondary .uk-nav-header, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-header, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-header, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-header, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-header, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-header, .uk-overlay-primary .uk-nav-secondary .uk-nav-header, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-header {
  color: #fff;
}
.uk-light .uk-nav-secondary .uk-nav-divider, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-divider, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-divider, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-divider, .uk-overlay-primary .uk-nav-secondary .uk-nav-divider, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-nav-secondary .uk-nav-sub a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-nav-secondary .uk-nav-sub a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub a:hover, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub a:hover, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-secondary.uk-card-body .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-overlay-primary .uk-nav-secondary .uk-nav-sub li.uk-active > a, .uk-offcanvas-bar .uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #fff;
}
.uk-light .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-section-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-section-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-tile-primary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-tile-secondary:not(.uk-preserve-color) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-primary > :not([class*=uk-card-media]) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-secondary.uk-card-body .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-card-secondary > :not([class*=uk-card-media]) .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-overlay-primary .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider), .uk-offcanvas-bar .uk-nav.uk-nav-divider > :not(.uk-nav-divider) + :not(.uk-nav-header, .uk-nav-divider) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-navbar-nav > li > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a, .uk-overlay-primary .uk-navbar-nav > li > a, .uk-offcanvas-bar .uk-navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-nav > li:hover > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li:hover > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li:hover > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li:hover > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li:hover > a, .uk-overlay-primary .uk-navbar-nav > li:hover > a, .uk-offcanvas-bar .uk-navbar-nav > li:hover > a,
.uk-light .uk-navbar-nav > li > a[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-nav > li > a[aria-expanded=true],
.uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a[aria-expanded=true],
.uk-overlay-primary .uk-navbar-nav > li > a[aria-expanded=true],
.uk-offcanvas-bar .uk-navbar-nav > li > a[aria-expanded=true] {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-navbar-nav > li > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li > a:active, .uk-card-primary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a:active, .uk-card-secondary.uk-card-body .uk-navbar-nav > li > a:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li > a:active, .uk-overlay-primary .uk-navbar-nav > li > a:active, .uk-offcanvas-bar .uk-navbar-nav > li > a:active {
  color: #fff;
}
.uk-light .uk-navbar-nav > li.uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-nav > li.uk-active > a, .uk-card-primary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-nav > li.uk-active > a, .uk-card-secondary.uk-card-body .uk-navbar-nav > li.uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-nav > li.uk-active > a, .uk-overlay-primary .uk-navbar-nav > li.uk-active > a, .uk-offcanvas-bar .uk-navbar-nav > li.uk-active > a {
  color: #fff;
}
.uk-light .uk-navbar-item, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-item, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-item, .uk-card-primary.uk-card-body .uk-navbar-item, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-item, .uk-card-secondary.uk-card-body .uk-navbar-item, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-item, .uk-overlay-primary .uk-navbar-item, .uk-offcanvas-bar .uk-navbar-item {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-navbar-toggle, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle, .uk-card-primary.uk-card-body .uk-navbar-toggle, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle, .uk-card-secondary.uk-card-body .uk-navbar-toggle, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle, .uk-overlay-primary .uk-navbar-toggle, .uk-offcanvas-bar .uk-navbar-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-navbar-toggle:hover, .uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle:hover, .uk-card-primary.uk-card-body .uk-navbar-toggle:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle:hover, .uk-card-secondary.uk-card-body .uk-navbar-toggle:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle:hover, .uk-overlay-primary .uk-navbar-toggle:hover, .uk-offcanvas-bar .uk-navbar-toggle:hover,
.uk-light .uk-navbar-toggle[aria-expanded=true],
.uk-section-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-section-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-primary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-tile-secondary:not(.uk-preserve-color) .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-primary > :not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary.uk-card-body .uk-navbar-toggle[aria-expanded=true],
.uk-card-secondary > :not([class*=uk-card-media]) .uk-navbar-toggle[aria-expanded=true],
.uk-overlay-primary .uk-navbar-toggle[aria-expanded=true],
.uk-offcanvas-bar .uk-navbar-toggle[aria-expanded=true] {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav > * > :first-child, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > * > :first-child, .uk-overlay-primary .uk-subnav > * > :first-child, .uk-offcanvas-bar .uk-subnav > * > :first-child {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > * > a:hover, .uk-overlay-primary .uk-subnav > * > a:hover, .uk-offcanvas-bar .uk-subnav > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > .uk-active > a, .uk-overlay-primary .uk-subnav > .uk-active > a, .uk-offcanvas-bar .uk-subnav > .uk-active > a {
  color: #fff;
}
.uk-light .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-subnav-pill > * > :first-child, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > :first-child, .uk-card-primary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > :first-child, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > :first-child, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > :first-child, .uk-overlay-primary .uk-subnav-pill > * > :first-child, .uk-offcanvas-bar .uk-subnav-pill > * > :first-child {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-subnav-pill > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:hover, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:hover, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:hover, .uk-overlay-primary .uk-subnav-pill > * > a:hover, .uk-offcanvas-bar .uk-subnav-pill > * > a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav-pill > * > a:active, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > * > a:active, .uk-card-primary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:active, .uk-card-secondary.uk-card-body .uk-subnav-pill > * > a:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > * > a:active, .uk-overlay-primary .uk-subnav-pill > * > a:active, .uk-offcanvas-bar .uk-subnav-pill > * > a:active {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-subnav-pill > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav-pill > .uk-active > a, .uk-card-primary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav-pill > .uk-active > a, .uk-card-secondary.uk-card-body .uk-subnav-pill > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav-pill > .uk-active > a, .uk-overlay-primary .uk-subnav-pill > .uk-active > a, .uk-offcanvas-bar .uk-subnav-pill > .uk-active > a {
  background-color: #fff;
  color: #666;
}
.uk-light .uk-subnav > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-subnav > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-subnav > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-subnav > .uk-disabled > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-subnav > .uk-disabled > a, .uk-overlay-primary .uk-subnav > .uk-disabled > a, .uk-offcanvas-bar .uk-subnav > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-breadcrumb > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > *, .uk-card-primary.uk-card-body .uk-breadcrumb > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > * > *, .uk-overlay-primary .uk-breadcrumb > * > *, .uk-offcanvas-bar .uk-breadcrumb > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-breadcrumb > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > * > :hover, .uk-card-primary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > * > :hover, .uk-card-secondary.uk-card-body .uk-breadcrumb > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > * > :hover, .uk-overlay-primary .uk-breadcrumb > * > :hover, .uk-offcanvas-bar .uk-breadcrumb > * > :hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-breadcrumb > :last-child > *, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :last-child > *, .uk-card-primary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :last-child > *, .uk-card-secondary.uk-card-body .uk-breadcrumb > :last-child > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > :last-child > *, .uk-overlay-primary .uk-breadcrumb > :last-child > *, .uk-offcanvas-bar .uk-breadcrumb > :last-child > * {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-section-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-primary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary.uk-card-body .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-overlay-primary .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before, .uk-offcanvas-bar .uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-pagination > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > *, .uk-card-primary.uk-card-body .uk-pagination > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > *, .uk-card-secondary.uk-card-body .uk-pagination > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > * > *, .uk-overlay-primary .uk-pagination > * > *, .uk-offcanvas-bar .uk-pagination > * > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-pagination > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > * > :hover, .uk-card-primary.uk-card-body .uk-pagination > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > * > :hover, .uk-card-secondary.uk-card-body .uk-pagination > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > * > :hover, .uk-overlay-primary .uk-pagination > * > :hover, .uk-offcanvas-bar .uk-pagination > * > :hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-pagination > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-active > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-active > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-active > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > .uk-active > *, .uk-overlay-primary .uk-pagination > .uk-active > *, .uk-offcanvas-bar .uk-pagination > .uk-active > * {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-pagination > .uk-disabled > *, .uk-section-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-section-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-primary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-pagination > .uk-disabled > *, .uk-card-primary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-pagination > .uk-disabled > *, .uk-card-secondary.uk-card-body .uk-pagination > .uk-disabled > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-pagination > .uk-disabled > *, .uk-overlay-primary .uk-pagination > .uk-disabled > *, .uk-offcanvas-bar .uk-pagination > .uk-disabled > * {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab::before, .uk-section-primary:not(.uk-preserve-color) .uk-tab::before, .uk-section-secondary:not(.uk-preserve-color) .uk-tab::before, .uk-tile-primary:not(.uk-preserve-color) .uk-tab::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab::before, .uk-card-primary.uk-card-body .uk-tab::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab::before, .uk-card-secondary.uk-card-body .uk-tab::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab::before, .uk-overlay-primary .uk-tab::before, .uk-offcanvas-bar .uk-tab::before {
  border-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-tab > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a, .uk-card-primary.uk-card-body .uk-tab > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a, .uk-card-secondary.uk-card-body .uk-tab > * > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > * > a, .uk-overlay-primary .uk-tab > * > a, .uk-offcanvas-bar .uk-tab > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-tab > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > * > a:hover, .uk-card-primary.uk-card-body .uk-tab > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > * > a:hover, .uk-card-secondary.uk-card-body .uk-tab > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > * > a:hover, .uk-overlay-primary .uk-tab > * > a:hover, .uk-offcanvas-bar .uk-tab > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-tab > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-active > a, .uk-card-primary.uk-card-body .uk-tab > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-active > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > .uk-active > a, .uk-overlay-primary .uk-tab > .uk-active > a, .uk-offcanvas-bar .uk-tab > .uk-active > a {
  color: #fff;
  border-color: #fff;
}
.uk-light .uk-tab > .uk-disabled > a, .uk-section-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-section-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-primary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-tab > .uk-disabled > a, .uk-card-primary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-tab > .uk-disabled > a, .uk-card-secondary.uk-card-body .uk-tab > .uk-disabled > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-tab > .uk-disabled > a, .uk-overlay-primary .uk-tab > .uk-disabled > a, .uk-offcanvas-bar .uk-tab > .uk-disabled > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav, .uk-overlay-primary .uk-slidenav, .uk-offcanvas-bar .uk-slidenav {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-slidenav:hover, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:hover, .uk-card-primary.uk-card-body .uk-slidenav:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:hover, .uk-card-secondary.uk-card-body .uk-slidenav:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav:hover, .uk-overlay-primary .uk-slidenav:hover, .uk-offcanvas-bar .uk-slidenav:hover {
  color: rgba(255, 255, 255, 0.95);
}
.uk-light .uk-slidenav:active, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav:active, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav:active, .uk-card-primary.uk-card-body .uk-slidenav:active, .uk-card-primary > :not([class*=uk-card-media]) .uk-slidenav:active, .uk-card-secondary.uk-card-body .uk-slidenav:active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-slidenav:active, .uk-overlay-primary .uk-slidenav:active, .uk-offcanvas-bar .uk-slidenav:active {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-dotnav > * > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > *, .uk-card-primary.uk-card-body .uk-dotnav > * > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > *, .uk-card-secondary.uk-card-body .uk-dotnav > * > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > *, .uk-overlay-primary .uk-dotnav > * > *, .uk-offcanvas-bar .uk-dotnav > * > * {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.9);
}
.uk-light .uk-dotnav > * > :hover, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :hover, .uk-card-primary.uk-card-body .uk-dotnav > * > :hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :hover, .uk-card-secondary.uk-card-body .uk-dotnav > * > :hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > :hover, .uk-overlay-primary .uk-dotnav > * > :hover, .uk-offcanvas-bar .uk-dotnav > * > :hover {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}
.uk-light .uk-dotnav > * > :active, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > * > :active, .uk-card-primary.uk-card-body .uk-dotnav > * > :active, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > * > :active, .uk-card-secondary.uk-card-body .uk-dotnav > * > :active, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > * > :active, .uk-overlay-primary .uk-dotnav > * > :active, .uk-offcanvas-bar .uk-dotnav > * > :active {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: transparent;
}
.uk-light .uk-dotnav > .uk-active > *, .uk-section-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-section-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-primary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-tile-secondary:not(.uk-preserve-color) .uk-dotnav > .uk-active > *, .uk-card-primary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-primary > :not([class*=uk-card-media]) .uk-dotnav > .uk-active > *, .uk-card-secondary.uk-card-body .uk-dotnav > .uk-active > *, .uk-card-secondary > :not([class*=uk-card-media]) .uk-dotnav > .uk-active > *, .uk-overlay-primary .uk-dotnav > .uk-active > *, .uk-offcanvas-bar .uk-dotnav > .uk-active > * {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
}
.uk-light .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-section-secondary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-tile-primary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-accordion-title::before, .uk-card-secondary.uk-card-body .uk-accordion-title::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-accordion-title::before, .uk-overlay-primary .uk-accordion-title::before, .uk-offcanvas-bar .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-open > .uk-accordion-title::before, .uk-section-primary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-section-secondary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-tile-primary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-tile-secondary:not(.uk-preserve-color) .uk-open > .uk-accordion-title::before, .uk-card-primary.uk-card-body .uk-open > .uk-accordion-title::before, .uk-card-primary > :not([class*=uk-card-media]) .uk-open > .uk-accordion-title::before, .uk-card-secondary.uk-card-body .uk-open > .uk-accordion-title::before, .uk-card-secondary > :not([class*=uk-card-media]) .uk-open > .uk-accordion-title::before, .uk-overlay-primary .uk-open > .uk-accordion-title::before, .uk-offcanvas-bar .uk-open > .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22rgba(255, 255, 255, 0.7)%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E");
}
.uk-light .uk-iconnav > * > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a, .uk-card-primary.uk-card-body .uk-iconnav > * > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a, .uk-card-secondary.uk-card-body .uk-iconnav > * > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > * > a, .uk-overlay-primary .uk-iconnav > * > a, .uk-offcanvas-bar .uk-iconnav > * > a {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-iconnav > * > a:hover, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > * > a:hover, .uk-card-primary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > * > a:hover, .uk-card-secondary.uk-card-body .uk-iconnav > * > a:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > * > a:hover, .uk-overlay-primary .uk-iconnav > * > a:hover, .uk-offcanvas-bar .uk-iconnav > * > a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-iconnav > .uk-active > a, .uk-section-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-section-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-primary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-tile-secondary:not(.uk-preserve-color) .uk-iconnav > .uk-active > a, .uk-card-primary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-primary > :not([class*=uk-card-media]) .uk-iconnav > .uk-active > a, .uk-card-secondary.uk-card-body .uk-iconnav > .uk-active > a, .uk-card-secondary > :not([class*=uk-card-media]) .uk-iconnav > .uk-active > a, .uk-overlay-primary .uk-iconnav > .uk-active > a, .uk-offcanvas-bar .uk-iconnav > .uk-active > a {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-text-lead, .uk-section-primary:not(.uk-preserve-color) .uk-text-lead, .uk-section-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-primary:not(.uk-preserve-color) .uk-text-lead, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-lead, .uk-card-primary.uk-card-body .uk-text-lead, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-lead, .uk-card-secondary.uk-card-body .uk-text-lead, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-lead, .uk-overlay-primary .uk-text-lead, .uk-offcanvas-bar .uk-text-lead {
  color: rgba(255, 255, 255, 0.7);
}
.uk-light .uk-text-meta, .uk-section-primary:not(.uk-preserve-color) .uk-text-meta, .uk-section-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-primary:not(.uk-preserve-color) .uk-text-meta, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-meta, .uk-card-primary.uk-card-body .uk-text-meta, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-meta, .uk-card-secondary.uk-card-body .uk-text-meta, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-meta, .uk-overlay-primary .uk-text-meta, .uk-offcanvas-bar .uk-text-meta {
  color: rgba(255, 255, 255, 0.5);
}
.uk-light .uk-text-muted, .uk-section-primary:not(.uk-preserve-color) .uk-text-muted, .uk-section-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-primary:not(.uk-preserve-color) .uk-text-muted, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-muted, .uk-card-primary.uk-card-body .uk-text-muted, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-muted, .uk-card-secondary.uk-card-body .uk-text-muted, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-muted, .uk-overlay-primary .uk-text-muted, .uk-offcanvas-bar .uk-text-muted {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-light .uk-text-emphasis, .uk-section-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-section-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-primary:not(.uk-preserve-color) .uk-text-emphasis, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-emphasis, .uk-card-primary.uk-card-body .uk-text-emphasis, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-emphasis, .uk-card-secondary.uk-card-body .uk-text-emphasis, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-emphasis, .uk-overlay-primary .uk-text-emphasis, .uk-offcanvas-bar .uk-text-emphasis {
  color: #fff !important;
}
.uk-light .uk-text-primary, .uk-section-primary:not(.uk-preserve-color) .uk-text-primary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-primary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-primary, .uk-card-primary.uk-card-body .uk-text-primary, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-primary, .uk-card-secondary.uk-card-body .uk-text-primary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-primary, .uk-overlay-primary .uk-text-primary, .uk-offcanvas-bar .uk-text-primary {
  color: #fff !important;
}
.uk-light .uk-text-secondary, .uk-section-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-section-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-primary:not(.uk-preserve-color) .uk-text-secondary, .uk-tile-secondary:not(.uk-preserve-color) .uk-text-secondary, .uk-card-primary.uk-card-body .uk-text-secondary, .uk-card-primary > :not([class*=uk-card-media]) .uk-text-secondary, .uk-card-secondary.uk-card-body .uk-text-secondary, .uk-card-secondary > :not([class*=uk-card-media]) .uk-text-secondary, .uk-overlay-primary .uk-text-secondary, .uk-offcanvas-bar .uk-text-secondary {
  color: #fff !important;
}
.uk-light .uk-column-divider, .uk-section-primary:not(.uk-preserve-color) .uk-column-divider, .uk-section-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-primary:not(.uk-preserve-color) .uk-column-divider, .uk-tile-secondary:not(.uk-preserve-color) .uk-column-divider, .uk-card-primary.uk-card-body .uk-column-divider, .uk-card-primary > :not([class*=uk-card-media]) .uk-column-divider, .uk-card-secondary.uk-card-body .uk-column-divider, .uk-card-secondary > :not([class*=uk-card-media]) .uk-column-divider, .uk-overlay-primary .uk-column-divider, .uk-offcanvas-bar .uk-column-divider {
  column-rule-color: rgba(255, 255, 255, 0.2);
}
.uk-light .uk-logo, .uk-section-primary:not(.uk-preserve-color) .uk-logo, .uk-section-secondary:not(.uk-preserve-color) .uk-logo, .uk-tile-primary:not(.uk-preserve-color) .uk-logo, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo, .uk-card-primary.uk-card-body .uk-logo, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo, .uk-card-secondary.uk-card-body .uk-logo, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo, .uk-overlay-primary .uk-logo, .uk-offcanvas-bar .uk-logo {
  color: #fff;
}
.uk-light .uk-logo:hover, .uk-section-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-section-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-primary:not(.uk-preserve-color) .uk-logo:hover, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo:hover, .uk-card-primary.uk-card-body .uk-logo:hover, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo:hover, .uk-card-secondary.uk-card-body .uk-logo:hover, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo:hover, .uk-overlay-primary .uk-logo:hover, .uk-offcanvas-bar .uk-logo:hover {
  color: #fff;
}
.uk-light .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-section-primary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-section-secondary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-tile-primary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-tile-secondary:not(.uk-preserve-color) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-primary.uk-card-body .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-primary > :not([class*=uk-card-media]) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-secondary.uk-card-body .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-overlay-primary .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse), .uk-offcanvas-bar .uk-logo > picture:not(:only-of-type) > :not(.uk-logo-inverse),
.uk-light .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-section-primary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-section-secondary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-primary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-tile-secondary:not(.uk-preserve-color) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary.uk-card-body .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-primary > :not([class*=uk-card-media]) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary.uk-card-body .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-card-secondary > :not([class*=uk-card-media]) .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-overlay-primary .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type),
.uk-offcanvas-bar .uk-logo > :not(picture):not(.uk-logo-inverse):not(:only-of-type) {
  display: none;
}
.uk-light .uk-logo-inverse, .uk-section-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-section-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-primary:not(.uk-preserve-color) .uk-logo-inverse, .uk-tile-secondary:not(.uk-preserve-color) .uk-logo-inverse, .uk-card-primary.uk-card-body .uk-logo-inverse, .uk-card-primary > :not([class*=uk-card-media]) .uk-logo-inverse, .uk-card-secondary.uk-card-body .uk-logo-inverse, .uk-card-secondary > :not([class*=uk-card-media]) .uk-logo-inverse, .uk-overlay-primary .uk-logo-inverse, .uk-offcanvas-bar .uk-logo-inverse {
  display: block;
}

/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
*::before,
*::after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
a:visited {
    text-decoration: underline;
  }
  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
}
* {
  box-sizing: border-box;
}

html,
body {
  word-break: break-word;
  overflow-x: hidden;
}

.w-1 {
  width: 1px;
}

.maxw-1 {
  max-width: 1px;
}

.minw-1 {
  min-width: 1px;
}

@media (min-width: 320px) {
  .w-1\@sx {
    width: 1px;
  }
  .maxw-1\@sx {
    max-width: 1px;
  }
  .minw-1\@sx {
    min-width: 1px;
  }
}
@media (min-width: 640px) {
  .w-1\@s {
    width: 1px;
  }
  .maxw-1\@s {
    max-width: 1px;
  }
  .minw-1\@s {
    min-width: 1px;
  }
}
@media (min-width: 960px) {
  .w-1\@m {
    width: 1px;
  }
  .maxw-1\@m {
    max-width: 1px;
  }
  .minw-1\@m {
    min-width: 1px;
  }
}
@media (min-width: 1200px) {
  .w-1\@l {
    width: 1px;
  }
  .maxw-1\@l {
    max-width: 1px;
  }
  .minw-1\@l {
    min-width: 1px;
  }
}
@media (min-width: 1600px) {
  .w-1\@xl {
    width: 1px;
  }
  .maxw-1\@xl {
    max-width: 1px;
  }
  .minw-1\@xl {
    min-width: 1px;
  }
}
.w-2 {
  width: 2px;
}

.maxw-2 {
  max-width: 2px;
}

.minw-2 {
  min-width: 2px;
}

@media (min-width: 320px) {
  .w-2\@sx {
    width: 2px;
  }
  .maxw-2\@sx {
    max-width: 2px;
  }
  .minw-2\@sx {
    min-width: 2px;
  }
}
@media (min-width: 640px) {
  .w-2\@s {
    width: 2px;
  }
  .maxw-2\@s {
    max-width: 2px;
  }
  .minw-2\@s {
    min-width: 2px;
  }
}
@media (min-width: 960px) {
  .w-2\@m {
    width: 2px;
  }
  .maxw-2\@m {
    max-width: 2px;
  }
  .minw-2\@m {
    min-width: 2px;
  }
}
@media (min-width: 1200px) {
  .w-2\@l {
    width: 2px;
  }
  .maxw-2\@l {
    max-width: 2px;
  }
  .minw-2\@l {
    min-width: 2px;
  }
}
@media (min-width: 1600px) {
  .w-2\@xl {
    width: 2px;
  }
  .maxw-2\@xl {
    max-width: 2px;
  }
  .minw-2\@xl {
    min-width: 2px;
  }
}
.w-3 {
  width: 3px;
}

.maxw-3 {
  max-width: 3px;
}

.minw-3 {
  min-width: 3px;
}

@media (min-width: 320px) {
  .w-3\@sx {
    width: 3px;
  }
  .maxw-3\@sx {
    max-width: 3px;
  }
  .minw-3\@sx {
    min-width: 3px;
  }
}
@media (min-width: 640px) {
  .w-3\@s {
    width: 3px;
  }
  .maxw-3\@s {
    max-width: 3px;
  }
  .minw-3\@s {
    min-width: 3px;
  }
}
@media (min-width: 960px) {
  .w-3\@m {
    width: 3px;
  }
  .maxw-3\@m {
    max-width: 3px;
  }
  .minw-3\@m {
    min-width: 3px;
  }
}
@media (min-width: 1200px) {
  .w-3\@l {
    width: 3px;
  }
  .maxw-3\@l {
    max-width: 3px;
  }
  .minw-3\@l {
    min-width: 3px;
  }
}
@media (min-width: 1600px) {
  .w-3\@xl {
    width: 3px;
  }
  .maxw-3\@xl {
    max-width: 3px;
  }
  .minw-3\@xl {
    min-width: 3px;
  }
}
.w-4 {
  width: 4px;
}

.maxw-4 {
  max-width: 4px;
}

.minw-4 {
  min-width: 4px;
}

@media (min-width: 320px) {
  .w-4\@sx {
    width: 4px;
  }
  .maxw-4\@sx {
    max-width: 4px;
  }
  .minw-4\@sx {
    min-width: 4px;
  }
}
@media (min-width: 640px) {
  .w-4\@s {
    width: 4px;
  }
  .maxw-4\@s {
    max-width: 4px;
  }
  .minw-4\@s {
    min-width: 4px;
  }
}
@media (min-width: 960px) {
  .w-4\@m {
    width: 4px;
  }
  .maxw-4\@m {
    max-width: 4px;
  }
  .minw-4\@m {
    min-width: 4px;
  }
}
@media (min-width: 1200px) {
  .w-4\@l {
    width: 4px;
  }
  .maxw-4\@l {
    max-width: 4px;
  }
  .minw-4\@l {
    min-width: 4px;
  }
}
@media (min-width: 1600px) {
  .w-4\@xl {
    width: 4px;
  }
  .maxw-4\@xl {
    max-width: 4px;
  }
  .minw-4\@xl {
    min-width: 4px;
  }
}
.w-5 {
  width: 5px;
}

.maxw-5 {
  max-width: 5px;
}

.minw-5 {
  min-width: 5px;
}

@media (min-width: 320px) {
  .w-5\@sx {
    width: 5px;
  }
  .maxw-5\@sx {
    max-width: 5px;
  }
  .minw-5\@sx {
    min-width: 5px;
  }
}
@media (min-width: 640px) {
  .w-5\@s {
    width: 5px;
  }
  .maxw-5\@s {
    max-width: 5px;
  }
  .minw-5\@s {
    min-width: 5px;
  }
}
@media (min-width: 960px) {
  .w-5\@m {
    width: 5px;
  }
  .maxw-5\@m {
    max-width: 5px;
  }
  .minw-5\@m {
    min-width: 5px;
  }
}
@media (min-width: 1200px) {
  .w-5\@l {
    width: 5px;
  }
  .maxw-5\@l {
    max-width: 5px;
  }
  .minw-5\@l {
    min-width: 5px;
  }
}
@media (min-width: 1600px) {
  .w-5\@xl {
    width: 5px;
  }
  .maxw-5\@xl {
    max-width: 5px;
  }
  .minw-5\@xl {
    min-width: 5px;
  }
}
.w-6 {
  width: 6px;
}

.maxw-6 {
  max-width: 6px;
}

.minw-6 {
  min-width: 6px;
}

@media (min-width: 320px) {
  .w-6\@sx {
    width: 6px;
  }
  .maxw-6\@sx {
    max-width: 6px;
  }
  .minw-6\@sx {
    min-width: 6px;
  }
}
@media (min-width: 640px) {
  .w-6\@s {
    width: 6px;
  }
  .maxw-6\@s {
    max-width: 6px;
  }
  .minw-6\@s {
    min-width: 6px;
  }
}
@media (min-width: 960px) {
  .w-6\@m {
    width: 6px;
  }
  .maxw-6\@m {
    max-width: 6px;
  }
  .minw-6\@m {
    min-width: 6px;
  }
}
@media (min-width: 1200px) {
  .w-6\@l {
    width: 6px;
  }
  .maxw-6\@l {
    max-width: 6px;
  }
  .minw-6\@l {
    min-width: 6px;
  }
}
@media (min-width: 1600px) {
  .w-6\@xl {
    width: 6px;
  }
  .maxw-6\@xl {
    max-width: 6px;
  }
  .minw-6\@xl {
    min-width: 6px;
  }
}
.w-7 {
  width: 7px;
}

.maxw-7 {
  max-width: 7px;
}

.minw-7 {
  min-width: 7px;
}

@media (min-width: 320px) {
  .w-7\@sx {
    width: 7px;
  }
  .maxw-7\@sx {
    max-width: 7px;
  }
  .minw-7\@sx {
    min-width: 7px;
  }
}
@media (min-width: 640px) {
  .w-7\@s {
    width: 7px;
  }
  .maxw-7\@s {
    max-width: 7px;
  }
  .minw-7\@s {
    min-width: 7px;
  }
}
@media (min-width: 960px) {
  .w-7\@m {
    width: 7px;
  }
  .maxw-7\@m {
    max-width: 7px;
  }
  .minw-7\@m {
    min-width: 7px;
  }
}
@media (min-width: 1200px) {
  .w-7\@l {
    width: 7px;
  }
  .maxw-7\@l {
    max-width: 7px;
  }
  .minw-7\@l {
    min-width: 7px;
  }
}
@media (min-width: 1600px) {
  .w-7\@xl {
    width: 7px;
  }
  .maxw-7\@xl {
    max-width: 7px;
  }
  .minw-7\@xl {
    min-width: 7px;
  }
}
.w-8 {
  width: 8px;
}

.maxw-8 {
  max-width: 8px;
}

.minw-8 {
  min-width: 8px;
}

@media (min-width: 320px) {
  .w-8\@sx {
    width: 8px;
  }
  .maxw-8\@sx {
    max-width: 8px;
  }
  .minw-8\@sx {
    min-width: 8px;
  }
}
@media (min-width: 640px) {
  .w-8\@s {
    width: 8px;
  }
  .maxw-8\@s {
    max-width: 8px;
  }
  .minw-8\@s {
    min-width: 8px;
  }
}
@media (min-width: 960px) {
  .w-8\@m {
    width: 8px;
  }
  .maxw-8\@m {
    max-width: 8px;
  }
  .minw-8\@m {
    min-width: 8px;
  }
}
@media (min-width: 1200px) {
  .w-8\@l {
    width: 8px;
  }
  .maxw-8\@l {
    max-width: 8px;
  }
  .minw-8\@l {
    min-width: 8px;
  }
}
@media (min-width: 1600px) {
  .w-8\@xl {
    width: 8px;
  }
  .maxw-8\@xl {
    max-width: 8px;
  }
  .minw-8\@xl {
    min-width: 8px;
  }
}
.w-9 {
  width: 9px;
}

.maxw-9 {
  max-width: 9px;
}

.minw-9 {
  min-width: 9px;
}

@media (min-width: 320px) {
  .w-9\@sx {
    width: 9px;
  }
  .maxw-9\@sx {
    max-width: 9px;
  }
  .minw-9\@sx {
    min-width: 9px;
  }
}
@media (min-width: 640px) {
  .w-9\@s {
    width: 9px;
  }
  .maxw-9\@s {
    max-width: 9px;
  }
  .minw-9\@s {
    min-width: 9px;
  }
}
@media (min-width: 960px) {
  .w-9\@m {
    width: 9px;
  }
  .maxw-9\@m {
    max-width: 9px;
  }
  .minw-9\@m {
    min-width: 9px;
  }
}
@media (min-width: 1200px) {
  .w-9\@l {
    width: 9px;
  }
  .maxw-9\@l {
    max-width: 9px;
  }
  .minw-9\@l {
    min-width: 9px;
  }
}
@media (min-width: 1600px) {
  .w-9\@xl {
    width: 9px;
  }
  .maxw-9\@xl {
    max-width: 9px;
  }
  .minw-9\@xl {
    min-width: 9px;
  }
}
.w-10 {
  width: 10px;
}

.maxw-10 {
  max-width: 10px;
}

.minw-10 {
  min-width: 10px;
}

@media (min-width: 320px) {
  .w-10\@sx {
    width: 10px;
  }
  .maxw-10\@sx {
    max-width: 10px;
  }
  .minw-10\@sx {
    min-width: 10px;
  }
}
@media (min-width: 640px) {
  .w-10\@s {
    width: 10px;
  }
  .maxw-10\@s {
    max-width: 10px;
  }
  .minw-10\@s {
    min-width: 10px;
  }
}
@media (min-width: 960px) {
  .w-10\@m {
    width: 10px;
  }
  .maxw-10\@m {
    max-width: 10px;
  }
  .minw-10\@m {
    min-width: 10px;
  }
}
@media (min-width: 1200px) {
  .w-10\@l {
    width: 10px;
  }
  .maxw-10\@l {
    max-width: 10px;
  }
  .minw-10\@l {
    min-width: 10px;
  }
}
@media (min-width: 1600px) {
  .w-10\@xl {
    width: 10px;
  }
  .maxw-10\@xl {
    max-width: 10px;
  }
  .minw-10\@xl {
    min-width: 10px;
  }
}
.w-11 {
  width: 11px;
}

.maxw-11 {
  max-width: 11px;
}

.minw-11 {
  min-width: 11px;
}

@media (min-width: 320px) {
  .w-11\@sx {
    width: 11px;
  }
  .maxw-11\@sx {
    max-width: 11px;
  }
  .minw-11\@sx {
    min-width: 11px;
  }
}
@media (min-width: 640px) {
  .w-11\@s {
    width: 11px;
  }
  .maxw-11\@s {
    max-width: 11px;
  }
  .minw-11\@s {
    min-width: 11px;
  }
}
@media (min-width: 960px) {
  .w-11\@m {
    width: 11px;
  }
  .maxw-11\@m {
    max-width: 11px;
  }
  .minw-11\@m {
    min-width: 11px;
  }
}
@media (min-width: 1200px) {
  .w-11\@l {
    width: 11px;
  }
  .maxw-11\@l {
    max-width: 11px;
  }
  .minw-11\@l {
    min-width: 11px;
  }
}
@media (min-width: 1600px) {
  .w-11\@xl {
    width: 11px;
  }
  .maxw-11\@xl {
    max-width: 11px;
  }
  .minw-11\@xl {
    min-width: 11px;
  }
}
.w-12 {
  width: 12px;
}

.maxw-12 {
  max-width: 12px;
}

.minw-12 {
  min-width: 12px;
}

@media (min-width: 320px) {
  .w-12\@sx {
    width: 12px;
  }
  .maxw-12\@sx {
    max-width: 12px;
  }
  .minw-12\@sx {
    min-width: 12px;
  }
}
@media (min-width: 640px) {
  .w-12\@s {
    width: 12px;
  }
  .maxw-12\@s {
    max-width: 12px;
  }
  .minw-12\@s {
    min-width: 12px;
  }
}
@media (min-width: 960px) {
  .w-12\@m {
    width: 12px;
  }
  .maxw-12\@m {
    max-width: 12px;
  }
  .minw-12\@m {
    min-width: 12px;
  }
}
@media (min-width: 1200px) {
  .w-12\@l {
    width: 12px;
  }
  .maxw-12\@l {
    max-width: 12px;
  }
  .minw-12\@l {
    min-width: 12px;
  }
}
@media (min-width: 1600px) {
  .w-12\@xl {
    width: 12px;
  }
  .maxw-12\@xl {
    max-width: 12px;
  }
  .minw-12\@xl {
    min-width: 12px;
  }
}
.w-13 {
  width: 13px;
}

.maxw-13 {
  max-width: 13px;
}

.minw-13 {
  min-width: 13px;
}

@media (min-width: 320px) {
  .w-13\@sx {
    width: 13px;
  }
  .maxw-13\@sx {
    max-width: 13px;
  }
  .minw-13\@sx {
    min-width: 13px;
  }
}
@media (min-width: 640px) {
  .w-13\@s {
    width: 13px;
  }
  .maxw-13\@s {
    max-width: 13px;
  }
  .minw-13\@s {
    min-width: 13px;
  }
}
@media (min-width: 960px) {
  .w-13\@m {
    width: 13px;
  }
  .maxw-13\@m {
    max-width: 13px;
  }
  .minw-13\@m {
    min-width: 13px;
  }
}
@media (min-width: 1200px) {
  .w-13\@l {
    width: 13px;
  }
  .maxw-13\@l {
    max-width: 13px;
  }
  .minw-13\@l {
    min-width: 13px;
  }
}
@media (min-width: 1600px) {
  .w-13\@xl {
    width: 13px;
  }
  .maxw-13\@xl {
    max-width: 13px;
  }
  .minw-13\@xl {
    min-width: 13px;
  }
}
.w-14 {
  width: 14px;
}

.maxw-14 {
  max-width: 14px;
}

.minw-14 {
  min-width: 14px;
}

@media (min-width: 320px) {
  .w-14\@sx {
    width: 14px;
  }
  .maxw-14\@sx {
    max-width: 14px;
  }
  .minw-14\@sx {
    min-width: 14px;
  }
}
@media (min-width: 640px) {
  .w-14\@s {
    width: 14px;
  }
  .maxw-14\@s {
    max-width: 14px;
  }
  .minw-14\@s {
    min-width: 14px;
  }
}
@media (min-width: 960px) {
  .w-14\@m {
    width: 14px;
  }
  .maxw-14\@m {
    max-width: 14px;
  }
  .minw-14\@m {
    min-width: 14px;
  }
}
@media (min-width: 1200px) {
  .w-14\@l {
    width: 14px;
  }
  .maxw-14\@l {
    max-width: 14px;
  }
  .minw-14\@l {
    min-width: 14px;
  }
}
@media (min-width: 1600px) {
  .w-14\@xl {
    width: 14px;
  }
  .maxw-14\@xl {
    max-width: 14px;
  }
  .minw-14\@xl {
    min-width: 14px;
  }
}
.w-15 {
  width: 15px;
}

.maxw-15 {
  max-width: 15px;
}

.minw-15 {
  min-width: 15px;
}

@media (min-width: 320px) {
  .w-15\@sx {
    width: 15px;
  }
  .maxw-15\@sx {
    max-width: 15px;
  }
  .minw-15\@sx {
    min-width: 15px;
  }
}
@media (min-width: 640px) {
  .w-15\@s {
    width: 15px;
  }
  .maxw-15\@s {
    max-width: 15px;
  }
  .minw-15\@s {
    min-width: 15px;
  }
}
@media (min-width: 960px) {
  .w-15\@m {
    width: 15px;
  }
  .maxw-15\@m {
    max-width: 15px;
  }
  .minw-15\@m {
    min-width: 15px;
  }
}
@media (min-width: 1200px) {
  .w-15\@l {
    width: 15px;
  }
  .maxw-15\@l {
    max-width: 15px;
  }
  .minw-15\@l {
    min-width: 15px;
  }
}
@media (min-width: 1600px) {
  .w-15\@xl {
    width: 15px;
  }
  .maxw-15\@xl {
    max-width: 15px;
  }
  .minw-15\@xl {
    min-width: 15px;
  }
}
.w-16 {
  width: 16px;
}

.maxw-16 {
  max-width: 16px;
}

.minw-16 {
  min-width: 16px;
}

@media (min-width: 320px) {
  .w-16\@sx {
    width: 16px;
  }
  .maxw-16\@sx {
    max-width: 16px;
  }
  .minw-16\@sx {
    min-width: 16px;
  }
}
@media (min-width: 640px) {
  .w-16\@s {
    width: 16px;
  }
  .maxw-16\@s {
    max-width: 16px;
  }
  .minw-16\@s {
    min-width: 16px;
  }
}
@media (min-width: 960px) {
  .w-16\@m {
    width: 16px;
  }
  .maxw-16\@m {
    max-width: 16px;
  }
  .minw-16\@m {
    min-width: 16px;
  }
}
@media (min-width: 1200px) {
  .w-16\@l {
    width: 16px;
  }
  .maxw-16\@l {
    max-width: 16px;
  }
  .minw-16\@l {
    min-width: 16px;
  }
}
@media (min-width: 1600px) {
  .w-16\@xl {
    width: 16px;
  }
  .maxw-16\@xl {
    max-width: 16px;
  }
  .minw-16\@xl {
    min-width: 16px;
  }
}
.w-17 {
  width: 17px;
}

.maxw-17 {
  max-width: 17px;
}

.minw-17 {
  min-width: 17px;
}

@media (min-width: 320px) {
  .w-17\@sx {
    width: 17px;
  }
  .maxw-17\@sx {
    max-width: 17px;
  }
  .minw-17\@sx {
    min-width: 17px;
  }
}
@media (min-width: 640px) {
  .w-17\@s {
    width: 17px;
  }
  .maxw-17\@s {
    max-width: 17px;
  }
  .minw-17\@s {
    min-width: 17px;
  }
}
@media (min-width: 960px) {
  .w-17\@m {
    width: 17px;
  }
  .maxw-17\@m {
    max-width: 17px;
  }
  .minw-17\@m {
    min-width: 17px;
  }
}
@media (min-width: 1200px) {
  .w-17\@l {
    width: 17px;
  }
  .maxw-17\@l {
    max-width: 17px;
  }
  .minw-17\@l {
    min-width: 17px;
  }
}
@media (min-width: 1600px) {
  .w-17\@xl {
    width: 17px;
  }
  .maxw-17\@xl {
    max-width: 17px;
  }
  .minw-17\@xl {
    min-width: 17px;
  }
}
.w-18 {
  width: 18px;
}

.maxw-18 {
  max-width: 18px;
}

.minw-18 {
  min-width: 18px;
}

@media (min-width: 320px) {
  .w-18\@sx {
    width: 18px;
  }
  .maxw-18\@sx {
    max-width: 18px;
  }
  .minw-18\@sx {
    min-width: 18px;
  }
}
@media (min-width: 640px) {
  .w-18\@s {
    width: 18px;
  }
  .maxw-18\@s {
    max-width: 18px;
  }
  .minw-18\@s {
    min-width: 18px;
  }
}
@media (min-width: 960px) {
  .w-18\@m {
    width: 18px;
  }
  .maxw-18\@m {
    max-width: 18px;
  }
  .minw-18\@m {
    min-width: 18px;
  }
}
@media (min-width: 1200px) {
  .w-18\@l {
    width: 18px;
  }
  .maxw-18\@l {
    max-width: 18px;
  }
  .minw-18\@l {
    min-width: 18px;
  }
}
@media (min-width: 1600px) {
  .w-18\@xl {
    width: 18px;
  }
  .maxw-18\@xl {
    max-width: 18px;
  }
  .minw-18\@xl {
    min-width: 18px;
  }
}
.w-19 {
  width: 19px;
}

.maxw-19 {
  max-width: 19px;
}

.minw-19 {
  min-width: 19px;
}

@media (min-width: 320px) {
  .w-19\@sx {
    width: 19px;
  }
  .maxw-19\@sx {
    max-width: 19px;
  }
  .minw-19\@sx {
    min-width: 19px;
  }
}
@media (min-width: 640px) {
  .w-19\@s {
    width: 19px;
  }
  .maxw-19\@s {
    max-width: 19px;
  }
  .minw-19\@s {
    min-width: 19px;
  }
}
@media (min-width: 960px) {
  .w-19\@m {
    width: 19px;
  }
  .maxw-19\@m {
    max-width: 19px;
  }
  .minw-19\@m {
    min-width: 19px;
  }
}
@media (min-width: 1200px) {
  .w-19\@l {
    width: 19px;
  }
  .maxw-19\@l {
    max-width: 19px;
  }
  .minw-19\@l {
    min-width: 19px;
  }
}
@media (min-width: 1600px) {
  .w-19\@xl {
    width: 19px;
  }
  .maxw-19\@xl {
    max-width: 19px;
  }
  .minw-19\@xl {
    min-width: 19px;
  }
}
.w-20 {
  width: 20px;
}

.maxw-20 {
  max-width: 20px;
}

.minw-20 {
  min-width: 20px;
}

@media (min-width: 320px) {
  .w-20\@sx {
    width: 20px;
  }
  .maxw-20\@sx {
    max-width: 20px;
  }
  .minw-20\@sx {
    min-width: 20px;
  }
}
@media (min-width: 640px) {
  .w-20\@s {
    width: 20px;
  }
  .maxw-20\@s {
    max-width: 20px;
  }
  .minw-20\@s {
    min-width: 20px;
  }
}
@media (min-width: 960px) {
  .w-20\@m {
    width: 20px;
  }
  .maxw-20\@m {
    max-width: 20px;
  }
  .minw-20\@m {
    min-width: 20px;
  }
}
@media (min-width: 1200px) {
  .w-20\@l {
    width: 20px;
  }
  .maxw-20\@l {
    max-width: 20px;
  }
  .minw-20\@l {
    min-width: 20px;
  }
}
@media (min-width: 1600px) {
  .w-20\@xl {
    width: 20px;
  }
  .maxw-20\@xl {
    max-width: 20px;
  }
  .minw-20\@xl {
    min-width: 20px;
  }
}
.w-21 {
  width: 21px;
}

.maxw-21 {
  max-width: 21px;
}

.minw-21 {
  min-width: 21px;
}

@media (min-width: 320px) {
  .w-21\@sx {
    width: 21px;
  }
  .maxw-21\@sx {
    max-width: 21px;
  }
  .minw-21\@sx {
    min-width: 21px;
  }
}
@media (min-width: 640px) {
  .w-21\@s {
    width: 21px;
  }
  .maxw-21\@s {
    max-width: 21px;
  }
  .minw-21\@s {
    min-width: 21px;
  }
}
@media (min-width: 960px) {
  .w-21\@m {
    width: 21px;
  }
  .maxw-21\@m {
    max-width: 21px;
  }
  .minw-21\@m {
    min-width: 21px;
  }
}
@media (min-width: 1200px) {
  .w-21\@l {
    width: 21px;
  }
  .maxw-21\@l {
    max-width: 21px;
  }
  .minw-21\@l {
    min-width: 21px;
  }
}
@media (min-width: 1600px) {
  .w-21\@xl {
    width: 21px;
  }
  .maxw-21\@xl {
    max-width: 21px;
  }
  .minw-21\@xl {
    min-width: 21px;
  }
}
.w-22 {
  width: 22px;
}

.maxw-22 {
  max-width: 22px;
}

.minw-22 {
  min-width: 22px;
}

@media (min-width: 320px) {
  .w-22\@sx {
    width: 22px;
  }
  .maxw-22\@sx {
    max-width: 22px;
  }
  .minw-22\@sx {
    min-width: 22px;
  }
}
@media (min-width: 640px) {
  .w-22\@s {
    width: 22px;
  }
  .maxw-22\@s {
    max-width: 22px;
  }
  .minw-22\@s {
    min-width: 22px;
  }
}
@media (min-width: 960px) {
  .w-22\@m {
    width: 22px;
  }
  .maxw-22\@m {
    max-width: 22px;
  }
  .minw-22\@m {
    min-width: 22px;
  }
}
@media (min-width: 1200px) {
  .w-22\@l {
    width: 22px;
  }
  .maxw-22\@l {
    max-width: 22px;
  }
  .minw-22\@l {
    min-width: 22px;
  }
}
@media (min-width: 1600px) {
  .w-22\@xl {
    width: 22px;
  }
  .maxw-22\@xl {
    max-width: 22px;
  }
  .minw-22\@xl {
    min-width: 22px;
  }
}
.w-23 {
  width: 23px;
}

.maxw-23 {
  max-width: 23px;
}

.minw-23 {
  min-width: 23px;
}

@media (min-width: 320px) {
  .w-23\@sx {
    width: 23px;
  }
  .maxw-23\@sx {
    max-width: 23px;
  }
  .minw-23\@sx {
    min-width: 23px;
  }
}
@media (min-width: 640px) {
  .w-23\@s {
    width: 23px;
  }
  .maxw-23\@s {
    max-width: 23px;
  }
  .minw-23\@s {
    min-width: 23px;
  }
}
@media (min-width: 960px) {
  .w-23\@m {
    width: 23px;
  }
  .maxw-23\@m {
    max-width: 23px;
  }
  .minw-23\@m {
    min-width: 23px;
  }
}
@media (min-width: 1200px) {
  .w-23\@l {
    width: 23px;
  }
  .maxw-23\@l {
    max-width: 23px;
  }
  .minw-23\@l {
    min-width: 23px;
  }
}
@media (min-width: 1600px) {
  .w-23\@xl {
    width: 23px;
  }
  .maxw-23\@xl {
    max-width: 23px;
  }
  .minw-23\@xl {
    min-width: 23px;
  }
}
.w-24 {
  width: 24px;
}

.maxw-24 {
  max-width: 24px;
}

.minw-24 {
  min-width: 24px;
}

@media (min-width: 320px) {
  .w-24\@sx {
    width: 24px;
  }
  .maxw-24\@sx {
    max-width: 24px;
  }
  .minw-24\@sx {
    min-width: 24px;
  }
}
@media (min-width: 640px) {
  .w-24\@s {
    width: 24px;
  }
  .maxw-24\@s {
    max-width: 24px;
  }
  .minw-24\@s {
    min-width: 24px;
  }
}
@media (min-width: 960px) {
  .w-24\@m {
    width: 24px;
  }
  .maxw-24\@m {
    max-width: 24px;
  }
  .minw-24\@m {
    min-width: 24px;
  }
}
@media (min-width: 1200px) {
  .w-24\@l {
    width: 24px;
  }
  .maxw-24\@l {
    max-width: 24px;
  }
  .minw-24\@l {
    min-width: 24px;
  }
}
@media (min-width: 1600px) {
  .w-24\@xl {
    width: 24px;
  }
  .maxw-24\@xl {
    max-width: 24px;
  }
  .minw-24\@xl {
    min-width: 24px;
  }
}
.w-25 {
  width: 25px;
}

.maxw-25 {
  max-width: 25px;
}

.minw-25 {
  min-width: 25px;
}

@media (min-width: 320px) {
  .w-25\@sx {
    width: 25px;
  }
  .maxw-25\@sx {
    max-width: 25px;
  }
  .minw-25\@sx {
    min-width: 25px;
  }
}
@media (min-width: 640px) {
  .w-25\@s {
    width: 25px;
  }
  .maxw-25\@s {
    max-width: 25px;
  }
  .minw-25\@s {
    min-width: 25px;
  }
}
@media (min-width: 960px) {
  .w-25\@m {
    width: 25px;
  }
  .maxw-25\@m {
    max-width: 25px;
  }
  .minw-25\@m {
    min-width: 25px;
  }
}
@media (min-width: 1200px) {
  .w-25\@l {
    width: 25px;
  }
  .maxw-25\@l {
    max-width: 25px;
  }
  .minw-25\@l {
    min-width: 25px;
  }
}
@media (min-width: 1600px) {
  .w-25\@xl {
    width: 25px;
  }
  .maxw-25\@xl {
    max-width: 25px;
  }
  .minw-25\@xl {
    min-width: 25px;
  }
}
.w-26 {
  width: 26px;
}

.maxw-26 {
  max-width: 26px;
}

.minw-26 {
  min-width: 26px;
}

@media (min-width: 320px) {
  .w-26\@sx {
    width: 26px;
  }
  .maxw-26\@sx {
    max-width: 26px;
  }
  .minw-26\@sx {
    min-width: 26px;
  }
}
@media (min-width: 640px) {
  .w-26\@s {
    width: 26px;
  }
  .maxw-26\@s {
    max-width: 26px;
  }
  .minw-26\@s {
    min-width: 26px;
  }
}
@media (min-width: 960px) {
  .w-26\@m {
    width: 26px;
  }
  .maxw-26\@m {
    max-width: 26px;
  }
  .minw-26\@m {
    min-width: 26px;
  }
}
@media (min-width: 1200px) {
  .w-26\@l {
    width: 26px;
  }
  .maxw-26\@l {
    max-width: 26px;
  }
  .minw-26\@l {
    min-width: 26px;
  }
}
@media (min-width: 1600px) {
  .w-26\@xl {
    width: 26px;
  }
  .maxw-26\@xl {
    max-width: 26px;
  }
  .minw-26\@xl {
    min-width: 26px;
  }
}
.w-27 {
  width: 27px;
}

.maxw-27 {
  max-width: 27px;
}

.minw-27 {
  min-width: 27px;
}

@media (min-width: 320px) {
  .w-27\@sx {
    width: 27px;
  }
  .maxw-27\@sx {
    max-width: 27px;
  }
  .minw-27\@sx {
    min-width: 27px;
  }
}
@media (min-width: 640px) {
  .w-27\@s {
    width: 27px;
  }
  .maxw-27\@s {
    max-width: 27px;
  }
  .minw-27\@s {
    min-width: 27px;
  }
}
@media (min-width: 960px) {
  .w-27\@m {
    width: 27px;
  }
  .maxw-27\@m {
    max-width: 27px;
  }
  .minw-27\@m {
    min-width: 27px;
  }
}
@media (min-width: 1200px) {
  .w-27\@l {
    width: 27px;
  }
  .maxw-27\@l {
    max-width: 27px;
  }
  .minw-27\@l {
    min-width: 27px;
  }
}
@media (min-width: 1600px) {
  .w-27\@xl {
    width: 27px;
  }
  .maxw-27\@xl {
    max-width: 27px;
  }
  .minw-27\@xl {
    min-width: 27px;
  }
}
.w-28 {
  width: 28px;
}

.maxw-28 {
  max-width: 28px;
}

.minw-28 {
  min-width: 28px;
}

@media (min-width: 320px) {
  .w-28\@sx {
    width: 28px;
  }
  .maxw-28\@sx {
    max-width: 28px;
  }
  .minw-28\@sx {
    min-width: 28px;
  }
}
@media (min-width: 640px) {
  .w-28\@s {
    width: 28px;
  }
  .maxw-28\@s {
    max-width: 28px;
  }
  .minw-28\@s {
    min-width: 28px;
  }
}
@media (min-width: 960px) {
  .w-28\@m {
    width: 28px;
  }
  .maxw-28\@m {
    max-width: 28px;
  }
  .minw-28\@m {
    min-width: 28px;
  }
}
@media (min-width: 1200px) {
  .w-28\@l {
    width: 28px;
  }
  .maxw-28\@l {
    max-width: 28px;
  }
  .minw-28\@l {
    min-width: 28px;
  }
}
@media (min-width: 1600px) {
  .w-28\@xl {
    width: 28px;
  }
  .maxw-28\@xl {
    max-width: 28px;
  }
  .minw-28\@xl {
    min-width: 28px;
  }
}
.w-29 {
  width: 29px;
}

.maxw-29 {
  max-width: 29px;
}

.minw-29 {
  min-width: 29px;
}

@media (min-width: 320px) {
  .w-29\@sx {
    width: 29px;
  }
  .maxw-29\@sx {
    max-width: 29px;
  }
  .minw-29\@sx {
    min-width: 29px;
  }
}
@media (min-width: 640px) {
  .w-29\@s {
    width: 29px;
  }
  .maxw-29\@s {
    max-width: 29px;
  }
  .minw-29\@s {
    min-width: 29px;
  }
}
@media (min-width: 960px) {
  .w-29\@m {
    width: 29px;
  }
  .maxw-29\@m {
    max-width: 29px;
  }
  .minw-29\@m {
    min-width: 29px;
  }
}
@media (min-width: 1200px) {
  .w-29\@l {
    width: 29px;
  }
  .maxw-29\@l {
    max-width: 29px;
  }
  .minw-29\@l {
    min-width: 29px;
  }
}
@media (min-width: 1600px) {
  .w-29\@xl {
    width: 29px;
  }
  .maxw-29\@xl {
    max-width: 29px;
  }
  .minw-29\@xl {
    min-width: 29px;
  }
}
.w-30 {
  width: 30px;
}

.maxw-30 {
  max-width: 30px;
}

.minw-30 {
  min-width: 30px;
}

@media (min-width: 320px) {
  .w-30\@sx {
    width: 30px;
  }
  .maxw-30\@sx {
    max-width: 30px;
  }
  .minw-30\@sx {
    min-width: 30px;
  }
}
@media (min-width: 640px) {
  .w-30\@s {
    width: 30px;
  }
  .maxw-30\@s {
    max-width: 30px;
  }
  .minw-30\@s {
    min-width: 30px;
  }
}
@media (min-width: 960px) {
  .w-30\@m {
    width: 30px;
  }
  .maxw-30\@m {
    max-width: 30px;
  }
  .minw-30\@m {
    min-width: 30px;
  }
}
@media (min-width: 1200px) {
  .w-30\@l {
    width: 30px;
  }
  .maxw-30\@l {
    max-width: 30px;
  }
  .minw-30\@l {
    min-width: 30px;
  }
}
@media (min-width: 1600px) {
  .w-30\@xl {
    width: 30px;
  }
  .maxw-30\@xl {
    max-width: 30px;
  }
  .minw-30\@xl {
    min-width: 30px;
  }
}
.w-31 {
  width: 31px;
}

.maxw-31 {
  max-width: 31px;
}

.minw-31 {
  min-width: 31px;
}

@media (min-width: 320px) {
  .w-31\@sx {
    width: 31px;
  }
  .maxw-31\@sx {
    max-width: 31px;
  }
  .minw-31\@sx {
    min-width: 31px;
  }
}
@media (min-width: 640px) {
  .w-31\@s {
    width: 31px;
  }
  .maxw-31\@s {
    max-width: 31px;
  }
  .minw-31\@s {
    min-width: 31px;
  }
}
@media (min-width: 960px) {
  .w-31\@m {
    width: 31px;
  }
  .maxw-31\@m {
    max-width: 31px;
  }
  .minw-31\@m {
    min-width: 31px;
  }
}
@media (min-width: 1200px) {
  .w-31\@l {
    width: 31px;
  }
  .maxw-31\@l {
    max-width: 31px;
  }
  .minw-31\@l {
    min-width: 31px;
  }
}
@media (min-width: 1600px) {
  .w-31\@xl {
    width: 31px;
  }
  .maxw-31\@xl {
    max-width: 31px;
  }
  .minw-31\@xl {
    min-width: 31px;
  }
}
.w-32 {
  width: 32px;
}

.maxw-32 {
  max-width: 32px;
}

.minw-32 {
  min-width: 32px;
}

@media (min-width: 320px) {
  .w-32\@sx {
    width: 32px;
  }
  .maxw-32\@sx {
    max-width: 32px;
  }
  .minw-32\@sx {
    min-width: 32px;
  }
}
@media (min-width: 640px) {
  .w-32\@s {
    width: 32px;
  }
  .maxw-32\@s {
    max-width: 32px;
  }
  .minw-32\@s {
    min-width: 32px;
  }
}
@media (min-width: 960px) {
  .w-32\@m {
    width: 32px;
  }
  .maxw-32\@m {
    max-width: 32px;
  }
  .minw-32\@m {
    min-width: 32px;
  }
}
@media (min-width: 1200px) {
  .w-32\@l {
    width: 32px;
  }
  .maxw-32\@l {
    max-width: 32px;
  }
  .minw-32\@l {
    min-width: 32px;
  }
}
@media (min-width: 1600px) {
  .w-32\@xl {
    width: 32px;
  }
  .maxw-32\@xl {
    max-width: 32px;
  }
  .minw-32\@xl {
    min-width: 32px;
  }
}
.w-33 {
  width: 33px;
}

.maxw-33 {
  max-width: 33px;
}

.minw-33 {
  min-width: 33px;
}

@media (min-width: 320px) {
  .w-33\@sx {
    width: 33px;
  }
  .maxw-33\@sx {
    max-width: 33px;
  }
  .minw-33\@sx {
    min-width: 33px;
  }
}
@media (min-width: 640px) {
  .w-33\@s {
    width: 33px;
  }
  .maxw-33\@s {
    max-width: 33px;
  }
  .minw-33\@s {
    min-width: 33px;
  }
}
@media (min-width: 960px) {
  .w-33\@m {
    width: 33px;
  }
  .maxw-33\@m {
    max-width: 33px;
  }
  .minw-33\@m {
    min-width: 33px;
  }
}
@media (min-width: 1200px) {
  .w-33\@l {
    width: 33px;
  }
  .maxw-33\@l {
    max-width: 33px;
  }
  .minw-33\@l {
    min-width: 33px;
  }
}
@media (min-width: 1600px) {
  .w-33\@xl {
    width: 33px;
  }
  .maxw-33\@xl {
    max-width: 33px;
  }
  .minw-33\@xl {
    min-width: 33px;
  }
}
.w-34 {
  width: 34px;
}

.maxw-34 {
  max-width: 34px;
}

.minw-34 {
  min-width: 34px;
}

@media (min-width: 320px) {
  .w-34\@sx {
    width: 34px;
  }
  .maxw-34\@sx {
    max-width: 34px;
  }
  .minw-34\@sx {
    min-width: 34px;
  }
}
@media (min-width: 640px) {
  .w-34\@s {
    width: 34px;
  }
  .maxw-34\@s {
    max-width: 34px;
  }
  .minw-34\@s {
    min-width: 34px;
  }
}
@media (min-width: 960px) {
  .w-34\@m {
    width: 34px;
  }
  .maxw-34\@m {
    max-width: 34px;
  }
  .minw-34\@m {
    min-width: 34px;
  }
}
@media (min-width: 1200px) {
  .w-34\@l {
    width: 34px;
  }
  .maxw-34\@l {
    max-width: 34px;
  }
  .minw-34\@l {
    min-width: 34px;
  }
}
@media (min-width: 1600px) {
  .w-34\@xl {
    width: 34px;
  }
  .maxw-34\@xl {
    max-width: 34px;
  }
  .minw-34\@xl {
    min-width: 34px;
  }
}
.w-35 {
  width: 35px;
}

.maxw-35 {
  max-width: 35px;
}

.minw-35 {
  min-width: 35px;
}

@media (min-width: 320px) {
  .w-35\@sx {
    width: 35px;
  }
  .maxw-35\@sx {
    max-width: 35px;
  }
  .minw-35\@sx {
    min-width: 35px;
  }
}
@media (min-width: 640px) {
  .w-35\@s {
    width: 35px;
  }
  .maxw-35\@s {
    max-width: 35px;
  }
  .minw-35\@s {
    min-width: 35px;
  }
}
@media (min-width: 960px) {
  .w-35\@m {
    width: 35px;
  }
  .maxw-35\@m {
    max-width: 35px;
  }
  .minw-35\@m {
    min-width: 35px;
  }
}
@media (min-width: 1200px) {
  .w-35\@l {
    width: 35px;
  }
  .maxw-35\@l {
    max-width: 35px;
  }
  .minw-35\@l {
    min-width: 35px;
  }
}
@media (min-width: 1600px) {
  .w-35\@xl {
    width: 35px;
  }
  .maxw-35\@xl {
    max-width: 35px;
  }
  .minw-35\@xl {
    min-width: 35px;
  }
}
.w-36 {
  width: 36px;
}

.maxw-36 {
  max-width: 36px;
}

.minw-36 {
  min-width: 36px;
}

@media (min-width: 320px) {
  .w-36\@sx {
    width: 36px;
  }
  .maxw-36\@sx {
    max-width: 36px;
  }
  .minw-36\@sx {
    min-width: 36px;
  }
}
@media (min-width: 640px) {
  .w-36\@s {
    width: 36px;
  }
  .maxw-36\@s {
    max-width: 36px;
  }
  .minw-36\@s {
    min-width: 36px;
  }
}
@media (min-width: 960px) {
  .w-36\@m {
    width: 36px;
  }
  .maxw-36\@m {
    max-width: 36px;
  }
  .minw-36\@m {
    min-width: 36px;
  }
}
@media (min-width: 1200px) {
  .w-36\@l {
    width: 36px;
  }
  .maxw-36\@l {
    max-width: 36px;
  }
  .minw-36\@l {
    min-width: 36px;
  }
}
@media (min-width: 1600px) {
  .w-36\@xl {
    width: 36px;
  }
  .maxw-36\@xl {
    max-width: 36px;
  }
  .minw-36\@xl {
    min-width: 36px;
  }
}
.w-37 {
  width: 37px;
}

.maxw-37 {
  max-width: 37px;
}

.minw-37 {
  min-width: 37px;
}

@media (min-width: 320px) {
  .w-37\@sx {
    width: 37px;
  }
  .maxw-37\@sx {
    max-width: 37px;
  }
  .minw-37\@sx {
    min-width: 37px;
  }
}
@media (min-width: 640px) {
  .w-37\@s {
    width: 37px;
  }
  .maxw-37\@s {
    max-width: 37px;
  }
  .minw-37\@s {
    min-width: 37px;
  }
}
@media (min-width: 960px) {
  .w-37\@m {
    width: 37px;
  }
  .maxw-37\@m {
    max-width: 37px;
  }
  .minw-37\@m {
    min-width: 37px;
  }
}
@media (min-width: 1200px) {
  .w-37\@l {
    width: 37px;
  }
  .maxw-37\@l {
    max-width: 37px;
  }
  .minw-37\@l {
    min-width: 37px;
  }
}
@media (min-width: 1600px) {
  .w-37\@xl {
    width: 37px;
  }
  .maxw-37\@xl {
    max-width: 37px;
  }
  .minw-37\@xl {
    min-width: 37px;
  }
}
.w-38 {
  width: 38px;
}

.maxw-38 {
  max-width: 38px;
}

.minw-38 {
  min-width: 38px;
}

@media (min-width: 320px) {
  .w-38\@sx {
    width: 38px;
  }
  .maxw-38\@sx {
    max-width: 38px;
  }
  .minw-38\@sx {
    min-width: 38px;
  }
}
@media (min-width: 640px) {
  .w-38\@s {
    width: 38px;
  }
  .maxw-38\@s {
    max-width: 38px;
  }
  .minw-38\@s {
    min-width: 38px;
  }
}
@media (min-width: 960px) {
  .w-38\@m {
    width: 38px;
  }
  .maxw-38\@m {
    max-width: 38px;
  }
  .minw-38\@m {
    min-width: 38px;
  }
}
@media (min-width: 1200px) {
  .w-38\@l {
    width: 38px;
  }
  .maxw-38\@l {
    max-width: 38px;
  }
  .minw-38\@l {
    min-width: 38px;
  }
}
@media (min-width: 1600px) {
  .w-38\@xl {
    width: 38px;
  }
  .maxw-38\@xl {
    max-width: 38px;
  }
  .minw-38\@xl {
    min-width: 38px;
  }
}
.w-39 {
  width: 39px;
}

.maxw-39 {
  max-width: 39px;
}

.minw-39 {
  min-width: 39px;
}

@media (min-width: 320px) {
  .w-39\@sx {
    width: 39px;
  }
  .maxw-39\@sx {
    max-width: 39px;
  }
  .minw-39\@sx {
    min-width: 39px;
  }
}
@media (min-width: 640px) {
  .w-39\@s {
    width: 39px;
  }
  .maxw-39\@s {
    max-width: 39px;
  }
  .minw-39\@s {
    min-width: 39px;
  }
}
@media (min-width: 960px) {
  .w-39\@m {
    width: 39px;
  }
  .maxw-39\@m {
    max-width: 39px;
  }
  .minw-39\@m {
    min-width: 39px;
  }
}
@media (min-width: 1200px) {
  .w-39\@l {
    width: 39px;
  }
  .maxw-39\@l {
    max-width: 39px;
  }
  .minw-39\@l {
    min-width: 39px;
  }
}
@media (min-width: 1600px) {
  .w-39\@xl {
    width: 39px;
  }
  .maxw-39\@xl {
    max-width: 39px;
  }
  .minw-39\@xl {
    min-width: 39px;
  }
}
.w-40 {
  width: 40px;
}

.maxw-40 {
  max-width: 40px;
}

.minw-40 {
  min-width: 40px;
}

@media (min-width: 320px) {
  .w-40\@sx {
    width: 40px;
  }
  .maxw-40\@sx {
    max-width: 40px;
  }
  .minw-40\@sx {
    min-width: 40px;
  }
}
@media (min-width: 640px) {
  .w-40\@s {
    width: 40px;
  }
  .maxw-40\@s {
    max-width: 40px;
  }
  .minw-40\@s {
    min-width: 40px;
  }
}
@media (min-width: 960px) {
  .w-40\@m {
    width: 40px;
  }
  .maxw-40\@m {
    max-width: 40px;
  }
  .minw-40\@m {
    min-width: 40px;
  }
}
@media (min-width: 1200px) {
  .w-40\@l {
    width: 40px;
  }
  .maxw-40\@l {
    max-width: 40px;
  }
  .minw-40\@l {
    min-width: 40px;
  }
}
@media (min-width: 1600px) {
  .w-40\@xl {
    width: 40px;
  }
  .maxw-40\@xl {
    max-width: 40px;
  }
  .minw-40\@xl {
    min-width: 40px;
  }
}
.w-41 {
  width: 41px;
}

.maxw-41 {
  max-width: 41px;
}

.minw-41 {
  min-width: 41px;
}

@media (min-width: 320px) {
  .w-41\@sx {
    width: 41px;
  }
  .maxw-41\@sx {
    max-width: 41px;
  }
  .minw-41\@sx {
    min-width: 41px;
  }
}
@media (min-width: 640px) {
  .w-41\@s {
    width: 41px;
  }
  .maxw-41\@s {
    max-width: 41px;
  }
  .minw-41\@s {
    min-width: 41px;
  }
}
@media (min-width: 960px) {
  .w-41\@m {
    width: 41px;
  }
  .maxw-41\@m {
    max-width: 41px;
  }
  .minw-41\@m {
    min-width: 41px;
  }
}
@media (min-width: 1200px) {
  .w-41\@l {
    width: 41px;
  }
  .maxw-41\@l {
    max-width: 41px;
  }
  .minw-41\@l {
    min-width: 41px;
  }
}
@media (min-width: 1600px) {
  .w-41\@xl {
    width: 41px;
  }
  .maxw-41\@xl {
    max-width: 41px;
  }
  .minw-41\@xl {
    min-width: 41px;
  }
}
.w-42 {
  width: 42px;
}

.maxw-42 {
  max-width: 42px;
}

.minw-42 {
  min-width: 42px;
}

@media (min-width: 320px) {
  .w-42\@sx {
    width: 42px;
  }
  .maxw-42\@sx {
    max-width: 42px;
  }
  .minw-42\@sx {
    min-width: 42px;
  }
}
@media (min-width: 640px) {
  .w-42\@s {
    width: 42px;
  }
  .maxw-42\@s {
    max-width: 42px;
  }
  .minw-42\@s {
    min-width: 42px;
  }
}
@media (min-width: 960px) {
  .w-42\@m {
    width: 42px;
  }
  .maxw-42\@m {
    max-width: 42px;
  }
  .minw-42\@m {
    min-width: 42px;
  }
}
@media (min-width: 1200px) {
  .w-42\@l {
    width: 42px;
  }
  .maxw-42\@l {
    max-width: 42px;
  }
  .minw-42\@l {
    min-width: 42px;
  }
}
@media (min-width: 1600px) {
  .w-42\@xl {
    width: 42px;
  }
  .maxw-42\@xl {
    max-width: 42px;
  }
  .minw-42\@xl {
    min-width: 42px;
  }
}
.w-43 {
  width: 43px;
}

.maxw-43 {
  max-width: 43px;
}

.minw-43 {
  min-width: 43px;
}

@media (min-width: 320px) {
  .w-43\@sx {
    width: 43px;
  }
  .maxw-43\@sx {
    max-width: 43px;
  }
  .minw-43\@sx {
    min-width: 43px;
  }
}
@media (min-width: 640px) {
  .w-43\@s {
    width: 43px;
  }
  .maxw-43\@s {
    max-width: 43px;
  }
  .minw-43\@s {
    min-width: 43px;
  }
}
@media (min-width: 960px) {
  .w-43\@m {
    width: 43px;
  }
  .maxw-43\@m {
    max-width: 43px;
  }
  .minw-43\@m {
    min-width: 43px;
  }
}
@media (min-width: 1200px) {
  .w-43\@l {
    width: 43px;
  }
  .maxw-43\@l {
    max-width: 43px;
  }
  .minw-43\@l {
    min-width: 43px;
  }
}
@media (min-width: 1600px) {
  .w-43\@xl {
    width: 43px;
  }
  .maxw-43\@xl {
    max-width: 43px;
  }
  .minw-43\@xl {
    min-width: 43px;
  }
}
.w-44 {
  width: 44px;
}

.maxw-44 {
  max-width: 44px;
}

.minw-44 {
  min-width: 44px;
}

@media (min-width: 320px) {
  .w-44\@sx {
    width: 44px;
  }
  .maxw-44\@sx {
    max-width: 44px;
  }
  .minw-44\@sx {
    min-width: 44px;
  }
}
@media (min-width: 640px) {
  .w-44\@s {
    width: 44px;
  }
  .maxw-44\@s {
    max-width: 44px;
  }
  .minw-44\@s {
    min-width: 44px;
  }
}
@media (min-width: 960px) {
  .w-44\@m {
    width: 44px;
  }
  .maxw-44\@m {
    max-width: 44px;
  }
  .minw-44\@m {
    min-width: 44px;
  }
}
@media (min-width: 1200px) {
  .w-44\@l {
    width: 44px;
  }
  .maxw-44\@l {
    max-width: 44px;
  }
  .minw-44\@l {
    min-width: 44px;
  }
}
@media (min-width: 1600px) {
  .w-44\@xl {
    width: 44px;
  }
  .maxw-44\@xl {
    max-width: 44px;
  }
  .minw-44\@xl {
    min-width: 44px;
  }
}
.w-45 {
  width: 45px;
}

.maxw-45 {
  max-width: 45px;
}

.minw-45 {
  min-width: 45px;
}

@media (min-width: 320px) {
  .w-45\@sx {
    width: 45px;
  }
  .maxw-45\@sx {
    max-width: 45px;
  }
  .minw-45\@sx {
    min-width: 45px;
  }
}
@media (min-width: 640px) {
  .w-45\@s {
    width: 45px;
  }
  .maxw-45\@s {
    max-width: 45px;
  }
  .minw-45\@s {
    min-width: 45px;
  }
}
@media (min-width: 960px) {
  .w-45\@m {
    width: 45px;
  }
  .maxw-45\@m {
    max-width: 45px;
  }
  .minw-45\@m {
    min-width: 45px;
  }
}
@media (min-width: 1200px) {
  .w-45\@l {
    width: 45px;
  }
  .maxw-45\@l {
    max-width: 45px;
  }
  .minw-45\@l {
    min-width: 45px;
  }
}
@media (min-width: 1600px) {
  .w-45\@xl {
    width: 45px;
  }
  .maxw-45\@xl {
    max-width: 45px;
  }
  .minw-45\@xl {
    min-width: 45px;
  }
}
.w-46 {
  width: 46px;
}

.maxw-46 {
  max-width: 46px;
}

.minw-46 {
  min-width: 46px;
}

@media (min-width: 320px) {
  .w-46\@sx {
    width: 46px;
  }
  .maxw-46\@sx {
    max-width: 46px;
  }
  .minw-46\@sx {
    min-width: 46px;
  }
}
@media (min-width: 640px) {
  .w-46\@s {
    width: 46px;
  }
  .maxw-46\@s {
    max-width: 46px;
  }
  .minw-46\@s {
    min-width: 46px;
  }
}
@media (min-width: 960px) {
  .w-46\@m {
    width: 46px;
  }
  .maxw-46\@m {
    max-width: 46px;
  }
  .minw-46\@m {
    min-width: 46px;
  }
}
@media (min-width: 1200px) {
  .w-46\@l {
    width: 46px;
  }
  .maxw-46\@l {
    max-width: 46px;
  }
  .minw-46\@l {
    min-width: 46px;
  }
}
@media (min-width: 1600px) {
  .w-46\@xl {
    width: 46px;
  }
  .maxw-46\@xl {
    max-width: 46px;
  }
  .minw-46\@xl {
    min-width: 46px;
  }
}
.w-47 {
  width: 47px;
}

.maxw-47 {
  max-width: 47px;
}

.minw-47 {
  min-width: 47px;
}

@media (min-width: 320px) {
  .w-47\@sx {
    width: 47px;
  }
  .maxw-47\@sx {
    max-width: 47px;
  }
  .minw-47\@sx {
    min-width: 47px;
  }
}
@media (min-width: 640px) {
  .w-47\@s {
    width: 47px;
  }
  .maxw-47\@s {
    max-width: 47px;
  }
  .minw-47\@s {
    min-width: 47px;
  }
}
@media (min-width: 960px) {
  .w-47\@m {
    width: 47px;
  }
  .maxw-47\@m {
    max-width: 47px;
  }
  .minw-47\@m {
    min-width: 47px;
  }
}
@media (min-width: 1200px) {
  .w-47\@l {
    width: 47px;
  }
  .maxw-47\@l {
    max-width: 47px;
  }
  .minw-47\@l {
    min-width: 47px;
  }
}
@media (min-width: 1600px) {
  .w-47\@xl {
    width: 47px;
  }
  .maxw-47\@xl {
    max-width: 47px;
  }
  .minw-47\@xl {
    min-width: 47px;
  }
}
.w-48 {
  width: 48px;
}

.maxw-48 {
  max-width: 48px;
}

.minw-48 {
  min-width: 48px;
}

@media (min-width: 320px) {
  .w-48\@sx {
    width: 48px;
  }
  .maxw-48\@sx {
    max-width: 48px;
  }
  .minw-48\@sx {
    min-width: 48px;
  }
}
@media (min-width: 640px) {
  .w-48\@s {
    width: 48px;
  }
  .maxw-48\@s {
    max-width: 48px;
  }
  .minw-48\@s {
    min-width: 48px;
  }
}
@media (min-width: 960px) {
  .w-48\@m {
    width: 48px;
  }
  .maxw-48\@m {
    max-width: 48px;
  }
  .minw-48\@m {
    min-width: 48px;
  }
}
@media (min-width: 1200px) {
  .w-48\@l {
    width: 48px;
  }
  .maxw-48\@l {
    max-width: 48px;
  }
  .minw-48\@l {
    min-width: 48px;
  }
}
@media (min-width: 1600px) {
  .w-48\@xl {
    width: 48px;
  }
  .maxw-48\@xl {
    max-width: 48px;
  }
  .minw-48\@xl {
    min-width: 48px;
  }
}
.w-49 {
  width: 49px;
}

.maxw-49 {
  max-width: 49px;
}

.minw-49 {
  min-width: 49px;
}

@media (min-width: 320px) {
  .w-49\@sx {
    width: 49px;
  }
  .maxw-49\@sx {
    max-width: 49px;
  }
  .minw-49\@sx {
    min-width: 49px;
  }
}
@media (min-width: 640px) {
  .w-49\@s {
    width: 49px;
  }
  .maxw-49\@s {
    max-width: 49px;
  }
  .minw-49\@s {
    min-width: 49px;
  }
}
@media (min-width: 960px) {
  .w-49\@m {
    width: 49px;
  }
  .maxw-49\@m {
    max-width: 49px;
  }
  .minw-49\@m {
    min-width: 49px;
  }
}
@media (min-width: 1200px) {
  .w-49\@l {
    width: 49px;
  }
  .maxw-49\@l {
    max-width: 49px;
  }
  .minw-49\@l {
    min-width: 49px;
  }
}
@media (min-width: 1600px) {
  .w-49\@xl {
    width: 49px;
  }
  .maxw-49\@xl {
    max-width: 49px;
  }
  .minw-49\@xl {
    min-width: 49px;
  }
}
.w-50 {
  width: 50px;
}

.maxw-50 {
  max-width: 50px;
}

.minw-50 {
  min-width: 50px;
}

@media (min-width: 320px) {
  .w-50\@sx {
    width: 50px;
  }
  .maxw-50\@sx {
    max-width: 50px;
  }
  .minw-50\@sx {
    min-width: 50px;
  }
}
@media (min-width: 640px) {
  .w-50\@s {
    width: 50px;
  }
  .maxw-50\@s {
    max-width: 50px;
  }
  .minw-50\@s {
    min-width: 50px;
  }
}
@media (min-width: 960px) {
  .w-50\@m {
    width: 50px;
  }
  .maxw-50\@m {
    max-width: 50px;
  }
  .minw-50\@m {
    min-width: 50px;
  }
}
@media (min-width: 1200px) {
  .w-50\@l {
    width: 50px;
  }
  .maxw-50\@l {
    max-width: 50px;
  }
  .minw-50\@l {
    min-width: 50px;
  }
}
@media (min-width: 1600px) {
  .w-50\@xl {
    width: 50px;
  }
  .maxw-50\@xl {
    max-width: 50px;
  }
  .minw-50\@xl {
    min-width: 50px;
  }
}
.w-51 {
  width: 51px;
}

.maxw-51 {
  max-width: 51px;
}

.minw-51 {
  min-width: 51px;
}

@media (min-width: 320px) {
  .w-51\@sx {
    width: 51px;
  }
  .maxw-51\@sx {
    max-width: 51px;
  }
  .minw-51\@sx {
    min-width: 51px;
  }
}
@media (min-width: 640px) {
  .w-51\@s {
    width: 51px;
  }
  .maxw-51\@s {
    max-width: 51px;
  }
  .minw-51\@s {
    min-width: 51px;
  }
}
@media (min-width: 960px) {
  .w-51\@m {
    width: 51px;
  }
  .maxw-51\@m {
    max-width: 51px;
  }
  .minw-51\@m {
    min-width: 51px;
  }
}
@media (min-width: 1200px) {
  .w-51\@l {
    width: 51px;
  }
  .maxw-51\@l {
    max-width: 51px;
  }
  .minw-51\@l {
    min-width: 51px;
  }
}
@media (min-width: 1600px) {
  .w-51\@xl {
    width: 51px;
  }
  .maxw-51\@xl {
    max-width: 51px;
  }
  .minw-51\@xl {
    min-width: 51px;
  }
}
.w-52 {
  width: 52px;
}

.maxw-52 {
  max-width: 52px;
}

.minw-52 {
  min-width: 52px;
}

@media (min-width: 320px) {
  .w-52\@sx {
    width: 52px;
  }
  .maxw-52\@sx {
    max-width: 52px;
  }
  .minw-52\@sx {
    min-width: 52px;
  }
}
@media (min-width: 640px) {
  .w-52\@s {
    width: 52px;
  }
  .maxw-52\@s {
    max-width: 52px;
  }
  .minw-52\@s {
    min-width: 52px;
  }
}
@media (min-width: 960px) {
  .w-52\@m {
    width: 52px;
  }
  .maxw-52\@m {
    max-width: 52px;
  }
  .minw-52\@m {
    min-width: 52px;
  }
}
@media (min-width: 1200px) {
  .w-52\@l {
    width: 52px;
  }
  .maxw-52\@l {
    max-width: 52px;
  }
  .minw-52\@l {
    min-width: 52px;
  }
}
@media (min-width: 1600px) {
  .w-52\@xl {
    width: 52px;
  }
  .maxw-52\@xl {
    max-width: 52px;
  }
  .minw-52\@xl {
    min-width: 52px;
  }
}
.w-53 {
  width: 53px;
}

.maxw-53 {
  max-width: 53px;
}

.minw-53 {
  min-width: 53px;
}

@media (min-width: 320px) {
  .w-53\@sx {
    width: 53px;
  }
  .maxw-53\@sx {
    max-width: 53px;
  }
  .minw-53\@sx {
    min-width: 53px;
  }
}
@media (min-width: 640px) {
  .w-53\@s {
    width: 53px;
  }
  .maxw-53\@s {
    max-width: 53px;
  }
  .minw-53\@s {
    min-width: 53px;
  }
}
@media (min-width: 960px) {
  .w-53\@m {
    width: 53px;
  }
  .maxw-53\@m {
    max-width: 53px;
  }
  .minw-53\@m {
    min-width: 53px;
  }
}
@media (min-width: 1200px) {
  .w-53\@l {
    width: 53px;
  }
  .maxw-53\@l {
    max-width: 53px;
  }
  .minw-53\@l {
    min-width: 53px;
  }
}
@media (min-width: 1600px) {
  .w-53\@xl {
    width: 53px;
  }
  .maxw-53\@xl {
    max-width: 53px;
  }
  .minw-53\@xl {
    min-width: 53px;
  }
}
.w-54 {
  width: 54px;
}

.maxw-54 {
  max-width: 54px;
}

.minw-54 {
  min-width: 54px;
}

@media (min-width: 320px) {
  .w-54\@sx {
    width: 54px;
  }
  .maxw-54\@sx {
    max-width: 54px;
  }
  .minw-54\@sx {
    min-width: 54px;
  }
}
@media (min-width: 640px) {
  .w-54\@s {
    width: 54px;
  }
  .maxw-54\@s {
    max-width: 54px;
  }
  .minw-54\@s {
    min-width: 54px;
  }
}
@media (min-width: 960px) {
  .w-54\@m {
    width: 54px;
  }
  .maxw-54\@m {
    max-width: 54px;
  }
  .minw-54\@m {
    min-width: 54px;
  }
}
@media (min-width: 1200px) {
  .w-54\@l {
    width: 54px;
  }
  .maxw-54\@l {
    max-width: 54px;
  }
  .minw-54\@l {
    min-width: 54px;
  }
}
@media (min-width: 1600px) {
  .w-54\@xl {
    width: 54px;
  }
  .maxw-54\@xl {
    max-width: 54px;
  }
  .minw-54\@xl {
    min-width: 54px;
  }
}
.w-55 {
  width: 55px;
}

.maxw-55 {
  max-width: 55px;
}

.minw-55 {
  min-width: 55px;
}

@media (min-width: 320px) {
  .w-55\@sx {
    width: 55px;
  }
  .maxw-55\@sx {
    max-width: 55px;
  }
  .minw-55\@sx {
    min-width: 55px;
  }
}
@media (min-width: 640px) {
  .w-55\@s {
    width: 55px;
  }
  .maxw-55\@s {
    max-width: 55px;
  }
  .minw-55\@s {
    min-width: 55px;
  }
}
@media (min-width: 960px) {
  .w-55\@m {
    width: 55px;
  }
  .maxw-55\@m {
    max-width: 55px;
  }
  .minw-55\@m {
    min-width: 55px;
  }
}
@media (min-width: 1200px) {
  .w-55\@l {
    width: 55px;
  }
  .maxw-55\@l {
    max-width: 55px;
  }
  .minw-55\@l {
    min-width: 55px;
  }
}
@media (min-width: 1600px) {
  .w-55\@xl {
    width: 55px;
  }
  .maxw-55\@xl {
    max-width: 55px;
  }
  .minw-55\@xl {
    min-width: 55px;
  }
}
.w-56 {
  width: 56px;
}

.maxw-56 {
  max-width: 56px;
}

.minw-56 {
  min-width: 56px;
}

@media (min-width: 320px) {
  .w-56\@sx {
    width: 56px;
  }
  .maxw-56\@sx {
    max-width: 56px;
  }
  .minw-56\@sx {
    min-width: 56px;
  }
}
@media (min-width: 640px) {
  .w-56\@s {
    width: 56px;
  }
  .maxw-56\@s {
    max-width: 56px;
  }
  .minw-56\@s {
    min-width: 56px;
  }
}
@media (min-width: 960px) {
  .w-56\@m {
    width: 56px;
  }
  .maxw-56\@m {
    max-width: 56px;
  }
  .minw-56\@m {
    min-width: 56px;
  }
}
@media (min-width: 1200px) {
  .w-56\@l {
    width: 56px;
  }
  .maxw-56\@l {
    max-width: 56px;
  }
  .minw-56\@l {
    min-width: 56px;
  }
}
@media (min-width: 1600px) {
  .w-56\@xl {
    width: 56px;
  }
  .maxw-56\@xl {
    max-width: 56px;
  }
  .minw-56\@xl {
    min-width: 56px;
  }
}
.w-57 {
  width: 57px;
}

.maxw-57 {
  max-width: 57px;
}

.minw-57 {
  min-width: 57px;
}

@media (min-width: 320px) {
  .w-57\@sx {
    width: 57px;
  }
  .maxw-57\@sx {
    max-width: 57px;
  }
  .minw-57\@sx {
    min-width: 57px;
  }
}
@media (min-width: 640px) {
  .w-57\@s {
    width: 57px;
  }
  .maxw-57\@s {
    max-width: 57px;
  }
  .minw-57\@s {
    min-width: 57px;
  }
}
@media (min-width: 960px) {
  .w-57\@m {
    width: 57px;
  }
  .maxw-57\@m {
    max-width: 57px;
  }
  .minw-57\@m {
    min-width: 57px;
  }
}
@media (min-width: 1200px) {
  .w-57\@l {
    width: 57px;
  }
  .maxw-57\@l {
    max-width: 57px;
  }
  .minw-57\@l {
    min-width: 57px;
  }
}
@media (min-width: 1600px) {
  .w-57\@xl {
    width: 57px;
  }
  .maxw-57\@xl {
    max-width: 57px;
  }
  .minw-57\@xl {
    min-width: 57px;
  }
}
.w-58 {
  width: 58px;
}

.maxw-58 {
  max-width: 58px;
}

.minw-58 {
  min-width: 58px;
}

@media (min-width: 320px) {
  .w-58\@sx {
    width: 58px;
  }
  .maxw-58\@sx {
    max-width: 58px;
  }
  .minw-58\@sx {
    min-width: 58px;
  }
}
@media (min-width: 640px) {
  .w-58\@s {
    width: 58px;
  }
  .maxw-58\@s {
    max-width: 58px;
  }
  .minw-58\@s {
    min-width: 58px;
  }
}
@media (min-width: 960px) {
  .w-58\@m {
    width: 58px;
  }
  .maxw-58\@m {
    max-width: 58px;
  }
  .minw-58\@m {
    min-width: 58px;
  }
}
@media (min-width: 1200px) {
  .w-58\@l {
    width: 58px;
  }
  .maxw-58\@l {
    max-width: 58px;
  }
  .minw-58\@l {
    min-width: 58px;
  }
}
@media (min-width: 1600px) {
  .w-58\@xl {
    width: 58px;
  }
  .maxw-58\@xl {
    max-width: 58px;
  }
  .minw-58\@xl {
    min-width: 58px;
  }
}
.w-59 {
  width: 59px;
}

.maxw-59 {
  max-width: 59px;
}

.minw-59 {
  min-width: 59px;
}

@media (min-width: 320px) {
  .w-59\@sx {
    width: 59px;
  }
  .maxw-59\@sx {
    max-width: 59px;
  }
  .minw-59\@sx {
    min-width: 59px;
  }
}
@media (min-width: 640px) {
  .w-59\@s {
    width: 59px;
  }
  .maxw-59\@s {
    max-width: 59px;
  }
  .minw-59\@s {
    min-width: 59px;
  }
}
@media (min-width: 960px) {
  .w-59\@m {
    width: 59px;
  }
  .maxw-59\@m {
    max-width: 59px;
  }
  .minw-59\@m {
    min-width: 59px;
  }
}
@media (min-width: 1200px) {
  .w-59\@l {
    width: 59px;
  }
  .maxw-59\@l {
    max-width: 59px;
  }
  .minw-59\@l {
    min-width: 59px;
  }
}
@media (min-width: 1600px) {
  .w-59\@xl {
    width: 59px;
  }
  .maxw-59\@xl {
    max-width: 59px;
  }
  .minw-59\@xl {
    min-width: 59px;
  }
}
.w-60 {
  width: 60px;
}

.maxw-60 {
  max-width: 60px;
}

.minw-60 {
  min-width: 60px;
}

@media (min-width: 320px) {
  .w-60\@sx {
    width: 60px;
  }
  .maxw-60\@sx {
    max-width: 60px;
  }
  .minw-60\@sx {
    min-width: 60px;
  }
}
@media (min-width: 640px) {
  .w-60\@s {
    width: 60px;
  }
  .maxw-60\@s {
    max-width: 60px;
  }
  .minw-60\@s {
    min-width: 60px;
  }
}
@media (min-width: 960px) {
  .w-60\@m {
    width: 60px;
  }
  .maxw-60\@m {
    max-width: 60px;
  }
  .minw-60\@m {
    min-width: 60px;
  }
}
@media (min-width: 1200px) {
  .w-60\@l {
    width: 60px;
  }
  .maxw-60\@l {
    max-width: 60px;
  }
  .minw-60\@l {
    min-width: 60px;
  }
}
@media (min-width: 1600px) {
  .w-60\@xl {
    width: 60px;
  }
  .maxw-60\@xl {
    max-width: 60px;
  }
  .minw-60\@xl {
    min-width: 60px;
  }
}
.w-61 {
  width: 61px;
}

.maxw-61 {
  max-width: 61px;
}

.minw-61 {
  min-width: 61px;
}

@media (min-width: 320px) {
  .w-61\@sx {
    width: 61px;
  }
  .maxw-61\@sx {
    max-width: 61px;
  }
  .minw-61\@sx {
    min-width: 61px;
  }
}
@media (min-width: 640px) {
  .w-61\@s {
    width: 61px;
  }
  .maxw-61\@s {
    max-width: 61px;
  }
  .minw-61\@s {
    min-width: 61px;
  }
}
@media (min-width: 960px) {
  .w-61\@m {
    width: 61px;
  }
  .maxw-61\@m {
    max-width: 61px;
  }
  .minw-61\@m {
    min-width: 61px;
  }
}
@media (min-width: 1200px) {
  .w-61\@l {
    width: 61px;
  }
  .maxw-61\@l {
    max-width: 61px;
  }
  .minw-61\@l {
    min-width: 61px;
  }
}
@media (min-width: 1600px) {
  .w-61\@xl {
    width: 61px;
  }
  .maxw-61\@xl {
    max-width: 61px;
  }
  .minw-61\@xl {
    min-width: 61px;
  }
}
.w-62 {
  width: 62px;
}

.maxw-62 {
  max-width: 62px;
}

.minw-62 {
  min-width: 62px;
}

@media (min-width: 320px) {
  .w-62\@sx {
    width: 62px;
  }
  .maxw-62\@sx {
    max-width: 62px;
  }
  .minw-62\@sx {
    min-width: 62px;
  }
}
@media (min-width: 640px) {
  .w-62\@s {
    width: 62px;
  }
  .maxw-62\@s {
    max-width: 62px;
  }
  .minw-62\@s {
    min-width: 62px;
  }
}
@media (min-width: 960px) {
  .w-62\@m {
    width: 62px;
  }
  .maxw-62\@m {
    max-width: 62px;
  }
  .minw-62\@m {
    min-width: 62px;
  }
}
@media (min-width: 1200px) {
  .w-62\@l {
    width: 62px;
  }
  .maxw-62\@l {
    max-width: 62px;
  }
  .minw-62\@l {
    min-width: 62px;
  }
}
@media (min-width: 1600px) {
  .w-62\@xl {
    width: 62px;
  }
  .maxw-62\@xl {
    max-width: 62px;
  }
  .minw-62\@xl {
    min-width: 62px;
  }
}
.w-63 {
  width: 63px;
}

.maxw-63 {
  max-width: 63px;
}

.minw-63 {
  min-width: 63px;
}

@media (min-width: 320px) {
  .w-63\@sx {
    width: 63px;
  }
  .maxw-63\@sx {
    max-width: 63px;
  }
  .minw-63\@sx {
    min-width: 63px;
  }
}
@media (min-width: 640px) {
  .w-63\@s {
    width: 63px;
  }
  .maxw-63\@s {
    max-width: 63px;
  }
  .minw-63\@s {
    min-width: 63px;
  }
}
@media (min-width: 960px) {
  .w-63\@m {
    width: 63px;
  }
  .maxw-63\@m {
    max-width: 63px;
  }
  .minw-63\@m {
    min-width: 63px;
  }
}
@media (min-width: 1200px) {
  .w-63\@l {
    width: 63px;
  }
  .maxw-63\@l {
    max-width: 63px;
  }
  .minw-63\@l {
    min-width: 63px;
  }
}
@media (min-width: 1600px) {
  .w-63\@xl {
    width: 63px;
  }
  .maxw-63\@xl {
    max-width: 63px;
  }
  .minw-63\@xl {
    min-width: 63px;
  }
}
.w-64 {
  width: 64px;
}

.maxw-64 {
  max-width: 64px;
}

.minw-64 {
  min-width: 64px;
}

@media (min-width: 320px) {
  .w-64\@sx {
    width: 64px;
  }
  .maxw-64\@sx {
    max-width: 64px;
  }
  .minw-64\@sx {
    min-width: 64px;
  }
}
@media (min-width: 640px) {
  .w-64\@s {
    width: 64px;
  }
  .maxw-64\@s {
    max-width: 64px;
  }
  .minw-64\@s {
    min-width: 64px;
  }
}
@media (min-width: 960px) {
  .w-64\@m {
    width: 64px;
  }
  .maxw-64\@m {
    max-width: 64px;
  }
  .minw-64\@m {
    min-width: 64px;
  }
}
@media (min-width: 1200px) {
  .w-64\@l {
    width: 64px;
  }
  .maxw-64\@l {
    max-width: 64px;
  }
  .minw-64\@l {
    min-width: 64px;
  }
}
@media (min-width: 1600px) {
  .w-64\@xl {
    width: 64px;
  }
  .maxw-64\@xl {
    max-width: 64px;
  }
  .minw-64\@xl {
    min-width: 64px;
  }
}
.w-65 {
  width: 65px;
}

.maxw-65 {
  max-width: 65px;
}

.minw-65 {
  min-width: 65px;
}

@media (min-width: 320px) {
  .w-65\@sx {
    width: 65px;
  }
  .maxw-65\@sx {
    max-width: 65px;
  }
  .minw-65\@sx {
    min-width: 65px;
  }
}
@media (min-width: 640px) {
  .w-65\@s {
    width: 65px;
  }
  .maxw-65\@s {
    max-width: 65px;
  }
  .minw-65\@s {
    min-width: 65px;
  }
}
@media (min-width: 960px) {
  .w-65\@m {
    width: 65px;
  }
  .maxw-65\@m {
    max-width: 65px;
  }
  .minw-65\@m {
    min-width: 65px;
  }
}
@media (min-width: 1200px) {
  .w-65\@l {
    width: 65px;
  }
  .maxw-65\@l {
    max-width: 65px;
  }
  .minw-65\@l {
    min-width: 65px;
  }
}
@media (min-width: 1600px) {
  .w-65\@xl {
    width: 65px;
  }
  .maxw-65\@xl {
    max-width: 65px;
  }
  .minw-65\@xl {
    min-width: 65px;
  }
}
.w-66 {
  width: 66px;
}

.maxw-66 {
  max-width: 66px;
}

.minw-66 {
  min-width: 66px;
}

@media (min-width: 320px) {
  .w-66\@sx {
    width: 66px;
  }
  .maxw-66\@sx {
    max-width: 66px;
  }
  .minw-66\@sx {
    min-width: 66px;
  }
}
@media (min-width: 640px) {
  .w-66\@s {
    width: 66px;
  }
  .maxw-66\@s {
    max-width: 66px;
  }
  .minw-66\@s {
    min-width: 66px;
  }
}
@media (min-width: 960px) {
  .w-66\@m {
    width: 66px;
  }
  .maxw-66\@m {
    max-width: 66px;
  }
  .minw-66\@m {
    min-width: 66px;
  }
}
@media (min-width: 1200px) {
  .w-66\@l {
    width: 66px;
  }
  .maxw-66\@l {
    max-width: 66px;
  }
  .minw-66\@l {
    min-width: 66px;
  }
}
@media (min-width: 1600px) {
  .w-66\@xl {
    width: 66px;
  }
  .maxw-66\@xl {
    max-width: 66px;
  }
  .minw-66\@xl {
    min-width: 66px;
  }
}
.w-67 {
  width: 67px;
}

.maxw-67 {
  max-width: 67px;
}

.minw-67 {
  min-width: 67px;
}

@media (min-width: 320px) {
  .w-67\@sx {
    width: 67px;
  }
  .maxw-67\@sx {
    max-width: 67px;
  }
  .minw-67\@sx {
    min-width: 67px;
  }
}
@media (min-width: 640px) {
  .w-67\@s {
    width: 67px;
  }
  .maxw-67\@s {
    max-width: 67px;
  }
  .minw-67\@s {
    min-width: 67px;
  }
}
@media (min-width: 960px) {
  .w-67\@m {
    width: 67px;
  }
  .maxw-67\@m {
    max-width: 67px;
  }
  .minw-67\@m {
    min-width: 67px;
  }
}
@media (min-width: 1200px) {
  .w-67\@l {
    width: 67px;
  }
  .maxw-67\@l {
    max-width: 67px;
  }
  .minw-67\@l {
    min-width: 67px;
  }
}
@media (min-width: 1600px) {
  .w-67\@xl {
    width: 67px;
  }
  .maxw-67\@xl {
    max-width: 67px;
  }
  .minw-67\@xl {
    min-width: 67px;
  }
}
.w-68 {
  width: 68px;
}

.maxw-68 {
  max-width: 68px;
}

.minw-68 {
  min-width: 68px;
}

@media (min-width: 320px) {
  .w-68\@sx {
    width: 68px;
  }
  .maxw-68\@sx {
    max-width: 68px;
  }
  .minw-68\@sx {
    min-width: 68px;
  }
}
@media (min-width: 640px) {
  .w-68\@s {
    width: 68px;
  }
  .maxw-68\@s {
    max-width: 68px;
  }
  .minw-68\@s {
    min-width: 68px;
  }
}
@media (min-width: 960px) {
  .w-68\@m {
    width: 68px;
  }
  .maxw-68\@m {
    max-width: 68px;
  }
  .minw-68\@m {
    min-width: 68px;
  }
}
@media (min-width: 1200px) {
  .w-68\@l {
    width: 68px;
  }
  .maxw-68\@l {
    max-width: 68px;
  }
  .minw-68\@l {
    min-width: 68px;
  }
}
@media (min-width: 1600px) {
  .w-68\@xl {
    width: 68px;
  }
  .maxw-68\@xl {
    max-width: 68px;
  }
  .minw-68\@xl {
    min-width: 68px;
  }
}
.w-69 {
  width: 69px;
}

.maxw-69 {
  max-width: 69px;
}

.minw-69 {
  min-width: 69px;
}

@media (min-width: 320px) {
  .w-69\@sx {
    width: 69px;
  }
  .maxw-69\@sx {
    max-width: 69px;
  }
  .minw-69\@sx {
    min-width: 69px;
  }
}
@media (min-width: 640px) {
  .w-69\@s {
    width: 69px;
  }
  .maxw-69\@s {
    max-width: 69px;
  }
  .minw-69\@s {
    min-width: 69px;
  }
}
@media (min-width: 960px) {
  .w-69\@m {
    width: 69px;
  }
  .maxw-69\@m {
    max-width: 69px;
  }
  .minw-69\@m {
    min-width: 69px;
  }
}
@media (min-width: 1200px) {
  .w-69\@l {
    width: 69px;
  }
  .maxw-69\@l {
    max-width: 69px;
  }
  .minw-69\@l {
    min-width: 69px;
  }
}
@media (min-width: 1600px) {
  .w-69\@xl {
    width: 69px;
  }
  .maxw-69\@xl {
    max-width: 69px;
  }
  .minw-69\@xl {
    min-width: 69px;
  }
}
.w-70 {
  width: 70px;
}

.maxw-70 {
  max-width: 70px;
}

.minw-70 {
  min-width: 70px;
}

@media (min-width: 320px) {
  .w-70\@sx {
    width: 70px;
  }
  .maxw-70\@sx {
    max-width: 70px;
  }
  .minw-70\@sx {
    min-width: 70px;
  }
}
@media (min-width: 640px) {
  .w-70\@s {
    width: 70px;
  }
  .maxw-70\@s {
    max-width: 70px;
  }
  .minw-70\@s {
    min-width: 70px;
  }
}
@media (min-width: 960px) {
  .w-70\@m {
    width: 70px;
  }
  .maxw-70\@m {
    max-width: 70px;
  }
  .minw-70\@m {
    min-width: 70px;
  }
}
@media (min-width: 1200px) {
  .w-70\@l {
    width: 70px;
  }
  .maxw-70\@l {
    max-width: 70px;
  }
  .minw-70\@l {
    min-width: 70px;
  }
}
@media (min-width: 1600px) {
  .w-70\@xl {
    width: 70px;
  }
  .maxw-70\@xl {
    max-width: 70px;
  }
  .minw-70\@xl {
    min-width: 70px;
  }
}
.w-71 {
  width: 71px;
}

.maxw-71 {
  max-width: 71px;
}

.minw-71 {
  min-width: 71px;
}

@media (min-width: 320px) {
  .w-71\@sx {
    width: 71px;
  }
  .maxw-71\@sx {
    max-width: 71px;
  }
  .minw-71\@sx {
    min-width: 71px;
  }
}
@media (min-width: 640px) {
  .w-71\@s {
    width: 71px;
  }
  .maxw-71\@s {
    max-width: 71px;
  }
  .minw-71\@s {
    min-width: 71px;
  }
}
@media (min-width: 960px) {
  .w-71\@m {
    width: 71px;
  }
  .maxw-71\@m {
    max-width: 71px;
  }
  .minw-71\@m {
    min-width: 71px;
  }
}
@media (min-width: 1200px) {
  .w-71\@l {
    width: 71px;
  }
  .maxw-71\@l {
    max-width: 71px;
  }
  .minw-71\@l {
    min-width: 71px;
  }
}
@media (min-width: 1600px) {
  .w-71\@xl {
    width: 71px;
  }
  .maxw-71\@xl {
    max-width: 71px;
  }
  .minw-71\@xl {
    min-width: 71px;
  }
}
.w-72 {
  width: 72px;
}

.maxw-72 {
  max-width: 72px;
}

.minw-72 {
  min-width: 72px;
}

@media (min-width: 320px) {
  .w-72\@sx {
    width: 72px;
  }
  .maxw-72\@sx {
    max-width: 72px;
  }
  .minw-72\@sx {
    min-width: 72px;
  }
}
@media (min-width: 640px) {
  .w-72\@s {
    width: 72px;
  }
  .maxw-72\@s {
    max-width: 72px;
  }
  .minw-72\@s {
    min-width: 72px;
  }
}
@media (min-width: 960px) {
  .w-72\@m {
    width: 72px;
  }
  .maxw-72\@m {
    max-width: 72px;
  }
  .minw-72\@m {
    min-width: 72px;
  }
}
@media (min-width: 1200px) {
  .w-72\@l {
    width: 72px;
  }
  .maxw-72\@l {
    max-width: 72px;
  }
  .minw-72\@l {
    min-width: 72px;
  }
}
@media (min-width: 1600px) {
  .w-72\@xl {
    width: 72px;
  }
  .maxw-72\@xl {
    max-width: 72px;
  }
  .minw-72\@xl {
    min-width: 72px;
  }
}
.w-73 {
  width: 73px;
}

.maxw-73 {
  max-width: 73px;
}

.minw-73 {
  min-width: 73px;
}

@media (min-width: 320px) {
  .w-73\@sx {
    width: 73px;
  }
  .maxw-73\@sx {
    max-width: 73px;
  }
  .minw-73\@sx {
    min-width: 73px;
  }
}
@media (min-width: 640px) {
  .w-73\@s {
    width: 73px;
  }
  .maxw-73\@s {
    max-width: 73px;
  }
  .minw-73\@s {
    min-width: 73px;
  }
}
@media (min-width: 960px) {
  .w-73\@m {
    width: 73px;
  }
  .maxw-73\@m {
    max-width: 73px;
  }
  .minw-73\@m {
    min-width: 73px;
  }
}
@media (min-width: 1200px) {
  .w-73\@l {
    width: 73px;
  }
  .maxw-73\@l {
    max-width: 73px;
  }
  .minw-73\@l {
    min-width: 73px;
  }
}
@media (min-width: 1600px) {
  .w-73\@xl {
    width: 73px;
  }
  .maxw-73\@xl {
    max-width: 73px;
  }
  .minw-73\@xl {
    min-width: 73px;
  }
}
.w-74 {
  width: 74px;
}

.maxw-74 {
  max-width: 74px;
}

.minw-74 {
  min-width: 74px;
}

@media (min-width: 320px) {
  .w-74\@sx {
    width: 74px;
  }
  .maxw-74\@sx {
    max-width: 74px;
  }
  .minw-74\@sx {
    min-width: 74px;
  }
}
@media (min-width: 640px) {
  .w-74\@s {
    width: 74px;
  }
  .maxw-74\@s {
    max-width: 74px;
  }
  .minw-74\@s {
    min-width: 74px;
  }
}
@media (min-width: 960px) {
  .w-74\@m {
    width: 74px;
  }
  .maxw-74\@m {
    max-width: 74px;
  }
  .minw-74\@m {
    min-width: 74px;
  }
}
@media (min-width: 1200px) {
  .w-74\@l {
    width: 74px;
  }
  .maxw-74\@l {
    max-width: 74px;
  }
  .minw-74\@l {
    min-width: 74px;
  }
}
@media (min-width: 1600px) {
  .w-74\@xl {
    width: 74px;
  }
  .maxw-74\@xl {
    max-width: 74px;
  }
  .minw-74\@xl {
    min-width: 74px;
  }
}
.w-75 {
  width: 75px;
}

.maxw-75 {
  max-width: 75px;
}

.minw-75 {
  min-width: 75px;
}

@media (min-width: 320px) {
  .w-75\@sx {
    width: 75px;
  }
  .maxw-75\@sx {
    max-width: 75px;
  }
  .minw-75\@sx {
    min-width: 75px;
  }
}
@media (min-width: 640px) {
  .w-75\@s {
    width: 75px;
  }
  .maxw-75\@s {
    max-width: 75px;
  }
  .minw-75\@s {
    min-width: 75px;
  }
}
@media (min-width: 960px) {
  .w-75\@m {
    width: 75px;
  }
  .maxw-75\@m {
    max-width: 75px;
  }
  .minw-75\@m {
    min-width: 75px;
  }
}
@media (min-width: 1200px) {
  .w-75\@l {
    width: 75px;
  }
  .maxw-75\@l {
    max-width: 75px;
  }
  .minw-75\@l {
    min-width: 75px;
  }
}
@media (min-width: 1600px) {
  .w-75\@xl {
    width: 75px;
  }
  .maxw-75\@xl {
    max-width: 75px;
  }
  .minw-75\@xl {
    min-width: 75px;
  }
}
.w-76 {
  width: 76px;
}

.maxw-76 {
  max-width: 76px;
}

.minw-76 {
  min-width: 76px;
}

@media (min-width: 320px) {
  .w-76\@sx {
    width: 76px;
  }
  .maxw-76\@sx {
    max-width: 76px;
  }
  .minw-76\@sx {
    min-width: 76px;
  }
}
@media (min-width: 640px) {
  .w-76\@s {
    width: 76px;
  }
  .maxw-76\@s {
    max-width: 76px;
  }
  .minw-76\@s {
    min-width: 76px;
  }
}
@media (min-width: 960px) {
  .w-76\@m {
    width: 76px;
  }
  .maxw-76\@m {
    max-width: 76px;
  }
  .minw-76\@m {
    min-width: 76px;
  }
}
@media (min-width: 1200px) {
  .w-76\@l {
    width: 76px;
  }
  .maxw-76\@l {
    max-width: 76px;
  }
  .minw-76\@l {
    min-width: 76px;
  }
}
@media (min-width: 1600px) {
  .w-76\@xl {
    width: 76px;
  }
  .maxw-76\@xl {
    max-width: 76px;
  }
  .minw-76\@xl {
    min-width: 76px;
  }
}
.w-77 {
  width: 77px;
}

.maxw-77 {
  max-width: 77px;
}

.minw-77 {
  min-width: 77px;
}

@media (min-width: 320px) {
  .w-77\@sx {
    width: 77px;
  }
  .maxw-77\@sx {
    max-width: 77px;
  }
  .minw-77\@sx {
    min-width: 77px;
  }
}
@media (min-width: 640px) {
  .w-77\@s {
    width: 77px;
  }
  .maxw-77\@s {
    max-width: 77px;
  }
  .minw-77\@s {
    min-width: 77px;
  }
}
@media (min-width: 960px) {
  .w-77\@m {
    width: 77px;
  }
  .maxw-77\@m {
    max-width: 77px;
  }
  .minw-77\@m {
    min-width: 77px;
  }
}
@media (min-width: 1200px) {
  .w-77\@l {
    width: 77px;
  }
  .maxw-77\@l {
    max-width: 77px;
  }
  .minw-77\@l {
    min-width: 77px;
  }
}
@media (min-width: 1600px) {
  .w-77\@xl {
    width: 77px;
  }
  .maxw-77\@xl {
    max-width: 77px;
  }
  .minw-77\@xl {
    min-width: 77px;
  }
}
.w-78 {
  width: 78px;
}

.maxw-78 {
  max-width: 78px;
}

.minw-78 {
  min-width: 78px;
}

@media (min-width: 320px) {
  .w-78\@sx {
    width: 78px;
  }
  .maxw-78\@sx {
    max-width: 78px;
  }
  .minw-78\@sx {
    min-width: 78px;
  }
}
@media (min-width: 640px) {
  .w-78\@s {
    width: 78px;
  }
  .maxw-78\@s {
    max-width: 78px;
  }
  .minw-78\@s {
    min-width: 78px;
  }
}
@media (min-width: 960px) {
  .w-78\@m {
    width: 78px;
  }
  .maxw-78\@m {
    max-width: 78px;
  }
  .minw-78\@m {
    min-width: 78px;
  }
}
@media (min-width: 1200px) {
  .w-78\@l {
    width: 78px;
  }
  .maxw-78\@l {
    max-width: 78px;
  }
  .minw-78\@l {
    min-width: 78px;
  }
}
@media (min-width: 1600px) {
  .w-78\@xl {
    width: 78px;
  }
  .maxw-78\@xl {
    max-width: 78px;
  }
  .minw-78\@xl {
    min-width: 78px;
  }
}
.w-79 {
  width: 79px;
}

.maxw-79 {
  max-width: 79px;
}

.minw-79 {
  min-width: 79px;
}

@media (min-width: 320px) {
  .w-79\@sx {
    width: 79px;
  }
  .maxw-79\@sx {
    max-width: 79px;
  }
  .minw-79\@sx {
    min-width: 79px;
  }
}
@media (min-width: 640px) {
  .w-79\@s {
    width: 79px;
  }
  .maxw-79\@s {
    max-width: 79px;
  }
  .minw-79\@s {
    min-width: 79px;
  }
}
@media (min-width: 960px) {
  .w-79\@m {
    width: 79px;
  }
  .maxw-79\@m {
    max-width: 79px;
  }
  .minw-79\@m {
    min-width: 79px;
  }
}
@media (min-width: 1200px) {
  .w-79\@l {
    width: 79px;
  }
  .maxw-79\@l {
    max-width: 79px;
  }
  .minw-79\@l {
    min-width: 79px;
  }
}
@media (min-width: 1600px) {
  .w-79\@xl {
    width: 79px;
  }
  .maxw-79\@xl {
    max-width: 79px;
  }
  .minw-79\@xl {
    min-width: 79px;
  }
}
.w-80 {
  width: 80px;
}

.maxw-80 {
  max-width: 80px;
}

.minw-80 {
  min-width: 80px;
}

@media (min-width: 320px) {
  .w-80\@sx {
    width: 80px;
  }
  .maxw-80\@sx {
    max-width: 80px;
  }
  .minw-80\@sx {
    min-width: 80px;
  }
}
@media (min-width: 640px) {
  .w-80\@s {
    width: 80px;
  }
  .maxw-80\@s {
    max-width: 80px;
  }
  .minw-80\@s {
    min-width: 80px;
  }
}
@media (min-width: 960px) {
  .w-80\@m {
    width: 80px;
  }
  .maxw-80\@m {
    max-width: 80px;
  }
  .minw-80\@m {
    min-width: 80px;
  }
}
@media (min-width: 1200px) {
  .w-80\@l {
    width: 80px;
  }
  .maxw-80\@l {
    max-width: 80px;
  }
  .minw-80\@l {
    min-width: 80px;
  }
}
@media (min-width: 1600px) {
  .w-80\@xl {
    width: 80px;
  }
  .maxw-80\@xl {
    max-width: 80px;
  }
  .minw-80\@xl {
    min-width: 80px;
  }
}
.w-81 {
  width: 81px;
}

.maxw-81 {
  max-width: 81px;
}

.minw-81 {
  min-width: 81px;
}

@media (min-width: 320px) {
  .w-81\@sx {
    width: 81px;
  }
  .maxw-81\@sx {
    max-width: 81px;
  }
  .minw-81\@sx {
    min-width: 81px;
  }
}
@media (min-width: 640px) {
  .w-81\@s {
    width: 81px;
  }
  .maxw-81\@s {
    max-width: 81px;
  }
  .minw-81\@s {
    min-width: 81px;
  }
}
@media (min-width: 960px) {
  .w-81\@m {
    width: 81px;
  }
  .maxw-81\@m {
    max-width: 81px;
  }
  .minw-81\@m {
    min-width: 81px;
  }
}
@media (min-width: 1200px) {
  .w-81\@l {
    width: 81px;
  }
  .maxw-81\@l {
    max-width: 81px;
  }
  .minw-81\@l {
    min-width: 81px;
  }
}
@media (min-width: 1600px) {
  .w-81\@xl {
    width: 81px;
  }
  .maxw-81\@xl {
    max-width: 81px;
  }
  .minw-81\@xl {
    min-width: 81px;
  }
}
.w-82 {
  width: 82px;
}

.maxw-82 {
  max-width: 82px;
}

.minw-82 {
  min-width: 82px;
}

@media (min-width: 320px) {
  .w-82\@sx {
    width: 82px;
  }
  .maxw-82\@sx {
    max-width: 82px;
  }
  .minw-82\@sx {
    min-width: 82px;
  }
}
@media (min-width: 640px) {
  .w-82\@s {
    width: 82px;
  }
  .maxw-82\@s {
    max-width: 82px;
  }
  .minw-82\@s {
    min-width: 82px;
  }
}
@media (min-width: 960px) {
  .w-82\@m {
    width: 82px;
  }
  .maxw-82\@m {
    max-width: 82px;
  }
  .minw-82\@m {
    min-width: 82px;
  }
}
@media (min-width: 1200px) {
  .w-82\@l {
    width: 82px;
  }
  .maxw-82\@l {
    max-width: 82px;
  }
  .minw-82\@l {
    min-width: 82px;
  }
}
@media (min-width: 1600px) {
  .w-82\@xl {
    width: 82px;
  }
  .maxw-82\@xl {
    max-width: 82px;
  }
  .minw-82\@xl {
    min-width: 82px;
  }
}
.w-83 {
  width: 83px;
}

.maxw-83 {
  max-width: 83px;
}

.minw-83 {
  min-width: 83px;
}

@media (min-width: 320px) {
  .w-83\@sx {
    width: 83px;
  }
  .maxw-83\@sx {
    max-width: 83px;
  }
  .minw-83\@sx {
    min-width: 83px;
  }
}
@media (min-width: 640px) {
  .w-83\@s {
    width: 83px;
  }
  .maxw-83\@s {
    max-width: 83px;
  }
  .minw-83\@s {
    min-width: 83px;
  }
}
@media (min-width: 960px) {
  .w-83\@m {
    width: 83px;
  }
  .maxw-83\@m {
    max-width: 83px;
  }
  .minw-83\@m {
    min-width: 83px;
  }
}
@media (min-width: 1200px) {
  .w-83\@l {
    width: 83px;
  }
  .maxw-83\@l {
    max-width: 83px;
  }
  .minw-83\@l {
    min-width: 83px;
  }
}
@media (min-width: 1600px) {
  .w-83\@xl {
    width: 83px;
  }
  .maxw-83\@xl {
    max-width: 83px;
  }
  .minw-83\@xl {
    min-width: 83px;
  }
}
.w-84 {
  width: 84px;
}

.maxw-84 {
  max-width: 84px;
}

.minw-84 {
  min-width: 84px;
}

@media (min-width: 320px) {
  .w-84\@sx {
    width: 84px;
  }
  .maxw-84\@sx {
    max-width: 84px;
  }
  .minw-84\@sx {
    min-width: 84px;
  }
}
@media (min-width: 640px) {
  .w-84\@s {
    width: 84px;
  }
  .maxw-84\@s {
    max-width: 84px;
  }
  .minw-84\@s {
    min-width: 84px;
  }
}
@media (min-width: 960px) {
  .w-84\@m {
    width: 84px;
  }
  .maxw-84\@m {
    max-width: 84px;
  }
  .minw-84\@m {
    min-width: 84px;
  }
}
@media (min-width: 1200px) {
  .w-84\@l {
    width: 84px;
  }
  .maxw-84\@l {
    max-width: 84px;
  }
  .minw-84\@l {
    min-width: 84px;
  }
}
@media (min-width: 1600px) {
  .w-84\@xl {
    width: 84px;
  }
  .maxw-84\@xl {
    max-width: 84px;
  }
  .minw-84\@xl {
    min-width: 84px;
  }
}
.w-85 {
  width: 85px;
}

.maxw-85 {
  max-width: 85px;
}

.minw-85 {
  min-width: 85px;
}

@media (min-width: 320px) {
  .w-85\@sx {
    width: 85px;
  }
  .maxw-85\@sx {
    max-width: 85px;
  }
  .minw-85\@sx {
    min-width: 85px;
  }
}
@media (min-width: 640px) {
  .w-85\@s {
    width: 85px;
  }
  .maxw-85\@s {
    max-width: 85px;
  }
  .minw-85\@s {
    min-width: 85px;
  }
}
@media (min-width: 960px) {
  .w-85\@m {
    width: 85px;
  }
  .maxw-85\@m {
    max-width: 85px;
  }
  .minw-85\@m {
    min-width: 85px;
  }
}
@media (min-width: 1200px) {
  .w-85\@l {
    width: 85px;
  }
  .maxw-85\@l {
    max-width: 85px;
  }
  .minw-85\@l {
    min-width: 85px;
  }
}
@media (min-width: 1600px) {
  .w-85\@xl {
    width: 85px;
  }
  .maxw-85\@xl {
    max-width: 85px;
  }
  .minw-85\@xl {
    min-width: 85px;
  }
}
.w-86 {
  width: 86px;
}

.maxw-86 {
  max-width: 86px;
}

.minw-86 {
  min-width: 86px;
}

@media (min-width: 320px) {
  .w-86\@sx {
    width: 86px;
  }
  .maxw-86\@sx {
    max-width: 86px;
  }
  .minw-86\@sx {
    min-width: 86px;
  }
}
@media (min-width: 640px) {
  .w-86\@s {
    width: 86px;
  }
  .maxw-86\@s {
    max-width: 86px;
  }
  .minw-86\@s {
    min-width: 86px;
  }
}
@media (min-width: 960px) {
  .w-86\@m {
    width: 86px;
  }
  .maxw-86\@m {
    max-width: 86px;
  }
  .minw-86\@m {
    min-width: 86px;
  }
}
@media (min-width: 1200px) {
  .w-86\@l {
    width: 86px;
  }
  .maxw-86\@l {
    max-width: 86px;
  }
  .minw-86\@l {
    min-width: 86px;
  }
}
@media (min-width: 1600px) {
  .w-86\@xl {
    width: 86px;
  }
  .maxw-86\@xl {
    max-width: 86px;
  }
  .minw-86\@xl {
    min-width: 86px;
  }
}
.w-87 {
  width: 87px;
}

.maxw-87 {
  max-width: 87px;
}

.minw-87 {
  min-width: 87px;
}

@media (min-width: 320px) {
  .w-87\@sx {
    width: 87px;
  }
  .maxw-87\@sx {
    max-width: 87px;
  }
  .minw-87\@sx {
    min-width: 87px;
  }
}
@media (min-width: 640px) {
  .w-87\@s {
    width: 87px;
  }
  .maxw-87\@s {
    max-width: 87px;
  }
  .minw-87\@s {
    min-width: 87px;
  }
}
@media (min-width: 960px) {
  .w-87\@m {
    width: 87px;
  }
  .maxw-87\@m {
    max-width: 87px;
  }
  .minw-87\@m {
    min-width: 87px;
  }
}
@media (min-width: 1200px) {
  .w-87\@l {
    width: 87px;
  }
  .maxw-87\@l {
    max-width: 87px;
  }
  .minw-87\@l {
    min-width: 87px;
  }
}
@media (min-width: 1600px) {
  .w-87\@xl {
    width: 87px;
  }
  .maxw-87\@xl {
    max-width: 87px;
  }
  .minw-87\@xl {
    min-width: 87px;
  }
}
.w-88 {
  width: 88px;
}

.maxw-88 {
  max-width: 88px;
}

.minw-88 {
  min-width: 88px;
}

@media (min-width: 320px) {
  .w-88\@sx {
    width: 88px;
  }
  .maxw-88\@sx {
    max-width: 88px;
  }
  .minw-88\@sx {
    min-width: 88px;
  }
}
@media (min-width: 640px) {
  .w-88\@s {
    width: 88px;
  }
  .maxw-88\@s {
    max-width: 88px;
  }
  .minw-88\@s {
    min-width: 88px;
  }
}
@media (min-width: 960px) {
  .w-88\@m {
    width: 88px;
  }
  .maxw-88\@m {
    max-width: 88px;
  }
  .minw-88\@m {
    min-width: 88px;
  }
}
@media (min-width: 1200px) {
  .w-88\@l {
    width: 88px;
  }
  .maxw-88\@l {
    max-width: 88px;
  }
  .minw-88\@l {
    min-width: 88px;
  }
}
@media (min-width: 1600px) {
  .w-88\@xl {
    width: 88px;
  }
  .maxw-88\@xl {
    max-width: 88px;
  }
  .minw-88\@xl {
    min-width: 88px;
  }
}
.w-89 {
  width: 89px;
}

.maxw-89 {
  max-width: 89px;
}

.minw-89 {
  min-width: 89px;
}

@media (min-width: 320px) {
  .w-89\@sx {
    width: 89px;
  }
  .maxw-89\@sx {
    max-width: 89px;
  }
  .minw-89\@sx {
    min-width: 89px;
  }
}
@media (min-width: 640px) {
  .w-89\@s {
    width: 89px;
  }
  .maxw-89\@s {
    max-width: 89px;
  }
  .minw-89\@s {
    min-width: 89px;
  }
}
@media (min-width: 960px) {
  .w-89\@m {
    width: 89px;
  }
  .maxw-89\@m {
    max-width: 89px;
  }
  .minw-89\@m {
    min-width: 89px;
  }
}
@media (min-width: 1200px) {
  .w-89\@l {
    width: 89px;
  }
  .maxw-89\@l {
    max-width: 89px;
  }
  .minw-89\@l {
    min-width: 89px;
  }
}
@media (min-width: 1600px) {
  .w-89\@xl {
    width: 89px;
  }
  .maxw-89\@xl {
    max-width: 89px;
  }
  .minw-89\@xl {
    min-width: 89px;
  }
}
.w-90 {
  width: 90px;
}

.maxw-90 {
  max-width: 90px;
}

.minw-90 {
  min-width: 90px;
}

@media (min-width: 320px) {
  .w-90\@sx {
    width: 90px;
  }
  .maxw-90\@sx {
    max-width: 90px;
  }
  .minw-90\@sx {
    min-width: 90px;
  }
}
@media (min-width: 640px) {
  .w-90\@s {
    width: 90px;
  }
  .maxw-90\@s {
    max-width: 90px;
  }
  .minw-90\@s {
    min-width: 90px;
  }
}
@media (min-width: 960px) {
  .w-90\@m {
    width: 90px;
  }
  .maxw-90\@m {
    max-width: 90px;
  }
  .minw-90\@m {
    min-width: 90px;
  }
}
@media (min-width: 1200px) {
  .w-90\@l {
    width: 90px;
  }
  .maxw-90\@l {
    max-width: 90px;
  }
  .minw-90\@l {
    min-width: 90px;
  }
}
@media (min-width: 1600px) {
  .w-90\@xl {
    width: 90px;
  }
  .maxw-90\@xl {
    max-width: 90px;
  }
  .minw-90\@xl {
    min-width: 90px;
  }
}
.w-91 {
  width: 91px;
}

.maxw-91 {
  max-width: 91px;
}

.minw-91 {
  min-width: 91px;
}

@media (min-width: 320px) {
  .w-91\@sx {
    width: 91px;
  }
  .maxw-91\@sx {
    max-width: 91px;
  }
  .minw-91\@sx {
    min-width: 91px;
  }
}
@media (min-width: 640px) {
  .w-91\@s {
    width: 91px;
  }
  .maxw-91\@s {
    max-width: 91px;
  }
  .minw-91\@s {
    min-width: 91px;
  }
}
@media (min-width: 960px) {
  .w-91\@m {
    width: 91px;
  }
  .maxw-91\@m {
    max-width: 91px;
  }
  .minw-91\@m {
    min-width: 91px;
  }
}
@media (min-width: 1200px) {
  .w-91\@l {
    width: 91px;
  }
  .maxw-91\@l {
    max-width: 91px;
  }
  .minw-91\@l {
    min-width: 91px;
  }
}
@media (min-width: 1600px) {
  .w-91\@xl {
    width: 91px;
  }
  .maxw-91\@xl {
    max-width: 91px;
  }
  .minw-91\@xl {
    min-width: 91px;
  }
}
.w-92 {
  width: 92px;
}

.maxw-92 {
  max-width: 92px;
}

.minw-92 {
  min-width: 92px;
}

@media (min-width: 320px) {
  .w-92\@sx {
    width: 92px;
  }
  .maxw-92\@sx {
    max-width: 92px;
  }
  .minw-92\@sx {
    min-width: 92px;
  }
}
@media (min-width: 640px) {
  .w-92\@s {
    width: 92px;
  }
  .maxw-92\@s {
    max-width: 92px;
  }
  .minw-92\@s {
    min-width: 92px;
  }
}
@media (min-width: 960px) {
  .w-92\@m {
    width: 92px;
  }
  .maxw-92\@m {
    max-width: 92px;
  }
  .minw-92\@m {
    min-width: 92px;
  }
}
@media (min-width: 1200px) {
  .w-92\@l {
    width: 92px;
  }
  .maxw-92\@l {
    max-width: 92px;
  }
  .minw-92\@l {
    min-width: 92px;
  }
}
@media (min-width: 1600px) {
  .w-92\@xl {
    width: 92px;
  }
  .maxw-92\@xl {
    max-width: 92px;
  }
  .minw-92\@xl {
    min-width: 92px;
  }
}
.w-93 {
  width: 93px;
}

.maxw-93 {
  max-width: 93px;
}

.minw-93 {
  min-width: 93px;
}

@media (min-width: 320px) {
  .w-93\@sx {
    width: 93px;
  }
  .maxw-93\@sx {
    max-width: 93px;
  }
  .minw-93\@sx {
    min-width: 93px;
  }
}
@media (min-width: 640px) {
  .w-93\@s {
    width: 93px;
  }
  .maxw-93\@s {
    max-width: 93px;
  }
  .minw-93\@s {
    min-width: 93px;
  }
}
@media (min-width: 960px) {
  .w-93\@m {
    width: 93px;
  }
  .maxw-93\@m {
    max-width: 93px;
  }
  .minw-93\@m {
    min-width: 93px;
  }
}
@media (min-width: 1200px) {
  .w-93\@l {
    width: 93px;
  }
  .maxw-93\@l {
    max-width: 93px;
  }
  .minw-93\@l {
    min-width: 93px;
  }
}
@media (min-width: 1600px) {
  .w-93\@xl {
    width: 93px;
  }
  .maxw-93\@xl {
    max-width: 93px;
  }
  .minw-93\@xl {
    min-width: 93px;
  }
}
.w-94 {
  width: 94px;
}

.maxw-94 {
  max-width: 94px;
}

.minw-94 {
  min-width: 94px;
}

@media (min-width: 320px) {
  .w-94\@sx {
    width: 94px;
  }
  .maxw-94\@sx {
    max-width: 94px;
  }
  .minw-94\@sx {
    min-width: 94px;
  }
}
@media (min-width: 640px) {
  .w-94\@s {
    width: 94px;
  }
  .maxw-94\@s {
    max-width: 94px;
  }
  .minw-94\@s {
    min-width: 94px;
  }
}
@media (min-width: 960px) {
  .w-94\@m {
    width: 94px;
  }
  .maxw-94\@m {
    max-width: 94px;
  }
  .minw-94\@m {
    min-width: 94px;
  }
}
@media (min-width: 1200px) {
  .w-94\@l {
    width: 94px;
  }
  .maxw-94\@l {
    max-width: 94px;
  }
  .minw-94\@l {
    min-width: 94px;
  }
}
@media (min-width: 1600px) {
  .w-94\@xl {
    width: 94px;
  }
  .maxw-94\@xl {
    max-width: 94px;
  }
  .minw-94\@xl {
    min-width: 94px;
  }
}
.w-95 {
  width: 95px;
}

.maxw-95 {
  max-width: 95px;
}

.minw-95 {
  min-width: 95px;
}

@media (min-width: 320px) {
  .w-95\@sx {
    width: 95px;
  }
  .maxw-95\@sx {
    max-width: 95px;
  }
  .minw-95\@sx {
    min-width: 95px;
  }
}
@media (min-width: 640px) {
  .w-95\@s {
    width: 95px;
  }
  .maxw-95\@s {
    max-width: 95px;
  }
  .minw-95\@s {
    min-width: 95px;
  }
}
@media (min-width: 960px) {
  .w-95\@m {
    width: 95px;
  }
  .maxw-95\@m {
    max-width: 95px;
  }
  .minw-95\@m {
    min-width: 95px;
  }
}
@media (min-width: 1200px) {
  .w-95\@l {
    width: 95px;
  }
  .maxw-95\@l {
    max-width: 95px;
  }
  .minw-95\@l {
    min-width: 95px;
  }
}
@media (min-width: 1600px) {
  .w-95\@xl {
    width: 95px;
  }
  .maxw-95\@xl {
    max-width: 95px;
  }
  .minw-95\@xl {
    min-width: 95px;
  }
}
.w-96 {
  width: 96px;
}

.maxw-96 {
  max-width: 96px;
}

.minw-96 {
  min-width: 96px;
}

@media (min-width: 320px) {
  .w-96\@sx {
    width: 96px;
  }
  .maxw-96\@sx {
    max-width: 96px;
  }
  .minw-96\@sx {
    min-width: 96px;
  }
}
@media (min-width: 640px) {
  .w-96\@s {
    width: 96px;
  }
  .maxw-96\@s {
    max-width: 96px;
  }
  .minw-96\@s {
    min-width: 96px;
  }
}
@media (min-width: 960px) {
  .w-96\@m {
    width: 96px;
  }
  .maxw-96\@m {
    max-width: 96px;
  }
  .minw-96\@m {
    min-width: 96px;
  }
}
@media (min-width: 1200px) {
  .w-96\@l {
    width: 96px;
  }
  .maxw-96\@l {
    max-width: 96px;
  }
  .minw-96\@l {
    min-width: 96px;
  }
}
@media (min-width: 1600px) {
  .w-96\@xl {
    width: 96px;
  }
  .maxw-96\@xl {
    max-width: 96px;
  }
  .minw-96\@xl {
    min-width: 96px;
  }
}
.w-97 {
  width: 97px;
}

.maxw-97 {
  max-width: 97px;
}

.minw-97 {
  min-width: 97px;
}

@media (min-width: 320px) {
  .w-97\@sx {
    width: 97px;
  }
  .maxw-97\@sx {
    max-width: 97px;
  }
  .minw-97\@sx {
    min-width: 97px;
  }
}
@media (min-width: 640px) {
  .w-97\@s {
    width: 97px;
  }
  .maxw-97\@s {
    max-width: 97px;
  }
  .minw-97\@s {
    min-width: 97px;
  }
}
@media (min-width: 960px) {
  .w-97\@m {
    width: 97px;
  }
  .maxw-97\@m {
    max-width: 97px;
  }
  .minw-97\@m {
    min-width: 97px;
  }
}
@media (min-width: 1200px) {
  .w-97\@l {
    width: 97px;
  }
  .maxw-97\@l {
    max-width: 97px;
  }
  .minw-97\@l {
    min-width: 97px;
  }
}
@media (min-width: 1600px) {
  .w-97\@xl {
    width: 97px;
  }
  .maxw-97\@xl {
    max-width: 97px;
  }
  .minw-97\@xl {
    min-width: 97px;
  }
}
.w-98 {
  width: 98px;
}

.maxw-98 {
  max-width: 98px;
}

.minw-98 {
  min-width: 98px;
}

@media (min-width: 320px) {
  .w-98\@sx {
    width: 98px;
  }
  .maxw-98\@sx {
    max-width: 98px;
  }
  .minw-98\@sx {
    min-width: 98px;
  }
}
@media (min-width: 640px) {
  .w-98\@s {
    width: 98px;
  }
  .maxw-98\@s {
    max-width: 98px;
  }
  .minw-98\@s {
    min-width: 98px;
  }
}
@media (min-width: 960px) {
  .w-98\@m {
    width: 98px;
  }
  .maxw-98\@m {
    max-width: 98px;
  }
  .minw-98\@m {
    min-width: 98px;
  }
}
@media (min-width: 1200px) {
  .w-98\@l {
    width: 98px;
  }
  .maxw-98\@l {
    max-width: 98px;
  }
  .minw-98\@l {
    min-width: 98px;
  }
}
@media (min-width: 1600px) {
  .w-98\@xl {
    width: 98px;
  }
  .maxw-98\@xl {
    max-width: 98px;
  }
  .minw-98\@xl {
    min-width: 98px;
  }
}
.w-99 {
  width: 99px;
}

.maxw-99 {
  max-width: 99px;
}

.minw-99 {
  min-width: 99px;
}

@media (min-width: 320px) {
  .w-99\@sx {
    width: 99px;
  }
  .maxw-99\@sx {
    max-width: 99px;
  }
  .minw-99\@sx {
    min-width: 99px;
  }
}
@media (min-width: 640px) {
  .w-99\@s {
    width: 99px;
  }
  .maxw-99\@s {
    max-width: 99px;
  }
  .minw-99\@s {
    min-width: 99px;
  }
}
@media (min-width: 960px) {
  .w-99\@m {
    width: 99px;
  }
  .maxw-99\@m {
    max-width: 99px;
  }
  .minw-99\@m {
    min-width: 99px;
  }
}
@media (min-width: 1200px) {
  .w-99\@l {
    width: 99px;
  }
  .maxw-99\@l {
    max-width: 99px;
  }
  .minw-99\@l {
    min-width: 99px;
  }
}
@media (min-width: 1600px) {
  .w-99\@xl {
    width: 99px;
  }
  .maxw-99\@xl {
    max-width: 99px;
  }
  .minw-99\@xl {
    min-width: 99px;
  }
}
.w-100 {
  width: 100px;
}

.maxw-100 {
  max-width: 100px;
}

.minw-100 {
  min-width: 100px;
}

@media (min-width: 320px) {
  .w-100\@sx {
    width: 100px;
  }
  .maxw-100\@sx {
    max-width: 100px;
  }
  .minw-100\@sx {
    min-width: 100px;
  }
}
@media (min-width: 640px) {
  .w-100\@s {
    width: 100px;
  }
  .maxw-100\@s {
    max-width: 100px;
  }
  .minw-100\@s {
    min-width: 100px;
  }
}
@media (min-width: 960px) {
  .w-100\@m {
    width: 100px;
  }
  .maxw-100\@m {
    max-width: 100px;
  }
  .minw-100\@m {
    min-width: 100px;
  }
}
@media (min-width: 1200px) {
  .w-100\@l {
    width: 100px;
  }
  .maxw-100\@l {
    max-width: 100px;
  }
  .minw-100\@l {
    min-width: 100px;
  }
}
@media (min-width: 1600px) {
  .w-100\@xl {
    width: 100px;
  }
  .maxw-100\@xl {
    max-width: 100px;
  }
  .minw-100\@xl {
    min-width: 100px;
  }
}
.mecanismos_img {
  min-height: 170px;
  background-position: center;
  background-size: cover;
}

.mecanismos_degradado {
  background: transparent linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #EEEEEE 45%, #EEEEEE 72%, #EEEEEE 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 32px;
  bottom: 30px;
}

.mecanismos_degradado_p {
  display: block;
  max-width: 100%;
  height: 75px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list_preve:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 25px;
  position: absolute;
  left: 26px;
  top: 8px;
}

.anexo {
  counter-reset: numero_inv;
  margin: 0 auto;
}

.anexo_num span:after {
  counter-increment: numero_inv;
  content: counter(numero_inv, decimal);
  color: #fff;
  font-size: 18px;
  margin-left: 5px;
  min-width: 53px;
  font-weight: 800;
}

/* Libraries Components */
/* External Components */
.uk-navbar-nav.uk-navbar-menu-top > li > a {
  min-height: inherit;
  padding: 5px 0;
}

.uk-navbar-nav > li > a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: initial;
}
.uk-navbar-nav > li > a:hover {
  color: #ffffff;
}
.uk-navbar-nav > li > a:active {
  color: #ffffff;
}
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #ffffff;
}

.uk-dropdown-nav > li > a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.uk-dropdown-nav > li > a:hover {
  color: #ffffff;
}

.uk-grid-divider-pc-pink.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before,
.uk-grid-divider-pc-pink.uk-grid-divider > :not(.uk-first-column)::before {
  border-color: #ff1784;
  border-width: 3px;
}

.uk-grid-divider-pc-pink {
  border-color: #ff1784;
  border-width: 3px;
}

.uk-dotnav--pc-navy li a {
  border: none;
  background-color: #002a68;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-navy li.uk-active a {
  border: none;
  background-color: #002a68;
}
.uk-dotnav--pc-pink li a {
  border: none;
  background-color: #ff1784;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-pink li.uk-active a {
  border: none;
  background-color: #ff1784;
}
.uk-dotnav--pc-sky-blue li a {
  border: none;
  background-color: #0090d3;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-sky-blue li.uk-active a {
  border: none;
  background-color: #0090d3;
}
.uk-dotnav--pc-white li a {
  border: none;
  background-color: white;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-white li.uk-active a {
  border: none;
  background-color: white;
}
.uk-dotnav--pc-grey-1 li a {
  border: none;
  background-color: #333333;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-1 li.uk-active a {
  border: none;
  background-color: #333333;
}
.uk-dotnav--pc-grey-2 li a {
  border: none;
  background-color: #888888;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-2 li.uk-active a {
  border: none;
  background-color: #888888;
}
.uk-dotnav--pc-grey-3 li a {
  border: none;
  background-color: #e5e5e5;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-3 li.uk-active a {
  border: none;
  background-color: #e5e5e5;
}
.uk-dotnav--com-green li a {
  border: none;
  background-color: #66af30;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-green li.uk-active a {
  border: none;
  background-color: #66af30;
}
.uk-dotnav--com-cherry li a {
  border: none;
  background-color: #8d1c50;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-cherry li.uk-active a {
  border: none;
  background-color: #8d1c50;
}
.uk-dotnav--com-red li a {
  border: none;
  background-color: #e20613;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-red li.uk-active a {
  border: none;
  background-color: #e20613;
}
.uk-dotnav--com-mint li a {
  border: none;
  background-color: #00999b;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-mint li.uk-active a {
  border: none;
  background-color: #00999b;
}
.uk-dotnav--com-blue li a {
  border: none;
  background-color: #004b9b;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-blue li.uk-active a {
  border: none;
  background-color: #004b9b;
}
.uk-dotnav--com-orange li a {
  border: none;
  background-color: #e14504;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-orange li.uk-active a {
  border: none;
  background-color: #e14504;
}
.uk-dotnav--com-gold li a {
  border: none;
  background-color: #f29100;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-gold li.uk-active a {
  border: none;
  background-color: #f29100;
}
.uk-dotnav--com-yellow li a {
  border: none;
  background-color: #ffda27;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-yellow li.uk-active a {
  border: none;
  background-color: #ffda27;
}
.uk-dotnav--sc-navy-2 li a {
  border: none;
  background-color: #011c43;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-navy-2 li.uk-active a {
  border: none;
  background-color: #011c43;
}
.uk-dotnav--sc-pink-2 li a {
  border: none;
  background-color: #a92462;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-pink-2 li.uk-active a {
  border: none;
  background-color: #a92462;
}
.uk-dotnav--sc-sky-blue-2 li a {
  border: none;
  background-color: #0277a0;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-2 li.uk-active a {
  border: none;
  background-color: #0277a0;
}
.uk-dotnav--sc-sky-blue-3 li a {
  border: none;
  background-color: #bce0f1;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-3 li.uk-active a {
  border: none;
  background-color: #bce0f1;
}
.uk-dotnav--sc-sky-blue-4 li a {
  border: none;
  background-color: #e5f4fb;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-4 li.uk-active a {
  border: none;
  background-color: #e5f4fb;
}
.uk-dotnav--sc-grey-4 li a {
  border: none;
  background-color: #666666;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-4 li.uk-active a {
  border: none;
  background-color: #666666;
}
.uk-dotnav--sc-grey-5 li a {
  border: none;
  background-color: #f6f6f6;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-5 li.uk-active a {
  border: none;
  background-color: #f6f6f6;
}
.uk-dotnav--sc-grey-6 li a {
  border: none;
  background-color: #eeeeee;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-6 li.uk-active a {
  border: none;
  background-color: #eeeeee;
}
.uk-dotnav--sc-grey-7 li a {
  border: none;
  background-color: #fcfcfc;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-7 li.uk-active a {
  border: none;
  background-color: #fcfcfc;
}
.uk-dotnav--sc-grey-8 li a {
  border: none;
  background-color: #f1efec;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-8 li.uk-active a {
  border: none;
  background-color: #f1efec;
}

/* Internal Components */
ul {
  list-style-image: url("/images/site/svg/icono-flecha.svg");
}

.sw-main-menu-offcanvas__container {
  background-color: #002a68 !important;
}
.sw-main-menu-offcanvas__item {
  color: #ffffff !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.sw-main-menu-offcanvas__item, .sw-main-menu-offcanvas__item:hover {
  border-style: none;
  border-width: none;
}
.sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item {
  border-left: 2px solid #0090d3 !important;
  padding: 5px 0 5px 10px !important;
  transition: border-left 250ms;
  font-size: 14px;
}
.sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item:hover, .sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item:focus {
  border-left: 2px solid #ff1784 !important;
}

.userdata {
  background-color: #002a68;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

.login-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.form-group {
  margin-bottom: 10px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input[type=text],
.form-group input[type=password] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.form-group i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: #ff1784;
  border: none;
}

.remember-me {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.forgot-questions {
  margin-top: 10px;
  text-align: left;
}

.forgot-questions p {
  margin: 5px 0;
}

.controls {
  background-color: #fff;
  color: #002A68;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  width: 100%;
}

a {
  color: white;
}

.forgot-questions a {
  text-decoration: none;
}

.paginacio_custom li.active a {
  background: #1e87f0;
  color: #333333;
}

.uk-pagination > * > * {
  color: #333333;
}

input[type=text] {
  border: none;
  border-radius: 5px;
  min-height: 40px;
  padding: 2px 10px;
  font-family: "Open Sans";
}

textarea {
  border: none;
  border-radius: 5px;
  min-height: 40px;
  padding: 2px 10px;
  font-family: "Open Sans";
}

.control-label-checkbox {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  display: inline-block;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px !important;
  height: 16px !important;
  border: 0.15em solid currentColor;
  border-radius: 5px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type=checkbox].large {
  width: 1.15em;
  height: 1.15em;
}

input[type=checkbox]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #002a68;
  background-color: CanvasText;
}

input[type=checkbox].check-pc-navy::before {
  box-shadow: inset 1em 1em #002a68;
}

input[type=checkbox].check-pc-pink::before {
  box-shadow: inset 1em 1em #ff1784;
}

input[type=checkbox]:checked::before {
  transform: scale(1);
}

.button-primary {
  padding: 8px 50px;
  background-color: #ffffff;
  color: #002a68;
  border: 2px solid #ff1784;
  border-radius: 5px;
  font-weight: bold;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: background 300ms;
}
.button-primary:focus, .button-primary:hover {
  background: #eeeeee;
  border: 2px solid #ff1784;
}

:root {
  --gutter-large: 24px;
  --gutter-medium: 16px;
  --gutter-small: 8px;
  --brand-color-gray: #f6f6f5;
}

.sw-horizontal-acordion-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
.sw-horizontal-acordion-panel {
  background-size: 352px 424px;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: var(--gutter-medium);
  position: relative;
  transition: all 700ms ease-in;
  max-width: 150px;
}
.sw-horizontal-acordion-panel.sw-active {
  flex: 3;
  max-width: 352px;
}
.sw-horizontal-acordion-title {
  opacity: 0;
  position: absolute;
  transition: all 500ms;
}
.sw-horizontal-acordion-title.sw-active {
  opacity: 1;
}

.registro::after {
  content: "*";
  color: #ff1784;
}
.registro .star {
  display: none;
}

.box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/images/Iconos/icono-flecha-preguntas.svg);
  background-repeat: no-repeat;
  background-position: 97% 51%;
  background-size: 25px;
}

.extrainput input, .extrainput select {
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  border: 0 none;
  padding: 0 10px;
  color: #666666;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 25px;
  font-size: 16px;
}
.extrainput input[type=radio] + label:before {
  content: "";
  background: #f6f6f6;
  border-radius: 100%;
  border: 1px solid #ff1784;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -2px !important;
  margin-right: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.extrainput input[type=radio]:checked + label:before {
  background-color: #002a68;
  box-shadow: inset 0 0 0 4px #f6f6f6;
}
.extrainput input[type=radio] + label:before {
  top: 1px;
}
.extrainput input[type=radio] {
  position: absolute;
  opacity: 0;
}
.extrainput .radio {
  border: 0px;
  padding: 0;
  margin-top: 15px;
  font-weight: 500;
}
.extrainput .radio label {
  margin-right: 15px;
}

.buscador .inputbox {
  border: 2px solid #ff1784;
  padding: 7px;
}
.buscador .inputbox:focus {
  border: 2px solid #ff1784;
}
.buscador .button {
  background-color: #ff1784;
  color: #ffffff;
  padding: 7px;
  border: 2px solid #ff1784;
  margin-left: -4px;
}

.redes__top {
  z-index: 10;
  top: 4px;
}
@media (min-width: 640px) {
  .redes__top {
    top: 15px;
  }
}

.remove-18-top {
  top: -18px;
}

.lin_lateral:before {
  content: "";
  height: 100%;
  display: block;
  width: 1px;
  background: #ff1784;
  position: absolute;
  left: -35px;
}

.lin_lateraR:after {
  content: "";
  height: 100%;
  display: block;
  width: 1px;
  background: #ff1784;
  position: absolute;
  right: -35px;
  top: 0;
  z-index: 0;
}

.titulo__modoneh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
  color: #002a68;
  font-family: "Open Sans Condensed ExtraBold";
}
.titulo__modoneh2:after {
  background-color: #002a68;
  content: "";
  width: 95%;
  height: 3px;
  display: block;
  margin-top: 15px;
  position: relative;
  left: -45px;
}

.titulo__modtwoh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
  text-align: center;
  font-family: "Open Sans Condensed ExtraBold";
  color: #002a68;
}

.titulo__modtreeh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
}
.titulo__modtreeh2:after {
  background-color: #002a68;
  content: "";
  width: 460px;
  max-width: 100%;
  height: 3px;
  display: block;
  margin-top: 15px;
  position: relative;
  left: -45px;
}

.remove__border_bottom:last-child {
  border-bottom: 0px;
}

.mejorespracticas--title:before {
  content: "";
}

.remove_bottom-20 {
  margin-bottom: -20px;
}

.white_deg {
  border-color: rgba(255, 255, 255, 0.3803921569);
}

.titulo__h2__interna {
  font-size: 28px;
  font-weight: 800;
  text-align: left;
  color: #002a68;
  font-family: "Open Sans Condensed ExtraBold";
}
.titulo__h2__interna:after {
  background-color: #ff1784;
  content: "";
  width: 188px;
  height: 6px;
  display: block;
  margin-top: 14px;
  position: relative;
}

.uk-modal-iframe-dialog {
  position: relative;
  padding-bottom: 50.625%; /* 16:9 */
  width: 90%;
  height: 0;
}
@media (min-width: 1200px) {
  .uk-modal-iframe-dialog {
    padding-bottom: 675px; /* 16:9 */
    width: 1200px !important;
  }
}
.uk-modal-iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.level1 .uk-accordion-title {
  background-color: #F1EFEC;
  border-bottom: 1px solid;
  border-radius: 0;
  font-weight: 400;
  padding-left: 0px;
}
.level1 svg {
  color: #ff1784;
}
.level1 > :nth-child(n+2) {
  margin-top: 2px;
}

.level2 .uk-accordion-title {
  background-color: #ffffff;
  border-bottom: 0px;
  border-radius: 30px;
  font-weight: 700;
  padding-left: 30px;
}
.level2 svg {
  color: #666666;
}

@font-face {
  font-family: "Open Sans Condensed Bold";
  src: url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.eot");
  src: url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.eot?#iefix") format("embedded-opentype"), url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.woff2") format("woff2"), url("/fonts/condensed/OpenSans-ExtraBold.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.woff") format("woff"), url("/fonts/condensed/c28df5d67b8e0fd14ff07235537b1c77.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/c28df5d67b8e0fd14ff07235537b1c77.svg#Open Sans Condensed Bold") format("svg");
}
@font-face {
  font-family: "Open Sans Condensed ExtraBold";
  src: url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.eot");
  src: url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.woff") format("woff"), url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.svg#Open Sans Condensed ExtraBold") format("svg");
}
@font-face {
  font-family: "Open Sans Condensed Light Regular";
  src: url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.eot");
  src: url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.woff") format("woff"), url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/3da269ebe23a7ec17e071469a3d5e884.svg#Open Sans Condensed Light Regular") format("svg");
}
html,
body {
  font: 500 16px "Open Sans", sans-serif;
}

.f-opensans,
.f-open-sans {
  font-family: "Open Sans", serif, sans-serif;
}

.f-CondensedBold,
.f-Condensed-Bold,
.f-CondensedBold,
.f-Condensedbold {
  font-family: "Open Sans Condensed Bold", serif, sans-serif;
}

.f-CondensedExtrabold,
.f-Condensed-Extrabold,
.f-CondensedExtrabold,
.f-Condensedextrabold {
  font-family: "Open Sans Condensed ExtraBold", serif, sans-serif;
}

.f-CondensedLight,
.f-Condensed-Light,
.f-Condensedlight {
  font-family: "Open Sans Condensed Light Regular", serif, sans-serif;
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6 {
  font-family: "Open Sans", serif, sans-serif;
  line-height: 1.2;
  text-align: center;
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3 {
  font-size: 22px;
  font-weight: 700;
}
@media (min-width: 960px) {
  h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3 {
    font-size: 24px;
  }
}

h3, .uk-h3 {
  font-size: 20px;
}

h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6 {
  font-size: 18px;
  font-weight: 600;
}

.fw-light, .fw-300 {
  font-weight: 300;
}

.fwi-light, .fwi-300 {
  font-weight: 300;
  font-style: italic;
}

.fw-regular, .fw-400 {
  font-weight: 400;
}

.fwi-regular, .fwi-400 {
  font-weight: 400;
  font-style: italic;
}

.fw-normal, .fw-400 {
  font-weight: 400;
}

.fwi-normal, .fwi-400 {
  font-weight: 400;
  font-style: italic;
}

.fw-medium, .fw-500 {
  font-weight: 500;
}

.fwi-medium, .fwi-500 {
  font-weight: 500;
  font-style: italic;
}

.fw-semi-bold, .fw-600 {
  font-weight: 600;
}

.fwi-semi-bold, .fwi-600 {
  font-weight: 600;
  font-style: italic;
}

.fw-semibold, .fw-600 {
  font-weight: 600;
}

.fwi-semibold, .fwi-600 {
  font-weight: 600;
  font-style: italic;
}

.fw-bold, .fw-700 {
  font-weight: 700;
}

.fwi-bold, .fwi-700 {
  font-weight: 700;
  font-style: italic;
}

.fw-extra-bold, .fw-800 {
  font-weight: 800;
}

.fwi-extra-bold, .fwi-800 {
  font-weight: 800;
  font-style: italic;
}

.fw-extrabold, .fw-800 {
  font-weight: 800;
}

.fwi-extrabold, .fwi-800 {
  font-weight: 800;
  font-style: italic;
}

.fs-1 {
  font-size: 1px;
}

@media (min-width: 320px) {
  .fs-1\@sx {
    font-size: 1px;
  }
}
@media (min-width: 640px) {
  .fs-1\@s {
    font-size: 1px;
  }
}
@media (min-width: 960px) {
  .fs-1\@m {
    font-size: 1px;
  }
}
@media (min-width: 1200px) {
  .fs-1\@l {
    font-size: 1px;
  }
}
@media (min-width: 1600px) {
  .fs-1\@xl {
    font-size: 1px;
  }
}
.fs-2 {
  font-size: 2px;
}

@media (min-width: 320px) {
  .fs-2\@sx {
    font-size: 2px;
  }
}
@media (min-width: 640px) {
  .fs-2\@s {
    font-size: 2px;
  }
}
@media (min-width: 960px) {
  .fs-2\@m {
    font-size: 2px;
  }
}
@media (min-width: 1200px) {
  .fs-2\@l {
    font-size: 2px;
  }
}
@media (min-width: 1600px) {
  .fs-2\@xl {
    font-size: 2px;
  }
}
.fs-3 {
  font-size: 3px;
}

@media (min-width: 320px) {
  .fs-3\@sx {
    font-size: 3px;
  }
}
@media (min-width: 640px) {
  .fs-3\@s {
    font-size: 3px;
  }
}
@media (min-width: 960px) {
  .fs-3\@m {
    font-size: 3px;
  }
}
@media (min-width: 1200px) {
  .fs-3\@l {
    font-size: 3px;
  }
}
@media (min-width: 1600px) {
  .fs-3\@xl {
    font-size: 3px;
  }
}
.fs-4 {
  font-size: 4px;
}

@media (min-width: 320px) {
  .fs-4\@sx {
    font-size: 4px;
  }
}
@media (min-width: 640px) {
  .fs-4\@s {
    font-size: 4px;
  }
}
@media (min-width: 960px) {
  .fs-4\@m {
    font-size: 4px;
  }
}
@media (min-width: 1200px) {
  .fs-4\@l {
    font-size: 4px;
  }
}
@media (min-width: 1600px) {
  .fs-4\@xl {
    font-size: 4px;
  }
}
.fs-5 {
  font-size: 5px;
}

@media (min-width: 320px) {
  .fs-5\@sx {
    font-size: 5px;
  }
}
@media (min-width: 640px) {
  .fs-5\@s {
    font-size: 5px;
  }
}
@media (min-width: 960px) {
  .fs-5\@m {
    font-size: 5px;
  }
}
@media (min-width: 1200px) {
  .fs-5\@l {
    font-size: 5px;
  }
}
@media (min-width: 1600px) {
  .fs-5\@xl {
    font-size: 5px;
  }
}
.fs-6 {
  font-size: 6px;
}

@media (min-width: 320px) {
  .fs-6\@sx {
    font-size: 6px;
  }
}
@media (min-width: 640px) {
  .fs-6\@s {
    font-size: 6px;
  }
}
@media (min-width: 960px) {
  .fs-6\@m {
    font-size: 6px;
  }
}
@media (min-width: 1200px) {
  .fs-6\@l {
    font-size: 6px;
  }
}
@media (min-width: 1600px) {
  .fs-6\@xl {
    font-size: 6px;
  }
}
.fs-7 {
  font-size: 7px;
}

@media (min-width: 320px) {
  .fs-7\@sx {
    font-size: 7px;
  }
}
@media (min-width: 640px) {
  .fs-7\@s {
    font-size: 7px;
  }
}
@media (min-width: 960px) {
  .fs-7\@m {
    font-size: 7px;
  }
}
@media (min-width: 1200px) {
  .fs-7\@l {
    font-size: 7px;
  }
}
@media (min-width: 1600px) {
  .fs-7\@xl {
    font-size: 7px;
  }
}
.fs-8 {
  font-size: 8px;
}

@media (min-width: 320px) {
  .fs-8\@sx {
    font-size: 8px;
  }
}
@media (min-width: 640px) {
  .fs-8\@s {
    font-size: 8px;
  }
}
@media (min-width: 960px) {
  .fs-8\@m {
    font-size: 8px;
  }
}
@media (min-width: 1200px) {
  .fs-8\@l {
    font-size: 8px;
  }
}
@media (min-width: 1600px) {
  .fs-8\@xl {
    font-size: 8px;
  }
}
.fs-9 {
  font-size: 9px;
}

@media (min-width: 320px) {
  .fs-9\@sx {
    font-size: 9px;
  }
}
@media (min-width: 640px) {
  .fs-9\@s {
    font-size: 9px;
  }
}
@media (min-width: 960px) {
  .fs-9\@m {
    font-size: 9px;
  }
}
@media (min-width: 1200px) {
  .fs-9\@l {
    font-size: 9px;
  }
}
@media (min-width: 1600px) {
  .fs-9\@xl {
    font-size: 9px;
  }
}
.fs-10 {
  font-size: 10px;
}

@media (min-width: 320px) {
  .fs-10\@sx {
    font-size: 10px;
  }
}
@media (min-width: 640px) {
  .fs-10\@s {
    font-size: 10px;
  }
}
@media (min-width: 960px) {
  .fs-10\@m {
    font-size: 10px;
  }
}
@media (min-width: 1200px) {
  .fs-10\@l {
    font-size: 10px;
  }
}
@media (min-width: 1600px) {
  .fs-10\@xl {
    font-size: 10px;
  }
}
.fs-11 {
  font-size: 11px;
}

@media (min-width: 320px) {
  .fs-11\@sx {
    font-size: 11px;
  }
}
@media (min-width: 640px) {
  .fs-11\@s {
    font-size: 11px;
  }
}
@media (min-width: 960px) {
  .fs-11\@m {
    font-size: 11px;
  }
}
@media (min-width: 1200px) {
  .fs-11\@l {
    font-size: 11px;
  }
}
@media (min-width: 1600px) {
  .fs-11\@xl {
    font-size: 11px;
  }
}
.fs-12 {
  font-size: 12px;
}

@media (min-width: 320px) {
  .fs-12\@sx {
    font-size: 12px;
  }
}
@media (min-width: 640px) {
  .fs-12\@s {
    font-size: 12px;
  }
}
@media (min-width: 960px) {
  .fs-12\@m {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .fs-12\@l {
    font-size: 12px;
  }
}
@media (min-width: 1600px) {
  .fs-12\@xl {
    font-size: 12px;
  }
}
.fs-13 {
  font-size: 13px;
}

@media (min-width: 320px) {
  .fs-13\@sx {
    font-size: 13px;
  }
}
@media (min-width: 640px) {
  .fs-13\@s {
    font-size: 13px;
  }
}
@media (min-width: 960px) {
  .fs-13\@m {
    font-size: 13px;
  }
}
@media (min-width: 1200px) {
  .fs-13\@l {
    font-size: 13px;
  }
}
@media (min-width: 1600px) {
  .fs-13\@xl {
    font-size: 13px;
  }
}
.fs-14 {
  font-size: 14px;
}

@media (min-width: 320px) {
  .fs-14\@sx {
    font-size: 14px;
  }
}
@media (min-width: 640px) {
  .fs-14\@s {
    font-size: 14px;
  }
}
@media (min-width: 960px) {
  .fs-14\@m {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .fs-14\@l {
    font-size: 14px;
  }
}
@media (min-width: 1600px) {
  .fs-14\@xl {
    font-size: 14px;
  }
}
.fs-15 {
  font-size: 15px;
}

@media (min-width: 320px) {
  .fs-15\@sx {
    font-size: 15px;
  }
}
@media (min-width: 640px) {
  .fs-15\@s {
    font-size: 15px;
  }
}
@media (min-width: 960px) {
  .fs-15\@m {
    font-size: 15px;
  }
}
@media (min-width: 1200px) {
  .fs-15\@l {
    font-size: 15px;
  }
}
@media (min-width: 1600px) {
  .fs-15\@xl {
    font-size: 15px;
  }
}
.fs-16 {
  font-size: 16px;
}

@media (min-width: 320px) {
  .fs-16\@sx {
    font-size: 16px;
  }
}
@media (min-width: 640px) {
  .fs-16\@s {
    font-size: 16px;
  }
}
@media (min-width: 960px) {
  .fs-16\@m {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .fs-16\@l {
    font-size: 16px;
  }
}
@media (min-width: 1600px) {
  .fs-16\@xl {
    font-size: 16px;
  }
}
.fs-17 {
  font-size: 17px;
}

@media (min-width: 320px) {
  .fs-17\@sx {
    font-size: 17px;
  }
}
@media (min-width: 640px) {
  .fs-17\@s {
    font-size: 17px;
  }
}
@media (min-width: 960px) {
  .fs-17\@m {
    font-size: 17px;
  }
}
@media (min-width: 1200px) {
  .fs-17\@l {
    font-size: 17px;
  }
}
@media (min-width: 1600px) {
  .fs-17\@xl {
    font-size: 17px;
  }
}
.fs-18 {
  font-size: 18px;
}

@media (min-width: 320px) {
  .fs-18\@sx {
    font-size: 18px;
  }
}
@media (min-width: 640px) {
  .fs-18\@s {
    font-size: 18px;
  }
}
@media (min-width: 960px) {
  .fs-18\@m {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .fs-18\@l {
    font-size: 18px;
  }
}
@media (min-width: 1600px) {
  .fs-18\@xl {
    font-size: 18px;
  }
}
.fs-19 {
  font-size: 19px;
}

@media (min-width: 320px) {
  .fs-19\@sx {
    font-size: 19px;
  }
}
@media (min-width: 640px) {
  .fs-19\@s {
    font-size: 19px;
  }
}
@media (min-width: 960px) {
  .fs-19\@m {
    font-size: 19px;
  }
}
@media (min-width: 1200px) {
  .fs-19\@l {
    font-size: 19px;
  }
}
@media (min-width: 1600px) {
  .fs-19\@xl {
    font-size: 19px;
  }
}
.fs-20 {
  font-size: 20px;
}

@media (min-width: 320px) {
  .fs-20\@sx {
    font-size: 20px;
  }
}
@media (min-width: 640px) {
  .fs-20\@s {
    font-size: 20px;
  }
}
@media (min-width: 960px) {
  .fs-20\@m {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .fs-20\@l {
    font-size: 20px;
  }
}
@media (min-width: 1600px) {
  .fs-20\@xl {
    font-size: 20px;
  }
}
.fs-21 {
  font-size: 21px;
}

@media (min-width: 320px) {
  .fs-21\@sx {
    font-size: 21px;
  }
}
@media (min-width: 640px) {
  .fs-21\@s {
    font-size: 21px;
  }
}
@media (min-width: 960px) {
  .fs-21\@m {
    font-size: 21px;
  }
}
@media (min-width: 1200px) {
  .fs-21\@l {
    font-size: 21px;
  }
}
@media (min-width: 1600px) {
  .fs-21\@xl {
    font-size: 21px;
  }
}
.fs-22 {
  font-size: 22px;
}

@media (min-width: 320px) {
  .fs-22\@sx {
    font-size: 22px;
  }
}
@media (min-width: 640px) {
  .fs-22\@s {
    font-size: 22px;
  }
}
@media (min-width: 960px) {
  .fs-22\@m {
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .fs-22\@l {
    font-size: 22px;
  }
}
@media (min-width: 1600px) {
  .fs-22\@xl {
    font-size: 22px;
  }
}
.fs-23 {
  font-size: 23px;
}

@media (min-width: 320px) {
  .fs-23\@sx {
    font-size: 23px;
  }
}
@media (min-width: 640px) {
  .fs-23\@s {
    font-size: 23px;
  }
}
@media (min-width: 960px) {
  .fs-23\@m {
    font-size: 23px;
  }
}
@media (min-width: 1200px) {
  .fs-23\@l {
    font-size: 23px;
  }
}
@media (min-width: 1600px) {
  .fs-23\@xl {
    font-size: 23px;
  }
}
.fs-24 {
  font-size: 24px;
}

@media (min-width: 320px) {
  .fs-24\@sx {
    font-size: 24px;
  }
}
@media (min-width: 640px) {
  .fs-24\@s {
    font-size: 24px;
  }
}
@media (min-width: 960px) {
  .fs-24\@m {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .fs-24\@l {
    font-size: 24px;
  }
}
@media (min-width: 1600px) {
  .fs-24\@xl {
    font-size: 24px;
  }
}
.fs-25 {
  font-size: 25px;
}

@media (min-width: 320px) {
  .fs-25\@sx {
    font-size: 25px;
  }
}
@media (min-width: 640px) {
  .fs-25\@s {
    font-size: 25px;
  }
}
@media (min-width: 960px) {
  .fs-25\@m {
    font-size: 25px;
  }
}
@media (min-width: 1200px) {
  .fs-25\@l {
    font-size: 25px;
  }
}
@media (min-width: 1600px) {
  .fs-25\@xl {
    font-size: 25px;
  }
}
.fs-26 {
  font-size: 26px;
}

@media (min-width: 320px) {
  .fs-26\@sx {
    font-size: 26px;
  }
}
@media (min-width: 640px) {
  .fs-26\@s {
    font-size: 26px;
  }
}
@media (min-width: 960px) {
  .fs-26\@m {
    font-size: 26px;
  }
}
@media (min-width: 1200px) {
  .fs-26\@l {
    font-size: 26px;
  }
}
@media (min-width: 1600px) {
  .fs-26\@xl {
    font-size: 26px;
  }
}
.fs-27 {
  font-size: 27px;
}

@media (min-width: 320px) {
  .fs-27\@sx {
    font-size: 27px;
  }
}
@media (min-width: 640px) {
  .fs-27\@s {
    font-size: 27px;
  }
}
@media (min-width: 960px) {
  .fs-27\@m {
    font-size: 27px;
  }
}
@media (min-width: 1200px) {
  .fs-27\@l {
    font-size: 27px;
  }
}
@media (min-width: 1600px) {
  .fs-27\@xl {
    font-size: 27px;
  }
}
.fs-28 {
  font-size: 28px;
}

@media (min-width: 320px) {
  .fs-28\@sx {
    font-size: 28px;
  }
}
@media (min-width: 640px) {
  .fs-28\@s {
    font-size: 28px;
  }
}
@media (min-width: 960px) {
  .fs-28\@m {
    font-size: 28px;
  }
}
@media (min-width: 1200px) {
  .fs-28\@l {
    font-size: 28px;
  }
}
@media (min-width: 1600px) {
  .fs-28\@xl {
    font-size: 28px;
  }
}
.fs-29 {
  font-size: 29px;
}

@media (min-width: 320px) {
  .fs-29\@sx {
    font-size: 29px;
  }
}
@media (min-width: 640px) {
  .fs-29\@s {
    font-size: 29px;
  }
}
@media (min-width: 960px) {
  .fs-29\@m {
    font-size: 29px;
  }
}
@media (min-width: 1200px) {
  .fs-29\@l {
    font-size: 29px;
  }
}
@media (min-width: 1600px) {
  .fs-29\@xl {
    font-size: 29px;
  }
}
.fs-30 {
  font-size: 30px;
}

@media (min-width: 320px) {
  .fs-30\@sx {
    font-size: 30px;
  }
}
@media (min-width: 640px) {
  .fs-30\@s {
    font-size: 30px;
  }
}
@media (min-width: 960px) {
  .fs-30\@m {
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  .fs-30\@l {
    font-size: 30px;
  }
}
@media (min-width: 1600px) {
  .fs-30\@xl {
    font-size: 30px;
  }
}
.fs-31 {
  font-size: 31px;
}

@media (min-width: 320px) {
  .fs-31\@sx {
    font-size: 31px;
  }
}
@media (min-width: 640px) {
  .fs-31\@s {
    font-size: 31px;
  }
}
@media (min-width: 960px) {
  .fs-31\@m {
    font-size: 31px;
  }
}
@media (min-width: 1200px) {
  .fs-31\@l {
    font-size: 31px;
  }
}
@media (min-width: 1600px) {
  .fs-31\@xl {
    font-size: 31px;
  }
}
.fs-32 {
  font-size: 32px;
}

@media (min-width: 320px) {
  .fs-32\@sx {
    font-size: 32px;
  }
}
@media (min-width: 640px) {
  .fs-32\@s {
    font-size: 32px;
  }
}
@media (min-width: 960px) {
  .fs-32\@m {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .fs-32\@l {
    font-size: 32px;
  }
}
@media (min-width: 1600px) {
  .fs-32\@xl {
    font-size: 32px;
  }
}
.fs-33 {
  font-size: 33px;
}

@media (min-width: 320px) {
  .fs-33\@sx {
    font-size: 33px;
  }
}
@media (min-width: 640px) {
  .fs-33\@s {
    font-size: 33px;
  }
}
@media (min-width: 960px) {
  .fs-33\@m {
    font-size: 33px;
  }
}
@media (min-width: 1200px) {
  .fs-33\@l {
    font-size: 33px;
  }
}
@media (min-width: 1600px) {
  .fs-33\@xl {
    font-size: 33px;
  }
}
.fs-34 {
  font-size: 34px;
}

@media (min-width: 320px) {
  .fs-34\@sx {
    font-size: 34px;
  }
}
@media (min-width: 640px) {
  .fs-34\@s {
    font-size: 34px;
  }
}
@media (min-width: 960px) {
  .fs-34\@m {
    font-size: 34px;
  }
}
@media (min-width: 1200px) {
  .fs-34\@l {
    font-size: 34px;
  }
}
@media (min-width: 1600px) {
  .fs-34\@xl {
    font-size: 34px;
  }
}
.fs-35 {
  font-size: 35px;
}

@media (min-width: 320px) {
  .fs-35\@sx {
    font-size: 35px;
  }
}
@media (min-width: 640px) {
  .fs-35\@s {
    font-size: 35px;
  }
}
@media (min-width: 960px) {
  .fs-35\@m {
    font-size: 35px;
  }
}
@media (min-width: 1200px) {
  .fs-35\@l {
    font-size: 35px;
  }
}
@media (min-width: 1600px) {
  .fs-35\@xl {
    font-size: 35px;
  }
}
.fs-36 {
  font-size: 36px;
}

@media (min-width: 320px) {
  .fs-36\@sx {
    font-size: 36px;
  }
}
@media (min-width: 640px) {
  .fs-36\@s {
    font-size: 36px;
  }
}
@media (min-width: 960px) {
  .fs-36\@m {
    font-size: 36px;
  }
}
@media (min-width: 1200px) {
  .fs-36\@l {
    font-size: 36px;
  }
}
@media (min-width: 1600px) {
  .fs-36\@xl {
    font-size: 36px;
  }
}
.fs-37 {
  font-size: 37px;
}

@media (min-width: 320px) {
  .fs-37\@sx {
    font-size: 37px;
  }
}
@media (min-width: 640px) {
  .fs-37\@s {
    font-size: 37px;
  }
}
@media (min-width: 960px) {
  .fs-37\@m {
    font-size: 37px;
  }
}
@media (min-width: 1200px) {
  .fs-37\@l {
    font-size: 37px;
  }
}
@media (min-width: 1600px) {
  .fs-37\@xl {
    font-size: 37px;
  }
}
.fs-38 {
  font-size: 38px;
}

@media (min-width: 320px) {
  .fs-38\@sx {
    font-size: 38px;
  }
}
@media (min-width: 640px) {
  .fs-38\@s {
    font-size: 38px;
  }
}
@media (min-width: 960px) {
  .fs-38\@m {
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  .fs-38\@l {
    font-size: 38px;
  }
}
@media (min-width: 1600px) {
  .fs-38\@xl {
    font-size: 38px;
  }
}
.fs-39 {
  font-size: 39px;
}

@media (min-width: 320px) {
  .fs-39\@sx {
    font-size: 39px;
  }
}
@media (min-width: 640px) {
  .fs-39\@s {
    font-size: 39px;
  }
}
@media (min-width: 960px) {
  .fs-39\@m {
    font-size: 39px;
  }
}
@media (min-width: 1200px) {
  .fs-39\@l {
    font-size: 39px;
  }
}
@media (min-width: 1600px) {
  .fs-39\@xl {
    font-size: 39px;
  }
}
.fs-40 {
  font-size: 40px;
}

@media (min-width: 320px) {
  .fs-40\@sx {
    font-size: 40px;
  }
}
@media (min-width: 640px) {
  .fs-40\@s {
    font-size: 40px;
  }
}
@media (min-width: 960px) {
  .fs-40\@m {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  .fs-40\@l {
    font-size: 40px;
  }
}
@media (min-width: 1600px) {
  .fs-40\@xl {
    font-size: 40px;
  }
}
.fs-41 {
  font-size: 41px;
}

@media (min-width: 320px) {
  .fs-41\@sx {
    font-size: 41px;
  }
}
@media (min-width: 640px) {
  .fs-41\@s {
    font-size: 41px;
  }
}
@media (min-width: 960px) {
  .fs-41\@m {
    font-size: 41px;
  }
}
@media (min-width: 1200px) {
  .fs-41\@l {
    font-size: 41px;
  }
}
@media (min-width: 1600px) {
  .fs-41\@xl {
    font-size: 41px;
  }
}
.fs-42 {
  font-size: 42px;
}

@media (min-width: 320px) {
  .fs-42\@sx {
    font-size: 42px;
  }
}
@media (min-width: 640px) {
  .fs-42\@s {
    font-size: 42px;
  }
}
@media (min-width: 960px) {
  .fs-42\@m {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  .fs-42\@l {
    font-size: 42px;
  }
}
@media (min-width: 1600px) {
  .fs-42\@xl {
    font-size: 42px;
  }
}
.fs-43 {
  font-size: 43px;
}

@media (min-width: 320px) {
  .fs-43\@sx {
    font-size: 43px;
  }
}
@media (min-width: 640px) {
  .fs-43\@s {
    font-size: 43px;
  }
}
@media (min-width: 960px) {
  .fs-43\@m {
    font-size: 43px;
  }
}
@media (min-width: 1200px) {
  .fs-43\@l {
    font-size: 43px;
  }
}
@media (min-width: 1600px) {
  .fs-43\@xl {
    font-size: 43px;
  }
}
.fs-44 {
  font-size: 44px;
}

@media (min-width: 320px) {
  .fs-44\@sx {
    font-size: 44px;
  }
}
@media (min-width: 640px) {
  .fs-44\@s {
    font-size: 44px;
  }
}
@media (min-width: 960px) {
  .fs-44\@m {
    font-size: 44px;
  }
}
@media (min-width: 1200px) {
  .fs-44\@l {
    font-size: 44px;
  }
}
@media (min-width: 1600px) {
  .fs-44\@xl {
    font-size: 44px;
  }
}
.fs-45 {
  font-size: 45px;
}

@media (min-width: 320px) {
  .fs-45\@sx {
    font-size: 45px;
  }
}
@media (min-width: 640px) {
  .fs-45\@s {
    font-size: 45px;
  }
}
@media (min-width: 960px) {
  .fs-45\@m {
    font-size: 45px;
  }
}
@media (min-width: 1200px) {
  .fs-45\@l {
    font-size: 45px;
  }
}
@media (min-width: 1600px) {
  .fs-45\@xl {
    font-size: 45px;
  }
}
.fs-46 {
  font-size: 46px;
}

@media (min-width: 320px) {
  .fs-46\@sx {
    font-size: 46px;
  }
}
@media (min-width: 640px) {
  .fs-46\@s {
    font-size: 46px;
  }
}
@media (min-width: 960px) {
  .fs-46\@m {
    font-size: 46px;
  }
}
@media (min-width: 1200px) {
  .fs-46\@l {
    font-size: 46px;
  }
}
@media (min-width: 1600px) {
  .fs-46\@xl {
    font-size: 46px;
  }
}
.fs-47 {
  font-size: 47px;
}

@media (min-width: 320px) {
  .fs-47\@sx {
    font-size: 47px;
  }
}
@media (min-width: 640px) {
  .fs-47\@s {
    font-size: 47px;
  }
}
@media (min-width: 960px) {
  .fs-47\@m {
    font-size: 47px;
  }
}
@media (min-width: 1200px) {
  .fs-47\@l {
    font-size: 47px;
  }
}
@media (min-width: 1600px) {
  .fs-47\@xl {
    font-size: 47px;
  }
}
.fs-48 {
  font-size: 48px;
}

@media (min-width: 320px) {
  .fs-48\@sx {
    font-size: 48px;
  }
}
@media (min-width: 640px) {
  .fs-48\@s {
    font-size: 48px;
  }
}
@media (min-width: 960px) {
  .fs-48\@m {
    font-size: 48px;
  }
}
@media (min-width: 1200px) {
  .fs-48\@l {
    font-size: 48px;
  }
}
@media (min-width: 1600px) {
  .fs-48\@xl {
    font-size: 48px;
  }
}
.fs-49 {
  font-size: 49px;
}

@media (min-width: 320px) {
  .fs-49\@sx {
    font-size: 49px;
  }
}
@media (min-width: 640px) {
  .fs-49\@s {
    font-size: 49px;
  }
}
@media (min-width: 960px) {
  .fs-49\@m {
    font-size: 49px;
  }
}
@media (min-width: 1200px) {
  .fs-49\@l {
    font-size: 49px;
  }
}
@media (min-width: 1600px) {
  .fs-49\@xl {
    font-size: 49px;
  }
}
.fs-50 {
  font-size: 50px;
}

@media (min-width: 320px) {
  .fs-50\@sx {
    font-size: 50px;
  }
}
@media (min-width: 640px) {
  .fs-50\@s {
    font-size: 50px;
  }
}
@media (min-width: 960px) {
  .fs-50\@m {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .fs-50\@l {
    font-size: 50px;
  }
}
@media (min-width: 1600px) {
  .fs-50\@xl {
    font-size: 50px;
  }
}
.fs-51 {
  font-size: 51px;
}

@media (min-width: 320px) {
  .fs-51\@sx {
    font-size: 51px;
  }
}
@media (min-width: 640px) {
  .fs-51\@s {
    font-size: 51px;
  }
}
@media (min-width: 960px) {
  .fs-51\@m {
    font-size: 51px;
  }
}
@media (min-width: 1200px) {
  .fs-51\@l {
    font-size: 51px;
  }
}
@media (min-width: 1600px) {
  .fs-51\@xl {
    font-size: 51px;
  }
}
.fs-52 {
  font-size: 52px;
}

@media (min-width: 320px) {
  .fs-52\@sx {
    font-size: 52px;
  }
}
@media (min-width: 640px) {
  .fs-52\@s {
    font-size: 52px;
  }
}
@media (min-width: 960px) {
  .fs-52\@m {
    font-size: 52px;
  }
}
@media (min-width: 1200px) {
  .fs-52\@l {
    font-size: 52px;
  }
}
@media (min-width: 1600px) {
  .fs-52\@xl {
    font-size: 52px;
  }
}
.fs-53 {
  font-size: 53px;
}

@media (min-width: 320px) {
  .fs-53\@sx {
    font-size: 53px;
  }
}
@media (min-width: 640px) {
  .fs-53\@s {
    font-size: 53px;
  }
}
@media (min-width: 960px) {
  .fs-53\@m {
    font-size: 53px;
  }
}
@media (min-width: 1200px) {
  .fs-53\@l {
    font-size: 53px;
  }
}
@media (min-width: 1600px) {
  .fs-53\@xl {
    font-size: 53px;
  }
}
.fs-54 {
  font-size: 54px;
}

@media (min-width: 320px) {
  .fs-54\@sx {
    font-size: 54px;
  }
}
@media (min-width: 640px) {
  .fs-54\@s {
    font-size: 54px;
  }
}
@media (min-width: 960px) {
  .fs-54\@m {
    font-size: 54px;
  }
}
@media (min-width: 1200px) {
  .fs-54\@l {
    font-size: 54px;
  }
}
@media (min-width: 1600px) {
  .fs-54\@xl {
    font-size: 54px;
  }
}
.fs-55 {
  font-size: 55px;
}

@media (min-width: 320px) {
  .fs-55\@sx {
    font-size: 55px;
  }
}
@media (min-width: 640px) {
  .fs-55\@s {
    font-size: 55px;
  }
}
@media (min-width: 960px) {
  .fs-55\@m {
    font-size: 55px;
  }
}
@media (min-width: 1200px) {
  .fs-55\@l {
    font-size: 55px;
  }
}
@media (min-width: 1600px) {
  .fs-55\@xl {
    font-size: 55px;
  }
}
.fs-56 {
  font-size: 56px;
}

@media (min-width: 320px) {
  .fs-56\@sx {
    font-size: 56px;
  }
}
@media (min-width: 640px) {
  .fs-56\@s {
    font-size: 56px;
  }
}
@media (min-width: 960px) {
  .fs-56\@m {
    font-size: 56px;
  }
}
@media (min-width: 1200px) {
  .fs-56\@l {
    font-size: 56px;
  }
}
@media (min-width: 1600px) {
  .fs-56\@xl {
    font-size: 56px;
  }
}
.fs-57 {
  font-size: 57px;
}

@media (min-width: 320px) {
  .fs-57\@sx {
    font-size: 57px;
  }
}
@media (min-width: 640px) {
  .fs-57\@s {
    font-size: 57px;
  }
}
@media (min-width: 960px) {
  .fs-57\@m {
    font-size: 57px;
  }
}
@media (min-width: 1200px) {
  .fs-57\@l {
    font-size: 57px;
  }
}
@media (min-width: 1600px) {
  .fs-57\@xl {
    font-size: 57px;
  }
}
.fs-58 {
  font-size: 58px;
}

@media (min-width: 320px) {
  .fs-58\@sx {
    font-size: 58px;
  }
}
@media (min-width: 640px) {
  .fs-58\@s {
    font-size: 58px;
  }
}
@media (min-width: 960px) {
  .fs-58\@m {
    font-size: 58px;
  }
}
@media (min-width: 1200px) {
  .fs-58\@l {
    font-size: 58px;
  }
}
@media (min-width: 1600px) {
  .fs-58\@xl {
    font-size: 58px;
  }
}
.fs-59 {
  font-size: 59px;
}

@media (min-width: 320px) {
  .fs-59\@sx {
    font-size: 59px;
  }
}
@media (min-width: 640px) {
  .fs-59\@s {
    font-size: 59px;
  }
}
@media (min-width: 960px) {
  .fs-59\@m {
    font-size: 59px;
  }
}
@media (min-width: 1200px) {
  .fs-59\@l {
    font-size: 59px;
  }
}
@media (min-width: 1600px) {
  .fs-59\@xl {
    font-size: 59px;
  }
}
.fs-60 {
  font-size: 60px;
}

@media (min-width: 320px) {
  .fs-60\@sx {
    font-size: 60px;
  }
}
@media (min-width: 640px) {
  .fs-60\@s {
    font-size: 60px;
  }
}
@media (min-width: 960px) {
  .fs-60\@m {
    font-size: 60px;
  }
}
@media (min-width: 1200px) {
  .fs-60\@l {
    font-size: 60px;
  }
}
@media (min-width: 1600px) {
  .fs-60\@xl {
    font-size: 60px;
  }
}
.fs-61 {
  font-size: 61px;
}

@media (min-width: 320px) {
  .fs-61\@sx {
    font-size: 61px;
  }
}
@media (min-width: 640px) {
  .fs-61\@s {
    font-size: 61px;
  }
}
@media (min-width: 960px) {
  .fs-61\@m {
    font-size: 61px;
  }
}
@media (min-width: 1200px) {
  .fs-61\@l {
    font-size: 61px;
  }
}
@media (min-width: 1600px) {
  .fs-61\@xl {
    font-size: 61px;
  }
}
.fs-62 {
  font-size: 62px;
}

@media (min-width: 320px) {
  .fs-62\@sx {
    font-size: 62px;
  }
}
@media (min-width: 640px) {
  .fs-62\@s {
    font-size: 62px;
  }
}
@media (min-width: 960px) {
  .fs-62\@m {
    font-size: 62px;
  }
}
@media (min-width: 1200px) {
  .fs-62\@l {
    font-size: 62px;
  }
}
@media (min-width: 1600px) {
  .fs-62\@xl {
    font-size: 62px;
  }
}
.fs-63 {
  font-size: 63px;
}

@media (min-width: 320px) {
  .fs-63\@sx {
    font-size: 63px;
  }
}
@media (min-width: 640px) {
  .fs-63\@s {
    font-size: 63px;
  }
}
@media (min-width: 960px) {
  .fs-63\@m {
    font-size: 63px;
  }
}
@media (min-width: 1200px) {
  .fs-63\@l {
    font-size: 63px;
  }
}
@media (min-width: 1600px) {
  .fs-63\@xl {
    font-size: 63px;
  }
}
.fs-64 {
  font-size: 64px;
}

@media (min-width: 320px) {
  .fs-64\@sx {
    font-size: 64px;
  }
}
@media (min-width: 640px) {
  .fs-64\@s {
    font-size: 64px;
  }
}
@media (min-width: 960px) {
  .fs-64\@m {
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .fs-64\@l {
    font-size: 64px;
  }
}
@media (min-width: 1600px) {
  .fs-64\@xl {
    font-size: 64px;
  }
}
.fs-65 {
  font-size: 65px;
}

@media (min-width: 320px) {
  .fs-65\@sx {
    font-size: 65px;
  }
}
@media (min-width: 640px) {
  .fs-65\@s {
    font-size: 65px;
  }
}
@media (min-width: 960px) {
  .fs-65\@m {
    font-size: 65px;
  }
}
@media (min-width: 1200px) {
  .fs-65\@l {
    font-size: 65px;
  }
}
@media (min-width: 1600px) {
  .fs-65\@xl {
    font-size: 65px;
  }
}
.fs-66 {
  font-size: 66px;
}

@media (min-width: 320px) {
  .fs-66\@sx {
    font-size: 66px;
  }
}
@media (min-width: 640px) {
  .fs-66\@s {
    font-size: 66px;
  }
}
@media (min-width: 960px) {
  .fs-66\@m {
    font-size: 66px;
  }
}
@media (min-width: 1200px) {
  .fs-66\@l {
    font-size: 66px;
  }
}
@media (min-width: 1600px) {
  .fs-66\@xl {
    font-size: 66px;
  }
}
.fs-67 {
  font-size: 67px;
}

@media (min-width: 320px) {
  .fs-67\@sx {
    font-size: 67px;
  }
}
@media (min-width: 640px) {
  .fs-67\@s {
    font-size: 67px;
  }
}
@media (min-width: 960px) {
  .fs-67\@m {
    font-size: 67px;
  }
}
@media (min-width: 1200px) {
  .fs-67\@l {
    font-size: 67px;
  }
}
@media (min-width: 1600px) {
  .fs-67\@xl {
    font-size: 67px;
  }
}
.fs-68 {
  font-size: 68px;
}

@media (min-width: 320px) {
  .fs-68\@sx {
    font-size: 68px;
  }
}
@media (min-width: 640px) {
  .fs-68\@s {
    font-size: 68px;
  }
}
@media (min-width: 960px) {
  .fs-68\@m {
    font-size: 68px;
  }
}
@media (min-width: 1200px) {
  .fs-68\@l {
    font-size: 68px;
  }
}
@media (min-width: 1600px) {
  .fs-68\@xl {
    font-size: 68px;
  }
}
.fs-69 {
  font-size: 69px;
}

@media (min-width: 320px) {
  .fs-69\@sx {
    font-size: 69px;
  }
}
@media (min-width: 640px) {
  .fs-69\@s {
    font-size: 69px;
  }
}
@media (min-width: 960px) {
  .fs-69\@m {
    font-size: 69px;
  }
}
@media (min-width: 1200px) {
  .fs-69\@l {
    font-size: 69px;
  }
}
@media (min-width: 1600px) {
  .fs-69\@xl {
    font-size: 69px;
  }
}
.fs-70 {
  font-size: 70px;
}

@media (min-width: 320px) {
  .fs-70\@sx {
    font-size: 70px;
  }
}
@media (min-width: 640px) {
  .fs-70\@s {
    font-size: 70px;
  }
}
@media (min-width: 960px) {
  .fs-70\@m {
    font-size: 70px;
  }
}
@media (min-width: 1200px) {
  .fs-70\@l {
    font-size: 70px;
  }
}
@media (min-width: 1600px) {
  .fs-70\@xl {
    font-size: 70px;
  }
}
.fs-71 {
  font-size: 71px;
}

@media (min-width: 320px) {
  .fs-71\@sx {
    font-size: 71px;
  }
}
@media (min-width: 640px) {
  .fs-71\@s {
    font-size: 71px;
  }
}
@media (min-width: 960px) {
  .fs-71\@m {
    font-size: 71px;
  }
}
@media (min-width: 1200px) {
  .fs-71\@l {
    font-size: 71px;
  }
}
@media (min-width: 1600px) {
  .fs-71\@xl {
    font-size: 71px;
  }
}
.fs-72 {
  font-size: 72px;
}

@media (min-width: 320px) {
  .fs-72\@sx {
    font-size: 72px;
  }
}
@media (min-width: 640px) {
  .fs-72\@s {
    font-size: 72px;
  }
}
@media (min-width: 960px) {
  .fs-72\@m {
    font-size: 72px;
  }
}
@media (min-width: 1200px) {
  .fs-72\@l {
    font-size: 72px;
  }
}
@media (min-width: 1600px) {
  .fs-72\@xl {
    font-size: 72px;
  }
}
.fs-73 {
  font-size: 73px;
}

@media (min-width: 320px) {
  .fs-73\@sx {
    font-size: 73px;
  }
}
@media (min-width: 640px) {
  .fs-73\@s {
    font-size: 73px;
  }
}
@media (min-width: 960px) {
  .fs-73\@m {
    font-size: 73px;
  }
}
@media (min-width: 1200px) {
  .fs-73\@l {
    font-size: 73px;
  }
}
@media (min-width: 1600px) {
  .fs-73\@xl {
    font-size: 73px;
  }
}
.fs-74 {
  font-size: 74px;
}

@media (min-width: 320px) {
  .fs-74\@sx {
    font-size: 74px;
  }
}
@media (min-width: 640px) {
  .fs-74\@s {
    font-size: 74px;
  }
}
@media (min-width: 960px) {
  .fs-74\@m {
    font-size: 74px;
  }
}
@media (min-width: 1200px) {
  .fs-74\@l {
    font-size: 74px;
  }
}
@media (min-width: 1600px) {
  .fs-74\@xl {
    font-size: 74px;
  }
}
.fs-75 {
  font-size: 75px;
}

@media (min-width: 320px) {
  .fs-75\@sx {
    font-size: 75px;
  }
}
@media (min-width: 640px) {
  .fs-75\@s {
    font-size: 75px;
  }
}
@media (min-width: 960px) {
  .fs-75\@m {
    font-size: 75px;
  }
}
@media (min-width: 1200px) {
  .fs-75\@l {
    font-size: 75px;
  }
}
@media (min-width: 1600px) {
  .fs-75\@xl {
    font-size: 75px;
  }
}
.fs-76 {
  font-size: 76px;
}

@media (min-width: 320px) {
  .fs-76\@sx {
    font-size: 76px;
  }
}
@media (min-width: 640px) {
  .fs-76\@s {
    font-size: 76px;
  }
}
@media (min-width: 960px) {
  .fs-76\@m {
    font-size: 76px;
  }
}
@media (min-width: 1200px) {
  .fs-76\@l {
    font-size: 76px;
  }
}
@media (min-width: 1600px) {
  .fs-76\@xl {
    font-size: 76px;
  }
}
.fs-77 {
  font-size: 77px;
}

@media (min-width: 320px) {
  .fs-77\@sx {
    font-size: 77px;
  }
}
@media (min-width: 640px) {
  .fs-77\@s {
    font-size: 77px;
  }
}
@media (min-width: 960px) {
  .fs-77\@m {
    font-size: 77px;
  }
}
@media (min-width: 1200px) {
  .fs-77\@l {
    font-size: 77px;
  }
}
@media (min-width: 1600px) {
  .fs-77\@xl {
    font-size: 77px;
  }
}
.fs-78 {
  font-size: 78px;
}

@media (min-width: 320px) {
  .fs-78\@sx {
    font-size: 78px;
  }
}
@media (min-width: 640px) {
  .fs-78\@s {
    font-size: 78px;
  }
}
@media (min-width: 960px) {
  .fs-78\@m {
    font-size: 78px;
  }
}
@media (min-width: 1200px) {
  .fs-78\@l {
    font-size: 78px;
  }
}
@media (min-width: 1600px) {
  .fs-78\@xl {
    font-size: 78px;
  }
}
.fs-79 {
  font-size: 79px;
}

@media (min-width: 320px) {
  .fs-79\@sx {
    font-size: 79px;
  }
}
@media (min-width: 640px) {
  .fs-79\@s {
    font-size: 79px;
  }
}
@media (min-width: 960px) {
  .fs-79\@m {
    font-size: 79px;
  }
}
@media (min-width: 1200px) {
  .fs-79\@l {
    font-size: 79px;
  }
}
@media (min-width: 1600px) {
  .fs-79\@xl {
    font-size: 79px;
  }
}
.fs-80 {
  font-size: 80px;
}

@media (min-width: 320px) {
  .fs-80\@sx {
    font-size: 80px;
  }
}
@media (min-width: 640px) {
  .fs-80\@s {
    font-size: 80px;
  }
}
@media (min-width: 960px) {
  .fs-80\@m {
    font-size: 80px;
  }
}
@media (min-width: 1200px) {
  .fs-80\@l {
    font-size: 80px;
  }
}
@media (min-width: 1600px) {
  .fs-80\@xl {
    font-size: 80px;
  }
}
.fs-81 {
  font-size: 81px;
}

@media (min-width: 320px) {
  .fs-81\@sx {
    font-size: 81px;
  }
}
@media (min-width: 640px) {
  .fs-81\@s {
    font-size: 81px;
  }
}
@media (min-width: 960px) {
  .fs-81\@m {
    font-size: 81px;
  }
}
@media (min-width: 1200px) {
  .fs-81\@l {
    font-size: 81px;
  }
}
@media (min-width: 1600px) {
  .fs-81\@xl {
    font-size: 81px;
  }
}
.fs-82 {
  font-size: 82px;
}

@media (min-width: 320px) {
  .fs-82\@sx {
    font-size: 82px;
  }
}
@media (min-width: 640px) {
  .fs-82\@s {
    font-size: 82px;
  }
}
@media (min-width: 960px) {
  .fs-82\@m {
    font-size: 82px;
  }
}
@media (min-width: 1200px) {
  .fs-82\@l {
    font-size: 82px;
  }
}
@media (min-width: 1600px) {
  .fs-82\@xl {
    font-size: 82px;
  }
}
.fs-83 {
  font-size: 83px;
}

@media (min-width: 320px) {
  .fs-83\@sx {
    font-size: 83px;
  }
}
@media (min-width: 640px) {
  .fs-83\@s {
    font-size: 83px;
  }
}
@media (min-width: 960px) {
  .fs-83\@m {
    font-size: 83px;
  }
}
@media (min-width: 1200px) {
  .fs-83\@l {
    font-size: 83px;
  }
}
@media (min-width: 1600px) {
  .fs-83\@xl {
    font-size: 83px;
  }
}
.fs-84 {
  font-size: 84px;
}

@media (min-width: 320px) {
  .fs-84\@sx {
    font-size: 84px;
  }
}
@media (min-width: 640px) {
  .fs-84\@s {
    font-size: 84px;
  }
}
@media (min-width: 960px) {
  .fs-84\@m {
    font-size: 84px;
  }
}
@media (min-width: 1200px) {
  .fs-84\@l {
    font-size: 84px;
  }
}
@media (min-width: 1600px) {
  .fs-84\@xl {
    font-size: 84px;
  }
}
.fs-85 {
  font-size: 85px;
}

@media (min-width: 320px) {
  .fs-85\@sx {
    font-size: 85px;
  }
}
@media (min-width: 640px) {
  .fs-85\@s {
    font-size: 85px;
  }
}
@media (min-width: 960px) {
  .fs-85\@m {
    font-size: 85px;
  }
}
@media (min-width: 1200px) {
  .fs-85\@l {
    font-size: 85px;
  }
}
@media (min-width: 1600px) {
  .fs-85\@xl {
    font-size: 85px;
  }
}
.fs-86 {
  font-size: 86px;
}

@media (min-width: 320px) {
  .fs-86\@sx {
    font-size: 86px;
  }
}
@media (min-width: 640px) {
  .fs-86\@s {
    font-size: 86px;
  }
}
@media (min-width: 960px) {
  .fs-86\@m {
    font-size: 86px;
  }
}
@media (min-width: 1200px) {
  .fs-86\@l {
    font-size: 86px;
  }
}
@media (min-width: 1600px) {
  .fs-86\@xl {
    font-size: 86px;
  }
}
.fs-87 {
  font-size: 87px;
}

@media (min-width: 320px) {
  .fs-87\@sx {
    font-size: 87px;
  }
}
@media (min-width: 640px) {
  .fs-87\@s {
    font-size: 87px;
  }
}
@media (min-width: 960px) {
  .fs-87\@m {
    font-size: 87px;
  }
}
@media (min-width: 1200px) {
  .fs-87\@l {
    font-size: 87px;
  }
}
@media (min-width: 1600px) {
  .fs-87\@xl {
    font-size: 87px;
  }
}
.fs-88 {
  font-size: 88px;
}

@media (min-width: 320px) {
  .fs-88\@sx {
    font-size: 88px;
  }
}
@media (min-width: 640px) {
  .fs-88\@s {
    font-size: 88px;
  }
}
@media (min-width: 960px) {
  .fs-88\@m {
    font-size: 88px;
  }
}
@media (min-width: 1200px) {
  .fs-88\@l {
    font-size: 88px;
  }
}
@media (min-width: 1600px) {
  .fs-88\@xl {
    font-size: 88px;
  }
}
.fs-89 {
  font-size: 89px;
}

@media (min-width: 320px) {
  .fs-89\@sx {
    font-size: 89px;
  }
}
@media (min-width: 640px) {
  .fs-89\@s {
    font-size: 89px;
  }
}
@media (min-width: 960px) {
  .fs-89\@m {
    font-size: 89px;
  }
}
@media (min-width: 1200px) {
  .fs-89\@l {
    font-size: 89px;
  }
}
@media (min-width: 1600px) {
  .fs-89\@xl {
    font-size: 89px;
  }
}
.fs-90 {
  font-size: 90px;
}

@media (min-width: 320px) {
  .fs-90\@sx {
    font-size: 90px;
  }
}
@media (min-width: 640px) {
  .fs-90\@s {
    font-size: 90px;
  }
}
@media (min-width: 960px) {
  .fs-90\@m {
    font-size: 90px;
  }
}
@media (min-width: 1200px) {
  .fs-90\@l {
    font-size: 90px;
  }
}
@media (min-width: 1600px) {
  .fs-90\@xl {
    font-size: 90px;
  }
}
.fs-91 {
  font-size: 91px;
}

@media (min-width: 320px) {
  .fs-91\@sx {
    font-size: 91px;
  }
}
@media (min-width: 640px) {
  .fs-91\@s {
    font-size: 91px;
  }
}
@media (min-width: 960px) {
  .fs-91\@m {
    font-size: 91px;
  }
}
@media (min-width: 1200px) {
  .fs-91\@l {
    font-size: 91px;
  }
}
@media (min-width: 1600px) {
  .fs-91\@xl {
    font-size: 91px;
  }
}
.fs-92 {
  font-size: 92px;
}

@media (min-width: 320px) {
  .fs-92\@sx {
    font-size: 92px;
  }
}
@media (min-width: 640px) {
  .fs-92\@s {
    font-size: 92px;
  }
}
@media (min-width: 960px) {
  .fs-92\@m {
    font-size: 92px;
  }
}
@media (min-width: 1200px) {
  .fs-92\@l {
    font-size: 92px;
  }
}
@media (min-width: 1600px) {
  .fs-92\@xl {
    font-size: 92px;
  }
}
.fs-93 {
  font-size: 93px;
}

@media (min-width: 320px) {
  .fs-93\@sx {
    font-size: 93px;
  }
}
@media (min-width: 640px) {
  .fs-93\@s {
    font-size: 93px;
  }
}
@media (min-width: 960px) {
  .fs-93\@m {
    font-size: 93px;
  }
}
@media (min-width: 1200px) {
  .fs-93\@l {
    font-size: 93px;
  }
}
@media (min-width: 1600px) {
  .fs-93\@xl {
    font-size: 93px;
  }
}
.fs-94 {
  font-size: 94px;
}

@media (min-width: 320px) {
  .fs-94\@sx {
    font-size: 94px;
  }
}
@media (min-width: 640px) {
  .fs-94\@s {
    font-size: 94px;
  }
}
@media (min-width: 960px) {
  .fs-94\@m {
    font-size: 94px;
  }
}
@media (min-width: 1200px) {
  .fs-94\@l {
    font-size: 94px;
  }
}
@media (min-width: 1600px) {
  .fs-94\@xl {
    font-size: 94px;
  }
}
.fs-95 {
  font-size: 95px;
}

@media (min-width: 320px) {
  .fs-95\@sx {
    font-size: 95px;
  }
}
@media (min-width: 640px) {
  .fs-95\@s {
    font-size: 95px;
  }
}
@media (min-width: 960px) {
  .fs-95\@m {
    font-size: 95px;
  }
}
@media (min-width: 1200px) {
  .fs-95\@l {
    font-size: 95px;
  }
}
@media (min-width: 1600px) {
  .fs-95\@xl {
    font-size: 95px;
  }
}
.fs-96 {
  font-size: 96px;
}

@media (min-width: 320px) {
  .fs-96\@sx {
    font-size: 96px;
  }
}
@media (min-width: 640px) {
  .fs-96\@s {
    font-size: 96px;
  }
}
@media (min-width: 960px) {
  .fs-96\@m {
    font-size: 96px;
  }
}
@media (min-width: 1200px) {
  .fs-96\@l {
    font-size: 96px;
  }
}
@media (min-width: 1600px) {
  .fs-96\@xl {
    font-size: 96px;
  }
}
.fs-97 {
  font-size: 97px;
}

@media (min-width: 320px) {
  .fs-97\@sx {
    font-size: 97px;
  }
}
@media (min-width: 640px) {
  .fs-97\@s {
    font-size: 97px;
  }
}
@media (min-width: 960px) {
  .fs-97\@m {
    font-size: 97px;
  }
}
@media (min-width: 1200px) {
  .fs-97\@l {
    font-size: 97px;
  }
}
@media (min-width: 1600px) {
  .fs-97\@xl {
    font-size: 97px;
  }
}
.fs-98 {
  font-size: 98px;
}

@media (min-width: 320px) {
  .fs-98\@sx {
    font-size: 98px;
  }
}
@media (min-width: 640px) {
  .fs-98\@s {
    font-size: 98px;
  }
}
@media (min-width: 960px) {
  .fs-98\@m {
    font-size: 98px;
  }
}
@media (min-width: 1200px) {
  .fs-98\@l {
    font-size: 98px;
  }
}
@media (min-width: 1600px) {
  .fs-98\@xl {
    font-size: 98px;
  }
}
.fs-99 {
  font-size: 99px;
}

@media (min-width: 320px) {
  .fs-99\@sx {
    font-size: 99px;
  }
}
@media (min-width: 640px) {
  .fs-99\@s {
    font-size: 99px;
  }
}
@media (min-width: 960px) {
  .fs-99\@m {
    font-size: 99px;
  }
}
@media (min-width: 1200px) {
  .fs-99\@l {
    font-size: 99px;
  }
}
@media (min-width: 1600px) {
  .fs-99\@xl {
    font-size: 99px;
  }
}
.fs-100 {
  font-size: 100px;
}

@media (min-width: 320px) {
  .fs-100\@sx {
    font-size: 100px;
  }
}
@media (min-width: 640px) {
  .fs-100\@s {
    font-size: 100px;
  }
}
@media (min-width: 960px) {
  .fs-100\@m {
    font-size: 100px;
  }
}
@media (min-width: 1200px) {
  .fs-100\@l {
    font-size: 100px;
  }
}
@media (min-width: 1600px) {
  .fs-100\@xl {
    font-size: 100px;
  }
}
:root {
  --pc-navy: #002a68;
  --pc-pink: #ff1784;
  --pc-sky-blue: #0090d3;
  --pc-white: white;
  --pc-grey-1: #333333;
  --pc-grey-2: #888888;
  --pc-grey-3: #e5e5e5;
  --com-green: #66af30;
  --com-cherry: #8d1c50;
  --com-red: #e20613;
  --com-mint: #00999b;
  --com-blue: #004b9b;
  --com-orange: #e14504;
  --com-gold: #f29100;
  --com-yellow: #ffda27;
  --sc-navy-2: #011c43;
  --sc-pink-2: #a92462;
  --sc-sky-blue-2: #0277a0;
  --sc-sky-blue-3: #bce0f1;
  --sc-sky-blue-4: #e5f4fb;
  --sc-grey-4: #666666;
  --sc-grey-5: #f6f6f6;
  --sc-grey-6: #eeeeee;
  --sc-grey-7: #fcfcfc;
  --sc-grey-8: #f1efec;
  --pc-navy-opacity-1: rgba(0, 42, 104, 0.1);
  --pc-navy-opacity-2: rgba(0, 42, 104, 0.2);
  --pc-navy-opacity-3: rgba(0, 42, 104, 0.3);
  --pc-navy-opacity-4: rgba(0, 42, 104, 0.4);
  --pc-navy-opacity-5: rgba(0, 42, 104, 0.5);
  --pc-navy-opacity-6: rgba(0, 42, 104, 0.6);
  --pc-navy-opacity-7: rgba(0, 42, 104, 0.7);
  --pc-navy-opacity-8: rgba(0, 42, 104, 0.8);
  --pc-navy-opacity-9: rgba(0, 42, 104, 0.9);
  --pc-pink-opacity-1: rgba(255, 23, 132, 0.1);
  --pc-pink-opacity-2: rgba(255, 23, 132, 0.2);
  --pc-pink-opacity-3: rgba(255, 23, 132, 0.3);
  --pc-pink-opacity-4: rgba(255, 23, 132, 0.4);
  --pc-pink-opacity-5: rgba(255, 23, 132, 0.5);
  --pc-pink-opacity-6: rgba(255, 23, 132, 0.6);
  --pc-pink-opacity-7: rgba(255, 23, 132, 0.7);
  --pc-pink-opacity-8: rgba(255, 23, 132, 0.8);
  --pc-pink-opacity-9: rgba(255, 23, 132, 0.9);
  --pc-sky-blue-opacity-1: rgba(0, 144, 211, 0.1);
  --pc-sky-blue-opacity-2: rgba(0, 144, 211, 0.2);
  --pc-sky-blue-opacity-3: rgba(0, 144, 211, 0.3);
  --pc-sky-blue-opacity-4: rgba(0, 144, 211, 0.4);
  --pc-sky-blue-opacity-5: rgba(0, 144, 211, 0.5);
  --pc-sky-blue-opacity-6: rgba(0, 144, 211, 0.6);
  --pc-sky-blue-opacity-7: rgba(0, 144, 211, 0.7);
  --pc-sky-blue-opacity-8: rgba(0, 144, 211, 0.8);
  --pc-sky-blue-opacity-9: rgba(0, 144, 211, 0.9);
  --pc-white-opacity-1: rgba(255, 255, 255, 0.1);
  --pc-white-opacity-2: rgba(255, 255, 255, 0.2);
  --pc-white-opacity-3: rgba(255, 255, 255, 0.3);
  --pc-white-opacity-4: rgba(255, 255, 255, 0.4);
  --pc-white-opacity-5: rgba(255, 255, 255, 0.5);
  --pc-white-opacity-6: rgba(255, 255, 255, 0.6);
  --pc-white-opacity-7: rgba(255, 255, 255, 0.7);
  --pc-white-opacity-8: rgba(255, 255, 255, 0.8);
  --pc-white-opacity-9: rgba(255, 255, 255, 0.9);
  --pc-grey-1-opacity-1: rgba(51, 51, 51, 0.1);
  --pc-grey-1-opacity-2: rgba(51, 51, 51, 0.2);
  --pc-grey-1-opacity-3: rgba(51, 51, 51, 0.3);
  --pc-grey-1-opacity-4: rgba(51, 51, 51, 0.4);
  --pc-grey-1-opacity-5: rgba(51, 51, 51, 0.5);
  --pc-grey-1-opacity-6: rgba(51, 51, 51, 0.6);
  --pc-grey-1-opacity-7: rgba(51, 51, 51, 0.7);
  --pc-grey-1-opacity-8: rgba(51, 51, 51, 0.8);
  --pc-grey-1-opacity-9: rgba(51, 51, 51, 0.9);
  --pc-grey-2-opacity-1: rgba(136, 136, 136, 0.1);
  --pc-grey-2-opacity-2: rgba(136, 136, 136, 0.2);
  --pc-grey-2-opacity-3: rgba(136, 136, 136, 0.3);
  --pc-grey-2-opacity-4: rgba(136, 136, 136, 0.4);
  --pc-grey-2-opacity-5: rgba(136, 136, 136, 0.5);
  --pc-grey-2-opacity-6: rgba(136, 136, 136, 0.6);
  --pc-grey-2-opacity-7: rgba(136, 136, 136, 0.7);
  --pc-grey-2-opacity-8: rgba(136, 136, 136, 0.8);
  --pc-grey-2-opacity-9: rgba(136, 136, 136, 0.9);
  --pc-grey-3-opacity-1: rgba(229, 229, 229, 0.1);
  --pc-grey-3-opacity-2: rgba(229, 229, 229, 0.2);
  --pc-grey-3-opacity-3: rgba(229, 229, 229, 0.3);
  --pc-grey-3-opacity-4: rgba(229, 229, 229, 0.4);
  --pc-grey-3-opacity-5: rgba(229, 229, 229, 0.5);
  --pc-grey-3-opacity-6: rgba(229, 229, 229, 0.6);
  --pc-grey-3-opacity-7: rgba(229, 229, 229, 0.7);
  --pc-grey-3-opacity-8: rgba(229, 229, 229, 0.8);
  --pc-grey-3-opacity-9: rgba(229, 229, 229, 0.9);
  --com-green-opacity-1: rgba(102, 175, 48, 0.1);
  --com-green-opacity-2: rgba(102, 175, 48, 0.2);
  --com-green-opacity-3: rgba(102, 175, 48, 0.3);
  --com-green-opacity-4: rgba(102, 175, 48, 0.4);
  --com-green-opacity-5: rgba(102, 175, 48, 0.5);
  --com-green-opacity-6: rgba(102, 175, 48, 0.6);
  --com-green-opacity-7: rgba(102, 175, 48, 0.7);
  --com-green-opacity-8: rgba(102, 175, 48, 0.8);
  --com-green-opacity-9: rgba(102, 175, 48, 0.9);
  --com-cherry-opacity-1: rgba(141, 28, 80, 0.1);
  --com-cherry-opacity-2: rgba(141, 28, 80, 0.2);
  --com-cherry-opacity-3: rgba(141, 28, 80, 0.3);
  --com-cherry-opacity-4: rgba(141, 28, 80, 0.4);
  --com-cherry-opacity-5: rgba(141, 28, 80, 0.5);
  --com-cherry-opacity-6: rgba(141, 28, 80, 0.6);
  --com-cherry-opacity-7: rgba(141, 28, 80, 0.7);
  --com-cherry-opacity-8: rgba(141, 28, 80, 0.8);
  --com-cherry-opacity-9: rgba(141, 28, 80, 0.9);
  --com-red-opacity-1: rgba(226, 6, 19, 0.1);
  --com-red-opacity-2: rgba(226, 6, 19, 0.2);
  --com-red-opacity-3: rgba(226, 6, 19, 0.3);
  --com-red-opacity-4: rgba(226, 6, 19, 0.4);
  --com-red-opacity-5: rgba(226, 6, 19, 0.5);
  --com-red-opacity-6: rgba(226, 6, 19, 0.6);
  --com-red-opacity-7: rgba(226, 6, 19, 0.7);
  --com-red-opacity-8: rgba(226, 6, 19, 0.8);
  --com-red-opacity-9: rgba(226, 6, 19, 0.9);
  --com-mint-opacity-1: rgba(0, 153, 155, 0.1);
  --com-mint-opacity-2: rgba(0, 153, 155, 0.2);
  --com-mint-opacity-3: rgba(0, 153, 155, 0.3);
  --com-mint-opacity-4: rgba(0, 153, 155, 0.4);
  --com-mint-opacity-5: rgba(0, 153, 155, 0.5);
  --com-mint-opacity-6: rgba(0, 153, 155, 0.6);
  --com-mint-opacity-7: rgba(0, 153, 155, 0.7);
  --com-mint-opacity-8: rgba(0, 153, 155, 0.8);
  --com-mint-opacity-9: rgba(0, 153, 155, 0.9);
  --com-blue-opacity-1: rgba(0, 75, 155, 0.1);
  --com-blue-opacity-2: rgba(0, 75, 155, 0.2);
  --com-blue-opacity-3: rgba(0, 75, 155, 0.3);
  --com-blue-opacity-4: rgba(0, 75, 155, 0.4);
  --com-blue-opacity-5: rgba(0, 75, 155, 0.5);
  --com-blue-opacity-6: rgba(0, 75, 155, 0.6);
  --com-blue-opacity-7: rgba(0, 75, 155, 0.7);
  --com-blue-opacity-8: rgba(0, 75, 155, 0.8);
  --com-blue-opacity-9: rgba(0, 75, 155, 0.9);
  --com-orange-opacity-1: rgba(225, 69, 4, 0.1);
  --com-orange-opacity-2: rgba(225, 69, 4, 0.2);
  --com-orange-opacity-3: rgba(225, 69, 4, 0.3);
  --com-orange-opacity-4: rgba(225, 69, 4, 0.4);
  --com-orange-opacity-5: rgba(225, 69, 4, 0.5);
  --com-orange-opacity-6: rgba(225, 69, 4, 0.6);
  --com-orange-opacity-7: rgba(225, 69, 4, 0.7);
  --com-orange-opacity-8: rgba(225, 69, 4, 0.8);
  --com-orange-opacity-9: rgba(225, 69, 4, 0.9);
  --com-gold-opacity-1: rgba(242, 145, 0, 0.1);
  --com-gold-opacity-2: rgba(242, 145, 0, 0.2);
  --com-gold-opacity-3: rgba(242, 145, 0, 0.3);
  --com-gold-opacity-4: rgba(242, 145, 0, 0.4);
  --com-gold-opacity-5: rgba(242, 145, 0, 0.5);
  --com-gold-opacity-6: rgba(242, 145, 0, 0.6);
  --com-gold-opacity-7: rgba(242, 145, 0, 0.7);
  --com-gold-opacity-8: rgba(242, 145, 0, 0.8);
  --com-gold-opacity-9: rgba(242, 145, 0, 0.9);
  --com-yellow-opacity-1: rgba(255, 218, 39, 0.1);
  --com-yellow-opacity-2: rgba(255, 218, 39, 0.2);
  --com-yellow-opacity-3: rgba(255, 218, 39, 0.3);
  --com-yellow-opacity-4: rgba(255, 218, 39, 0.4);
  --com-yellow-opacity-5: rgba(255, 218, 39, 0.5);
  --com-yellow-opacity-6: rgba(255, 218, 39, 0.6);
  --com-yellow-opacity-7: rgba(255, 218, 39, 0.7);
  --com-yellow-opacity-8: rgba(255, 218, 39, 0.8);
  --com-yellow-opacity-9: rgba(255, 218, 39, 0.9);
  --sc-navy-2-opacity-1: rgba(1, 28, 67, 0.1);
  --sc-navy-2-opacity-2: rgba(1, 28, 67, 0.2);
  --sc-navy-2-opacity-3: rgba(1, 28, 67, 0.3);
  --sc-navy-2-opacity-4: rgba(1, 28, 67, 0.4);
  --sc-navy-2-opacity-5: rgba(1, 28, 67, 0.5);
  --sc-navy-2-opacity-6: rgba(1, 28, 67, 0.6);
  --sc-navy-2-opacity-7: rgba(1, 28, 67, 0.7);
  --sc-navy-2-opacity-8: rgba(1, 28, 67, 0.8);
  --sc-navy-2-opacity-9: rgba(1, 28, 67, 0.9);
  --sc-pink-2-opacity-1: rgba(169, 36, 98, 0.1);
  --sc-pink-2-opacity-2: rgba(169, 36, 98, 0.2);
  --sc-pink-2-opacity-3: rgba(169, 36, 98, 0.3);
  --sc-pink-2-opacity-4: rgba(169, 36, 98, 0.4);
  --sc-pink-2-opacity-5: rgba(169, 36, 98, 0.5);
  --sc-pink-2-opacity-6: rgba(169, 36, 98, 0.6);
  --sc-pink-2-opacity-7: rgba(169, 36, 98, 0.7);
  --sc-pink-2-opacity-8: rgba(169, 36, 98, 0.8);
  --sc-pink-2-opacity-9: rgba(169, 36, 98, 0.9);
  --sc-sky-blue-2-opacity-1: rgba(2, 119, 160, 0.1);
  --sc-sky-blue-2-opacity-2: rgba(2, 119, 160, 0.2);
  --sc-sky-blue-2-opacity-3: rgba(2, 119, 160, 0.3);
  --sc-sky-blue-2-opacity-4: rgba(2, 119, 160, 0.4);
  --sc-sky-blue-2-opacity-5: rgba(2, 119, 160, 0.5);
  --sc-sky-blue-2-opacity-6: rgba(2, 119, 160, 0.6);
  --sc-sky-blue-2-opacity-7: rgba(2, 119, 160, 0.7);
  --sc-sky-blue-2-opacity-8: rgba(2, 119, 160, 0.8);
  --sc-sky-blue-2-opacity-9: rgba(2, 119, 160, 0.9);
  --sc-sky-blue-3-opacity-1: rgba(188, 224, 241, 0.1);
  --sc-sky-blue-3-opacity-2: rgba(188, 224, 241, 0.2);
  --sc-sky-blue-3-opacity-3: rgba(188, 224, 241, 0.3);
  --sc-sky-blue-3-opacity-4: rgba(188, 224, 241, 0.4);
  --sc-sky-blue-3-opacity-5: rgba(188, 224, 241, 0.5);
  --sc-sky-blue-3-opacity-6: rgba(188, 224, 241, 0.6);
  --sc-sky-blue-3-opacity-7: rgba(188, 224, 241, 0.7);
  --sc-sky-blue-3-opacity-8: rgba(188, 224, 241, 0.8);
  --sc-sky-blue-3-opacity-9: rgba(188, 224, 241, 0.9);
  --sc-sky-blue-4-opacity-1: rgba(229, 244, 251, 0.1);
  --sc-sky-blue-4-opacity-2: rgba(229, 244, 251, 0.2);
  --sc-sky-blue-4-opacity-3: rgba(229, 244, 251, 0.3);
  --sc-sky-blue-4-opacity-4: rgba(229, 244, 251, 0.4);
  --sc-sky-blue-4-opacity-5: rgba(229, 244, 251, 0.5);
  --sc-sky-blue-4-opacity-6: rgba(229, 244, 251, 0.6);
  --sc-sky-blue-4-opacity-7: rgba(229, 244, 251, 0.7);
  --sc-sky-blue-4-opacity-8: rgba(229, 244, 251, 0.8);
  --sc-sky-blue-4-opacity-9: rgba(229, 244, 251, 0.9);
  --sc-grey-4-opacity-1: rgba(102, 102, 102, 0.1);
  --sc-grey-4-opacity-2: rgba(102, 102, 102, 0.2);
  --sc-grey-4-opacity-3: rgba(102, 102, 102, 0.3);
  --sc-grey-4-opacity-4: rgba(102, 102, 102, 0.4);
  --sc-grey-4-opacity-5: rgba(102, 102, 102, 0.5);
  --sc-grey-4-opacity-6: rgba(102, 102, 102, 0.6);
  --sc-grey-4-opacity-7: rgba(102, 102, 102, 0.7);
  --sc-grey-4-opacity-8: rgba(102, 102, 102, 0.8);
  --sc-grey-4-opacity-9: rgba(102, 102, 102, 0.9);
  --sc-grey-5-opacity-1: rgba(246, 246, 246, 0.1);
  --sc-grey-5-opacity-2: rgba(246, 246, 246, 0.2);
  --sc-grey-5-opacity-3: rgba(246, 246, 246, 0.3);
  --sc-grey-5-opacity-4: rgba(246, 246, 246, 0.4);
  --sc-grey-5-opacity-5: rgba(246, 246, 246, 0.5);
  --sc-grey-5-opacity-6: rgba(246, 246, 246, 0.6);
  --sc-grey-5-opacity-7: rgba(246, 246, 246, 0.7);
  --sc-grey-5-opacity-8: rgba(246, 246, 246, 0.8);
  --sc-grey-5-opacity-9: rgba(246, 246, 246, 0.9);
  --sc-grey-6-opacity-1: rgba(238, 238, 238, 0.1);
  --sc-grey-6-opacity-2: rgba(238, 238, 238, 0.2);
  --sc-grey-6-opacity-3: rgba(238, 238, 238, 0.3);
  --sc-grey-6-opacity-4: rgba(238, 238, 238, 0.4);
  --sc-grey-6-opacity-5: rgba(238, 238, 238, 0.5);
  --sc-grey-6-opacity-6: rgba(238, 238, 238, 0.6);
  --sc-grey-6-opacity-7: rgba(238, 238, 238, 0.7);
  --sc-grey-6-opacity-8: rgba(238, 238, 238, 0.8);
  --sc-grey-6-opacity-9: rgba(238, 238, 238, 0.9);
  --sc-grey-7-opacity-1: rgba(252, 252, 252, 0.1);
  --sc-grey-7-opacity-2: rgba(252, 252, 252, 0.2);
  --sc-grey-7-opacity-3: rgba(252, 252, 252, 0.3);
  --sc-grey-7-opacity-4: rgba(252, 252, 252, 0.4);
  --sc-grey-7-opacity-5: rgba(252, 252, 252, 0.5);
  --sc-grey-7-opacity-6: rgba(252, 252, 252, 0.6);
  --sc-grey-7-opacity-7: rgba(252, 252, 252, 0.7);
  --sc-grey-7-opacity-8: rgba(252, 252, 252, 0.8);
  --sc-grey-7-opacity-9: rgba(252, 252, 252, 0.9);
  --sc-grey-8-opacity-1: rgba(241, 239, 236, 0.1);
  --sc-grey-8-opacity-2: rgba(241, 239, 236, 0.2);
  --sc-grey-8-opacity-3: rgba(241, 239, 236, 0.3);
  --sc-grey-8-opacity-4: rgba(241, 239, 236, 0.4);
  --sc-grey-8-opacity-5: rgba(241, 239, 236, 0.5);
  --sc-grey-8-opacity-6: rgba(241, 239, 236, 0.6);
  --sc-grey-8-opacity-7: rgba(241, 239, 236, 0.7);
  --sc-grey-8-opacity-8: rgba(241, 239, 236, 0.8);
  --sc-grey-8-opacity-9: rgba(241, 239, 236, 0.9);
}

:focus,
:focus-visible {
  outline: solid 2px #ff1784 !important;
}

input::placeholder {
  font-weight: 500;
}

.body {
  background-color: #fcfcfc;
}

.footer {
  background-color: #011C43;
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5 {
  color: #002a68;
}

.uk-link, a {
  color: #ffffff;
  color: #002a68;
  transition: border-bottom 200ms ease-in-out;
}
.uk-link:hover, .uk-link:focus, a:hover, a:focus {
  color: #ffffff;
}

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #ffffff;
  text-decoration: none;
}

.uk-subnav > * > :first-child {
  border: 2px solid #ff1784;
  border-radius: 5px;
  color: #002a68;
}

.uk-subnav-pill > * > a:hover {
  color: #002a68;
}

.uk-subnav-pill > .uk-active > a {
  background-color: #ff1784;
  color: #ffffff;
}

.uk-link-boder {
  border-bottom: 2px solid #ff1784;
}

.uk-link-remove-boder,
.uk-link-remove-boder:hover,
.uk-link-remove-boder:focus {
  border-style: inherit;
  border-width: inherit;
}

.c-pc-navy-opacity-1 {
  color: rgba(0, 42, 104, 0.1) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-1\:h:hover,
.c-pc-navy-opacity-1\:h:focus,
.c-pc-navy-opacity-1\:h:active {
  color: rgba(0, 42, 104, 0.1) !important;
}

.bgc-pc-navy-opacity-1 {
  background-color: rgba(0, 42, 104, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-1\:h:hover,
.bgc-pc-navy-opacity-1\:h:focus,
.bgc-pc-navy-opacity-1\:h:active {
  background-color: rgba(0, 42, 104, 0.1) !important;
}

.bc-pc-navy-opacity-1 {
  border-color: rgba(0, 42, 104, 0.1);
}

.c-pc-navy-opacity-2 {
  color: rgba(0, 42, 104, 0.2) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-2\:h:hover,
.c-pc-navy-opacity-2\:h:focus,
.c-pc-navy-opacity-2\:h:active {
  color: rgba(0, 42, 104, 0.2) !important;
}

.bgc-pc-navy-opacity-2 {
  background-color: rgba(0, 42, 104, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-2\:h:hover,
.bgc-pc-navy-opacity-2\:h:focus,
.bgc-pc-navy-opacity-2\:h:active {
  background-color: rgba(0, 42, 104, 0.2) !important;
}

.bc-pc-navy-opacity-2 {
  border-color: rgba(0, 42, 104, 0.2);
}

.c-pc-navy-opacity-3 {
  color: rgba(0, 42, 104, 0.3) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-3\:h:hover,
.c-pc-navy-opacity-3\:h:focus,
.c-pc-navy-opacity-3\:h:active {
  color: rgba(0, 42, 104, 0.3) !important;
}

.bgc-pc-navy-opacity-3 {
  background-color: rgba(0, 42, 104, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-3\:h:hover,
.bgc-pc-navy-opacity-3\:h:focus,
.bgc-pc-navy-opacity-3\:h:active {
  background-color: rgba(0, 42, 104, 0.3) !important;
}

.bc-pc-navy-opacity-3 {
  border-color: rgba(0, 42, 104, 0.3);
}

.c-pc-navy-opacity-4 {
  color: rgba(0, 42, 104, 0.4) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-4\:h:hover,
.c-pc-navy-opacity-4\:h:focus,
.c-pc-navy-opacity-4\:h:active {
  color: rgba(0, 42, 104, 0.4) !important;
}

.bgc-pc-navy-opacity-4 {
  background-color: rgba(0, 42, 104, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-4\:h:hover,
.bgc-pc-navy-opacity-4\:h:focus,
.bgc-pc-navy-opacity-4\:h:active {
  background-color: rgba(0, 42, 104, 0.4) !important;
}

.bc-pc-navy-opacity-4 {
  border-color: rgba(0, 42, 104, 0.4);
}

.c-pc-navy-opacity-5 {
  color: rgba(0, 42, 104, 0.5) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-5\:h:hover,
.c-pc-navy-opacity-5\:h:focus,
.c-pc-navy-opacity-5\:h:active {
  color: rgba(0, 42, 104, 0.5) !important;
}

.bgc-pc-navy-opacity-5 {
  background-color: rgba(0, 42, 104, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-5\:h:hover,
.bgc-pc-navy-opacity-5\:h:focus,
.bgc-pc-navy-opacity-5\:h:active {
  background-color: rgba(0, 42, 104, 0.5) !important;
}

.bc-pc-navy-opacity-5 {
  border-color: rgba(0, 42, 104, 0.5);
}

.c-pc-navy-opacity-6 {
  color: rgba(0, 42, 104, 0.6) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-6\:h:hover,
.c-pc-navy-opacity-6\:h:focus,
.c-pc-navy-opacity-6\:h:active {
  color: rgba(0, 42, 104, 0.6) !important;
}

.bgc-pc-navy-opacity-6 {
  background-color: rgba(0, 42, 104, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-6\:h:hover,
.bgc-pc-navy-opacity-6\:h:focus,
.bgc-pc-navy-opacity-6\:h:active {
  background-color: rgba(0, 42, 104, 0.6) !important;
}

.bc-pc-navy-opacity-6 {
  border-color: rgba(0, 42, 104, 0.6);
}

.c-pc-navy-opacity-7 {
  color: rgba(0, 42, 104, 0.7) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-7\:h:hover,
.c-pc-navy-opacity-7\:h:focus,
.c-pc-navy-opacity-7\:h:active {
  color: rgba(0, 42, 104, 0.7) !important;
}

.bgc-pc-navy-opacity-7 {
  background-color: rgba(0, 42, 104, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-7\:h:hover,
.bgc-pc-navy-opacity-7\:h:focus,
.bgc-pc-navy-opacity-7\:h:active {
  background-color: rgba(0, 42, 104, 0.7) !important;
}

.bc-pc-navy-opacity-7 {
  border-color: rgba(0, 42, 104, 0.7);
}

.c-pc-navy-opacity-8 {
  color: rgba(0, 42, 104, 0.8) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-8\:h:hover,
.c-pc-navy-opacity-8\:h:focus,
.c-pc-navy-opacity-8\:h:active {
  color: rgba(0, 42, 104, 0.8) !important;
}

.bgc-pc-navy-opacity-8 {
  background-color: rgba(0, 42, 104, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-8\:h:hover,
.bgc-pc-navy-opacity-8\:h:focus,
.bgc-pc-navy-opacity-8\:h:active {
  background-color: rgba(0, 42, 104, 0.8) !important;
}

.bc-pc-navy-opacity-8 {
  border-color: rgba(0, 42, 104, 0.8);
}

.c-pc-navy-opacity-9 {
  color: rgba(0, 42, 104, 0.9) !important;
  transition: color 300ms;
}

.c-pc-navy-opacity-9\:h:hover,
.c-pc-navy-opacity-9\:h:focus,
.c-pc-navy-opacity-9\:h:active {
  color: rgba(0, 42, 104, 0.9) !important;
}

.bgc-pc-navy-opacity-9 {
  background-color: rgba(0, 42, 104, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-navy-opacity-9\:h:hover,
.bgc-pc-navy-opacity-9\:h:focus,
.bgc-pc-navy-opacity-9\:h:active {
  background-color: rgba(0, 42, 104, 0.9) !important;
}

.bc-pc-navy-opacity-9 {
  border-color: rgba(0, 42, 104, 0.9);
}

.c-pc-navy {
  color: #002a68 !important;
  transition: color 300ms;
}

.c-pc-navy\:h:hover,
.c-pc-navy\:h:focus,
.c-pc-navy\:h:active {
  color: #002a68 !important;
}

.bgc-pc-navy {
  background-color: #002a68 !important;
  transition: background-color 300ms;
}

.bgc-pc-navy\:h:hover,
.bgc-pc-navy\:h:focus,
.bgc-pc-navy\:h:active {
  background-color: #002a68 !important;
}

.bc-pc-navy {
  border-color: #002a68;
}

.c-pc-pink-opacity-1 {
  color: rgba(255, 23, 132, 0.1) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-1\:h:hover,
.c-pc-pink-opacity-1\:h:focus,
.c-pc-pink-opacity-1\:h:active {
  color: rgba(255, 23, 132, 0.1) !important;
}

.bgc-pc-pink-opacity-1 {
  background-color: rgba(255, 23, 132, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-1\:h:hover,
.bgc-pc-pink-opacity-1\:h:focus,
.bgc-pc-pink-opacity-1\:h:active {
  background-color: rgba(255, 23, 132, 0.1) !important;
}

.bc-pc-pink-opacity-1 {
  border-color: rgba(255, 23, 132, 0.1);
}

.c-pc-pink-opacity-2 {
  color: rgba(255, 23, 132, 0.2) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-2\:h:hover,
.c-pc-pink-opacity-2\:h:focus,
.c-pc-pink-opacity-2\:h:active {
  color: rgba(255, 23, 132, 0.2) !important;
}

.bgc-pc-pink-opacity-2 {
  background-color: rgba(255, 23, 132, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-2\:h:hover,
.bgc-pc-pink-opacity-2\:h:focus,
.bgc-pc-pink-opacity-2\:h:active {
  background-color: rgba(255, 23, 132, 0.2) !important;
}

.bc-pc-pink-opacity-2 {
  border-color: rgba(255, 23, 132, 0.2);
}

.c-pc-pink-opacity-3 {
  color: rgba(255, 23, 132, 0.3) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-3\:h:hover,
.c-pc-pink-opacity-3\:h:focus,
.c-pc-pink-opacity-3\:h:active {
  color: rgba(255, 23, 132, 0.3) !important;
}

.bgc-pc-pink-opacity-3 {
  background-color: rgba(255, 23, 132, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-3\:h:hover,
.bgc-pc-pink-opacity-3\:h:focus,
.bgc-pc-pink-opacity-3\:h:active {
  background-color: rgba(255, 23, 132, 0.3) !important;
}

.bc-pc-pink-opacity-3 {
  border-color: rgba(255, 23, 132, 0.3);
}

.c-pc-pink-opacity-4 {
  color: rgba(255, 23, 132, 0.4) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-4\:h:hover,
.c-pc-pink-opacity-4\:h:focus,
.c-pc-pink-opacity-4\:h:active {
  color: rgba(255, 23, 132, 0.4) !important;
}

.bgc-pc-pink-opacity-4 {
  background-color: rgba(255, 23, 132, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-4\:h:hover,
.bgc-pc-pink-opacity-4\:h:focus,
.bgc-pc-pink-opacity-4\:h:active {
  background-color: rgba(255, 23, 132, 0.4) !important;
}

.bc-pc-pink-opacity-4 {
  border-color: rgba(255, 23, 132, 0.4);
}

.c-pc-pink-opacity-5 {
  color: rgba(255, 23, 132, 0.5) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-5\:h:hover,
.c-pc-pink-opacity-5\:h:focus,
.c-pc-pink-opacity-5\:h:active {
  color: rgba(255, 23, 132, 0.5) !important;
}

.bgc-pc-pink-opacity-5 {
  background-color: rgba(255, 23, 132, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-5\:h:hover,
.bgc-pc-pink-opacity-5\:h:focus,
.bgc-pc-pink-opacity-5\:h:active {
  background-color: rgba(255, 23, 132, 0.5) !important;
}

.bc-pc-pink-opacity-5 {
  border-color: rgba(255, 23, 132, 0.5);
}

.c-pc-pink-opacity-6 {
  color: rgba(255, 23, 132, 0.6) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-6\:h:hover,
.c-pc-pink-opacity-6\:h:focus,
.c-pc-pink-opacity-6\:h:active {
  color: rgba(255, 23, 132, 0.6) !important;
}

.bgc-pc-pink-opacity-6 {
  background-color: rgba(255, 23, 132, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-6\:h:hover,
.bgc-pc-pink-opacity-6\:h:focus,
.bgc-pc-pink-opacity-6\:h:active {
  background-color: rgba(255, 23, 132, 0.6) !important;
}

.bc-pc-pink-opacity-6 {
  border-color: rgba(255, 23, 132, 0.6);
}

.c-pc-pink-opacity-7 {
  color: rgba(255, 23, 132, 0.7) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-7\:h:hover,
.c-pc-pink-opacity-7\:h:focus,
.c-pc-pink-opacity-7\:h:active {
  color: rgba(255, 23, 132, 0.7) !important;
}

.bgc-pc-pink-opacity-7 {
  background-color: rgba(255, 23, 132, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-7\:h:hover,
.bgc-pc-pink-opacity-7\:h:focus,
.bgc-pc-pink-opacity-7\:h:active {
  background-color: rgba(255, 23, 132, 0.7) !important;
}

.bc-pc-pink-opacity-7 {
  border-color: rgba(255, 23, 132, 0.7);
}

.c-pc-pink-opacity-8 {
  color: rgba(255, 23, 132, 0.8) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-8\:h:hover,
.c-pc-pink-opacity-8\:h:focus,
.c-pc-pink-opacity-8\:h:active {
  color: rgba(255, 23, 132, 0.8) !important;
}

.bgc-pc-pink-opacity-8 {
  background-color: rgba(255, 23, 132, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-8\:h:hover,
.bgc-pc-pink-opacity-8\:h:focus,
.bgc-pc-pink-opacity-8\:h:active {
  background-color: rgba(255, 23, 132, 0.8) !important;
}

.bc-pc-pink-opacity-8 {
  border-color: rgba(255, 23, 132, 0.8);
}

.c-pc-pink-opacity-9 {
  color: rgba(255, 23, 132, 0.9) !important;
  transition: color 300ms;
}

.c-pc-pink-opacity-9\:h:hover,
.c-pc-pink-opacity-9\:h:focus,
.c-pc-pink-opacity-9\:h:active {
  color: rgba(255, 23, 132, 0.9) !important;
}

.bgc-pc-pink-opacity-9 {
  background-color: rgba(255, 23, 132, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-pink-opacity-9\:h:hover,
.bgc-pc-pink-opacity-9\:h:focus,
.bgc-pc-pink-opacity-9\:h:active {
  background-color: rgba(255, 23, 132, 0.9) !important;
}

.bc-pc-pink-opacity-9 {
  border-color: rgba(255, 23, 132, 0.9);
}

.c-pc-pink {
  color: #ff1784 !important;
  transition: color 300ms;
}

.c-pc-pink\:h:hover,
.c-pc-pink\:h:focus,
.c-pc-pink\:h:active {
  color: #ff1784 !important;
}

.bgc-pc-pink {
  background-color: #ff1784 !important;
  transition: background-color 300ms;
}

.bgc-pc-pink\:h:hover,
.bgc-pc-pink\:h:focus,
.bgc-pc-pink\:h:active {
  background-color: #ff1784 !important;
}

.bc-pc-pink {
  border-color: #ff1784;
}

.c-pc-sky-blue-opacity-1 {
  color: rgba(0, 144, 211, 0.1) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-1\:h:hover,
.c-pc-sky-blue-opacity-1\:h:focus,
.c-pc-sky-blue-opacity-1\:h:active {
  color: rgba(0, 144, 211, 0.1) !important;
}

.bgc-pc-sky-blue-opacity-1 {
  background-color: rgba(0, 144, 211, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-1\:h:hover,
.bgc-pc-sky-blue-opacity-1\:h:focus,
.bgc-pc-sky-blue-opacity-1\:h:active {
  background-color: rgba(0, 144, 211, 0.1) !important;
}

.bc-pc-sky-blue-opacity-1 {
  border-color: rgba(0, 144, 211, 0.1);
}

.c-pc-sky-blue-opacity-2 {
  color: rgba(0, 144, 211, 0.2) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-2\:h:hover,
.c-pc-sky-blue-opacity-2\:h:focus,
.c-pc-sky-blue-opacity-2\:h:active {
  color: rgba(0, 144, 211, 0.2) !important;
}

.bgc-pc-sky-blue-opacity-2 {
  background-color: rgba(0, 144, 211, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-2\:h:hover,
.bgc-pc-sky-blue-opacity-2\:h:focus,
.bgc-pc-sky-blue-opacity-2\:h:active {
  background-color: rgba(0, 144, 211, 0.2) !important;
}

.bc-pc-sky-blue-opacity-2 {
  border-color: rgba(0, 144, 211, 0.2);
}

.c-pc-sky-blue-opacity-3 {
  color: rgba(0, 144, 211, 0.3) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-3\:h:hover,
.c-pc-sky-blue-opacity-3\:h:focus,
.c-pc-sky-blue-opacity-3\:h:active {
  color: rgba(0, 144, 211, 0.3) !important;
}

.bgc-pc-sky-blue-opacity-3 {
  background-color: rgba(0, 144, 211, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-3\:h:hover,
.bgc-pc-sky-blue-opacity-3\:h:focus,
.bgc-pc-sky-blue-opacity-3\:h:active {
  background-color: rgba(0, 144, 211, 0.3) !important;
}

.bc-pc-sky-blue-opacity-3 {
  border-color: rgba(0, 144, 211, 0.3);
}

.c-pc-sky-blue-opacity-4 {
  color: rgba(0, 144, 211, 0.4) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-4\:h:hover,
.c-pc-sky-blue-opacity-4\:h:focus,
.c-pc-sky-blue-opacity-4\:h:active {
  color: rgba(0, 144, 211, 0.4) !important;
}

.bgc-pc-sky-blue-opacity-4 {
  background-color: rgba(0, 144, 211, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-4\:h:hover,
.bgc-pc-sky-blue-opacity-4\:h:focus,
.bgc-pc-sky-blue-opacity-4\:h:active {
  background-color: rgba(0, 144, 211, 0.4) !important;
}

.bc-pc-sky-blue-opacity-4 {
  border-color: rgba(0, 144, 211, 0.4);
}

.c-pc-sky-blue-opacity-5 {
  color: rgba(0, 144, 211, 0.5) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-5\:h:hover,
.c-pc-sky-blue-opacity-5\:h:focus,
.c-pc-sky-blue-opacity-5\:h:active {
  color: rgba(0, 144, 211, 0.5) !important;
}

.bgc-pc-sky-blue-opacity-5 {
  background-color: rgba(0, 144, 211, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-5\:h:hover,
.bgc-pc-sky-blue-opacity-5\:h:focus,
.bgc-pc-sky-blue-opacity-5\:h:active {
  background-color: rgba(0, 144, 211, 0.5) !important;
}

.bc-pc-sky-blue-opacity-5 {
  border-color: rgba(0, 144, 211, 0.5);
}

.c-pc-sky-blue-opacity-6 {
  color: rgba(0, 144, 211, 0.6) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-6\:h:hover,
.c-pc-sky-blue-opacity-6\:h:focus,
.c-pc-sky-blue-opacity-6\:h:active {
  color: rgba(0, 144, 211, 0.6) !important;
}

.bgc-pc-sky-blue-opacity-6 {
  background-color: rgba(0, 144, 211, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-6\:h:hover,
.bgc-pc-sky-blue-opacity-6\:h:focus,
.bgc-pc-sky-blue-opacity-6\:h:active {
  background-color: rgba(0, 144, 211, 0.6) !important;
}

.bc-pc-sky-blue-opacity-6 {
  border-color: rgba(0, 144, 211, 0.6);
}

.c-pc-sky-blue-opacity-7 {
  color: rgba(0, 144, 211, 0.7) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-7\:h:hover,
.c-pc-sky-blue-opacity-7\:h:focus,
.c-pc-sky-blue-opacity-7\:h:active {
  color: rgba(0, 144, 211, 0.7) !important;
}

.bgc-pc-sky-blue-opacity-7 {
  background-color: rgba(0, 144, 211, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-7\:h:hover,
.bgc-pc-sky-blue-opacity-7\:h:focus,
.bgc-pc-sky-blue-opacity-7\:h:active {
  background-color: rgba(0, 144, 211, 0.7) !important;
}

.bc-pc-sky-blue-opacity-7 {
  border-color: rgba(0, 144, 211, 0.7);
}

.c-pc-sky-blue-opacity-8 {
  color: rgba(0, 144, 211, 0.8) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-8\:h:hover,
.c-pc-sky-blue-opacity-8\:h:focus,
.c-pc-sky-blue-opacity-8\:h:active {
  color: rgba(0, 144, 211, 0.8) !important;
}

.bgc-pc-sky-blue-opacity-8 {
  background-color: rgba(0, 144, 211, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-8\:h:hover,
.bgc-pc-sky-blue-opacity-8\:h:focus,
.bgc-pc-sky-blue-opacity-8\:h:active {
  background-color: rgba(0, 144, 211, 0.8) !important;
}

.bc-pc-sky-blue-opacity-8 {
  border-color: rgba(0, 144, 211, 0.8);
}

.c-pc-sky-blue-opacity-9 {
  color: rgba(0, 144, 211, 0.9) !important;
  transition: color 300ms;
}

.c-pc-sky-blue-opacity-9\:h:hover,
.c-pc-sky-blue-opacity-9\:h:focus,
.c-pc-sky-blue-opacity-9\:h:active {
  color: rgba(0, 144, 211, 0.9) !important;
}

.bgc-pc-sky-blue-opacity-9 {
  background-color: rgba(0, 144, 211, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue-opacity-9\:h:hover,
.bgc-pc-sky-blue-opacity-9\:h:focus,
.bgc-pc-sky-blue-opacity-9\:h:active {
  background-color: rgba(0, 144, 211, 0.9) !important;
}

.bc-pc-sky-blue-opacity-9 {
  border-color: rgba(0, 144, 211, 0.9);
}

.c-pc-sky-blue {
  color: #0090d3 !important;
  transition: color 300ms;
}

.c-pc-sky-blue\:h:hover,
.c-pc-sky-blue\:h:focus,
.c-pc-sky-blue\:h:active {
  color: #0090d3 !important;
}

.bgc-pc-sky-blue {
  background-color: #0090d3 !important;
  transition: background-color 300ms;
}

.bgc-pc-sky-blue\:h:hover,
.bgc-pc-sky-blue\:h:focus,
.bgc-pc-sky-blue\:h:active {
  background-color: #0090d3 !important;
}

.bc-pc-sky-blue {
  border-color: #0090d3;
}

.c-pc-white-opacity-1 {
  color: rgba(255, 255, 255, 0.1) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-1\:h:hover,
.c-pc-white-opacity-1\:h:focus,
.c-pc-white-opacity-1\:h:active {
  color: rgba(255, 255, 255, 0.1) !important;
}

.bgc-pc-white-opacity-1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-1\:h:hover,
.bgc-pc-white-opacity-1\:h:focus,
.bgc-pc-white-opacity-1\:h:active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bc-pc-white-opacity-1 {
  border-color: rgba(255, 255, 255, 0.1);
}

.c-pc-white-opacity-2 {
  color: rgba(255, 255, 255, 0.2) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-2\:h:hover,
.c-pc-white-opacity-2\:h:focus,
.c-pc-white-opacity-2\:h:active {
  color: rgba(255, 255, 255, 0.2) !important;
}

.bgc-pc-white-opacity-2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-2\:h:hover,
.bgc-pc-white-opacity-2\:h:focus,
.bgc-pc-white-opacity-2\:h:active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bc-pc-white-opacity-2 {
  border-color: rgba(255, 255, 255, 0.2);
}

.c-pc-white-opacity-3 {
  color: rgba(255, 255, 255, 0.3) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-3\:h:hover,
.c-pc-white-opacity-3\:h:focus,
.c-pc-white-opacity-3\:h:active {
  color: rgba(255, 255, 255, 0.3) !important;
}

.bgc-pc-white-opacity-3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-3\:h:hover,
.bgc-pc-white-opacity-3\:h:focus,
.bgc-pc-white-opacity-3\:h:active {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.bc-pc-white-opacity-3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.c-pc-white-opacity-4 {
  color: rgba(255, 255, 255, 0.4) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-4\:h:hover,
.c-pc-white-opacity-4\:h:focus,
.c-pc-white-opacity-4\:h:active {
  color: rgba(255, 255, 255, 0.4) !important;
}

.bgc-pc-white-opacity-4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-4\:h:hover,
.bgc-pc-white-opacity-4\:h:focus,
.bgc-pc-white-opacity-4\:h:active {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.bc-pc-white-opacity-4 {
  border-color: rgba(255, 255, 255, 0.4);
}

.c-pc-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-5\:h:hover,
.c-pc-white-opacity-5\:h:focus,
.c-pc-white-opacity-5\:h:active {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bgc-pc-white-opacity-5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-5\:h:hover,
.bgc-pc-white-opacity-5\:h:focus,
.bgc-pc-white-opacity-5\:h:active {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.bc-pc-white-opacity-5 {
  border-color: rgba(255, 255, 255, 0.5);
}

.c-pc-white-opacity-6 {
  color: rgba(255, 255, 255, 0.6) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-6\:h:hover,
.c-pc-white-opacity-6\:h:focus,
.c-pc-white-opacity-6\:h:active {
  color: rgba(255, 255, 255, 0.6) !important;
}

.bgc-pc-white-opacity-6 {
  background-color: rgba(255, 255, 255, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-6\:h:hover,
.bgc-pc-white-opacity-6\:h:focus,
.bgc-pc-white-opacity-6\:h:active {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.bc-pc-white-opacity-6 {
  border-color: rgba(255, 255, 255, 0.6);
}

.c-pc-white-opacity-7 {
  color: rgba(255, 255, 255, 0.7) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-7\:h:hover,
.c-pc-white-opacity-7\:h:focus,
.c-pc-white-opacity-7\:h:active {
  color: rgba(255, 255, 255, 0.7) !important;
}

.bgc-pc-white-opacity-7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-7\:h:hover,
.bgc-pc-white-opacity-7\:h:focus,
.bgc-pc-white-opacity-7\:h:active {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.bc-pc-white-opacity-7 {
  border-color: rgba(255, 255, 255, 0.7);
}

.c-pc-white-opacity-8 {
  color: rgba(255, 255, 255, 0.8) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-8\:h:hover,
.c-pc-white-opacity-8\:h:focus,
.c-pc-white-opacity-8\:h:active {
  color: rgba(255, 255, 255, 0.8) !important;
}

.bgc-pc-white-opacity-8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-8\:h:hover,
.bgc-pc-white-opacity-8\:h:focus,
.bgc-pc-white-opacity-8\:h:active {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.bc-pc-white-opacity-8 {
  border-color: rgba(255, 255, 255, 0.8);
}

.c-pc-white-opacity-9 {
  color: rgba(255, 255, 255, 0.9) !important;
  transition: color 300ms;
}

.c-pc-white-opacity-9\:h:hover,
.c-pc-white-opacity-9\:h:focus,
.c-pc-white-opacity-9\:h:active {
  color: rgba(255, 255, 255, 0.9) !important;
}

.bgc-pc-white-opacity-9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-white-opacity-9\:h:hover,
.bgc-pc-white-opacity-9\:h:focus,
.bgc-pc-white-opacity-9\:h:active {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.bc-pc-white-opacity-9 {
  border-color: rgba(255, 255, 255, 0.9);
}

.c-pc-white {
  color: white !important;
  transition: color 300ms;
}

.c-pc-white\:h:hover,
.c-pc-white\:h:focus,
.c-pc-white\:h:active {
  color: white !important;
}

.bgc-pc-white {
  background-color: white !important;
  transition: background-color 300ms;
}

.bgc-pc-white\:h:hover,
.bgc-pc-white\:h:focus,
.bgc-pc-white\:h:active {
  background-color: white !important;
}

.bc-pc-white {
  border-color: white;
}

.c-pc-grey-1-opacity-1 {
  color: rgba(51, 51, 51, 0.1) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-1\:h:hover,
.c-pc-grey-1-opacity-1\:h:focus,
.c-pc-grey-1-opacity-1\:h:active {
  color: rgba(51, 51, 51, 0.1) !important;
}

.bgc-pc-grey-1-opacity-1 {
  background-color: rgba(51, 51, 51, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-1\:h:hover,
.bgc-pc-grey-1-opacity-1\:h:focus,
.bgc-pc-grey-1-opacity-1\:h:active {
  background-color: rgba(51, 51, 51, 0.1) !important;
}

.bc-pc-grey-1-opacity-1 {
  border-color: rgba(51, 51, 51, 0.1);
}

.c-pc-grey-1-opacity-2 {
  color: rgba(51, 51, 51, 0.2) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-2\:h:hover,
.c-pc-grey-1-opacity-2\:h:focus,
.c-pc-grey-1-opacity-2\:h:active {
  color: rgba(51, 51, 51, 0.2) !important;
}

.bgc-pc-grey-1-opacity-2 {
  background-color: rgba(51, 51, 51, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-2\:h:hover,
.bgc-pc-grey-1-opacity-2\:h:focus,
.bgc-pc-grey-1-opacity-2\:h:active {
  background-color: rgba(51, 51, 51, 0.2) !important;
}

.bc-pc-grey-1-opacity-2 {
  border-color: rgba(51, 51, 51, 0.2);
}

.c-pc-grey-1-opacity-3 {
  color: rgba(51, 51, 51, 0.3) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-3\:h:hover,
.c-pc-grey-1-opacity-3\:h:focus,
.c-pc-grey-1-opacity-3\:h:active {
  color: rgba(51, 51, 51, 0.3) !important;
}

.bgc-pc-grey-1-opacity-3 {
  background-color: rgba(51, 51, 51, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-3\:h:hover,
.bgc-pc-grey-1-opacity-3\:h:focus,
.bgc-pc-grey-1-opacity-3\:h:active {
  background-color: rgba(51, 51, 51, 0.3) !important;
}

.bc-pc-grey-1-opacity-3 {
  border-color: rgba(51, 51, 51, 0.3);
}

.c-pc-grey-1-opacity-4 {
  color: rgba(51, 51, 51, 0.4) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-4\:h:hover,
.c-pc-grey-1-opacity-4\:h:focus,
.c-pc-grey-1-opacity-4\:h:active {
  color: rgba(51, 51, 51, 0.4) !important;
}

.bgc-pc-grey-1-opacity-4 {
  background-color: rgba(51, 51, 51, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-4\:h:hover,
.bgc-pc-grey-1-opacity-4\:h:focus,
.bgc-pc-grey-1-opacity-4\:h:active {
  background-color: rgba(51, 51, 51, 0.4) !important;
}

.bc-pc-grey-1-opacity-4 {
  border-color: rgba(51, 51, 51, 0.4);
}

.c-pc-grey-1-opacity-5 {
  color: rgba(51, 51, 51, 0.5) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-5\:h:hover,
.c-pc-grey-1-opacity-5\:h:focus,
.c-pc-grey-1-opacity-5\:h:active {
  color: rgba(51, 51, 51, 0.5) !important;
}

.bgc-pc-grey-1-opacity-5 {
  background-color: rgba(51, 51, 51, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-5\:h:hover,
.bgc-pc-grey-1-opacity-5\:h:focus,
.bgc-pc-grey-1-opacity-5\:h:active {
  background-color: rgba(51, 51, 51, 0.5) !important;
}

.bc-pc-grey-1-opacity-5 {
  border-color: rgba(51, 51, 51, 0.5);
}

.c-pc-grey-1-opacity-6 {
  color: rgba(51, 51, 51, 0.6) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-6\:h:hover,
.c-pc-grey-1-opacity-6\:h:focus,
.c-pc-grey-1-opacity-6\:h:active {
  color: rgba(51, 51, 51, 0.6) !important;
}

.bgc-pc-grey-1-opacity-6 {
  background-color: rgba(51, 51, 51, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-6\:h:hover,
.bgc-pc-grey-1-opacity-6\:h:focus,
.bgc-pc-grey-1-opacity-6\:h:active {
  background-color: rgba(51, 51, 51, 0.6) !important;
}

.bc-pc-grey-1-opacity-6 {
  border-color: rgba(51, 51, 51, 0.6);
}

.c-pc-grey-1-opacity-7 {
  color: rgba(51, 51, 51, 0.7) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-7\:h:hover,
.c-pc-grey-1-opacity-7\:h:focus,
.c-pc-grey-1-opacity-7\:h:active {
  color: rgba(51, 51, 51, 0.7) !important;
}

.bgc-pc-grey-1-opacity-7 {
  background-color: rgba(51, 51, 51, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-7\:h:hover,
.bgc-pc-grey-1-opacity-7\:h:focus,
.bgc-pc-grey-1-opacity-7\:h:active {
  background-color: rgba(51, 51, 51, 0.7) !important;
}

.bc-pc-grey-1-opacity-7 {
  border-color: rgba(51, 51, 51, 0.7);
}

.c-pc-grey-1-opacity-8 {
  color: rgba(51, 51, 51, 0.8) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-8\:h:hover,
.c-pc-grey-1-opacity-8\:h:focus,
.c-pc-grey-1-opacity-8\:h:active {
  color: rgba(51, 51, 51, 0.8) !important;
}

.bgc-pc-grey-1-opacity-8 {
  background-color: rgba(51, 51, 51, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-8\:h:hover,
.bgc-pc-grey-1-opacity-8\:h:focus,
.bgc-pc-grey-1-opacity-8\:h:active {
  background-color: rgba(51, 51, 51, 0.8) !important;
}

.bc-pc-grey-1-opacity-8 {
  border-color: rgba(51, 51, 51, 0.8);
}

.c-pc-grey-1-opacity-9 {
  color: rgba(51, 51, 51, 0.9) !important;
  transition: color 300ms;
}

.c-pc-grey-1-opacity-9\:h:hover,
.c-pc-grey-1-opacity-9\:h:focus,
.c-pc-grey-1-opacity-9\:h:active {
  color: rgba(51, 51, 51, 0.9) !important;
}

.bgc-pc-grey-1-opacity-9 {
  background-color: rgba(51, 51, 51, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1-opacity-9\:h:hover,
.bgc-pc-grey-1-opacity-9\:h:focus,
.bgc-pc-grey-1-opacity-9\:h:active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}

.bc-pc-grey-1-opacity-9 {
  border-color: rgba(51, 51, 51, 0.9);
}

.c-pc-grey-1 {
  color: #333333 !important;
  transition: color 300ms;
}

.c-pc-grey-1\:h:hover,
.c-pc-grey-1\:h:focus,
.c-pc-grey-1\:h:active {
  color: #333333 !important;
}

.bgc-pc-grey-1 {
  background-color: #333333 !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-1\:h:hover,
.bgc-pc-grey-1\:h:focus,
.bgc-pc-grey-1\:h:active {
  background-color: #333333 !important;
}

.bc-pc-grey-1 {
  border-color: #333333;
}

.c-pc-grey-2-opacity-1 {
  color: rgba(136, 136, 136, 0.1) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-1\:h:hover,
.c-pc-grey-2-opacity-1\:h:focus,
.c-pc-grey-2-opacity-1\:h:active {
  color: rgba(136, 136, 136, 0.1) !important;
}

.bgc-pc-grey-2-opacity-1 {
  background-color: rgba(136, 136, 136, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-1\:h:hover,
.bgc-pc-grey-2-opacity-1\:h:focus,
.bgc-pc-grey-2-opacity-1\:h:active {
  background-color: rgba(136, 136, 136, 0.1) !important;
}

.bc-pc-grey-2-opacity-1 {
  border-color: rgba(136, 136, 136, 0.1);
}

.c-pc-grey-2-opacity-2 {
  color: rgba(136, 136, 136, 0.2) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-2\:h:hover,
.c-pc-grey-2-opacity-2\:h:focus,
.c-pc-grey-2-opacity-2\:h:active {
  color: rgba(136, 136, 136, 0.2) !important;
}

.bgc-pc-grey-2-opacity-2 {
  background-color: rgba(136, 136, 136, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-2\:h:hover,
.bgc-pc-grey-2-opacity-2\:h:focus,
.bgc-pc-grey-2-opacity-2\:h:active {
  background-color: rgba(136, 136, 136, 0.2) !important;
}

.bc-pc-grey-2-opacity-2 {
  border-color: rgba(136, 136, 136, 0.2);
}

.c-pc-grey-2-opacity-3 {
  color: rgba(136, 136, 136, 0.3) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-3\:h:hover,
.c-pc-grey-2-opacity-3\:h:focus,
.c-pc-grey-2-opacity-3\:h:active {
  color: rgba(136, 136, 136, 0.3) !important;
}

.bgc-pc-grey-2-opacity-3 {
  background-color: rgba(136, 136, 136, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-3\:h:hover,
.bgc-pc-grey-2-opacity-3\:h:focus,
.bgc-pc-grey-2-opacity-3\:h:active {
  background-color: rgba(136, 136, 136, 0.3) !important;
}

.bc-pc-grey-2-opacity-3 {
  border-color: rgba(136, 136, 136, 0.3);
}

.c-pc-grey-2-opacity-4 {
  color: rgba(136, 136, 136, 0.4) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-4\:h:hover,
.c-pc-grey-2-opacity-4\:h:focus,
.c-pc-grey-2-opacity-4\:h:active {
  color: rgba(136, 136, 136, 0.4) !important;
}

.bgc-pc-grey-2-opacity-4 {
  background-color: rgba(136, 136, 136, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-4\:h:hover,
.bgc-pc-grey-2-opacity-4\:h:focus,
.bgc-pc-grey-2-opacity-4\:h:active {
  background-color: rgba(136, 136, 136, 0.4) !important;
}

.bc-pc-grey-2-opacity-4 {
  border-color: rgba(136, 136, 136, 0.4);
}

.c-pc-grey-2-opacity-5 {
  color: rgba(136, 136, 136, 0.5) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-5\:h:hover,
.c-pc-grey-2-opacity-5\:h:focus,
.c-pc-grey-2-opacity-5\:h:active {
  color: rgba(136, 136, 136, 0.5) !important;
}

.bgc-pc-grey-2-opacity-5 {
  background-color: rgba(136, 136, 136, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-5\:h:hover,
.bgc-pc-grey-2-opacity-5\:h:focus,
.bgc-pc-grey-2-opacity-5\:h:active {
  background-color: rgba(136, 136, 136, 0.5) !important;
}

.bc-pc-grey-2-opacity-5 {
  border-color: rgba(136, 136, 136, 0.5);
}

.c-pc-grey-2-opacity-6 {
  color: rgba(136, 136, 136, 0.6) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-6\:h:hover,
.c-pc-grey-2-opacity-6\:h:focus,
.c-pc-grey-2-opacity-6\:h:active {
  color: rgba(136, 136, 136, 0.6) !important;
}

.bgc-pc-grey-2-opacity-6 {
  background-color: rgba(136, 136, 136, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-6\:h:hover,
.bgc-pc-grey-2-opacity-6\:h:focus,
.bgc-pc-grey-2-opacity-6\:h:active {
  background-color: rgba(136, 136, 136, 0.6) !important;
}

.bc-pc-grey-2-opacity-6 {
  border-color: rgba(136, 136, 136, 0.6);
}

.c-pc-grey-2-opacity-7 {
  color: rgba(136, 136, 136, 0.7) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-7\:h:hover,
.c-pc-grey-2-opacity-7\:h:focus,
.c-pc-grey-2-opacity-7\:h:active {
  color: rgba(136, 136, 136, 0.7) !important;
}

.bgc-pc-grey-2-opacity-7 {
  background-color: rgba(136, 136, 136, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-7\:h:hover,
.bgc-pc-grey-2-opacity-7\:h:focus,
.bgc-pc-grey-2-opacity-7\:h:active {
  background-color: rgba(136, 136, 136, 0.7) !important;
}

.bc-pc-grey-2-opacity-7 {
  border-color: rgba(136, 136, 136, 0.7);
}

.c-pc-grey-2-opacity-8 {
  color: rgba(136, 136, 136, 0.8) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-8\:h:hover,
.c-pc-grey-2-opacity-8\:h:focus,
.c-pc-grey-2-opacity-8\:h:active {
  color: rgba(136, 136, 136, 0.8) !important;
}

.bgc-pc-grey-2-opacity-8 {
  background-color: rgba(136, 136, 136, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-8\:h:hover,
.bgc-pc-grey-2-opacity-8\:h:focus,
.bgc-pc-grey-2-opacity-8\:h:active {
  background-color: rgba(136, 136, 136, 0.8) !important;
}

.bc-pc-grey-2-opacity-8 {
  border-color: rgba(136, 136, 136, 0.8);
}

.c-pc-grey-2-opacity-9 {
  color: rgba(136, 136, 136, 0.9) !important;
  transition: color 300ms;
}

.c-pc-grey-2-opacity-9\:h:hover,
.c-pc-grey-2-opacity-9\:h:focus,
.c-pc-grey-2-opacity-9\:h:active {
  color: rgba(136, 136, 136, 0.9) !important;
}

.bgc-pc-grey-2-opacity-9 {
  background-color: rgba(136, 136, 136, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2-opacity-9\:h:hover,
.bgc-pc-grey-2-opacity-9\:h:focus,
.bgc-pc-grey-2-opacity-9\:h:active {
  background-color: rgba(136, 136, 136, 0.9) !important;
}

.bc-pc-grey-2-opacity-9 {
  border-color: rgba(136, 136, 136, 0.9);
}

.c-pc-grey-2 {
  color: #888888 !important;
  transition: color 300ms;
}

.c-pc-grey-2\:h:hover,
.c-pc-grey-2\:h:focus,
.c-pc-grey-2\:h:active {
  color: #888888 !important;
}

.bgc-pc-grey-2 {
  background-color: #888888 !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-2\:h:hover,
.bgc-pc-grey-2\:h:focus,
.bgc-pc-grey-2\:h:active {
  background-color: #888888 !important;
}

.bc-pc-grey-2 {
  border-color: #888888;
}

.c-pc-grey-3-opacity-1 {
  color: rgba(229, 229, 229, 0.1) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-1\:h:hover,
.c-pc-grey-3-opacity-1\:h:focus,
.c-pc-grey-3-opacity-1\:h:active {
  color: rgba(229, 229, 229, 0.1) !important;
}

.bgc-pc-grey-3-opacity-1 {
  background-color: rgba(229, 229, 229, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-1\:h:hover,
.bgc-pc-grey-3-opacity-1\:h:focus,
.bgc-pc-grey-3-opacity-1\:h:active {
  background-color: rgba(229, 229, 229, 0.1) !important;
}

.bc-pc-grey-3-opacity-1 {
  border-color: rgba(229, 229, 229, 0.1);
}

.c-pc-grey-3-opacity-2 {
  color: rgba(229, 229, 229, 0.2) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-2\:h:hover,
.c-pc-grey-3-opacity-2\:h:focus,
.c-pc-grey-3-opacity-2\:h:active {
  color: rgba(229, 229, 229, 0.2) !important;
}

.bgc-pc-grey-3-opacity-2 {
  background-color: rgba(229, 229, 229, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-2\:h:hover,
.bgc-pc-grey-3-opacity-2\:h:focus,
.bgc-pc-grey-3-opacity-2\:h:active {
  background-color: rgba(229, 229, 229, 0.2) !important;
}

.bc-pc-grey-3-opacity-2 {
  border-color: rgba(229, 229, 229, 0.2);
}

.c-pc-grey-3-opacity-3 {
  color: rgba(229, 229, 229, 0.3) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-3\:h:hover,
.c-pc-grey-3-opacity-3\:h:focus,
.c-pc-grey-3-opacity-3\:h:active {
  color: rgba(229, 229, 229, 0.3) !important;
}

.bgc-pc-grey-3-opacity-3 {
  background-color: rgba(229, 229, 229, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-3\:h:hover,
.bgc-pc-grey-3-opacity-3\:h:focus,
.bgc-pc-grey-3-opacity-3\:h:active {
  background-color: rgba(229, 229, 229, 0.3) !important;
}

.bc-pc-grey-3-opacity-3 {
  border-color: rgba(229, 229, 229, 0.3);
}

.c-pc-grey-3-opacity-4 {
  color: rgba(229, 229, 229, 0.4) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-4\:h:hover,
.c-pc-grey-3-opacity-4\:h:focus,
.c-pc-grey-3-opacity-4\:h:active {
  color: rgba(229, 229, 229, 0.4) !important;
}

.bgc-pc-grey-3-opacity-4 {
  background-color: rgba(229, 229, 229, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-4\:h:hover,
.bgc-pc-grey-3-opacity-4\:h:focus,
.bgc-pc-grey-3-opacity-4\:h:active {
  background-color: rgba(229, 229, 229, 0.4) !important;
}

.bc-pc-grey-3-opacity-4 {
  border-color: rgba(229, 229, 229, 0.4);
}

.c-pc-grey-3-opacity-5 {
  color: rgba(229, 229, 229, 0.5) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-5\:h:hover,
.c-pc-grey-3-opacity-5\:h:focus,
.c-pc-grey-3-opacity-5\:h:active {
  color: rgba(229, 229, 229, 0.5) !important;
}

.bgc-pc-grey-3-opacity-5 {
  background-color: rgba(229, 229, 229, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-5\:h:hover,
.bgc-pc-grey-3-opacity-5\:h:focus,
.bgc-pc-grey-3-opacity-5\:h:active {
  background-color: rgba(229, 229, 229, 0.5) !important;
}

.bc-pc-grey-3-opacity-5 {
  border-color: rgba(229, 229, 229, 0.5);
}

.c-pc-grey-3-opacity-6 {
  color: rgba(229, 229, 229, 0.6) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-6\:h:hover,
.c-pc-grey-3-opacity-6\:h:focus,
.c-pc-grey-3-opacity-6\:h:active {
  color: rgba(229, 229, 229, 0.6) !important;
}

.bgc-pc-grey-3-opacity-6 {
  background-color: rgba(229, 229, 229, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-6\:h:hover,
.bgc-pc-grey-3-opacity-6\:h:focus,
.bgc-pc-grey-3-opacity-6\:h:active {
  background-color: rgba(229, 229, 229, 0.6) !important;
}

.bc-pc-grey-3-opacity-6 {
  border-color: rgba(229, 229, 229, 0.6);
}

.c-pc-grey-3-opacity-7 {
  color: rgba(229, 229, 229, 0.7) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-7\:h:hover,
.c-pc-grey-3-opacity-7\:h:focus,
.c-pc-grey-3-opacity-7\:h:active {
  color: rgba(229, 229, 229, 0.7) !important;
}

.bgc-pc-grey-3-opacity-7 {
  background-color: rgba(229, 229, 229, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-7\:h:hover,
.bgc-pc-grey-3-opacity-7\:h:focus,
.bgc-pc-grey-3-opacity-7\:h:active {
  background-color: rgba(229, 229, 229, 0.7) !important;
}

.bc-pc-grey-3-opacity-7 {
  border-color: rgba(229, 229, 229, 0.7);
}

.c-pc-grey-3-opacity-8 {
  color: rgba(229, 229, 229, 0.8) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-8\:h:hover,
.c-pc-grey-3-opacity-8\:h:focus,
.c-pc-grey-3-opacity-8\:h:active {
  color: rgba(229, 229, 229, 0.8) !important;
}

.bgc-pc-grey-3-opacity-8 {
  background-color: rgba(229, 229, 229, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-8\:h:hover,
.bgc-pc-grey-3-opacity-8\:h:focus,
.bgc-pc-grey-3-opacity-8\:h:active {
  background-color: rgba(229, 229, 229, 0.8) !important;
}

.bc-pc-grey-3-opacity-8 {
  border-color: rgba(229, 229, 229, 0.8);
}

.c-pc-grey-3-opacity-9 {
  color: rgba(229, 229, 229, 0.9) !important;
  transition: color 300ms;
}

.c-pc-grey-3-opacity-9\:h:hover,
.c-pc-grey-3-opacity-9\:h:focus,
.c-pc-grey-3-opacity-9\:h:active {
  color: rgba(229, 229, 229, 0.9) !important;
}

.bgc-pc-grey-3-opacity-9 {
  background-color: rgba(229, 229, 229, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3-opacity-9\:h:hover,
.bgc-pc-grey-3-opacity-9\:h:focus,
.bgc-pc-grey-3-opacity-9\:h:active {
  background-color: rgba(229, 229, 229, 0.9) !important;
}

.bc-pc-grey-3-opacity-9 {
  border-color: rgba(229, 229, 229, 0.9);
}

.c-pc-grey-3 {
  color: #e5e5e5 !important;
  transition: color 300ms;
}

.c-pc-grey-3\:h:hover,
.c-pc-grey-3\:h:focus,
.c-pc-grey-3\:h:active {
  color: #e5e5e5 !important;
}

.bgc-pc-grey-3 {
  background-color: #e5e5e5 !important;
  transition: background-color 300ms;
}

.bgc-pc-grey-3\:h:hover,
.bgc-pc-grey-3\:h:focus,
.bgc-pc-grey-3\:h:active {
  background-color: #e5e5e5 !important;
}

.bc-pc-grey-3 {
  border-color: #e5e5e5;
}

.c-com-green-opacity-1 {
  color: rgba(102, 175, 48, 0.1) !important;
  transition: color 300ms;
}

.c-com-green-opacity-1\:h:hover,
.c-com-green-opacity-1\:h:focus,
.c-com-green-opacity-1\:h:active {
  color: rgba(102, 175, 48, 0.1) !important;
}

.bgc-com-green-opacity-1 {
  background-color: rgba(102, 175, 48, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-1\:h:hover,
.bgc-com-green-opacity-1\:h:focus,
.bgc-com-green-opacity-1\:h:active {
  background-color: rgba(102, 175, 48, 0.1) !important;
}

.bc-com-green-opacity-1 {
  border-color: rgba(102, 175, 48, 0.1);
}

.c-com-green-opacity-2 {
  color: rgba(102, 175, 48, 0.2) !important;
  transition: color 300ms;
}

.c-com-green-opacity-2\:h:hover,
.c-com-green-opacity-2\:h:focus,
.c-com-green-opacity-2\:h:active {
  color: rgba(102, 175, 48, 0.2) !important;
}

.bgc-com-green-opacity-2 {
  background-color: rgba(102, 175, 48, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-2\:h:hover,
.bgc-com-green-opacity-2\:h:focus,
.bgc-com-green-opacity-2\:h:active {
  background-color: rgba(102, 175, 48, 0.2) !important;
}

.bc-com-green-opacity-2 {
  border-color: rgba(102, 175, 48, 0.2);
}

.c-com-green-opacity-3 {
  color: rgba(102, 175, 48, 0.3) !important;
  transition: color 300ms;
}

.c-com-green-opacity-3\:h:hover,
.c-com-green-opacity-3\:h:focus,
.c-com-green-opacity-3\:h:active {
  color: rgba(102, 175, 48, 0.3) !important;
}

.bgc-com-green-opacity-3 {
  background-color: rgba(102, 175, 48, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-3\:h:hover,
.bgc-com-green-opacity-3\:h:focus,
.bgc-com-green-opacity-3\:h:active {
  background-color: rgba(102, 175, 48, 0.3) !important;
}

.bc-com-green-opacity-3 {
  border-color: rgba(102, 175, 48, 0.3);
}

.c-com-green-opacity-4 {
  color: rgba(102, 175, 48, 0.4) !important;
  transition: color 300ms;
}

.c-com-green-opacity-4\:h:hover,
.c-com-green-opacity-4\:h:focus,
.c-com-green-opacity-4\:h:active {
  color: rgba(102, 175, 48, 0.4) !important;
}

.bgc-com-green-opacity-4 {
  background-color: rgba(102, 175, 48, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-4\:h:hover,
.bgc-com-green-opacity-4\:h:focus,
.bgc-com-green-opacity-4\:h:active {
  background-color: rgba(102, 175, 48, 0.4) !important;
}

.bc-com-green-opacity-4 {
  border-color: rgba(102, 175, 48, 0.4);
}

.c-com-green-opacity-5 {
  color: rgba(102, 175, 48, 0.5) !important;
  transition: color 300ms;
}

.c-com-green-opacity-5\:h:hover,
.c-com-green-opacity-5\:h:focus,
.c-com-green-opacity-5\:h:active {
  color: rgba(102, 175, 48, 0.5) !important;
}

.bgc-com-green-opacity-5 {
  background-color: rgba(102, 175, 48, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-5\:h:hover,
.bgc-com-green-opacity-5\:h:focus,
.bgc-com-green-opacity-5\:h:active {
  background-color: rgba(102, 175, 48, 0.5) !important;
}

.bc-com-green-opacity-5 {
  border-color: rgba(102, 175, 48, 0.5);
}

.c-com-green-opacity-6 {
  color: rgba(102, 175, 48, 0.6) !important;
  transition: color 300ms;
}

.c-com-green-opacity-6\:h:hover,
.c-com-green-opacity-6\:h:focus,
.c-com-green-opacity-6\:h:active {
  color: rgba(102, 175, 48, 0.6) !important;
}

.bgc-com-green-opacity-6 {
  background-color: rgba(102, 175, 48, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-6\:h:hover,
.bgc-com-green-opacity-6\:h:focus,
.bgc-com-green-opacity-6\:h:active {
  background-color: rgba(102, 175, 48, 0.6) !important;
}

.bc-com-green-opacity-6 {
  border-color: rgba(102, 175, 48, 0.6);
}

.c-com-green-opacity-7 {
  color: rgba(102, 175, 48, 0.7) !important;
  transition: color 300ms;
}

.c-com-green-opacity-7\:h:hover,
.c-com-green-opacity-7\:h:focus,
.c-com-green-opacity-7\:h:active {
  color: rgba(102, 175, 48, 0.7) !important;
}

.bgc-com-green-opacity-7 {
  background-color: rgba(102, 175, 48, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-7\:h:hover,
.bgc-com-green-opacity-7\:h:focus,
.bgc-com-green-opacity-7\:h:active {
  background-color: rgba(102, 175, 48, 0.7) !important;
}

.bc-com-green-opacity-7 {
  border-color: rgba(102, 175, 48, 0.7);
}

.c-com-green-opacity-8 {
  color: rgba(102, 175, 48, 0.8) !important;
  transition: color 300ms;
}

.c-com-green-opacity-8\:h:hover,
.c-com-green-opacity-8\:h:focus,
.c-com-green-opacity-8\:h:active {
  color: rgba(102, 175, 48, 0.8) !important;
}

.bgc-com-green-opacity-8 {
  background-color: rgba(102, 175, 48, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-8\:h:hover,
.bgc-com-green-opacity-8\:h:focus,
.bgc-com-green-opacity-8\:h:active {
  background-color: rgba(102, 175, 48, 0.8) !important;
}

.bc-com-green-opacity-8 {
  border-color: rgba(102, 175, 48, 0.8);
}

.c-com-green-opacity-9 {
  color: rgba(102, 175, 48, 0.9) !important;
  transition: color 300ms;
}

.c-com-green-opacity-9\:h:hover,
.c-com-green-opacity-9\:h:focus,
.c-com-green-opacity-9\:h:active {
  color: rgba(102, 175, 48, 0.9) !important;
}

.bgc-com-green-opacity-9 {
  background-color: rgba(102, 175, 48, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-green-opacity-9\:h:hover,
.bgc-com-green-opacity-9\:h:focus,
.bgc-com-green-opacity-9\:h:active {
  background-color: rgba(102, 175, 48, 0.9) !important;
}

.bc-com-green-opacity-9 {
  border-color: rgba(102, 175, 48, 0.9);
}

.c-com-green {
  color: #66af30 !important;
  transition: color 300ms;
}

.c-com-green\:h:hover,
.c-com-green\:h:focus,
.c-com-green\:h:active {
  color: #66af30 !important;
}

.bgc-com-green {
  background-color: #66af30 !important;
  transition: background-color 300ms;
}

.bgc-com-green\:h:hover,
.bgc-com-green\:h:focus,
.bgc-com-green\:h:active {
  background-color: #66af30 !important;
}

.bc-com-green {
  border-color: #66af30;
}

.c-com-cherry-opacity-1 {
  color: rgba(141, 28, 80, 0.1) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-1\:h:hover,
.c-com-cherry-opacity-1\:h:focus,
.c-com-cherry-opacity-1\:h:active {
  color: rgba(141, 28, 80, 0.1) !important;
}

.bgc-com-cherry-opacity-1 {
  background-color: rgba(141, 28, 80, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-1\:h:hover,
.bgc-com-cherry-opacity-1\:h:focus,
.bgc-com-cherry-opacity-1\:h:active {
  background-color: rgba(141, 28, 80, 0.1) !important;
}

.bc-com-cherry-opacity-1 {
  border-color: rgba(141, 28, 80, 0.1);
}

.c-com-cherry-opacity-2 {
  color: rgba(141, 28, 80, 0.2) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-2\:h:hover,
.c-com-cherry-opacity-2\:h:focus,
.c-com-cherry-opacity-2\:h:active {
  color: rgba(141, 28, 80, 0.2) !important;
}

.bgc-com-cherry-opacity-2 {
  background-color: rgba(141, 28, 80, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-2\:h:hover,
.bgc-com-cherry-opacity-2\:h:focus,
.bgc-com-cherry-opacity-2\:h:active {
  background-color: rgba(141, 28, 80, 0.2) !important;
}

.bc-com-cherry-opacity-2 {
  border-color: rgba(141, 28, 80, 0.2);
}

.c-com-cherry-opacity-3 {
  color: rgba(141, 28, 80, 0.3) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-3\:h:hover,
.c-com-cherry-opacity-3\:h:focus,
.c-com-cherry-opacity-3\:h:active {
  color: rgba(141, 28, 80, 0.3) !important;
}

.bgc-com-cherry-opacity-3 {
  background-color: rgba(141, 28, 80, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-3\:h:hover,
.bgc-com-cherry-opacity-3\:h:focus,
.bgc-com-cherry-opacity-3\:h:active {
  background-color: rgba(141, 28, 80, 0.3) !important;
}

.bc-com-cherry-opacity-3 {
  border-color: rgba(141, 28, 80, 0.3);
}

.c-com-cherry-opacity-4 {
  color: rgba(141, 28, 80, 0.4) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-4\:h:hover,
.c-com-cherry-opacity-4\:h:focus,
.c-com-cherry-opacity-4\:h:active {
  color: rgba(141, 28, 80, 0.4) !important;
}

.bgc-com-cherry-opacity-4 {
  background-color: rgba(141, 28, 80, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-4\:h:hover,
.bgc-com-cherry-opacity-4\:h:focus,
.bgc-com-cherry-opacity-4\:h:active {
  background-color: rgba(141, 28, 80, 0.4) !important;
}

.bc-com-cherry-opacity-4 {
  border-color: rgba(141, 28, 80, 0.4);
}

.c-com-cherry-opacity-5 {
  color: rgba(141, 28, 80, 0.5) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-5\:h:hover,
.c-com-cherry-opacity-5\:h:focus,
.c-com-cherry-opacity-5\:h:active {
  color: rgba(141, 28, 80, 0.5) !important;
}

.bgc-com-cherry-opacity-5 {
  background-color: rgba(141, 28, 80, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-5\:h:hover,
.bgc-com-cherry-opacity-5\:h:focus,
.bgc-com-cherry-opacity-5\:h:active {
  background-color: rgba(141, 28, 80, 0.5) !important;
}

.bc-com-cherry-opacity-5 {
  border-color: rgba(141, 28, 80, 0.5);
}

.c-com-cherry-opacity-6 {
  color: rgba(141, 28, 80, 0.6) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-6\:h:hover,
.c-com-cherry-opacity-6\:h:focus,
.c-com-cherry-opacity-6\:h:active {
  color: rgba(141, 28, 80, 0.6) !important;
}

.bgc-com-cherry-opacity-6 {
  background-color: rgba(141, 28, 80, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-6\:h:hover,
.bgc-com-cherry-opacity-6\:h:focus,
.bgc-com-cherry-opacity-6\:h:active {
  background-color: rgba(141, 28, 80, 0.6) !important;
}

.bc-com-cherry-opacity-6 {
  border-color: rgba(141, 28, 80, 0.6);
}

.c-com-cherry-opacity-7 {
  color: rgba(141, 28, 80, 0.7) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-7\:h:hover,
.c-com-cherry-opacity-7\:h:focus,
.c-com-cherry-opacity-7\:h:active {
  color: rgba(141, 28, 80, 0.7) !important;
}

.bgc-com-cherry-opacity-7 {
  background-color: rgba(141, 28, 80, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-7\:h:hover,
.bgc-com-cherry-opacity-7\:h:focus,
.bgc-com-cherry-opacity-7\:h:active {
  background-color: rgba(141, 28, 80, 0.7) !important;
}

.bc-com-cherry-opacity-7 {
  border-color: rgba(141, 28, 80, 0.7);
}

.c-com-cherry-opacity-8 {
  color: rgba(141, 28, 80, 0.8) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-8\:h:hover,
.c-com-cherry-opacity-8\:h:focus,
.c-com-cherry-opacity-8\:h:active {
  color: rgba(141, 28, 80, 0.8) !important;
}

.bgc-com-cherry-opacity-8 {
  background-color: rgba(141, 28, 80, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-8\:h:hover,
.bgc-com-cherry-opacity-8\:h:focus,
.bgc-com-cherry-opacity-8\:h:active {
  background-color: rgba(141, 28, 80, 0.8) !important;
}

.bc-com-cherry-opacity-8 {
  border-color: rgba(141, 28, 80, 0.8);
}

.c-com-cherry-opacity-9 {
  color: rgba(141, 28, 80, 0.9) !important;
  transition: color 300ms;
}

.c-com-cherry-opacity-9\:h:hover,
.c-com-cherry-opacity-9\:h:focus,
.c-com-cherry-opacity-9\:h:active {
  color: rgba(141, 28, 80, 0.9) !important;
}

.bgc-com-cherry-opacity-9 {
  background-color: rgba(141, 28, 80, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-cherry-opacity-9\:h:hover,
.bgc-com-cherry-opacity-9\:h:focus,
.bgc-com-cherry-opacity-9\:h:active {
  background-color: rgba(141, 28, 80, 0.9) !important;
}

.bc-com-cherry-opacity-9 {
  border-color: rgba(141, 28, 80, 0.9);
}

.c-com-cherry {
  color: #8d1c50 !important;
  transition: color 300ms;
}

.c-com-cherry\:h:hover,
.c-com-cherry\:h:focus,
.c-com-cherry\:h:active {
  color: #8d1c50 !important;
}

.bgc-com-cherry {
  background-color: #8d1c50 !important;
  transition: background-color 300ms;
}

.bgc-com-cherry\:h:hover,
.bgc-com-cherry\:h:focus,
.bgc-com-cherry\:h:active {
  background-color: #8d1c50 !important;
}

.bc-com-cherry {
  border-color: #8d1c50;
}

.c-com-red-opacity-1 {
  color: rgba(226, 6, 19, 0.1) !important;
  transition: color 300ms;
}

.c-com-red-opacity-1\:h:hover,
.c-com-red-opacity-1\:h:focus,
.c-com-red-opacity-1\:h:active {
  color: rgba(226, 6, 19, 0.1) !important;
}

.bgc-com-red-opacity-1 {
  background-color: rgba(226, 6, 19, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-1\:h:hover,
.bgc-com-red-opacity-1\:h:focus,
.bgc-com-red-opacity-1\:h:active {
  background-color: rgba(226, 6, 19, 0.1) !important;
}

.bc-com-red-opacity-1 {
  border-color: rgba(226, 6, 19, 0.1);
}

.c-com-red-opacity-2 {
  color: rgba(226, 6, 19, 0.2) !important;
  transition: color 300ms;
}

.c-com-red-opacity-2\:h:hover,
.c-com-red-opacity-2\:h:focus,
.c-com-red-opacity-2\:h:active {
  color: rgba(226, 6, 19, 0.2) !important;
}

.bgc-com-red-opacity-2 {
  background-color: rgba(226, 6, 19, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-2\:h:hover,
.bgc-com-red-opacity-2\:h:focus,
.bgc-com-red-opacity-2\:h:active {
  background-color: rgba(226, 6, 19, 0.2) !important;
}

.bc-com-red-opacity-2 {
  border-color: rgba(226, 6, 19, 0.2);
}

.c-com-red-opacity-3 {
  color: rgba(226, 6, 19, 0.3) !important;
  transition: color 300ms;
}

.c-com-red-opacity-3\:h:hover,
.c-com-red-opacity-3\:h:focus,
.c-com-red-opacity-3\:h:active {
  color: rgba(226, 6, 19, 0.3) !important;
}

.bgc-com-red-opacity-3 {
  background-color: rgba(226, 6, 19, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-3\:h:hover,
.bgc-com-red-opacity-3\:h:focus,
.bgc-com-red-opacity-3\:h:active {
  background-color: rgba(226, 6, 19, 0.3) !important;
}

.bc-com-red-opacity-3 {
  border-color: rgba(226, 6, 19, 0.3);
}

.c-com-red-opacity-4 {
  color: rgba(226, 6, 19, 0.4) !important;
  transition: color 300ms;
}

.c-com-red-opacity-4\:h:hover,
.c-com-red-opacity-4\:h:focus,
.c-com-red-opacity-4\:h:active {
  color: rgba(226, 6, 19, 0.4) !important;
}

.bgc-com-red-opacity-4 {
  background-color: rgba(226, 6, 19, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-4\:h:hover,
.bgc-com-red-opacity-4\:h:focus,
.bgc-com-red-opacity-4\:h:active {
  background-color: rgba(226, 6, 19, 0.4) !important;
}

.bc-com-red-opacity-4 {
  border-color: rgba(226, 6, 19, 0.4);
}

.c-com-red-opacity-5 {
  color: rgba(226, 6, 19, 0.5) !important;
  transition: color 300ms;
}

.c-com-red-opacity-5\:h:hover,
.c-com-red-opacity-5\:h:focus,
.c-com-red-opacity-5\:h:active {
  color: rgba(226, 6, 19, 0.5) !important;
}

.bgc-com-red-opacity-5 {
  background-color: rgba(226, 6, 19, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-5\:h:hover,
.bgc-com-red-opacity-5\:h:focus,
.bgc-com-red-opacity-5\:h:active {
  background-color: rgba(226, 6, 19, 0.5) !important;
}

.bc-com-red-opacity-5 {
  border-color: rgba(226, 6, 19, 0.5);
}

.c-com-red-opacity-6 {
  color: rgba(226, 6, 19, 0.6) !important;
  transition: color 300ms;
}

.c-com-red-opacity-6\:h:hover,
.c-com-red-opacity-6\:h:focus,
.c-com-red-opacity-6\:h:active {
  color: rgba(226, 6, 19, 0.6) !important;
}

.bgc-com-red-opacity-6 {
  background-color: rgba(226, 6, 19, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-6\:h:hover,
.bgc-com-red-opacity-6\:h:focus,
.bgc-com-red-opacity-6\:h:active {
  background-color: rgba(226, 6, 19, 0.6) !important;
}

.bc-com-red-opacity-6 {
  border-color: rgba(226, 6, 19, 0.6);
}

.c-com-red-opacity-7 {
  color: rgba(226, 6, 19, 0.7) !important;
  transition: color 300ms;
}

.c-com-red-opacity-7\:h:hover,
.c-com-red-opacity-7\:h:focus,
.c-com-red-opacity-7\:h:active {
  color: rgba(226, 6, 19, 0.7) !important;
}

.bgc-com-red-opacity-7 {
  background-color: rgba(226, 6, 19, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-7\:h:hover,
.bgc-com-red-opacity-7\:h:focus,
.bgc-com-red-opacity-7\:h:active {
  background-color: rgba(226, 6, 19, 0.7) !important;
}

.bc-com-red-opacity-7 {
  border-color: rgba(226, 6, 19, 0.7);
}

.c-com-red-opacity-8 {
  color: rgba(226, 6, 19, 0.8) !important;
  transition: color 300ms;
}

.c-com-red-opacity-8\:h:hover,
.c-com-red-opacity-8\:h:focus,
.c-com-red-opacity-8\:h:active {
  color: rgba(226, 6, 19, 0.8) !important;
}

.bgc-com-red-opacity-8 {
  background-color: rgba(226, 6, 19, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-8\:h:hover,
.bgc-com-red-opacity-8\:h:focus,
.bgc-com-red-opacity-8\:h:active {
  background-color: rgba(226, 6, 19, 0.8) !important;
}

.bc-com-red-opacity-8 {
  border-color: rgba(226, 6, 19, 0.8);
}

.c-com-red-opacity-9 {
  color: rgba(226, 6, 19, 0.9) !important;
  transition: color 300ms;
}

.c-com-red-opacity-9\:h:hover,
.c-com-red-opacity-9\:h:focus,
.c-com-red-opacity-9\:h:active {
  color: rgba(226, 6, 19, 0.9) !important;
}

.bgc-com-red-opacity-9 {
  background-color: rgba(226, 6, 19, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-red-opacity-9\:h:hover,
.bgc-com-red-opacity-9\:h:focus,
.bgc-com-red-opacity-9\:h:active {
  background-color: rgba(226, 6, 19, 0.9) !important;
}

.bc-com-red-opacity-9 {
  border-color: rgba(226, 6, 19, 0.9);
}

.c-com-red {
  color: #e20613 !important;
  transition: color 300ms;
}

.c-com-red\:h:hover,
.c-com-red\:h:focus,
.c-com-red\:h:active {
  color: #e20613 !important;
}

.bgc-com-red {
  background-color: #e20613 !important;
  transition: background-color 300ms;
}

.bgc-com-red\:h:hover,
.bgc-com-red\:h:focus,
.bgc-com-red\:h:active {
  background-color: #e20613 !important;
}

.bc-com-red {
  border-color: #e20613;
}

.c-com-mint-opacity-1 {
  color: rgba(0, 153, 155, 0.1) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-1\:h:hover,
.c-com-mint-opacity-1\:h:focus,
.c-com-mint-opacity-1\:h:active {
  color: rgba(0, 153, 155, 0.1) !important;
}

.bgc-com-mint-opacity-1 {
  background-color: rgba(0, 153, 155, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-1\:h:hover,
.bgc-com-mint-opacity-1\:h:focus,
.bgc-com-mint-opacity-1\:h:active {
  background-color: rgba(0, 153, 155, 0.1) !important;
}

.bc-com-mint-opacity-1 {
  border-color: rgba(0, 153, 155, 0.1);
}

.c-com-mint-opacity-2 {
  color: rgba(0, 153, 155, 0.2) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-2\:h:hover,
.c-com-mint-opacity-2\:h:focus,
.c-com-mint-opacity-2\:h:active {
  color: rgba(0, 153, 155, 0.2) !important;
}

.bgc-com-mint-opacity-2 {
  background-color: rgba(0, 153, 155, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-2\:h:hover,
.bgc-com-mint-opacity-2\:h:focus,
.bgc-com-mint-opacity-2\:h:active {
  background-color: rgba(0, 153, 155, 0.2) !important;
}

.bc-com-mint-opacity-2 {
  border-color: rgba(0, 153, 155, 0.2);
}

.c-com-mint-opacity-3 {
  color: rgba(0, 153, 155, 0.3) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-3\:h:hover,
.c-com-mint-opacity-3\:h:focus,
.c-com-mint-opacity-3\:h:active {
  color: rgba(0, 153, 155, 0.3) !important;
}

.bgc-com-mint-opacity-3 {
  background-color: rgba(0, 153, 155, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-3\:h:hover,
.bgc-com-mint-opacity-3\:h:focus,
.bgc-com-mint-opacity-3\:h:active {
  background-color: rgba(0, 153, 155, 0.3) !important;
}

.bc-com-mint-opacity-3 {
  border-color: rgba(0, 153, 155, 0.3);
}

.c-com-mint-opacity-4 {
  color: rgba(0, 153, 155, 0.4) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-4\:h:hover,
.c-com-mint-opacity-4\:h:focus,
.c-com-mint-opacity-4\:h:active {
  color: rgba(0, 153, 155, 0.4) !important;
}

.bgc-com-mint-opacity-4 {
  background-color: rgba(0, 153, 155, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-4\:h:hover,
.bgc-com-mint-opacity-4\:h:focus,
.bgc-com-mint-opacity-4\:h:active {
  background-color: rgba(0, 153, 155, 0.4) !important;
}

.bc-com-mint-opacity-4 {
  border-color: rgba(0, 153, 155, 0.4);
}

.c-com-mint-opacity-5 {
  color: rgba(0, 153, 155, 0.5) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-5\:h:hover,
.c-com-mint-opacity-5\:h:focus,
.c-com-mint-opacity-5\:h:active {
  color: rgba(0, 153, 155, 0.5) !important;
}

.bgc-com-mint-opacity-5 {
  background-color: rgba(0, 153, 155, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-5\:h:hover,
.bgc-com-mint-opacity-5\:h:focus,
.bgc-com-mint-opacity-5\:h:active {
  background-color: rgba(0, 153, 155, 0.5) !important;
}

.bc-com-mint-opacity-5 {
  border-color: rgba(0, 153, 155, 0.5);
}

.c-com-mint-opacity-6 {
  color: rgba(0, 153, 155, 0.6) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-6\:h:hover,
.c-com-mint-opacity-6\:h:focus,
.c-com-mint-opacity-6\:h:active {
  color: rgba(0, 153, 155, 0.6) !important;
}

.bgc-com-mint-opacity-6 {
  background-color: rgba(0, 153, 155, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-6\:h:hover,
.bgc-com-mint-opacity-6\:h:focus,
.bgc-com-mint-opacity-6\:h:active {
  background-color: rgba(0, 153, 155, 0.6) !important;
}

.bc-com-mint-opacity-6 {
  border-color: rgba(0, 153, 155, 0.6);
}

.c-com-mint-opacity-7 {
  color: rgba(0, 153, 155, 0.7) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-7\:h:hover,
.c-com-mint-opacity-7\:h:focus,
.c-com-mint-opacity-7\:h:active {
  color: rgba(0, 153, 155, 0.7) !important;
}

.bgc-com-mint-opacity-7 {
  background-color: rgba(0, 153, 155, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-7\:h:hover,
.bgc-com-mint-opacity-7\:h:focus,
.bgc-com-mint-opacity-7\:h:active {
  background-color: rgba(0, 153, 155, 0.7) !important;
}

.bc-com-mint-opacity-7 {
  border-color: rgba(0, 153, 155, 0.7);
}

.c-com-mint-opacity-8 {
  color: rgba(0, 153, 155, 0.8) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-8\:h:hover,
.c-com-mint-opacity-8\:h:focus,
.c-com-mint-opacity-8\:h:active {
  color: rgba(0, 153, 155, 0.8) !important;
}

.bgc-com-mint-opacity-8 {
  background-color: rgba(0, 153, 155, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-8\:h:hover,
.bgc-com-mint-opacity-8\:h:focus,
.bgc-com-mint-opacity-8\:h:active {
  background-color: rgba(0, 153, 155, 0.8) !important;
}

.bc-com-mint-opacity-8 {
  border-color: rgba(0, 153, 155, 0.8);
}

.c-com-mint-opacity-9 {
  color: rgba(0, 153, 155, 0.9) !important;
  transition: color 300ms;
}

.c-com-mint-opacity-9\:h:hover,
.c-com-mint-opacity-9\:h:focus,
.c-com-mint-opacity-9\:h:active {
  color: rgba(0, 153, 155, 0.9) !important;
}

.bgc-com-mint-opacity-9 {
  background-color: rgba(0, 153, 155, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-mint-opacity-9\:h:hover,
.bgc-com-mint-opacity-9\:h:focus,
.bgc-com-mint-opacity-9\:h:active {
  background-color: rgba(0, 153, 155, 0.9) !important;
}

.bc-com-mint-opacity-9 {
  border-color: rgba(0, 153, 155, 0.9);
}

.c-com-mint {
  color: #00999b !important;
  transition: color 300ms;
}

.c-com-mint\:h:hover,
.c-com-mint\:h:focus,
.c-com-mint\:h:active {
  color: #00999b !important;
}

.bgc-com-mint {
  background-color: #00999b !important;
  transition: background-color 300ms;
}

.bgc-com-mint\:h:hover,
.bgc-com-mint\:h:focus,
.bgc-com-mint\:h:active {
  background-color: #00999b !important;
}

.bc-com-mint {
  border-color: #00999b;
}

.c-com-blue-opacity-1 {
  color: rgba(0, 75, 155, 0.1) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-1\:h:hover,
.c-com-blue-opacity-1\:h:focus,
.c-com-blue-opacity-1\:h:active {
  color: rgba(0, 75, 155, 0.1) !important;
}

.bgc-com-blue-opacity-1 {
  background-color: rgba(0, 75, 155, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-1\:h:hover,
.bgc-com-blue-opacity-1\:h:focus,
.bgc-com-blue-opacity-1\:h:active {
  background-color: rgba(0, 75, 155, 0.1) !important;
}

.bc-com-blue-opacity-1 {
  border-color: rgba(0, 75, 155, 0.1);
}

.c-com-blue-opacity-2 {
  color: rgba(0, 75, 155, 0.2) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-2\:h:hover,
.c-com-blue-opacity-2\:h:focus,
.c-com-blue-opacity-2\:h:active {
  color: rgba(0, 75, 155, 0.2) !important;
}

.bgc-com-blue-opacity-2 {
  background-color: rgba(0, 75, 155, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-2\:h:hover,
.bgc-com-blue-opacity-2\:h:focus,
.bgc-com-blue-opacity-2\:h:active {
  background-color: rgba(0, 75, 155, 0.2) !important;
}

.bc-com-blue-opacity-2 {
  border-color: rgba(0, 75, 155, 0.2);
}

.c-com-blue-opacity-3 {
  color: rgba(0, 75, 155, 0.3) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-3\:h:hover,
.c-com-blue-opacity-3\:h:focus,
.c-com-blue-opacity-3\:h:active {
  color: rgba(0, 75, 155, 0.3) !important;
}

.bgc-com-blue-opacity-3 {
  background-color: rgba(0, 75, 155, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-3\:h:hover,
.bgc-com-blue-opacity-3\:h:focus,
.bgc-com-blue-opacity-3\:h:active {
  background-color: rgba(0, 75, 155, 0.3) !important;
}

.bc-com-blue-opacity-3 {
  border-color: rgba(0, 75, 155, 0.3);
}

.c-com-blue-opacity-4 {
  color: rgba(0, 75, 155, 0.4) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-4\:h:hover,
.c-com-blue-opacity-4\:h:focus,
.c-com-blue-opacity-4\:h:active {
  color: rgba(0, 75, 155, 0.4) !important;
}

.bgc-com-blue-opacity-4 {
  background-color: rgba(0, 75, 155, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-4\:h:hover,
.bgc-com-blue-opacity-4\:h:focus,
.bgc-com-blue-opacity-4\:h:active {
  background-color: rgba(0, 75, 155, 0.4) !important;
}

.bc-com-blue-opacity-4 {
  border-color: rgba(0, 75, 155, 0.4);
}

.c-com-blue-opacity-5 {
  color: rgba(0, 75, 155, 0.5) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-5\:h:hover,
.c-com-blue-opacity-5\:h:focus,
.c-com-blue-opacity-5\:h:active {
  color: rgba(0, 75, 155, 0.5) !important;
}

.bgc-com-blue-opacity-5 {
  background-color: rgba(0, 75, 155, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-5\:h:hover,
.bgc-com-blue-opacity-5\:h:focus,
.bgc-com-blue-opacity-5\:h:active {
  background-color: rgba(0, 75, 155, 0.5) !important;
}

.bc-com-blue-opacity-5 {
  border-color: rgba(0, 75, 155, 0.5);
}

.c-com-blue-opacity-6 {
  color: rgba(0, 75, 155, 0.6) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-6\:h:hover,
.c-com-blue-opacity-6\:h:focus,
.c-com-blue-opacity-6\:h:active {
  color: rgba(0, 75, 155, 0.6) !important;
}

.bgc-com-blue-opacity-6 {
  background-color: rgba(0, 75, 155, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-6\:h:hover,
.bgc-com-blue-opacity-6\:h:focus,
.bgc-com-blue-opacity-6\:h:active {
  background-color: rgba(0, 75, 155, 0.6) !important;
}

.bc-com-blue-opacity-6 {
  border-color: rgba(0, 75, 155, 0.6);
}

.c-com-blue-opacity-7 {
  color: rgba(0, 75, 155, 0.7) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-7\:h:hover,
.c-com-blue-opacity-7\:h:focus,
.c-com-blue-opacity-7\:h:active {
  color: rgba(0, 75, 155, 0.7) !important;
}

.bgc-com-blue-opacity-7 {
  background-color: rgba(0, 75, 155, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-7\:h:hover,
.bgc-com-blue-opacity-7\:h:focus,
.bgc-com-blue-opacity-7\:h:active {
  background-color: rgba(0, 75, 155, 0.7) !important;
}

.bc-com-blue-opacity-7 {
  border-color: rgba(0, 75, 155, 0.7);
}

.c-com-blue-opacity-8 {
  color: rgba(0, 75, 155, 0.8) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-8\:h:hover,
.c-com-blue-opacity-8\:h:focus,
.c-com-blue-opacity-8\:h:active {
  color: rgba(0, 75, 155, 0.8) !important;
}

.bgc-com-blue-opacity-8 {
  background-color: rgba(0, 75, 155, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-8\:h:hover,
.bgc-com-blue-opacity-8\:h:focus,
.bgc-com-blue-opacity-8\:h:active {
  background-color: rgba(0, 75, 155, 0.8) !important;
}

.bc-com-blue-opacity-8 {
  border-color: rgba(0, 75, 155, 0.8);
}

.c-com-blue-opacity-9 {
  color: rgba(0, 75, 155, 0.9) !important;
  transition: color 300ms;
}

.c-com-blue-opacity-9\:h:hover,
.c-com-blue-opacity-9\:h:focus,
.c-com-blue-opacity-9\:h:active {
  color: rgba(0, 75, 155, 0.9) !important;
}

.bgc-com-blue-opacity-9 {
  background-color: rgba(0, 75, 155, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-blue-opacity-9\:h:hover,
.bgc-com-blue-opacity-9\:h:focus,
.bgc-com-blue-opacity-9\:h:active {
  background-color: rgba(0, 75, 155, 0.9) !important;
}

.bc-com-blue-opacity-9 {
  border-color: rgba(0, 75, 155, 0.9);
}

.c-com-blue {
  color: #004b9b !important;
  transition: color 300ms;
}

.c-com-blue\:h:hover,
.c-com-blue\:h:focus,
.c-com-blue\:h:active {
  color: #004b9b !important;
}

.bgc-com-blue {
  background-color: #004b9b !important;
  transition: background-color 300ms;
}

.bgc-com-blue\:h:hover,
.bgc-com-blue\:h:focus,
.bgc-com-blue\:h:active {
  background-color: #004b9b !important;
}

.bc-com-blue {
  border-color: #004b9b;
}

.c-com-orange-opacity-1 {
  color: rgba(225, 69, 4, 0.1) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-1\:h:hover,
.c-com-orange-opacity-1\:h:focus,
.c-com-orange-opacity-1\:h:active {
  color: rgba(225, 69, 4, 0.1) !important;
}

.bgc-com-orange-opacity-1 {
  background-color: rgba(225, 69, 4, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-1\:h:hover,
.bgc-com-orange-opacity-1\:h:focus,
.bgc-com-orange-opacity-1\:h:active {
  background-color: rgba(225, 69, 4, 0.1) !important;
}

.bc-com-orange-opacity-1 {
  border-color: rgba(225, 69, 4, 0.1);
}

.c-com-orange-opacity-2 {
  color: rgba(225, 69, 4, 0.2) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-2\:h:hover,
.c-com-orange-opacity-2\:h:focus,
.c-com-orange-opacity-2\:h:active {
  color: rgba(225, 69, 4, 0.2) !important;
}

.bgc-com-orange-opacity-2 {
  background-color: rgba(225, 69, 4, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-2\:h:hover,
.bgc-com-orange-opacity-2\:h:focus,
.bgc-com-orange-opacity-2\:h:active {
  background-color: rgba(225, 69, 4, 0.2) !important;
}

.bc-com-orange-opacity-2 {
  border-color: rgba(225, 69, 4, 0.2);
}

.c-com-orange-opacity-3 {
  color: rgba(225, 69, 4, 0.3) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-3\:h:hover,
.c-com-orange-opacity-3\:h:focus,
.c-com-orange-opacity-3\:h:active {
  color: rgba(225, 69, 4, 0.3) !important;
}

.bgc-com-orange-opacity-3 {
  background-color: rgba(225, 69, 4, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-3\:h:hover,
.bgc-com-orange-opacity-3\:h:focus,
.bgc-com-orange-opacity-3\:h:active {
  background-color: rgba(225, 69, 4, 0.3) !important;
}

.bc-com-orange-opacity-3 {
  border-color: rgba(225, 69, 4, 0.3);
}

.c-com-orange-opacity-4 {
  color: rgba(225, 69, 4, 0.4) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-4\:h:hover,
.c-com-orange-opacity-4\:h:focus,
.c-com-orange-opacity-4\:h:active {
  color: rgba(225, 69, 4, 0.4) !important;
}

.bgc-com-orange-opacity-4 {
  background-color: rgba(225, 69, 4, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-4\:h:hover,
.bgc-com-orange-opacity-4\:h:focus,
.bgc-com-orange-opacity-4\:h:active {
  background-color: rgba(225, 69, 4, 0.4) !important;
}

.bc-com-orange-opacity-4 {
  border-color: rgba(225, 69, 4, 0.4);
}

.c-com-orange-opacity-5 {
  color: rgba(225, 69, 4, 0.5) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-5\:h:hover,
.c-com-orange-opacity-5\:h:focus,
.c-com-orange-opacity-5\:h:active {
  color: rgba(225, 69, 4, 0.5) !important;
}

.bgc-com-orange-opacity-5 {
  background-color: rgba(225, 69, 4, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-5\:h:hover,
.bgc-com-orange-opacity-5\:h:focus,
.bgc-com-orange-opacity-5\:h:active {
  background-color: rgba(225, 69, 4, 0.5) !important;
}

.bc-com-orange-opacity-5 {
  border-color: rgba(225, 69, 4, 0.5);
}

.c-com-orange-opacity-6 {
  color: rgba(225, 69, 4, 0.6) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-6\:h:hover,
.c-com-orange-opacity-6\:h:focus,
.c-com-orange-opacity-6\:h:active {
  color: rgba(225, 69, 4, 0.6) !important;
}

.bgc-com-orange-opacity-6 {
  background-color: rgba(225, 69, 4, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-6\:h:hover,
.bgc-com-orange-opacity-6\:h:focus,
.bgc-com-orange-opacity-6\:h:active {
  background-color: rgba(225, 69, 4, 0.6) !important;
}

.bc-com-orange-opacity-6 {
  border-color: rgba(225, 69, 4, 0.6);
}

.c-com-orange-opacity-7 {
  color: rgba(225, 69, 4, 0.7) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-7\:h:hover,
.c-com-orange-opacity-7\:h:focus,
.c-com-orange-opacity-7\:h:active {
  color: rgba(225, 69, 4, 0.7) !important;
}

.bgc-com-orange-opacity-7 {
  background-color: rgba(225, 69, 4, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-7\:h:hover,
.bgc-com-orange-opacity-7\:h:focus,
.bgc-com-orange-opacity-7\:h:active {
  background-color: rgba(225, 69, 4, 0.7) !important;
}

.bc-com-orange-opacity-7 {
  border-color: rgba(225, 69, 4, 0.7);
}

.c-com-orange-opacity-8 {
  color: rgba(225, 69, 4, 0.8) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-8\:h:hover,
.c-com-orange-opacity-8\:h:focus,
.c-com-orange-opacity-8\:h:active {
  color: rgba(225, 69, 4, 0.8) !important;
}

.bgc-com-orange-opacity-8 {
  background-color: rgba(225, 69, 4, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-8\:h:hover,
.bgc-com-orange-opacity-8\:h:focus,
.bgc-com-orange-opacity-8\:h:active {
  background-color: rgba(225, 69, 4, 0.8) !important;
}

.bc-com-orange-opacity-8 {
  border-color: rgba(225, 69, 4, 0.8);
}

.c-com-orange-opacity-9 {
  color: rgba(225, 69, 4, 0.9) !important;
  transition: color 300ms;
}

.c-com-orange-opacity-9\:h:hover,
.c-com-orange-opacity-9\:h:focus,
.c-com-orange-opacity-9\:h:active {
  color: rgba(225, 69, 4, 0.9) !important;
}

.bgc-com-orange-opacity-9 {
  background-color: rgba(225, 69, 4, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-orange-opacity-9\:h:hover,
.bgc-com-orange-opacity-9\:h:focus,
.bgc-com-orange-opacity-9\:h:active {
  background-color: rgba(225, 69, 4, 0.9) !important;
}

.bc-com-orange-opacity-9 {
  border-color: rgba(225, 69, 4, 0.9);
}

.c-com-orange {
  color: #e14504 !important;
  transition: color 300ms;
}

.c-com-orange\:h:hover,
.c-com-orange\:h:focus,
.c-com-orange\:h:active {
  color: #e14504 !important;
}

.bgc-com-orange {
  background-color: #e14504 !important;
  transition: background-color 300ms;
}

.bgc-com-orange\:h:hover,
.bgc-com-orange\:h:focus,
.bgc-com-orange\:h:active {
  background-color: #e14504 !important;
}

.bc-com-orange {
  border-color: #e14504;
}

.c-com-gold-opacity-1 {
  color: rgba(242, 145, 0, 0.1) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-1\:h:hover,
.c-com-gold-opacity-1\:h:focus,
.c-com-gold-opacity-1\:h:active {
  color: rgba(242, 145, 0, 0.1) !important;
}

.bgc-com-gold-opacity-1 {
  background-color: rgba(242, 145, 0, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-1\:h:hover,
.bgc-com-gold-opacity-1\:h:focus,
.bgc-com-gold-opacity-1\:h:active {
  background-color: rgba(242, 145, 0, 0.1) !important;
}

.bc-com-gold-opacity-1 {
  border-color: rgba(242, 145, 0, 0.1);
}

.c-com-gold-opacity-2 {
  color: rgba(242, 145, 0, 0.2) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-2\:h:hover,
.c-com-gold-opacity-2\:h:focus,
.c-com-gold-opacity-2\:h:active {
  color: rgba(242, 145, 0, 0.2) !important;
}

.bgc-com-gold-opacity-2 {
  background-color: rgba(242, 145, 0, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-2\:h:hover,
.bgc-com-gold-opacity-2\:h:focus,
.bgc-com-gold-opacity-2\:h:active {
  background-color: rgba(242, 145, 0, 0.2) !important;
}

.bc-com-gold-opacity-2 {
  border-color: rgba(242, 145, 0, 0.2);
}

.c-com-gold-opacity-3 {
  color: rgba(242, 145, 0, 0.3) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-3\:h:hover,
.c-com-gold-opacity-3\:h:focus,
.c-com-gold-opacity-3\:h:active {
  color: rgba(242, 145, 0, 0.3) !important;
}

.bgc-com-gold-opacity-3 {
  background-color: rgba(242, 145, 0, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-3\:h:hover,
.bgc-com-gold-opacity-3\:h:focus,
.bgc-com-gold-opacity-3\:h:active {
  background-color: rgba(242, 145, 0, 0.3) !important;
}

.bc-com-gold-opacity-3 {
  border-color: rgba(242, 145, 0, 0.3);
}

.c-com-gold-opacity-4 {
  color: rgba(242, 145, 0, 0.4) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-4\:h:hover,
.c-com-gold-opacity-4\:h:focus,
.c-com-gold-opacity-4\:h:active {
  color: rgba(242, 145, 0, 0.4) !important;
}

.bgc-com-gold-opacity-4 {
  background-color: rgba(242, 145, 0, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-4\:h:hover,
.bgc-com-gold-opacity-4\:h:focus,
.bgc-com-gold-opacity-4\:h:active {
  background-color: rgba(242, 145, 0, 0.4) !important;
}

.bc-com-gold-opacity-4 {
  border-color: rgba(242, 145, 0, 0.4);
}

.c-com-gold-opacity-5 {
  color: rgba(242, 145, 0, 0.5) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-5\:h:hover,
.c-com-gold-opacity-5\:h:focus,
.c-com-gold-opacity-5\:h:active {
  color: rgba(242, 145, 0, 0.5) !important;
}

.bgc-com-gold-opacity-5 {
  background-color: rgba(242, 145, 0, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-5\:h:hover,
.bgc-com-gold-opacity-5\:h:focus,
.bgc-com-gold-opacity-5\:h:active {
  background-color: rgba(242, 145, 0, 0.5) !important;
}

.bc-com-gold-opacity-5 {
  border-color: rgba(242, 145, 0, 0.5);
}

.c-com-gold-opacity-6 {
  color: rgba(242, 145, 0, 0.6) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-6\:h:hover,
.c-com-gold-opacity-6\:h:focus,
.c-com-gold-opacity-6\:h:active {
  color: rgba(242, 145, 0, 0.6) !important;
}

.bgc-com-gold-opacity-6 {
  background-color: rgba(242, 145, 0, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-6\:h:hover,
.bgc-com-gold-opacity-6\:h:focus,
.bgc-com-gold-opacity-6\:h:active {
  background-color: rgba(242, 145, 0, 0.6) !important;
}

.bc-com-gold-opacity-6 {
  border-color: rgba(242, 145, 0, 0.6);
}

.c-com-gold-opacity-7 {
  color: rgba(242, 145, 0, 0.7) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-7\:h:hover,
.c-com-gold-opacity-7\:h:focus,
.c-com-gold-opacity-7\:h:active {
  color: rgba(242, 145, 0, 0.7) !important;
}

.bgc-com-gold-opacity-7 {
  background-color: rgba(242, 145, 0, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-7\:h:hover,
.bgc-com-gold-opacity-7\:h:focus,
.bgc-com-gold-opacity-7\:h:active {
  background-color: rgba(242, 145, 0, 0.7) !important;
}

.bc-com-gold-opacity-7 {
  border-color: rgba(242, 145, 0, 0.7);
}

.c-com-gold-opacity-8 {
  color: rgba(242, 145, 0, 0.8) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-8\:h:hover,
.c-com-gold-opacity-8\:h:focus,
.c-com-gold-opacity-8\:h:active {
  color: rgba(242, 145, 0, 0.8) !important;
}

.bgc-com-gold-opacity-8 {
  background-color: rgba(242, 145, 0, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-8\:h:hover,
.bgc-com-gold-opacity-8\:h:focus,
.bgc-com-gold-opacity-8\:h:active {
  background-color: rgba(242, 145, 0, 0.8) !important;
}

.bc-com-gold-opacity-8 {
  border-color: rgba(242, 145, 0, 0.8);
}

.c-com-gold-opacity-9 {
  color: rgba(242, 145, 0, 0.9) !important;
  transition: color 300ms;
}

.c-com-gold-opacity-9\:h:hover,
.c-com-gold-opacity-9\:h:focus,
.c-com-gold-opacity-9\:h:active {
  color: rgba(242, 145, 0, 0.9) !important;
}

.bgc-com-gold-opacity-9 {
  background-color: rgba(242, 145, 0, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-gold-opacity-9\:h:hover,
.bgc-com-gold-opacity-9\:h:focus,
.bgc-com-gold-opacity-9\:h:active {
  background-color: rgba(242, 145, 0, 0.9) !important;
}

.bc-com-gold-opacity-9 {
  border-color: rgba(242, 145, 0, 0.9);
}

.c-com-gold {
  color: #f29100 !important;
  transition: color 300ms;
}

.c-com-gold\:h:hover,
.c-com-gold\:h:focus,
.c-com-gold\:h:active {
  color: #f29100 !important;
}

.bgc-com-gold {
  background-color: #f29100 !important;
  transition: background-color 300ms;
}

.bgc-com-gold\:h:hover,
.bgc-com-gold\:h:focus,
.bgc-com-gold\:h:active {
  background-color: #f29100 !important;
}

.bc-com-gold {
  border-color: #f29100;
}

.c-com-yellow-opacity-1 {
  color: rgba(255, 218, 39, 0.1) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-1\:h:hover,
.c-com-yellow-opacity-1\:h:focus,
.c-com-yellow-opacity-1\:h:active {
  color: rgba(255, 218, 39, 0.1) !important;
}

.bgc-com-yellow-opacity-1 {
  background-color: rgba(255, 218, 39, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-1\:h:hover,
.bgc-com-yellow-opacity-1\:h:focus,
.bgc-com-yellow-opacity-1\:h:active {
  background-color: rgba(255, 218, 39, 0.1) !important;
}

.bc-com-yellow-opacity-1 {
  border-color: rgba(255, 218, 39, 0.1);
}

.c-com-yellow-opacity-2 {
  color: rgba(255, 218, 39, 0.2) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-2\:h:hover,
.c-com-yellow-opacity-2\:h:focus,
.c-com-yellow-opacity-2\:h:active {
  color: rgba(255, 218, 39, 0.2) !important;
}

.bgc-com-yellow-opacity-2 {
  background-color: rgba(255, 218, 39, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-2\:h:hover,
.bgc-com-yellow-opacity-2\:h:focus,
.bgc-com-yellow-opacity-2\:h:active {
  background-color: rgba(255, 218, 39, 0.2) !important;
}

.bc-com-yellow-opacity-2 {
  border-color: rgba(255, 218, 39, 0.2);
}

.c-com-yellow-opacity-3 {
  color: rgba(255, 218, 39, 0.3) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-3\:h:hover,
.c-com-yellow-opacity-3\:h:focus,
.c-com-yellow-opacity-3\:h:active {
  color: rgba(255, 218, 39, 0.3) !important;
}

.bgc-com-yellow-opacity-3 {
  background-color: rgba(255, 218, 39, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-3\:h:hover,
.bgc-com-yellow-opacity-3\:h:focus,
.bgc-com-yellow-opacity-3\:h:active {
  background-color: rgba(255, 218, 39, 0.3) !important;
}

.bc-com-yellow-opacity-3 {
  border-color: rgba(255, 218, 39, 0.3);
}

.c-com-yellow-opacity-4 {
  color: rgba(255, 218, 39, 0.4) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-4\:h:hover,
.c-com-yellow-opacity-4\:h:focus,
.c-com-yellow-opacity-4\:h:active {
  color: rgba(255, 218, 39, 0.4) !important;
}

.bgc-com-yellow-opacity-4 {
  background-color: rgba(255, 218, 39, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-4\:h:hover,
.bgc-com-yellow-opacity-4\:h:focus,
.bgc-com-yellow-opacity-4\:h:active {
  background-color: rgba(255, 218, 39, 0.4) !important;
}

.bc-com-yellow-opacity-4 {
  border-color: rgba(255, 218, 39, 0.4);
}

.c-com-yellow-opacity-5 {
  color: rgba(255, 218, 39, 0.5) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-5\:h:hover,
.c-com-yellow-opacity-5\:h:focus,
.c-com-yellow-opacity-5\:h:active {
  color: rgba(255, 218, 39, 0.5) !important;
}

.bgc-com-yellow-opacity-5 {
  background-color: rgba(255, 218, 39, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-5\:h:hover,
.bgc-com-yellow-opacity-5\:h:focus,
.bgc-com-yellow-opacity-5\:h:active {
  background-color: rgba(255, 218, 39, 0.5) !important;
}

.bc-com-yellow-opacity-5 {
  border-color: rgba(255, 218, 39, 0.5);
}

.c-com-yellow-opacity-6 {
  color: rgba(255, 218, 39, 0.6) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-6\:h:hover,
.c-com-yellow-opacity-6\:h:focus,
.c-com-yellow-opacity-6\:h:active {
  color: rgba(255, 218, 39, 0.6) !important;
}

.bgc-com-yellow-opacity-6 {
  background-color: rgba(255, 218, 39, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-6\:h:hover,
.bgc-com-yellow-opacity-6\:h:focus,
.bgc-com-yellow-opacity-6\:h:active {
  background-color: rgba(255, 218, 39, 0.6) !important;
}

.bc-com-yellow-opacity-6 {
  border-color: rgba(255, 218, 39, 0.6);
}

.c-com-yellow-opacity-7 {
  color: rgba(255, 218, 39, 0.7) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-7\:h:hover,
.c-com-yellow-opacity-7\:h:focus,
.c-com-yellow-opacity-7\:h:active {
  color: rgba(255, 218, 39, 0.7) !important;
}

.bgc-com-yellow-opacity-7 {
  background-color: rgba(255, 218, 39, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-7\:h:hover,
.bgc-com-yellow-opacity-7\:h:focus,
.bgc-com-yellow-opacity-7\:h:active {
  background-color: rgba(255, 218, 39, 0.7) !important;
}

.bc-com-yellow-opacity-7 {
  border-color: rgba(255, 218, 39, 0.7);
}

.c-com-yellow-opacity-8 {
  color: rgba(255, 218, 39, 0.8) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-8\:h:hover,
.c-com-yellow-opacity-8\:h:focus,
.c-com-yellow-opacity-8\:h:active {
  color: rgba(255, 218, 39, 0.8) !important;
}

.bgc-com-yellow-opacity-8 {
  background-color: rgba(255, 218, 39, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-8\:h:hover,
.bgc-com-yellow-opacity-8\:h:focus,
.bgc-com-yellow-opacity-8\:h:active {
  background-color: rgba(255, 218, 39, 0.8) !important;
}

.bc-com-yellow-opacity-8 {
  border-color: rgba(255, 218, 39, 0.8);
}

.c-com-yellow-opacity-9 {
  color: rgba(255, 218, 39, 0.9) !important;
  transition: color 300ms;
}

.c-com-yellow-opacity-9\:h:hover,
.c-com-yellow-opacity-9\:h:focus,
.c-com-yellow-opacity-9\:h:active {
  color: rgba(255, 218, 39, 0.9) !important;
}

.bgc-com-yellow-opacity-9 {
  background-color: rgba(255, 218, 39, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-com-yellow-opacity-9\:h:hover,
.bgc-com-yellow-opacity-9\:h:focus,
.bgc-com-yellow-opacity-9\:h:active {
  background-color: rgba(255, 218, 39, 0.9) !important;
}

.bc-com-yellow-opacity-9 {
  border-color: rgba(255, 218, 39, 0.9);
}

.c-com-yellow {
  color: #ffda27 !important;
  transition: color 300ms;
}

.c-com-yellow\:h:hover,
.c-com-yellow\:h:focus,
.c-com-yellow\:h:active {
  color: #ffda27 !important;
}

.bgc-com-yellow {
  background-color: #ffda27 !important;
  transition: background-color 300ms;
}

.bgc-com-yellow\:h:hover,
.bgc-com-yellow\:h:focus,
.bgc-com-yellow\:h:active {
  background-color: #ffda27 !important;
}

.bc-com-yellow {
  border-color: #ffda27;
}

.c-sc-navy-2-opacity-1 {
  color: rgba(1, 28, 67, 0.1) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-1\:h:hover,
.c-sc-navy-2-opacity-1\:h:focus,
.c-sc-navy-2-opacity-1\:h:active {
  color: rgba(1, 28, 67, 0.1) !important;
}

.bgc-sc-navy-2-opacity-1 {
  background-color: rgba(1, 28, 67, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-1\:h:hover,
.bgc-sc-navy-2-opacity-1\:h:focus,
.bgc-sc-navy-2-opacity-1\:h:active {
  background-color: rgba(1, 28, 67, 0.1) !important;
}

.bc-sc-navy-2-opacity-1 {
  border-color: rgba(1, 28, 67, 0.1);
}

.c-sc-navy-2-opacity-2 {
  color: rgba(1, 28, 67, 0.2) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-2\:h:hover,
.c-sc-navy-2-opacity-2\:h:focus,
.c-sc-navy-2-opacity-2\:h:active {
  color: rgba(1, 28, 67, 0.2) !important;
}

.bgc-sc-navy-2-opacity-2 {
  background-color: rgba(1, 28, 67, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-2\:h:hover,
.bgc-sc-navy-2-opacity-2\:h:focus,
.bgc-sc-navy-2-opacity-2\:h:active {
  background-color: rgba(1, 28, 67, 0.2) !important;
}

.bc-sc-navy-2-opacity-2 {
  border-color: rgba(1, 28, 67, 0.2);
}

.c-sc-navy-2-opacity-3 {
  color: rgba(1, 28, 67, 0.3) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-3\:h:hover,
.c-sc-navy-2-opacity-3\:h:focus,
.c-sc-navy-2-opacity-3\:h:active {
  color: rgba(1, 28, 67, 0.3) !important;
}

.bgc-sc-navy-2-opacity-3 {
  background-color: rgba(1, 28, 67, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-3\:h:hover,
.bgc-sc-navy-2-opacity-3\:h:focus,
.bgc-sc-navy-2-opacity-3\:h:active {
  background-color: rgba(1, 28, 67, 0.3) !important;
}

.bc-sc-navy-2-opacity-3 {
  border-color: rgba(1, 28, 67, 0.3);
}

.c-sc-navy-2-opacity-4 {
  color: rgba(1, 28, 67, 0.4) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-4\:h:hover,
.c-sc-navy-2-opacity-4\:h:focus,
.c-sc-navy-2-opacity-4\:h:active {
  color: rgba(1, 28, 67, 0.4) !important;
}

.bgc-sc-navy-2-opacity-4 {
  background-color: rgba(1, 28, 67, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-4\:h:hover,
.bgc-sc-navy-2-opacity-4\:h:focus,
.bgc-sc-navy-2-opacity-4\:h:active {
  background-color: rgba(1, 28, 67, 0.4) !important;
}

.bc-sc-navy-2-opacity-4 {
  border-color: rgba(1, 28, 67, 0.4);
}

.c-sc-navy-2-opacity-5 {
  color: rgba(1, 28, 67, 0.5) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-5\:h:hover,
.c-sc-navy-2-opacity-5\:h:focus,
.c-sc-navy-2-opacity-5\:h:active {
  color: rgba(1, 28, 67, 0.5) !important;
}

.bgc-sc-navy-2-opacity-5 {
  background-color: rgba(1, 28, 67, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-5\:h:hover,
.bgc-sc-navy-2-opacity-5\:h:focus,
.bgc-sc-navy-2-opacity-5\:h:active {
  background-color: rgba(1, 28, 67, 0.5) !important;
}

.bc-sc-navy-2-opacity-5 {
  border-color: rgba(1, 28, 67, 0.5);
}

.c-sc-navy-2-opacity-6 {
  color: rgba(1, 28, 67, 0.6) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-6\:h:hover,
.c-sc-navy-2-opacity-6\:h:focus,
.c-sc-navy-2-opacity-6\:h:active {
  color: rgba(1, 28, 67, 0.6) !important;
}

.bgc-sc-navy-2-opacity-6 {
  background-color: rgba(1, 28, 67, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-6\:h:hover,
.bgc-sc-navy-2-opacity-6\:h:focus,
.bgc-sc-navy-2-opacity-6\:h:active {
  background-color: rgba(1, 28, 67, 0.6) !important;
}

.bc-sc-navy-2-opacity-6 {
  border-color: rgba(1, 28, 67, 0.6);
}

.c-sc-navy-2-opacity-7 {
  color: rgba(1, 28, 67, 0.7) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-7\:h:hover,
.c-sc-navy-2-opacity-7\:h:focus,
.c-sc-navy-2-opacity-7\:h:active {
  color: rgba(1, 28, 67, 0.7) !important;
}

.bgc-sc-navy-2-opacity-7 {
  background-color: rgba(1, 28, 67, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-7\:h:hover,
.bgc-sc-navy-2-opacity-7\:h:focus,
.bgc-sc-navy-2-opacity-7\:h:active {
  background-color: rgba(1, 28, 67, 0.7) !important;
}

.bc-sc-navy-2-opacity-7 {
  border-color: rgba(1, 28, 67, 0.7);
}

.c-sc-navy-2-opacity-8 {
  color: rgba(1, 28, 67, 0.8) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-8\:h:hover,
.c-sc-navy-2-opacity-8\:h:focus,
.c-sc-navy-2-opacity-8\:h:active {
  color: rgba(1, 28, 67, 0.8) !important;
}

.bgc-sc-navy-2-opacity-8 {
  background-color: rgba(1, 28, 67, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-8\:h:hover,
.bgc-sc-navy-2-opacity-8\:h:focus,
.bgc-sc-navy-2-opacity-8\:h:active {
  background-color: rgba(1, 28, 67, 0.8) !important;
}

.bc-sc-navy-2-opacity-8 {
  border-color: rgba(1, 28, 67, 0.8);
}

.c-sc-navy-2-opacity-9 {
  color: rgba(1, 28, 67, 0.9) !important;
  transition: color 300ms;
}

.c-sc-navy-2-opacity-9\:h:hover,
.c-sc-navy-2-opacity-9\:h:focus,
.c-sc-navy-2-opacity-9\:h:active {
  color: rgba(1, 28, 67, 0.9) !important;
}

.bgc-sc-navy-2-opacity-9 {
  background-color: rgba(1, 28, 67, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2-opacity-9\:h:hover,
.bgc-sc-navy-2-opacity-9\:h:focus,
.bgc-sc-navy-2-opacity-9\:h:active {
  background-color: rgba(1, 28, 67, 0.9) !important;
}

.bc-sc-navy-2-opacity-9 {
  border-color: rgba(1, 28, 67, 0.9);
}

.c-sc-navy-2 {
  color: #011c43 !important;
  transition: color 300ms;
}

.c-sc-navy-2\:h:hover,
.c-sc-navy-2\:h:focus,
.c-sc-navy-2\:h:active {
  color: #011c43 !important;
}

.bgc-sc-navy-2 {
  background-color: #011c43 !important;
  transition: background-color 300ms;
}

.bgc-sc-navy-2\:h:hover,
.bgc-sc-navy-2\:h:focus,
.bgc-sc-navy-2\:h:active {
  background-color: #011c43 !important;
}

.bc-sc-navy-2 {
  border-color: #011c43;
}

.c-sc-pink-2-opacity-1 {
  color: rgba(169, 36, 98, 0.1) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-1\:h:hover,
.c-sc-pink-2-opacity-1\:h:focus,
.c-sc-pink-2-opacity-1\:h:active {
  color: rgba(169, 36, 98, 0.1) !important;
}

.bgc-sc-pink-2-opacity-1 {
  background-color: rgba(169, 36, 98, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-1\:h:hover,
.bgc-sc-pink-2-opacity-1\:h:focus,
.bgc-sc-pink-2-opacity-1\:h:active {
  background-color: rgba(169, 36, 98, 0.1) !important;
}

.bc-sc-pink-2-opacity-1 {
  border-color: rgba(169, 36, 98, 0.1);
}

.c-sc-pink-2-opacity-2 {
  color: rgba(169, 36, 98, 0.2) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-2\:h:hover,
.c-sc-pink-2-opacity-2\:h:focus,
.c-sc-pink-2-opacity-2\:h:active {
  color: rgba(169, 36, 98, 0.2) !important;
}

.bgc-sc-pink-2-opacity-2 {
  background-color: rgba(169, 36, 98, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-2\:h:hover,
.bgc-sc-pink-2-opacity-2\:h:focus,
.bgc-sc-pink-2-opacity-2\:h:active {
  background-color: rgba(169, 36, 98, 0.2) !important;
}

.bc-sc-pink-2-opacity-2 {
  border-color: rgba(169, 36, 98, 0.2);
}

.c-sc-pink-2-opacity-3 {
  color: rgba(169, 36, 98, 0.3) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-3\:h:hover,
.c-sc-pink-2-opacity-3\:h:focus,
.c-sc-pink-2-opacity-3\:h:active {
  color: rgba(169, 36, 98, 0.3) !important;
}

.bgc-sc-pink-2-opacity-3 {
  background-color: rgba(169, 36, 98, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-3\:h:hover,
.bgc-sc-pink-2-opacity-3\:h:focus,
.bgc-sc-pink-2-opacity-3\:h:active {
  background-color: rgba(169, 36, 98, 0.3) !important;
}

.bc-sc-pink-2-opacity-3 {
  border-color: rgba(169, 36, 98, 0.3);
}

.c-sc-pink-2-opacity-4 {
  color: rgba(169, 36, 98, 0.4) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-4\:h:hover,
.c-sc-pink-2-opacity-4\:h:focus,
.c-sc-pink-2-opacity-4\:h:active {
  color: rgba(169, 36, 98, 0.4) !important;
}

.bgc-sc-pink-2-opacity-4 {
  background-color: rgba(169, 36, 98, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-4\:h:hover,
.bgc-sc-pink-2-opacity-4\:h:focus,
.bgc-sc-pink-2-opacity-4\:h:active {
  background-color: rgba(169, 36, 98, 0.4) !important;
}

.bc-sc-pink-2-opacity-4 {
  border-color: rgba(169, 36, 98, 0.4);
}

.c-sc-pink-2-opacity-5 {
  color: rgba(169, 36, 98, 0.5) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-5\:h:hover,
.c-sc-pink-2-opacity-5\:h:focus,
.c-sc-pink-2-opacity-5\:h:active {
  color: rgba(169, 36, 98, 0.5) !important;
}

.bgc-sc-pink-2-opacity-5 {
  background-color: rgba(169, 36, 98, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-5\:h:hover,
.bgc-sc-pink-2-opacity-5\:h:focus,
.bgc-sc-pink-2-opacity-5\:h:active {
  background-color: rgba(169, 36, 98, 0.5) !important;
}

.bc-sc-pink-2-opacity-5 {
  border-color: rgba(169, 36, 98, 0.5);
}

.c-sc-pink-2-opacity-6 {
  color: rgba(169, 36, 98, 0.6) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-6\:h:hover,
.c-sc-pink-2-opacity-6\:h:focus,
.c-sc-pink-2-opacity-6\:h:active {
  color: rgba(169, 36, 98, 0.6) !important;
}

.bgc-sc-pink-2-opacity-6 {
  background-color: rgba(169, 36, 98, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-6\:h:hover,
.bgc-sc-pink-2-opacity-6\:h:focus,
.bgc-sc-pink-2-opacity-6\:h:active {
  background-color: rgba(169, 36, 98, 0.6) !important;
}

.bc-sc-pink-2-opacity-6 {
  border-color: rgba(169, 36, 98, 0.6);
}

.c-sc-pink-2-opacity-7 {
  color: rgba(169, 36, 98, 0.7) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-7\:h:hover,
.c-sc-pink-2-opacity-7\:h:focus,
.c-sc-pink-2-opacity-7\:h:active {
  color: rgba(169, 36, 98, 0.7) !important;
}

.bgc-sc-pink-2-opacity-7 {
  background-color: rgba(169, 36, 98, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-7\:h:hover,
.bgc-sc-pink-2-opacity-7\:h:focus,
.bgc-sc-pink-2-opacity-7\:h:active {
  background-color: rgba(169, 36, 98, 0.7) !important;
}

.bc-sc-pink-2-opacity-7 {
  border-color: rgba(169, 36, 98, 0.7);
}

.c-sc-pink-2-opacity-8 {
  color: rgba(169, 36, 98, 0.8) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-8\:h:hover,
.c-sc-pink-2-opacity-8\:h:focus,
.c-sc-pink-2-opacity-8\:h:active {
  color: rgba(169, 36, 98, 0.8) !important;
}

.bgc-sc-pink-2-opacity-8 {
  background-color: rgba(169, 36, 98, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-8\:h:hover,
.bgc-sc-pink-2-opacity-8\:h:focus,
.bgc-sc-pink-2-opacity-8\:h:active {
  background-color: rgba(169, 36, 98, 0.8) !important;
}

.bc-sc-pink-2-opacity-8 {
  border-color: rgba(169, 36, 98, 0.8);
}

.c-sc-pink-2-opacity-9 {
  color: rgba(169, 36, 98, 0.9) !important;
  transition: color 300ms;
}

.c-sc-pink-2-opacity-9\:h:hover,
.c-sc-pink-2-opacity-9\:h:focus,
.c-sc-pink-2-opacity-9\:h:active {
  color: rgba(169, 36, 98, 0.9) !important;
}

.bgc-sc-pink-2-opacity-9 {
  background-color: rgba(169, 36, 98, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2-opacity-9\:h:hover,
.bgc-sc-pink-2-opacity-9\:h:focus,
.bgc-sc-pink-2-opacity-9\:h:active {
  background-color: rgba(169, 36, 98, 0.9) !important;
}

.bc-sc-pink-2-opacity-9 {
  border-color: rgba(169, 36, 98, 0.9);
}

.c-sc-pink-2 {
  color: #a92462 !important;
  transition: color 300ms;
}

.c-sc-pink-2\:h:hover,
.c-sc-pink-2\:h:focus,
.c-sc-pink-2\:h:active {
  color: #a92462 !important;
}

.bgc-sc-pink-2 {
  background-color: #a92462 !important;
  transition: background-color 300ms;
}

.bgc-sc-pink-2\:h:hover,
.bgc-sc-pink-2\:h:focus,
.bgc-sc-pink-2\:h:active {
  background-color: #a92462 !important;
}

.bc-sc-pink-2 {
  border-color: #a92462;
}

.c-sc-sky-blue-2-opacity-1 {
  color: rgba(2, 119, 160, 0.1) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-1\:h:hover,
.c-sc-sky-blue-2-opacity-1\:h:focus,
.c-sc-sky-blue-2-opacity-1\:h:active {
  color: rgba(2, 119, 160, 0.1) !important;
}

.bgc-sc-sky-blue-2-opacity-1 {
  background-color: rgba(2, 119, 160, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-1\:h:hover,
.bgc-sc-sky-blue-2-opacity-1\:h:focus,
.bgc-sc-sky-blue-2-opacity-1\:h:active {
  background-color: rgba(2, 119, 160, 0.1) !important;
}

.bc-sc-sky-blue-2-opacity-1 {
  border-color: rgba(2, 119, 160, 0.1);
}

.c-sc-sky-blue-2-opacity-2 {
  color: rgba(2, 119, 160, 0.2) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-2\:h:hover,
.c-sc-sky-blue-2-opacity-2\:h:focus,
.c-sc-sky-blue-2-opacity-2\:h:active {
  color: rgba(2, 119, 160, 0.2) !important;
}

.bgc-sc-sky-blue-2-opacity-2 {
  background-color: rgba(2, 119, 160, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-2\:h:hover,
.bgc-sc-sky-blue-2-opacity-2\:h:focus,
.bgc-sc-sky-blue-2-opacity-2\:h:active {
  background-color: rgba(2, 119, 160, 0.2) !important;
}

.bc-sc-sky-blue-2-opacity-2 {
  border-color: rgba(2, 119, 160, 0.2);
}

.c-sc-sky-blue-2-opacity-3 {
  color: rgba(2, 119, 160, 0.3) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-3\:h:hover,
.c-sc-sky-blue-2-opacity-3\:h:focus,
.c-sc-sky-blue-2-opacity-3\:h:active {
  color: rgba(2, 119, 160, 0.3) !important;
}

.bgc-sc-sky-blue-2-opacity-3 {
  background-color: rgba(2, 119, 160, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-3\:h:hover,
.bgc-sc-sky-blue-2-opacity-3\:h:focus,
.bgc-sc-sky-blue-2-opacity-3\:h:active {
  background-color: rgba(2, 119, 160, 0.3) !important;
}

.bc-sc-sky-blue-2-opacity-3 {
  border-color: rgba(2, 119, 160, 0.3);
}

.c-sc-sky-blue-2-opacity-4 {
  color: rgba(2, 119, 160, 0.4) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-4\:h:hover,
.c-sc-sky-blue-2-opacity-4\:h:focus,
.c-sc-sky-blue-2-opacity-4\:h:active {
  color: rgba(2, 119, 160, 0.4) !important;
}

.bgc-sc-sky-blue-2-opacity-4 {
  background-color: rgba(2, 119, 160, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-4\:h:hover,
.bgc-sc-sky-blue-2-opacity-4\:h:focus,
.bgc-sc-sky-blue-2-opacity-4\:h:active {
  background-color: rgba(2, 119, 160, 0.4) !important;
}

.bc-sc-sky-blue-2-opacity-4 {
  border-color: rgba(2, 119, 160, 0.4);
}

.c-sc-sky-blue-2-opacity-5 {
  color: rgba(2, 119, 160, 0.5) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-5\:h:hover,
.c-sc-sky-blue-2-opacity-5\:h:focus,
.c-sc-sky-blue-2-opacity-5\:h:active {
  color: rgba(2, 119, 160, 0.5) !important;
}

.bgc-sc-sky-blue-2-opacity-5 {
  background-color: rgba(2, 119, 160, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-5\:h:hover,
.bgc-sc-sky-blue-2-opacity-5\:h:focus,
.bgc-sc-sky-blue-2-opacity-5\:h:active {
  background-color: rgba(2, 119, 160, 0.5) !important;
}

.bc-sc-sky-blue-2-opacity-5 {
  border-color: rgba(2, 119, 160, 0.5);
}

.c-sc-sky-blue-2-opacity-6 {
  color: rgba(2, 119, 160, 0.6) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-6\:h:hover,
.c-sc-sky-blue-2-opacity-6\:h:focus,
.c-sc-sky-blue-2-opacity-6\:h:active {
  color: rgba(2, 119, 160, 0.6) !important;
}

.bgc-sc-sky-blue-2-opacity-6 {
  background-color: rgba(2, 119, 160, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-6\:h:hover,
.bgc-sc-sky-blue-2-opacity-6\:h:focus,
.bgc-sc-sky-blue-2-opacity-6\:h:active {
  background-color: rgba(2, 119, 160, 0.6) !important;
}

.bc-sc-sky-blue-2-opacity-6 {
  border-color: rgba(2, 119, 160, 0.6);
}

.c-sc-sky-blue-2-opacity-7 {
  color: rgba(2, 119, 160, 0.7) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-7\:h:hover,
.c-sc-sky-blue-2-opacity-7\:h:focus,
.c-sc-sky-blue-2-opacity-7\:h:active {
  color: rgba(2, 119, 160, 0.7) !important;
}

.bgc-sc-sky-blue-2-opacity-7 {
  background-color: rgba(2, 119, 160, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-7\:h:hover,
.bgc-sc-sky-blue-2-opacity-7\:h:focus,
.bgc-sc-sky-blue-2-opacity-7\:h:active {
  background-color: rgba(2, 119, 160, 0.7) !important;
}

.bc-sc-sky-blue-2-opacity-7 {
  border-color: rgba(2, 119, 160, 0.7);
}

.c-sc-sky-blue-2-opacity-8 {
  color: rgba(2, 119, 160, 0.8) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-8\:h:hover,
.c-sc-sky-blue-2-opacity-8\:h:focus,
.c-sc-sky-blue-2-opacity-8\:h:active {
  color: rgba(2, 119, 160, 0.8) !important;
}

.bgc-sc-sky-blue-2-opacity-8 {
  background-color: rgba(2, 119, 160, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-8\:h:hover,
.bgc-sc-sky-blue-2-opacity-8\:h:focus,
.bgc-sc-sky-blue-2-opacity-8\:h:active {
  background-color: rgba(2, 119, 160, 0.8) !important;
}

.bc-sc-sky-blue-2-opacity-8 {
  border-color: rgba(2, 119, 160, 0.8);
}

.c-sc-sky-blue-2-opacity-9 {
  color: rgba(2, 119, 160, 0.9) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2-opacity-9\:h:hover,
.c-sc-sky-blue-2-opacity-9\:h:focus,
.c-sc-sky-blue-2-opacity-9\:h:active {
  color: rgba(2, 119, 160, 0.9) !important;
}

.bgc-sc-sky-blue-2-opacity-9 {
  background-color: rgba(2, 119, 160, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2-opacity-9\:h:hover,
.bgc-sc-sky-blue-2-opacity-9\:h:focus,
.bgc-sc-sky-blue-2-opacity-9\:h:active {
  background-color: rgba(2, 119, 160, 0.9) !important;
}

.bc-sc-sky-blue-2-opacity-9 {
  border-color: rgba(2, 119, 160, 0.9);
}

.c-sc-sky-blue-2 {
  color: #0277a0 !important;
  transition: color 300ms;
}

.c-sc-sky-blue-2\:h:hover,
.c-sc-sky-blue-2\:h:focus,
.c-sc-sky-blue-2\:h:active {
  color: #0277a0 !important;
}

.bgc-sc-sky-blue-2 {
  background-color: #0277a0 !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-2\:h:hover,
.bgc-sc-sky-blue-2\:h:focus,
.bgc-sc-sky-blue-2\:h:active {
  background-color: #0277a0 !important;
}

.bc-sc-sky-blue-2 {
  border-color: #0277a0;
}

.c-sc-sky-blue-3-opacity-1 {
  color: rgba(188, 224, 241, 0.1) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-1\:h:hover,
.c-sc-sky-blue-3-opacity-1\:h:focus,
.c-sc-sky-blue-3-opacity-1\:h:active {
  color: rgba(188, 224, 241, 0.1) !important;
}

.bgc-sc-sky-blue-3-opacity-1 {
  background-color: rgba(188, 224, 241, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-1\:h:hover,
.bgc-sc-sky-blue-3-opacity-1\:h:focus,
.bgc-sc-sky-blue-3-opacity-1\:h:active {
  background-color: rgba(188, 224, 241, 0.1) !important;
}

.bc-sc-sky-blue-3-opacity-1 {
  border-color: rgba(188, 224, 241, 0.1);
}

.c-sc-sky-blue-3-opacity-2 {
  color: rgba(188, 224, 241, 0.2) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-2\:h:hover,
.c-sc-sky-blue-3-opacity-2\:h:focus,
.c-sc-sky-blue-3-opacity-2\:h:active {
  color: rgba(188, 224, 241, 0.2) !important;
}

.bgc-sc-sky-blue-3-opacity-2 {
  background-color: rgba(188, 224, 241, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-2\:h:hover,
.bgc-sc-sky-blue-3-opacity-2\:h:focus,
.bgc-sc-sky-blue-3-opacity-2\:h:active {
  background-color: rgba(188, 224, 241, 0.2) !important;
}

.bc-sc-sky-blue-3-opacity-2 {
  border-color: rgba(188, 224, 241, 0.2);
}

.c-sc-sky-blue-3-opacity-3 {
  color: rgba(188, 224, 241, 0.3) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-3\:h:hover,
.c-sc-sky-blue-3-opacity-3\:h:focus,
.c-sc-sky-blue-3-opacity-3\:h:active {
  color: rgba(188, 224, 241, 0.3) !important;
}

.bgc-sc-sky-blue-3-opacity-3 {
  background-color: rgba(188, 224, 241, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-3\:h:hover,
.bgc-sc-sky-blue-3-opacity-3\:h:focus,
.bgc-sc-sky-blue-3-opacity-3\:h:active {
  background-color: rgba(188, 224, 241, 0.3) !important;
}

.bc-sc-sky-blue-3-opacity-3 {
  border-color: rgba(188, 224, 241, 0.3);
}

.c-sc-sky-blue-3-opacity-4 {
  color: rgba(188, 224, 241, 0.4) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-4\:h:hover,
.c-sc-sky-blue-3-opacity-4\:h:focus,
.c-sc-sky-blue-3-opacity-4\:h:active {
  color: rgba(188, 224, 241, 0.4) !important;
}

.bgc-sc-sky-blue-3-opacity-4 {
  background-color: rgba(188, 224, 241, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-4\:h:hover,
.bgc-sc-sky-blue-3-opacity-4\:h:focus,
.bgc-sc-sky-blue-3-opacity-4\:h:active {
  background-color: rgba(188, 224, 241, 0.4) !important;
}

.bc-sc-sky-blue-3-opacity-4 {
  border-color: rgba(188, 224, 241, 0.4);
}

.c-sc-sky-blue-3-opacity-5 {
  color: rgba(188, 224, 241, 0.5) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-5\:h:hover,
.c-sc-sky-blue-3-opacity-5\:h:focus,
.c-sc-sky-blue-3-opacity-5\:h:active {
  color: rgba(188, 224, 241, 0.5) !important;
}

.bgc-sc-sky-blue-3-opacity-5 {
  background-color: rgba(188, 224, 241, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-5\:h:hover,
.bgc-sc-sky-blue-3-opacity-5\:h:focus,
.bgc-sc-sky-blue-3-opacity-5\:h:active {
  background-color: rgba(188, 224, 241, 0.5) !important;
}

.bc-sc-sky-blue-3-opacity-5 {
  border-color: rgba(188, 224, 241, 0.5);
}

.c-sc-sky-blue-3-opacity-6 {
  color: rgba(188, 224, 241, 0.6) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-6\:h:hover,
.c-sc-sky-blue-3-opacity-6\:h:focus,
.c-sc-sky-blue-3-opacity-6\:h:active {
  color: rgba(188, 224, 241, 0.6) !important;
}

.bgc-sc-sky-blue-3-opacity-6 {
  background-color: rgba(188, 224, 241, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-6\:h:hover,
.bgc-sc-sky-blue-3-opacity-6\:h:focus,
.bgc-sc-sky-blue-3-opacity-6\:h:active {
  background-color: rgba(188, 224, 241, 0.6) !important;
}

.bc-sc-sky-blue-3-opacity-6 {
  border-color: rgba(188, 224, 241, 0.6);
}

.c-sc-sky-blue-3-opacity-7 {
  color: rgba(188, 224, 241, 0.7) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-7\:h:hover,
.c-sc-sky-blue-3-opacity-7\:h:focus,
.c-sc-sky-blue-3-opacity-7\:h:active {
  color: rgba(188, 224, 241, 0.7) !important;
}

.bgc-sc-sky-blue-3-opacity-7 {
  background-color: rgba(188, 224, 241, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-7\:h:hover,
.bgc-sc-sky-blue-3-opacity-7\:h:focus,
.bgc-sc-sky-blue-3-opacity-7\:h:active {
  background-color: rgba(188, 224, 241, 0.7) !important;
}

.bc-sc-sky-blue-3-opacity-7 {
  border-color: rgba(188, 224, 241, 0.7);
}

.c-sc-sky-blue-3-opacity-8 {
  color: rgba(188, 224, 241, 0.8) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-8\:h:hover,
.c-sc-sky-blue-3-opacity-8\:h:focus,
.c-sc-sky-blue-3-opacity-8\:h:active {
  color: rgba(188, 224, 241, 0.8) !important;
}

.bgc-sc-sky-blue-3-opacity-8 {
  background-color: rgba(188, 224, 241, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-8\:h:hover,
.bgc-sc-sky-blue-3-opacity-8\:h:focus,
.bgc-sc-sky-blue-3-opacity-8\:h:active {
  background-color: rgba(188, 224, 241, 0.8) !important;
}

.bc-sc-sky-blue-3-opacity-8 {
  border-color: rgba(188, 224, 241, 0.8);
}

.c-sc-sky-blue-3-opacity-9 {
  color: rgba(188, 224, 241, 0.9) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3-opacity-9\:h:hover,
.c-sc-sky-blue-3-opacity-9\:h:focus,
.c-sc-sky-blue-3-opacity-9\:h:active {
  color: rgba(188, 224, 241, 0.9) !important;
}

.bgc-sc-sky-blue-3-opacity-9 {
  background-color: rgba(188, 224, 241, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3-opacity-9\:h:hover,
.bgc-sc-sky-blue-3-opacity-9\:h:focus,
.bgc-sc-sky-blue-3-opacity-9\:h:active {
  background-color: rgba(188, 224, 241, 0.9) !important;
}

.bc-sc-sky-blue-3-opacity-9 {
  border-color: rgba(188, 224, 241, 0.9);
}

.c-sc-sky-blue-3 {
  color: #bce0f1 !important;
  transition: color 300ms;
}

.c-sc-sky-blue-3\:h:hover,
.c-sc-sky-blue-3\:h:focus,
.c-sc-sky-blue-3\:h:active {
  color: #bce0f1 !important;
}

.bgc-sc-sky-blue-3 {
  background-color: #bce0f1 !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-3\:h:hover,
.bgc-sc-sky-blue-3\:h:focus,
.bgc-sc-sky-blue-3\:h:active {
  background-color: #bce0f1 !important;
}

.bc-sc-sky-blue-3 {
  border-color: #bce0f1;
}

.c-sc-sky-blue-4-opacity-1 {
  color: rgba(229, 244, 251, 0.1) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-1\:h:hover,
.c-sc-sky-blue-4-opacity-1\:h:focus,
.c-sc-sky-blue-4-opacity-1\:h:active {
  color: rgba(229, 244, 251, 0.1) !important;
}

.bgc-sc-sky-blue-4-opacity-1 {
  background-color: rgba(229, 244, 251, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-1\:h:hover,
.bgc-sc-sky-blue-4-opacity-1\:h:focus,
.bgc-sc-sky-blue-4-opacity-1\:h:active {
  background-color: rgba(229, 244, 251, 0.1) !important;
}

.bc-sc-sky-blue-4-opacity-1 {
  border-color: rgba(229, 244, 251, 0.1);
}

.c-sc-sky-blue-4-opacity-2 {
  color: rgba(229, 244, 251, 0.2) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-2\:h:hover,
.c-sc-sky-blue-4-opacity-2\:h:focus,
.c-sc-sky-blue-4-opacity-2\:h:active {
  color: rgba(229, 244, 251, 0.2) !important;
}

.bgc-sc-sky-blue-4-opacity-2 {
  background-color: rgba(229, 244, 251, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-2\:h:hover,
.bgc-sc-sky-blue-4-opacity-2\:h:focus,
.bgc-sc-sky-blue-4-opacity-2\:h:active {
  background-color: rgba(229, 244, 251, 0.2) !important;
}

.bc-sc-sky-blue-4-opacity-2 {
  border-color: rgba(229, 244, 251, 0.2);
}

.c-sc-sky-blue-4-opacity-3 {
  color: rgba(229, 244, 251, 0.3) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-3\:h:hover,
.c-sc-sky-blue-4-opacity-3\:h:focus,
.c-sc-sky-blue-4-opacity-3\:h:active {
  color: rgba(229, 244, 251, 0.3) !important;
}

.bgc-sc-sky-blue-4-opacity-3 {
  background-color: rgba(229, 244, 251, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-3\:h:hover,
.bgc-sc-sky-blue-4-opacity-3\:h:focus,
.bgc-sc-sky-blue-4-opacity-3\:h:active {
  background-color: rgba(229, 244, 251, 0.3) !important;
}

.bc-sc-sky-blue-4-opacity-3 {
  border-color: rgba(229, 244, 251, 0.3);
}

.c-sc-sky-blue-4-opacity-4 {
  color: rgba(229, 244, 251, 0.4) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-4\:h:hover,
.c-sc-sky-blue-4-opacity-4\:h:focus,
.c-sc-sky-blue-4-opacity-4\:h:active {
  color: rgba(229, 244, 251, 0.4) !important;
}

.bgc-sc-sky-blue-4-opacity-4 {
  background-color: rgba(229, 244, 251, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-4\:h:hover,
.bgc-sc-sky-blue-4-opacity-4\:h:focus,
.bgc-sc-sky-blue-4-opacity-4\:h:active {
  background-color: rgba(229, 244, 251, 0.4) !important;
}

.bc-sc-sky-blue-4-opacity-4 {
  border-color: rgba(229, 244, 251, 0.4);
}

.c-sc-sky-blue-4-opacity-5 {
  color: rgba(229, 244, 251, 0.5) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-5\:h:hover,
.c-sc-sky-blue-4-opacity-5\:h:focus,
.c-sc-sky-blue-4-opacity-5\:h:active {
  color: rgba(229, 244, 251, 0.5) !important;
}

.bgc-sc-sky-blue-4-opacity-5 {
  background-color: rgba(229, 244, 251, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-5\:h:hover,
.bgc-sc-sky-blue-4-opacity-5\:h:focus,
.bgc-sc-sky-blue-4-opacity-5\:h:active {
  background-color: rgba(229, 244, 251, 0.5) !important;
}

.bc-sc-sky-blue-4-opacity-5 {
  border-color: rgba(229, 244, 251, 0.5);
}

.c-sc-sky-blue-4-opacity-6 {
  color: rgba(229, 244, 251, 0.6) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-6\:h:hover,
.c-sc-sky-blue-4-opacity-6\:h:focus,
.c-sc-sky-blue-4-opacity-6\:h:active {
  color: rgba(229, 244, 251, 0.6) !important;
}

.bgc-sc-sky-blue-4-opacity-6 {
  background-color: rgba(229, 244, 251, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-6\:h:hover,
.bgc-sc-sky-blue-4-opacity-6\:h:focus,
.bgc-sc-sky-blue-4-opacity-6\:h:active {
  background-color: rgba(229, 244, 251, 0.6) !important;
}

.bc-sc-sky-blue-4-opacity-6 {
  border-color: rgba(229, 244, 251, 0.6);
}

.c-sc-sky-blue-4-opacity-7 {
  color: rgba(229, 244, 251, 0.7) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-7\:h:hover,
.c-sc-sky-blue-4-opacity-7\:h:focus,
.c-sc-sky-blue-4-opacity-7\:h:active {
  color: rgba(229, 244, 251, 0.7) !important;
}

.bgc-sc-sky-blue-4-opacity-7 {
  background-color: rgba(229, 244, 251, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-7\:h:hover,
.bgc-sc-sky-blue-4-opacity-7\:h:focus,
.bgc-sc-sky-blue-4-opacity-7\:h:active {
  background-color: rgba(229, 244, 251, 0.7) !important;
}

.bc-sc-sky-blue-4-opacity-7 {
  border-color: rgba(229, 244, 251, 0.7);
}

.c-sc-sky-blue-4-opacity-8 {
  color: rgba(229, 244, 251, 0.8) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-8\:h:hover,
.c-sc-sky-blue-4-opacity-8\:h:focus,
.c-sc-sky-blue-4-opacity-8\:h:active {
  color: rgba(229, 244, 251, 0.8) !important;
}

.bgc-sc-sky-blue-4-opacity-8 {
  background-color: rgba(229, 244, 251, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-8\:h:hover,
.bgc-sc-sky-blue-4-opacity-8\:h:focus,
.bgc-sc-sky-blue-4-opacity-8\:h:active {
  background-color: rgba(229, 244, 251, 0.8) !important;
}

.bc-sc-sky-blue-4-opacity-8 {
  border-color: rgba(229, 244, 251, 0.8);
}

.c-sc-sky-blue-4-opacity-9 {
  color: rgba(229, 244, 251, 0.9) !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4-opacity-9\:h:hover,
.c-sc-sky-blue-4-opacity-9\:h:focus,
.c-sc-sky-blue-4-opacity-9\:h:active {
  color: rgba(229, 244, 251, 0.9) !important;
}

.bgc-sc-sky-blue-4-opacity-9 {
  background-color: rgba(229, 244, 251, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4-opacity-9\:h:hover,
.bgc-sc-sky-blue-4-opacity-9\:h:focus,
.bgc-sc-sky-blue-4-opacity-9\:h:active {
  background-color: rgba(229, 244, 251, 0.9) !important;
}

.bc-sc-sky-blue-4-opacity-9 {
  border-color: rgba(229, 244, 251, 0.9);
}

.c-sc-sky-blue-4 {
  color: #e5f4fb !important;
  transition: color 300ms;
}

.c-sc-sky-blue-4\:h:hover,
.c-sc-sky-blue-4\:h:focus,
.c-sc-sky-blue-4\:h:active {
  color: #e5f4fb !important;
}

.bgc-sc-sky-blue-4 {
  background-color: #e5f4fb !important;
  transition: background-color 300ms;
}

.bgc-sc-sky-blue-4\:h:hover,
.bgc-sc-sky-blue-4\:h:focus,
.bgc-sc-sky-blue-4\:h:active {
  background-color: #e5f4fb !important;
}

.bc-sc-sky-blue-4 {
  border-color: #e5f4fb;
}

.c-sc-grey-4-opacity-1 {
  color: rgba(102, 102, 102, 0.1) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-1\:h:hover,
.c-sc-grey-4-opacity-1\:h:focus,
.c-sc-grey-4-opacity-1\:h:active {
  color: rgba(102, 102, 102, 0.1) !important;
}

.bgc-sc-grey-4-opacity-1 {
  background-color: rgba(102, 102, 102, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-1\:h:hover,
.bgc-sc-grey-4-opacity-1\:h:focus,
.bgc-sc-grey-4-opacity-1\:h:active {
  background-color: rgba(102, 102, 102, 0.1) !important;
}

.bc-sc-grey-4-opacity-1 {
  border-color: rgba(102, 102, 102, 0.1);
}

.c-sc-grey-4-opacity-2 {
  color: rgba(102, 102, 102, 0.2) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-2\:h:hover,
.c-sc-grey-4-opacity-2\:h:focus,
.c-sc-grey-4-opacity-2\:h:active {
  color: rgba(102, 102, 102, 0.2) !important;
}

.bgc-sc-grey-4-opacity-2 {
  background-color: rgba(102, 102, 102, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-2\:h:hover,
.bgc-sc-grey-4-opacity-2\:h:focus,
.bgc-sc-grey-4-opacity-2\:h:active {
  background-color: rgba(102, 102, 102, 0.2) !important;
}

.bc-sc-grey-4-opacity-2 {
  border-color: rgba(102, 102, 102, 0.2);
}

.c-sc-grey-4-opacity-3 {
  color: rgba(102, 102, 102, 0.3) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-3\:h:hover,
.c-sc-grey-4-opacity-3\:h:focus,
.c-sc-grey-4-opacity-3\:h:active {
  color: rgba(102, 102, 102, 0.3) !important;
}

.bgc-sc-grey-4-opacity-3 {
  background-color: rgba(102, 102, 102, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-3\:h:hover,
.bgc-sc-grey-4-opacity-3\:h:focus,
.bgc-sc-grey-4-opacity-3\:h:active {
  background-color: rgba(102, 102, 102, 0.3) !important;
}

.bc-sc-grey-4-opacity-3 {
  border-color: rgba(102, 102, 102, 0.3);
}

.c-sc-grey-4-opacity-4 {
  color: rgba(102, 102, 102, 0.4) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-4\:h:hover,
.c-sc-grey-4-opacity-4\:h:focus,
.c-sc-grey-4-opacity-4\:h:active {
  color: rgba(102, 102, 102, 0.4) !important;
}

.bgc-sc-grey-4-opacity-4 {
  background-color: rgba(102, 102, 102, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-4\:h:hover,
.bgc-sc-grey-4-opacity-4\:h:focus,
.bgc-sc-grey-4-opacity-4\:h:active {
  background-color: rgba(102, 102, 102, 0.4) !important;
}

.bc-sc-grey-4-opacity-4 {
  border-color: rgba(102, 102, 102, 0.4);
}

.c-sc-grey-4-opacity-5 {
  color: rgba(102, 102, 102, 0.5) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-5\:h:hover,
.c-sc-grey-4-opacity-5\:h:focus,
.c-sc-grey-4-opacity-5\:h:active {
  color: rgba(102, 102, 102, 0.5) !important;
}

.bgc-sc-grey-4-opacity-5 {
  background-color: rgba(102, 102, 102, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-5\:h:hover,
.bgc-sc-grey-4-opacity-5\:h:focus,
.bgc-sc-grey-4-opacity-5\:h:active {
  background-color: rgba(102, 102, 102, 0.5) !important;
}

.bc-sc-grey-4-opacity-5 {
  border-color: rgba(102, 102, 102, 0.5);
}

.c-sc-grey-4-opacity-6 {
  color: rgba(102, 102, 102, 0.6) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-6\:h:hover,
.c-sc-grey-4-opacity-6\:h:focus,
.c-sc-grey-4-opacity-6\:h:active {
  color: rgba(102, 102, 102, 0.6) !important;
}

.bgc-sc-grey-4-opacity-6 {
  background-color: rgba(102, 102, 102, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-6\:h:hover,
.bgc-sc-grey-4-opacity-6\:h:focus,
.bgc-sc-grey-4-opacity-6\:h:active {
  background-color: rgba(102, 102, 102, 0.6) !important;
}

.bc-sc-grey-4-opacity-6 {
  border-color: rgba(102, 102, 102, 0.6);
}

.c-sc-grey-4-opacity-7 {
  color: rgba(102, 102, 102, 0.7) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-7\:h:hover,
.c-sc-grey-4-opacity-7\:h:focus,
.c-sc-grey-4-opacity-7\:h:active {
  color: rgba(102, 102, 102, 0.7) !important;
}

.bgc-sc-grey-4-opacity-7 {
  background-color: rgba(102, 102, 102, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-7\:h:hover,
.bgc-sc-grey-4-opacity-7\:h:focus,
.bgc-sc-grey-4-opacity-7\:h:active {
  background-color: rgba(102, 102, 102, 0.7) !important;
}

.bc-sc-grey-4-opacity-7 {
  border-color: rgba(102, 102, 102, 0.7);
}

.c-sc-grey-4-opacity-8 {
  color: rgba(102, 102, 102, 0.8) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-8\:h:hover,
.c-sc-grey-4-opacity-8\:h:focus,
.c-sc-grey-4-opacity-8\:h:active {
  color: rgba(102, 102, 102, 0.8) !important;
}

.bgc-sc-grey-4-opacity-8 {
  background-color: rgba(102, 102, 102, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-8\:h:hover,
.bgc-sc-grey-4-opacity-8\:h:focus,
.bgc-sc-grey-4-opacity-8\:h:active {
  background-color: rgba(102, 102, 102, 0.8) !important;
}

.bc-sc-grey-4-opacity-8 {
  border-color: rgba(102, 102, 102, 0.8);
}

.c-sc-grey-4-opacity-9 {
  color: rgba(102, 102, 102, 0.9) !important;
  transition: color 300ms;
}

.c-sc-grey-4-opacity-9\:h:hover,
.c-sc-grey-4-opacity-9\:h:focus,
.c-sc-grey-4-opacity-9\:h:active {
  color: rgba(102, 102, 102, 0.9) !important;
}

.bgc-sc-grey-4-opacity-9 {
  background-color: rgba(102, 102, 102, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4-opacity-9\:h:hover,
.bgc-sc-grey-4-opacity-9\:h:focus,
.bgc-sc-grey-4-opacity-9\:h:active {
  background-color: rgba(102, 102, 102, 0.9) !important;
}

.bc-sc-grey-4-opacity-9 {
  border-color: rgba(102, 102, 102, 0.9);
}

.c-sc-grey-4 {
  color: #666666 !important;
  transition: color 300ms;
}

.c-sc-grey-4\:h:hover,
.c-sc-grey-4\:h:focus,
.c-sc-grey-4\:h:active {
  color: #666666 !important;
}

.bgc-sc-grey-4 {
  background-color: #666666 !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-4\:h:hover,
.bgc-sc-grey-4\:h:focus,
.bgc-sc-grey-4\:h:active {
  background-color: #666666 !important;
}

.bc-sc-grey-4 {
  border-color: #666666;
}

.c-sc-grey-5-opacity-1 {
  color: rgba(246, 246, 246, 0.1) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-1\:h:hover,
.c-sc-grey-5-opacity-1\:h:focus,
.c-sc-grey-5-opacity-1\:h:active {
  color: rgba(246, 246, 246, 0.1) !important;
}

.bgc-sc-grey-5-opacity-1 {
  background-color: rgba(246, 246, 246, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-1\:h:hover,
.bgc-sc-grey-5-opacity-1\:h:focus,
.bgc-sc-grey-5-opacity-1\:h:active {
  background-color: rgba(246, 246, 246, 0.1) !important;
}

.bc-sc-grey-5-opacity-1 {
  border-color: rgba(246, 246, 246, 0.1);
}

.c-sc-grey-5-opacity-2 {
  color: rgba(246, 246, 246, 0.2) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-2\:h:hover,
.c-sc-grey-5-opacity-2\:h:focus,
.c-sc-grey-5-opacity-2\:h:active {
  color: rgba(246, 246, 246, 0.2) !important;
}

.bgc-sc-grey-5-opacity-2 {
  background-color: rgba(246, 246, 246, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-2\:h:hover,
.bgc-sc-grey-5-opacity-2\:h:focus,
.bgc-sc-grey-5-opacity-2\:h:active {
  background-color: rgba(246, 246, 246, 0.2) !important;
}

.bc-sc-grey-5-opacity-2 {
  border-color: rgba(246, 246, 246, 0.2);
}

.c-sc-grey-5-opacity-3 {
  color: rgba(246, 246, 246, 0.3) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-3\:h:hover,
.c-sc-grey-5-opacity-3\:h:focus,
.c-sc-grey-5-opacity-3\:h:active {
  color: rgba(246, 246, 246, 0.3) !important;
}

.bgc-sc-grey-5-opacity-3 {
  background-color: rgba(246, 246, 246, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-3\:h:hover,
.bgc-sc-grey-5-opacity-3\:h:focus,
.bgc-sc-grey-5-opacity-3\:h:active {
  background-color: rgba(246, 246, 246, 0.3) !important;
}

.bc-sc-grey-5-opacity-3 {
  border-color: rgba(246, 246, 246, 0.3);
}

.c-sc-grey-5-opacity-4 {
  color: rgba(246, 246, 246, 0.4) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-4\:h:hover,
.c-sc-grey-5-opacity-4\:h:focus,
.c-sc-grey-5-opacity-4\:h:active {
  color: rgba(246, 246, 246, 0.4) !important;
}

.bgc-sc-grey-5-opacity-4 {
  background-color: rgba(246, 246, 246, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-4\:h:hover,
.bgc-sc-grey-5-opacity-4\:h:focus,
.bgc-sc-grey-5-opacity-4\:h:active {
  background-color: rgba(246, 246, 246, 0.4) !important;
}

.bc-sc-grey-5-opacity-4 {
  border-color: rgba(246, 246, 246, 0.4);
}

.c-sc-grey-5-opacity-5 {
  color: rgba(246, 246, 246, 0.5) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-5\:h:hover,
.c-sc-grey-5-opacity-5\:h:focus,
.c-sc-grey-5-opacity-5\:h:active {
  color: rgba(246, 246, 246, 0.5) !important;
}

.bgc-sc-grey-5-opacity-5 {
  background-color: rgba(246, 246, 246, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-5\:h:hover,
.bgc-sc-grey-5-opacity-5\:h:focus,
.bgc-sc-grey-5-opacity-5\:h:active {
  background-color: rgba(246, 246, 246, 0.5) !important;
}

.bc-sc-grey-5-opacity-5 {
  border-color: rgba(246, 246, 246, 0.5);
}

.c-sc-grey-5-opacity-6 {
  color: rgba(246, 246, 246, 0.6) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-6\:h:hover,
.c-sc-grey-5-opacity-6\:h:focus,
.c-sc-grey-5-opacity-6\:h:active {
  color: rgba(246, 246, 246, 0.6) !important;
}

.bgc-sc-grey-5-opacity-6 {
  background-color: rgba(246, 246, 246, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-6\:h:hover,
.bgc-sc-grey-5-opacity-6\:h:focus,
.bgc-sc-grey-5-opacity-6\:h:active {
  background-color: rgba(246, 246, 246, 0.6) !important;
}

.bc-sc-grey-5-opacity-6 {
  border-color: rgba(246, 246, 246, 0.6);
}

.c-sc-grey-5-opacity-7 {
  color: rgba(246, 246, 246, 0.7) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-7\:h:hover,
.c-sc-grey-5-opacity-7\:h:focus,
.c-sc-grey-5-opacity-7\:h:active {
  color: rgba(246, 246, 246, 0.7) !important;
}

.bgc-sc-grey-5-opacity-7 {
  background-color: rgba(246, 246, 246, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-7\:h:hover,
.bgc-sc-grey-5-opacity-7\:h:focus,
.bgc-sc-grey-5-opacity-7\:h:active {
  background-color: rgba(246, 246, 246, 0.7) !important;
}

.bc-sc-grey-5-opacity-7 {
  border-color: rgba(246, 246, 246, 0.7);
}

.c-sc-grey-5-opacity-8 {
  color: rgba(246, 246, 246, 0.8) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-8\:h:hover,
.c-sc-grey-5-opacity-8\:h:focus,
.c-sc-grey-5-opacity-8\:h:active {
  color: rgba(246, 246, 246, 0.8) !important;
}

.bgc-sc-grey-5-opacity-8 {
  background-color: rgba(246, 246, 246, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-8\:h:hover,
.bgc-sc-grey-5-opacity-8\:h:focus,
.bgc-sc-grey-5-opacity-8\:h:active {
  background-color: rgba(246, 246, 246, 0.8) !important;
}

.bc-sc-grey-5-opacity-8 {
  border-color: rgba(246, 246, 246, 0.8);
}

.c-sc-grey-5-opacity-9 {
  color: rgba(246, 246, 246, 0.9) !important;
  transition: color 300ms;
}

.c-sc-grey-5-opacity-9\:h:hover,
.c-sc-grey-5-opacity-9\:h:focus,
.c-sc-grey-5-opacity-9\:h:active {
  color: rgba(246, 246, 246, 0.9) !important;
}

.bgc-sc-grey-5-opacity-9 {
  background-color: rgba(246, 246, 246, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5-opacity-9\:h:hover,
.bgc-sc-grey-5-opacity-9\:h:focus,
.bgc-sc-grey-5-opacity-9\:h:active {
  background-color: rgba(246, 246, 246, 0.9) !important;
}

.bc-sc-grey-5-opacity-9 {
  border-color: rgba(246, 246, 246, 0.9);
}

.c-sc-grey-5 {
  color: #f6f6f6 !important;
  transition: color 300ms;
}

.c-sc-grey-5\:h:hover,
.c-sc-grey-5\:h:focus,
.c-sc-grey-5\:h:active {
  color: #f6f6f6 !important;
}

.bgc-sc-grey-5 {
  background-color: #f6f6f6 !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-5\:h:hover,
.bgc-sc-grey-5\:h:focus,
.bgc-sc-grey-5\:h:active {
  background-color: #f6f6f6 !important;
}

.bc-sc-grey-5 {
  border-color: #f6f6f6;
}

.c-sc-grey-6-opacity-1 {
  color: rgba(238, 238, 238, 0.1) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-1\:h:hover,
.c-sc-grey-6-opacity-1\:h:focus,
.c-sc-grey-6-opacity-1\:h:active {
  color: rgba(238, 238, 238, 0.1) !important;
}

.bgc-sc-grey-6-opacity-1 {
  background-color: rgba(238, 238, 238, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-1\:h:hover,
.bgc-sc-grey-6-opacity-1\:h:focus,
.bgc-sc-grey-6-opacity-1\:h:active {
  background-color: rgba(238, 238, 238, 0.1) !important;
}

.bc-sc-grey-6-opacity-1 {
  border-color: rgba(238, 238, 238, 0.1);
}

.c-sc-grey-6-opacity-2 {
  color: rgba(238, 238, 238, 0.2) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-2\:h:hover,
.c-sc-grey-6-opacity-2\:h:focus,
.c-sc-grey-6-opacity-2\:h:active {
  color: rgba(238, 238, 238, 0.2) !important;
}

.bgc-sc-grey-6-opacity-2 {
  background-color: rgba(238, 238, 238, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-2\:h:hover,
.bgc-sc-grey-6-opacity-2\:h:focus,
.bgc-sc-grey-6-opacity-2\:h:active {
  background-color: rgba(238, 238, 238, 0.2) !important;
}

.bc-sc-grey-6-opacity-2 {
  border-color: rgba(238, 238, 238, 0.2);
}

.c-sc-grey-6-opacity-3 {
  color: rgba(238, 238, 238, 0.3) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-3\:h:hover,
.c-sc-grey-6-opacity-3\:h:focus,
.c-sc-grey-6-opacity-3\:h:active {
  color: rgba(238, 238, 238, 0.3) !important;
}

.bgc-sc-grey-6-opacity-3 {
  background-color: rgba(238, 238, 238, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-3\:h:hover,
.bgc-sc-grey-6-opacity-3\:h:focus,
.bgc-sc-grey-6-opacity-3\:h:active {
  background-color: rgba(238, 238, 238, 0.3) !important;
}

.bc-sc-grey-6-opacity-3 {
  border-color: rgba(238, 238, 238, 0.3);
}

.c-sc-grey-6-opacity-4 {
  color: rgba(238, 238, 238, 0.4) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-4\:h:hover,
.c-sc-grey-6-opacity-4\:h:focus,
.c-sc-grey-6-opacity-4\:h:active {
  color: rgba(238, 238, 238, 0.4) !important;
}

.bgc-sc-grey-6-opacity-4 {
  background-color: rgba(238, 238, 238, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-4\:h:hover,
.bgc-sc-grey-6-opacity-4\:h:focus,
.bgc-sc-grey-6-opacity-4\:h:active {
  background-color: rgba(238, 238, 238, 0.4) !important;
}

.bc-sc-grey-6-opacity-4 {
  border-color: rgba(238, 238, 238, 0.4);
}

.c-sc-grey-6-opacity-5 {
  color: rgba(238, 238, 238, 0.5) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-5\:h:hover,
.c-sc-grey-6-opacity-5\:h:focus,
.c-sc-grey-6-opacity-5\:h:active {
  color: rgba(238, 238, 238, 0.5) !important;
}

.bgc-sc-grey-6-opacity-5 {
  background-color: rgba(238, 238, 238, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-5\:h:hover,
.bgc-sc-grey-6-opacity-5\:h:focus,
.bgc-sc-grey-6-opacity-5\:h:active {
  background-color: rgba(238, 238, 238, 0.5) !important;
}

.bc-sc-grey-6-opacity-5 {
  border-color: rgba(238, 238, 238, 0.5);
}

.c-sc-grey-6-opacity-6 {
  color: rgba(238, 238, 238, 0.6) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-6\:h:hover,
.c-sc-grey-6-opacity-6\:h:focus,
.c-sc-grey-6-opacity-6\:h:active {
  color: rgba(238, 238, 238, 0.6) !important;
}

.bgc-sc-grey-6-opacity-6 {
  background-color: rgba(238, 238, 238, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-6\:h:hover,
.bgc-sc-grey-6-opacity-6\:h:focus,
.bgc-sc-grey-6-opacity-6\:h:active {
  background-color: rgba(238, 238, 238, 0.6) !important;
}

.bc-sc-grey-6-opacity-6 {
  border-color: rgba(238, 238, 238, 0.6);
}

.c-sc-grey-6-opacity-7 {
  color: rgba(238, 238, 238, 0.7) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-7\:h:hover,
.c-sc-grey-6-opacity-7\:h:focus,
.c-sc-grey-6-opacity-7\:h:active {
  color: rgba(238, 238, 238, 0.7) !important;
}

.bgc-sc-grey-6-opacity-7 {
  background-color: rgba(238, 238, 238, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-7\:h:hover,
.bgc-sc-grey-6-opacity-7\:h:focus,
.bgc-sc-grey-6-opacity-7\:h:active {
  background-color: rgba(238, 238, 238, 0.7) !important;
}

.bc-sc-grey-6-opacity-7 {
  border-color: rgba(238, 238, 238, 0.7);
}

.c-sc-grey-6-opacity-8 {
  color: rgba(238, 238, 238, 0.8) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-8\:h:hover,
.c-sc-grey-6-opacity-8\:h:focus,
.c-sc-grey-6-opacity-8\:h:active {
  color: rgba(238, 238, 238, 0.8) !important;
}

.bgc-sc-grey-6-opacity-8 {
  background-color: rgba(238, 238, 238, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-8\:h:hover,
.bgc-sc-grey-6-opacity-8\:h:focus,
.bgc-sc-grey-6-opacity-8\:h:active {
  background-color: rgba(238, 238, 238, 0.8) !important;
}

.bc-sc-grey-6-opacity-8 {
  border-color: rgba(238, 238, 238, 0.8);
}

.c-sc-grey-6-opacity-9 {
  color: rgba(238, 238, 238, 0.9) !important;
  transition: color 300ms;
}

.c-sc-grey-6-opacity-9\:h:hover,
.c-sc-grey-6-opacity-9\:h:focus,
.c-sc-grey-6-opacity-9\:h:active {
  color: rgba(238, 238, 238, 0.9) !important;
}

.bgc-sc-grey-6-opacity-9 {
  background-color: rgba(238, 238, 238, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6-opacity-9\:h:hover,
.bgc-sc-grey-6-opacity-9\:h:focus,
.bgc-sc-grey-6-opacity-9\:h:active {
  background-color: rgba(238, 238, 238, 0.9) !important;
}

.bc-sc-grey-6-opacity-9 {
  border-color: rgba(238, 238, 238, 0.9);
}

.c-sc-grey-6 {
  color: #eeeeee !important;
  transition: color 300ms;
}

.c-sc-grey-6\:h:hover,
.c-sc-grey-6\:h:focus,
.c-sc-grey-6\:h:active {
  color: #eeeeee !important;
}

.bgc-sc-grey-6 {
  background-color: #eeeeee !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-6\:h:hover,
.bgc-sc-grey-6\:h:focus,
.bgc-sc-grey-6\:h:active {
  background-color: #eeeeee !important;
}

.bc-sc-grey-6 {
  border-color: #eeeeee;
}

.c-sc-grey-7-opacity-1 {
  color: rgba(252, 252, 252, 0.1) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-1\:h:hover,
.c-sc-grey-7-opacity-1\:h:focus,
.c-sc-grey-7-opacity-1\:h:active {
  color: rgba(252, 252, 252, 0.1) !important;
}

.bgc-sc-grey-7-opacity-1 {
  background-color: rgba(252, 252, 252, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-1\:h:hover,
.bgc-sc-grey-7-opacity-1\:h:focus,
.bgc-sc-grey-7-opacity-1\:h:active {
  background-color: rgba(252, 252, 252, 0.1) !important;
}

.bc-sc-grey-7-opacity-1 {
  border-color: rgba(252, 252, 252, 0.1);
}

.c-sc-grey-7-opacity-2 {
  color: rgba(252, 252, 252, 0.2) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-2\:h:hover,
.c-sc-grey-7-opacity-2\:h:focus,
.c-sc-grey-7-opacity-2\:h:active {
  color: rgba(252, 252, 252, 0.2) !important;
}

.bgc-sc-grey-7-opacity-2 {
  background-color: rgba(252, 252, 252, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-2\:h:hover,
.bgc-sc-grey-7-opacity-2\:h:focus,
.bgc-sc-grey-7-opacity-2\:h:active {
  background-color: rgba(252, 252, 252, 0.2) !important;
}

.bc-sc-grey-7-opacity-2 {
  border-color: rgba(252, 252, 252, 0.2);
}

.c-sc-grey-7-opacity-3 {
  color: rgba(252, 252, 252, 0.3) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-3\:h:hover,
.c-sc-grey-7-opacity-3\:h:focus,
.c-sc-grey-7-opacity-3\:h:active {
  color: rgba(252, 252, 252, 0.3) !important;
}

.bgc-sc-grey-7-opacity-3 {
  background-color: rgba(252, 252, 252, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-3\:h:hover,
.bgc-sc-grey-7-opacity-3\:h:focus,
.bgc-sc-grey-7-opacity-3\:h:active {
  background-color: rgba(252, 252, 252, 0.3) !important;
}

.bc-sc-grey-7-opacity-3 {
  border-color: rgba(252, 252, 252, 0.3);
}

.c-sc-grey-7-opacity-4 {
  color: rgba(252, 252, 252, 0.4) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-4\:h:hover,
.c-sc-grey-7-opacity-4\:h:focus,
.c-sc-grey-7-opacity-4\:h:active {
  color: rgba(252, 252, 252, 0.4) !important;
}

.bgc-sc-grey-7-opacity-4 {
  background-color: rgba(252, 252, 252, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-4\:h:hover,
.bgc-sc-grey-7-opacity-4\:h:focus,
.bgc-sc-grey-7-opacity-4\:h:active {
  background-color: rgba(252, 252, 252, 0.4) !important;
}

.bc-sc-grey-7-opacity-4 {
  border-color: rgba(252, 252, 252, 0.4);
}

.c-sc-grey-7-opacity-5 {
  color: rgba(252, 252, 252, 0.5) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-5\:h:hover,
.c-sc-grey-7-opacity-5\:h:focus,
.c-sc-grey-7-opacity-5\:h:active {
  color: rgba(252, 252, 252, 0.5) !important;
}

.bgc-sc-grey-7-opacity-5 {
  background-color: rgba(252, 252, 252, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-5\:h:hover,
.bgc-sc-grey-7-opacity-5\:h:focus,
.bgc-sc-grey-7-opacity-5\:h:active {
  background-color: rgba(252, 252, 252, 0.5) !important;
}

.bc-sc-grey-7-opacity-5 {
  border-color: rgba(252, 252, 252, 0.5);
}

.c-sc-grey-7-opacity-6 {
  color: rgba(252, 252, 252, 0.6) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-6\:h:hover,
.c-sc-grey-7-opacity-6\:h:focus,
.c-sc-grey-7-opacity-6\:h:active {
  color: rgba(252, 252, 252, 0.6) !important;
}

.bgc-sc-grey-7-opacity-6 {
  background-color: rgba(252, 252, 252, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-6\:h:hover,
.bgc-sc-grey-7-opacity-6\:h:focus,
.bgc-sc-grey-7-opacity-6\:h:active {
  background-color: rgba(252, 252, 252, 0.6) !important;
}

.bc-sc-grey-7-opacity-6 {
  border-color: rgba(252, 252, 252, 0.6);
}

.c-sc-grey-7-opacity-7 {
  color: rgba(252, 252, 252, 0.7) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-7\:h:hover,
.c-sc-grey-7-opacity-7\:h:focus,
.c-sc-grey-7-opacity-7\:h:active {
  color: rgba(252, 252, 252, 0.7) !important;
}

.bgc-sc-grey-7-opacity-7 {
  background-color: rgba(252, 252, 252, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-7\:h:hover,
.bgc-sc-grey-7-opacity-7\:h:focus,
.bgc-sc-grey-7-opacity-7\:h:active {
  background-color: rgba(252, 252, 252, 0.7) !important;
}

.bc-sc-grey-7-opacity-7 {
  border-color: rgba(252, 252, 252, 0.7);
}

.c-sc-grey-7-opacity-8 {
  color: rgba(252, 252, 252, 0.8) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-8\:h:hover,
.c-sc-grey-7-opacity-8\:h:focus,
.c-sc-grey-7-opacity-8\:h:active {
  color: rgba(252, 252, 252, 0.8) !important;
}

.bgc-sc-grey-7-opacity-8 {
  background-color: rgba(252, 252, 252, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-8\:h:hover,
.bgc-sc-grey-7-opacity-8\:h:focus,
.bgc-sc-grey-7-opacity-8\:h:active {
  background-color: rgba(252, 252, 252, 0.8) !important;
}

.bc-sc-grey-7-opacity-8 {
  border-color: rgba(252, 252, 252, 0.8);
}

.c-sc-grey-7-opacity-9 {
  color: rgba(252, 252, 252, 0.9) !important;
  transition: color 300ms;
}

.c-sc-grey-7-opacity-9\:h:hover,
.c-sc-grey-7-opacity-9\:h:focus,
.c-sc-grey-7-opacity-9\:h:active {
  color: rgba(252, 252, 252, 0.9) !important;
}

.bgc-sc-grey-7-opacity-9 {
  background-color: rgba(252, 252, 252, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7-opacity-9\:h:hover,
.bgc-sc-grey-7-opacity-9\:h:focus,
.bgc-sc-grey-7-opacity-9\:h:active {
  background-color: rgba(252, 252, 252, 0.9) !important;
}

.bc-sc-grey-7-opacity-9 {
  border-color: rgba(252, 252, 252, 0.9);
}

.c-sc-grey-7 {
  color: #fcfcfc !important;
  transition: color 300ms;
}

.c-sc-grey-7\:h:hover,
.c-sc-grey-7\:h:focus,
.c-sc-grey-7\:h:active {
  color: #fcfcfc !important;
}

.bgc-sc-grey-7 {
  background-color: #fcfcfc !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-7\:h:hover,
.bgc-sc-grey-7\:h:focus,
.bgc-sc-grey-7\:h:active {
  background-color: #fcfcfc !important;
}

.bc-sc-grey-7 {
  border-color: #fcfcfc;
}

.c-sc-grey-8-opacity-1 {
  color: rgba(241, 239, 236, 0.1) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-1\:h:hover,
.c-sc-grey-8-opacity-1\:h:focus,
.c-sc-grey-8-opacity-1\:h:active {
  color: rgba(241, 239, 236, 0.1) !important;
}

.bgc-sc-grey-8-opacity-1 {
  background-color: rgba(241, 239, 236, 0.1) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-1\:h:hover,
.bgc-sc-grey-8-opacity-1\:h:focus,
.bgc-sc-grey-8-opacity-1\:h:active {
  background-color: rgba(241, 239, 236, 0.1) !important;
}

.bc-sc-grey-8-opacity-1 {
  border-color: rgba(241, 239, 236, 0.1);
}

.c-sc-grey-8-opacity-2 {
  color: rgba(241, 239, 236, 0.2) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-2\:h:hover,
.c-sc-grey-8-opacity-2\:h:focus,
.c-sc-grey-8-opacity-2\:h:active {
  color: rgba(241, 239, 236, 0.2) !important;
}

.bgc-sc-grey-8-opacity-2 {
  background-color: rgba(241, 239, 236, 0.2) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-2\:h:hover,
.bgc-sc-grey-8-opacity-2\:h:focus,
.bgc-sc-grey-8-opacity-2\:h:active {
  background-color: rgba(241, 239, 236, 0.2) !important;
}

.bc-sc-grey-8-opacity-2 {
  border-color: rgba(241, 239, 236, 0.2);
}

.c-sc-grey-8-opacity-3 {
  color: rgba(241, 239, 236, 0.3) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-3\:h:hover,
.c-sc-grey-8-opacity-3\:h:focus,
.c-sc-grey-8-opacity-3\:h:active {
  color: rgba(241, 239, 236, 0.3) !important;
}

.bgc-sc-grey-8-opacity-3 {
  background-color: rgba(241, 239, 236, 0.3) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-3\:h:hover,
.bgc-sc-grey-8-opacity-3\:h:focus,
.bgc-sc-grey-8-opacity-3\:h:active {
  background-color: rgba(241, 239, 236, 0.3) !important;
}

.bc-sc-grey-8-opacity-3 {
  border-color: rgba(241, 239, 236, 0.3);
}

.c-sc-grey-8-opacity-4 {
  color: rgba(241, 239, 236, 0.4) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-4\:h:hover,
.c-sc-grey-8-opacity-4\:h:focus,
.c-sc-grey-8-opacity-4\:h:active {
  color: rgba(241, 239, 236, 0.4) !important;
}

.bgc-sc-grey-8-opacity-4 {
  background-color: rgba(241, 239, 236, 0.4) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-4\:h:hover,
.bgc-sc-grey-8-opacity-4\:h:focus,
.bgc-sc-grey-8-opacity-4\:h:active {
  background-color: rgba(241, 239, 236, 0.4) !important;
}

.bc-sc-grey-8-opacity-4 {
  border-color: rgba(241, 239, 236, 0.4);
}

.c-sc-grey-8-opacity-5 {
  color: rgba(241, 239, 236, 0.5) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-5\:h:hover,
.c-sc-grey-8-opacity-5\:h:focus,
.c-sc-grey-8-opacity-5\:h:active {
  color: rgba(241, 239, 236, 0.5) !important;
}

.bgc-sc-grey-8-opacity-5 {
  background-color: rgba(241, 239, 236, 0.5) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-5\:h:hover,
.bgc-sc-grey-8-opacity-5\:h:focus,
.bgc-sc-grey-8-opacity-5\:h:active {
  background-color: rgba(241, 239, 236, 0.5) !important;
}

.bc-sc-grey-8-opacity-5 {
  border-color: rgba(241, 239, 236, 0.5);
}

.c-sc-grey-8-opacity-6 {
  color: rgba(241, 239, 236, 0.6) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-6\:h:hover,
.c-sc-grey-8-opacity-6\:h:focus,
.c-sc-grey-8-opacity-6\:h:active {
  color: rgba(241, 239, 236, 0.6) !important;
}

.bgc-sc-grey-8-opacity-6 {
  background-color: rgba(241, 239, 236, 0.6) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-6\:h:hover,
.bgc-sc-grey-8-opacity-6\:h:focus,
.bgc-sc-grey-8-opacity-6\:h:active {
  background-color: rgba(241, 239, 236, 0.6) !important;
}

.bc-sc-grey-8-opacity-6 {
  border-color: rgba(241, 239, 236, 0.6);
}

.c-sc-grey-8-opacity-7 {
  color: rgba(241, 239, 236, 0.7) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-7\:h:hover,
.c-sc-grey-8-opacity-7\:h:focus,
.c-sc-grey-8-opacity-7\:h:active {
  color: rgba(241, 239, 236, 0.7) !important;
}

.bgc-sc-grey-8-opacity-7 {
  background-color: rgba(241, 239, 236, 0.7) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-7\:h:hover,
.bgc-sc-grey-8-opacity-7\:h:focus,
.bgc-sc-grey-8-opacity-7\:h:active {
  background-color: rgba(241, 239, 236, 0.7) !important;
}

.bc-sc-grey-8-opacity-7 {
  border-color: rgba(241, 239, 236, 0.7);
}

.c-sc-grey-8-opacity-8 {
  color: rgba(241, 239, 236, 0.8) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-8\:h:hover,
.c-sc-grey-8-opacity-8\:h:focus,
.c-sc-grey-8-opacity-8\:h:active {
  color: rgba(241, 239, 236, 0.8) !important;
}

.bgc-sc-grey-8-opacity-8 {
  background-color: rgba(241, 239, 236, 0.8) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-8\:h:hover,
.bgc-sc-grey-8-opacity-8\:h:focus,
.bgc-sc-grey-8-opacity-8\:h:active {
  background-color: rgba(241, 239, 236, 0.8) !important;
}

.bc-sc-grey-8-opacity-8 {
  border-color: rgba(241, 239, 236, 0.8);
}

.c-sc-grey-8-opacity-9 {
  color: rgba(241, 239, 236, 0.9) !important;
  transition: color 300ms;
}

.c-sc-grey-8-opacity-9\:h:hover,
.c-sc-grey-8-opacity-9\:h:focus,
.c-sc-grey-8-opacity-9\:h:active {
  color: rgba(241, 239, 236, 0.9) !important;
}

.bgc-sc-grey-8-opacity-9 {
  background-color: rgba(241, 239, 236, 0.9) !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8-opacity-9\:h:hover,
.bgc-sc-grey-8-opacity-9\:h:focus,
.bgc-sc-grey-8-opacity-9\:h:active {
  background-color: rgba(241, 239, 236, 0.9) !important;
}

.bc-sc-grey-8-opacity-9 {
  border-color: rgba(241, 239, 236, 0.9);
}

.c-sc-grey-8 {
  color: #f1efec !important;
  transition: color 300ms;
}

.c-sc-grey-8\:h:hover,
.c-sc-grey-8\:h:focus,
.c-sc-grey-8\:h:active {
  color: #f1efec !important;
}

.bgc-sc-grey-8 {
  background-color: #f1efec !important;
  transition: background-color 300ms;
}

.bgc-sc-grey-8\:h:hover,
.bgc-sc-grey-8\:h:focus,
.bgc-sc-grey-8\:h:active {
  background-color: #f1efec !important;
}

.bc-sc-grey-8 {
  border-color: #f1efec;
}

.b-solid-1 {
  border-style: solid;
  border-width: 1px;
}

.b-radius-1 {
  border-radius: 1px;
}

.b-solid-1-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.b-solid-1-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-1-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.b-solid-1-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-1-right {
  border-right-style: solid;
  border-right-width: 1px;
}

.b-solid-1-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-1-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.b-solid-1-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-1-top-left {
  border-top-left-radius: 1px;
}

.b-radius-1-top-right {
  border-top-right-radius: 1px;
}

.b-radius-1-bottom-left {
  border-bottom-left-radius: 1px;
}

.b-radius-1-bottom-right {
  border-bottom-right-radius: 1px;
}

.b-solid-2 {
  border-style: solid;
  border-width: 2px;
}

.b-radius-2 {
  border-radius: 2px;
}

.b-solid-2-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.b-solid-2-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-2-left {
  border-left-style: solid;
  border-left-width: 2px;
}

.b-solid-2-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-2-right {
  border-right-style: solid;
  border-right-width: 2px;
}

.b-solid-2-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-2-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.b-solid-2-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-2-top-left {
  border-top-left-radius: 2px;
}

.b-radius-2-top-right {
  border-top-right-radius: 2px;
}

.b-radius-2-bottom-left {
  border-bottom-left-radius: 2px;
}

.b-radius-2-bottom-right {
  border-bottom-right-radius: 2px;
}

.b-solid-3 {
  border-style: solid;
  border-width: 3px;
}

.b-radius-3 {
  border-radius: 3px;
}

.b-solid-3-bottom {
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.b-solid-3-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-3-left {
  border-left-style: solid;
  border-left-width: 3px;
}

.b-solid-3-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-3-right {
  border-right-style: solid;
  border-right-width: 3px;
}

.b-solid-3-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-3-top {
  border-top-style: solid;
  border-top-width: 3px;
}

.b-solid-3-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-3-top-left {
  border-top-left-radius: 3px;
}

.b-radius-3-top-right {
  border-top-right-radius: 3px;
}

.b-radius-3-bottom-left {
  border-bottom-left-radius: 3px;
}

.b-radius-3-bottom-right {
  border-bottom-right-radius: 3px;
}

.b-solid-4 {
  border-style: solid;
  border-width: 4px;
}

.b-radius-4 {
  border-radius: 4px;
}

.b-solid-4-bottom {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.b-solid-4-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-4-left {
  border-left-style: solid;
  border-left-width: 4px;
}

.b-solid-4-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-4-right {
  border-right-style: solid;
  border-right-width: 4px;
}

.b-solid-4-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-4-top {
  border-top-style: solid;
  border-top-width: 4px;
}

.b-solid-4-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-4-top-left {
  border-top-left-radius: 4px;
}

.b-radius-4-top-right {
  border-top-right-radius: 4px;
}

.b-radius-4-bottom-left {
  border-bottom-left-radius: 4px;
}

.b-radius-4-bottom-right {
  border-bottom-right-radius: 4px;
}

.b-solid-5 {
  border-style: solid;
  border-width: 5px;
}

.b-radius-5 {
  border-radius: 5px;
}

.b-solid-5-bottom {
  border-bottom-style: solid;
  border-bottom-width: 5px;
}

.b-solid-5-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-5-left {
  border-left-style: solid;
  border-left-width: 5px;
}

.b-solid-5-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-5-right {
  border-right-style: solid;
  border-right-width: 5px;
}

.b-solid-5-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-5-top {
  border-top-style: solid;
  border-top-width: 5px;
}

.b-solid-5-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-5-top-left {
  border-top-left-radius: 5px;
}

.b-radius-5-top-right {
  border-top-right-radius: 5px;
}

.b-radius-5-bottom-left {
  border-bottom-left-radius: 5px;
}

.b-radius-5-bottom-right {
  border-bottom-right-radius: 5px;
}

.b-solid-6 {
  border-style: solid;
  border-width: 6px;
}

.b-radius-6 {
  border-radius: 6px;
}

.b-solid-6-bottom {
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.b-solid-6-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-6-left {
  border-left-style: solid;
  border-left-width: 6px;
}

.b-solid-6-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-6-right {
  border-right-style: solid;
  border-right-width: 6px;
}

.b-solid-6-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-6-top {
  border-top-style: solid;
  border-top-width: 6px;
}

.b-solid-6-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-6-top-left {
  border-top-left-radius: 6px;
}

.b-radius-6-top-right {
  border-top-right-radius: 6px;
}

.b-radius-6-bottom-left {
  border-bottom-left-radius: 6px;
}

.b-radius-6-bottom-right {
  border-bottom-right-radius: 6px;
}

.b-solid-7 {
  border-style: solid;
  border-width: 7px;
}

.b-radius-7 {
  border-radius: 7px;
}

.b-solid-7-bottom {
  border-bottom-style: solid;
  border-bottom-width: 7px;
}

.b-solid-7-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-7-left {
  border-left-style: solid;
  border-left-width: 7px;
}

.b-solid-7-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-7-right {
  border-right-style: solid;
  border-right-width: 7px;
}

.b-solid-7-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-7-top {
  border-top-style: solid;
  border-top-width: 7px;
}

.b-solid-7-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-7-top-left {
  border-top-left-radius: 7px;
}

.b-radius-7-top-right {
  border-top-right-radius: 7px;
}

.b-radius-7-bottom-left {
  border-bottom-left-radius: 7px;
}

.b-radius-7-bottom-right {
  border-bottom-right-radius: 7px;
}

.b-solid-8 {
  border-style: solid;
  border-width: 8px;
}

.b-radius-8 {
  border-radius: 8px;
}

.b-solid-8-bottom {
  border-bottom-style: solid;
  border-bottom-width: 8px;
}

.b-solid-8-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-8-left {
  border-left-style: solid;
  border-left-width: 8px;
}

.b-solid-8-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-8-right {
  border-right-style: solid;
  border-right-width: 8px;
}

.b-solid-8-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-8-top {
  border-top-style: solid;
  border-top-width: 8px;
}

.b-solid-8-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-8-top-left {
  border-top-left-radius: 8px;
}

.b-radius-8-top-right {
  border-top-right-radius: 8px;
}

.b-radius-8-bottom-left {
  border-bottom-left-radius: 8px;
}

.b-radius-8-bottom-right {
  border-bottom-right-radius: 8px;
}

.b-solid-9 {
  border-style: solid;
  border-width: 9px;
}

.b-radius-9 {
  border-radius: 9px;
}

.b-solid-9-bottom {
  border-bottom-style: solid;
  border-bottom-width: 9px;
}

.b-solid-9-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-9-left {
  border-left-style: solid;
  border-left-width: 9px;
}

.b-solid-9-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-9-right {
  border-right-style: solid;
  border-right-width: 9px;
}

.b-solid-9-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-9-top {
  border-top-style: solid;
  border-top-width: 9px;
}

.b-solid-9-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-9-top-left {
  border-top-left-radius: 9px;
}

.b-radius-9-top-right {
  border-top-right-radius: 9px;
}

.b-radius-9-bottom-left {
  border-bottom-left-radius: 9px;
}

.b-radius-9-bottom-right {
  border-bottom-right-radius: 9px;
}

.b-solid-10 {
  border-style: solid;
  border-width: 10px;
}

.b-radius-10 {
  border-radius: 10px;
}

.b-solid-10-bottom {
  border-bottom-style: solid;
  border-bottom-width: 10px;
}

.b-solid-10-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-10-left {
  border-left-style: solid;
  border-left-width: 10px;
}

.b-solid-10-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-10-right {
  border-right-style: solid;
  border-right-width: 10px;
}

.b-solid-10-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-10-top {
  border-top-style: solid;
  border-top-width: 10px;
}

.b-solid-10-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-10-top-left {
  border-top-left-radius: 10px;
}

.b-radius-10-top-right {
  border-top-right-radius: 10px;
}

.b-radius-10-bottom-left {
  border-bottom-left-radius: 10px;
}

.b-radius-10-bottom-right {
  border-bottom-right-radius: 10px;
}

.b-solid-11 {
  border-style: solid;
  border-width: 11px;
}

.b-radius-11 {
  border-radius: 11px;
}

.b-solid-11-bottom {
  border-bottom-style: solid;
  border-bottom-width: 11px;
}

.b-solid-11-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-11-left {
  border-left-style: solid;
  border-left-width: 11px;
}

.b-solid-11-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-11-right {
  border-right-style: solid;
  border-right-width: 11px;
}

.b-solid-11-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-11-top {
  border-top-style: solid;
  border-top-width: 11px;
}

.b-solid-11-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-11-top-left {
  border-top-left-radius: 11px;
}

.b-radius-11-top-right {
  border-top-right-radius: 11px;
}

.b-radius-11-bottom-left {
  border-bottom-left-radius: 11px;
}

.b-radius-11-bottom-right {
  border-bottom-right-radius: 11px;
}

.b-solid-12 {
  border-style: solid;
  border-width: 12px;
}

.b-radius-12 {
  border-radius: 12px;
}

.b-solid-12-bottom {
  border-bottom-style: solid;
  border-bottom-width: 12px;
}

.b-solid-12-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-12-left {
  border-left-style: solid;
  border-left-width: 12px;
}

.b-solid-12-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-12-right {
  border-right-style: solid;
  border-right-width: 12px;
}

.b-solid-12-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-12-top {
  border-top-style: solid;
  border-top-width: 12px;
}

.b-solid-12-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-12-top-left {
  border-top-left-radius: 12px;
}

.b-radius-12-top-right {
  border-top-right-radius: 12px;
}

.b-radius-12-bottom-left {
  border-bottom-left-radius: 12px;
}

.b-radius-12-bottom-right {
  border-bottom-right-radius: 12px;
}

.b-solid-13 {
  border-style: solid;
  border-width: 13px;
}

.b-radius-13 {
  border-radius: 13px;
}

.b-solid-13-bottom {
  border-bottom-style: solid;
  border-bottom-width: 13px;
}

.b-solid-13-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-13-left {
  border-left-style: solid;
  border-left-width: 13px;
}

.b-solid-13-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-13-right {
  border-right-style: solid;
  border-right-width: 13px;
}

.b-solid-13-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-13-top {
  border-top-style: solid;
  border-top-width: 13px;
}

.b-solid-13-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-13-top-left {
  border-top-left-radius: 13px;
}

.b-radius-13-top-right {
  border-top-right-radius: 13px;
}

.b-radius-13-bottom-left {
  border-bottom-left-radius: 13px;
}

.b-radius-13-bottom-right {
  border-bottom-right-radius: 13px;
}

.b-solid-14 {
  border-style: solid;
  border-width: 14px;
}

.b-radius-14 {
  border-radius: 14px;
}

.b-solid-14-bottom {
  border-bottom-style: solid;
  border-bottom-width: 14px;
}

.b-solid-14-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-14-left {
  border-left-style: solid;
  border-left-width: 14px;
}

.b-solid-14-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-14-right {
  border-right-style: solid;
  border-right-width: 14px;
}

.b-solid-14-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-14-top {
  border-top-style: solid;
  border-top-width: 14px;
}

.b-solid-14-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-14-top-left {
  border-top-left-radius: 14px;
}

.b-radius-14-top-right {
  border-top-right-radius: 14px;
}

.b-radius-14-bottom-left {
  border-bottom-left-radius: 14px;
}

.b-radius-14-bottom-right {
  border-bottom-right-radius: 14px;
}

.b-solid-15 {
  border-style: solid;
  border-width: 15px;
}

.b-radius-15 {
  border-radius: 15px;
}

.b-solid-15-bottom {
  border-bottom-style: solid;
  border-bottom-width: 15px;
}

.b-solid-15-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-15-left {
  border-left-style: solid;
  border-left-width: 15px;
}

.b-solid-15-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-15-right {
  border-right-style: solid;
  border-right-width: 15px;
}

.b-solid-15-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-15-top {
  border-top-style: solid;
  border-top-width: 15px;
}

.b-solid-15-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-15-top-left {
  border-top-left-radius: 15px;
}

.b-radius-15-top-right {
  border-top-right-radius: 15px;
}

.b-radius-15-bottom-left {
  border-bottom-left-radius: 15px;
}

.b-radius-15-bottom-right {
  border-bottom-right-radius: 15px;
}

.b-solid-16 {
  border-style: solid;
  border-width: 16px;
}

.b-radius-16 {
  border-radius: 16px;
}

.b-solid-16-bottom {
  border-bottom-style: solid;
  border-bottom-width: 16px;
}

.b-solid-16-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-16-left {
  border-left-style: solid;
  border-left-width: 16px;
}

.b-solid-16-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-16-right {
  border-right-style: solid;
  border-right-width: 16px;
}

.b-solid-16-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-16-top {
  border-top-style: solid;
  border-top-width: 16px;
}

.b-solid-16-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-16-top-left {
  border-top-left-radius: 16px;
}

.b-radius-16-top-right {
  border-top-right-radius: 16px;
}

.b-radius-16-bottom-left {
  border-bottom-left-radius: 16px;
}

.b-radius-16-bottom-right {
  border-bottom-right-radius: 16px;
}

.b-solid-17 {
  border-style: solid;
  border-width: 17px;
}

.b-radius-17 {
  border-radius: 17px;
}

.b-solid-17-bottom {
  border-bottom-style: solid;
  border-bottom-width: 17px;
}

.b-solid-17-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-17-left {
  border-left-style: solid;
  border-left-width: 17px;
}

.b-solid-17-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-17-right {
  border-right-style: solid;
  border-right-width: 17px;
}

.b-solid-17-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-17-top {
  border-top-style: solid;
  border-top-width: 17px;
}

.b-solid-17-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-17-top-left {
  border-top-left-radius: 17px;
}

.b-radius-17-top-right {
  border-top-right-radius: 17px;
}

.b-radius-17-bottom-left {
  border-bottom-left-radius: 17px;
}

.b-radius-17-bottom-right {
  border-bottom-right-radius: 17px;
}

.b-solid-18 {
  border-style: solid;
  border-width: 18px;
}

.b-radius-18 {
  border-radius: 18px;
}

.b-solid-18-bottom {
  border-bottom-style: solid;
  border-bottom-width: 18px;
}

.b-solid-18-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-18-left {
  border-left-style: solid;
  border-left-width: 18px;
}

.b-solid-18-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-18-right {
  border-right-style: solid;
  border-right-width: 18px;
}

.b-solid-18-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-18-top {
  border-top-style: solid;
  border-top-width: 18px;
}

.b-solid-18-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-18-top-left {
  border-top-left-radius: 18px;
}

.b-radius-18-top-right {
  border-top-right-radius: 18px;
}

.b-radius-18-bottom-left {
  border-bottom-left-radius: 18px;
}

.b-radius-18-bottom-right {
  border-bottom-right-radius: 18px;
}

.b-solid-19 {
  border-style: solid;
  border-width: 19px;
}

.b-radius-19 {
  border-radius: 19px;
}

.b-solid-19-bottom {
  border-bottom-style: solid;
  border-bottom-width: 19px;
}

.b-solid-19-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-19-left {
  border-left-style: solid;
  border-left-width: 19px;
}

.b-solid-19-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-19-right {
  border-right-style: solid;
  border-right-width: 19px;
}

.b-solid-19-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-19-top {
  border-top-style: solid;
  border-top-width: 19px;
}

.b-solid-19-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-19-top-left {
  border-top-left-radius: 19px;
}

.b-radius-19-top-right {
  border-top-right-radius: 19px;
}

.b-radius-19-bottom-left {
  border-bottom-left-radius: 19px;
}

.b-radius-19-bottom-right {
  border-bottom-right-radius: 19px;
}

.b-solid-20 {
  border-style: solid;
  border-width: 20px;
}

.b-radius-20 {
  border-radius: 20px;
}

.b-solid-20-bottom {
  border-bottom-style: solid;
  border-bottom-width: 20px;
}

.b-solid-20-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-20-left {
  border-left-style: solid;
  border-left-width: 20px;
}

.b-solid-20-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-20-right {
  border-right-style: solid;
  border-right-width: 20px;
}

.b-solid-20-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-20-top {
  border-top-style: solid;
  border-top-width: 20px;
}

.b-solid-20-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-20-top-left {
  border-top-left-radius: 20px;
}

.b-radius-20-top-right {
  border-top-right-radius: 20px;
}

.b-radius-20-bottom-left {
  border-bottom-left-radius: 20px;
}

.b-radius-20-bottom-right {
  border-bottom-right-radius: 20px;
}

.b-solid-21 {
  border-style: solid;
  border-width: 21px;
}

.b-radius-21 {
  border-radius: 21px;
}

.b-solid-21-bottom {
  border-bottom-style: solid;
  border-bottom-width: 21px;
}

.b-solid-21-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-21-left {
  border-left-style: solid;
  border-left-width: 21px;
}

.b-solid-21-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-21-right {
  border-right-style: solid;
  border-right-width: 21px;
}

.b-solid-21-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-21-top {
  border-top-style: solid;
  border-top-width: 21px;
}

.b-solid-21-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-21-top-left {
  border-top-left-radius: 21px;
}

.b-radius-21-top-right {
  border-top-right-radius: 21px;
}

.b-radius-21-bottom-left {
  border-bottom-left-radius: 21px;
}

.b-radius-21-bottom-right {
  border-bottom-right-radius: 21px;
}

.b-solid-22 {
  border-style: solid;
  border-width: 22px;
}

.b-radius-22 {
  border-radius: 22px;
}

.b-solid-22-bottom {
  border-bottom-style: solid;
  border-bottom-width: 22px;
}

.b-solid-22-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-22-left {
  border-left-style: solid;
  border-left-width: 22px;
}

.b-solid-22-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-22-right {
  border-right-style: solid;
  border-right-width: 22px;
}

.b-solid-22-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-22-top {
  border-top-style: solid;
  border-top-width: 22px;
}

.b-solid-22-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-22-top-left {
  border-top-left-radius: 22px;
}

.b-radius-22-top-right {
  border-top-right-radius: 22px;
}

.b-radius-22-bottom-left {
  border-bottom-left-radius: 22px;
}

.b-radius-22-bottom-right {
  border-bottom-right-radius: 22px;
}

.b-solid-23 {
  border-style: solid;
  border-width: 23px;
}

.b-radius-23 {
  border-radius: 23px;
}

.b-solid-23-bottom {
  border-bottom-style: solid;
  border-bottom-width: 23px;
}

.b-solid-23-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-23-left {
  border-left-style: solid;
  border-left-width: 23px;
}

.b-solid-23-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-23-right {
  border-right-style: solid;
  border-right-width: 23px;
}

.b-solid-23-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-23-top {
  border-top-style: solid;
  border-top-width: 23px;
}

.b-solid-23-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-23-top-left {
  border-top-left-radius: 23px;
}

.b-radius-23-top-right {
  border-top-right-radius: 23px;
}

.b-radius-23-bottom-left {
  border-bottom-left-radius: 23px;
}

.b-radius-23-bottom-right {
  border-bottom-right-radius: 23px;
}

.b-solid-24 {
  border-style: solid;
  border-width: 24px;
}

.b-radius-24 {
  border-radius: 24px;
}

.b-solid-24-bottom {
  border-bottom-style: solid;
  border-bottom-width: 24px;
}

.b-solid-24-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-24-left {
  border-left-style: solid;
  border-left-width: 24px;
}

.b-solid-24-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-24-right {
  border-right-style: solid;
  border-right-width: 24px;
}

.b-solid-24-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-24-top {
  border-top-style: solid;
  border-top-width: 24px;
}

.b-solid-24-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-24-top-left {
  border-top-left-radius: 24px;
}

.b-radius-24-top-right {
  border-top-right-radius: 24px;
}

.b-radius-24-bottom-left {
  border-bottom-left-radius: 24px;
}

.b-radius-24-bottom-right {
  border-bottom-right-radius: 24px;
}

.b-solid-25 {
  border-style: solid;
  border-width: 25px;
}

.b-radius-25 {
  border-radius: 25px;
}

.b-solid-25-bottom {
  border-bottom-style: solid;
  border-bottom-width: 25px;
}

.b-solid-25-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-25-left {
  border-left-style: solid;
  border-left-width: 25px;
}

.b-solid-25-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-25-right {
  border-right-style: solid;
  border-right-width: 25px;
}

.b-solid-25-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-25-top {
  border-top-style: solid;
  border-top-width: 25px;
}

.b-solid-25-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-25-top-left {
  border-top-left-radius: 25px;
}

.b-radius-25-top-right {
  border-top-right-radius: 25px;
}

.b-radius-25-bottom-left {
  border-bottom-left-radius: 25px;
}

.b-radius-25-bottom-right {
  border-bottom-right-radius: 25px;
}

.b-solid-26 {
  border-style: solid;
  border-width: 26px;
}

.b-radius-26 {
  border-radius: 26px;
}

.b-solid-26-bottom {
  border-bottom-style: solid;
  border-bottom-width: 26px;
}

.b-solid-26-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-26-left {
  border-left-style: solid;
  border-left-width: 26px;
}

.b-solid-26-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-26-right {
  border-right-style: solid;
  border-right-width: 26px;
}

.b-solid-26-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-26-top {
  border-top-style: solid;
  border-top-width: 26px;
}

.b-solid-26-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-26-top-left {
  border-top-left-radius: 26px;
}

.b-radius-26-top-right {
  border-top-right-radius: 26px;
}

.b-radius-26-bottom-left {
  border-bottom-left-radius: 26px;
}

.b-radius-26-bottom-right {
  border-bottom-right-radius: 26px;
}

.b-solid-27 {
  border-style: solid;
  border-width: 27px;
}

.b-radius-27 {
  border-radius: 27px;
}

.b-solid-27-bottom {
  border-bottom-style: solid;
  border-bottom-width: 27px;
}

.b-solid-27-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-27-left {
  border-left-style: solid;
  border-left-width: 27px;
}

.b-solid-27-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-27-right {
  border-right-style: solid;
  border-right-width: 27px;
}

.b-solid-27-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-27-top {
  border-top-style: solid;
  border-top-width: 27px;
}

.b-solid-27-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-27-top-left {
  border-top-left-radius: 27px;
}

.b-radius-27-top-right {
  border-top-right-radius: 27px;
}

.b-radius-27-bottom-left {
  border-bottom-left-radius: 27px;
}

.b-radius-27-bottom-right {
  border-bottom-right-radius: 27px;
}

.b-solid-28 {
  border-style: solid;
  border-width: 28px;
}

.b-radius-28 {
  border-radius: 28px;
}

.b-solid-28-bottom {
  border-bottom-style: solid;
  border-bottom-width: 28px;
}

.b-solid-28-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-28-left {
  border-left-style: solid;
  border-left-width: 28px;
}

.b-solid-28-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-28-right {
  border-right-style: solid;
  border-right-width: 28px;
}

.b-solid-28-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-28-top {
  border-top-style: solid;
  border-top-width: 28px;
}

.b-solid-28-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-28-top-left {
  border-top-left-radius: 28px;
}

.b-radius-28-top-right {
  border-top-right-radius: 28px;
}

.b-radius-28-bottom-left {
  border-bottom-left-radius: 28px;
}

.b-radius-28-bottom-right {
  border-bottom-right-radius: 28px;
}

.b-solid-29 {
  border-style: solid;
  border-width: 29px;
}

.b-radius-29 {
  border-radius: 29px;
}

.b-solid-29-bottom {
  border-bottom-style: solid;
  border-bottom-width: 29px;
}

.b-solid-29-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-29-left {
  border-left-style: solid;
  border-left-width: 29px;
}

.b-solid-29-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-29-right {
  border-right-style: solid;
  border-right-width: 29px;
}

.b-solid-29-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-29-top {
  border-top-style: solid;
  border-top-width: 29px;
}

.b-solid-29-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-29-top-left {
  border-top-left-radius: 29px;
}

.b-radius-29-top-right {
  border-top-right-radius: 29px;
}

.b-radius-29-bottom-left {
  border-bottom-left-radius: 29px;
}

.b-radius-29-bottom-right {
  border-bottom-right-radius: 29px;
}

.b-solid-30 {
  border-style: solid;
  border-width: 30px;
}

.b-radius-30 {
  border-radius: 30px;
}

.b-solid-30-bottom {
  border-bottom-style: solid;
  border-bottom-width: 30px;
}

.b-solid-30-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-30-left {
  border-left-style: solid;
  border-left-width: 30px;
}

.b-solid-30-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-30-right {
  border-right-style: solid;
  border-right-width: 30px;
}

.b-solid-30-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-30-top {
  border-top-style: solid;
  border-top-width: 30px;
}

.b-solid-30-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-30-top-left {
  border-top-left-radius: 30px;
}

.b-radius-30-top-right {
  border-top-right-radius: 30px;
}

.b-radius-30-bottom-left {
  border-bottom-left-radius: 30px;
}

.b-radius-30-bottom-right {
  border-bottom-right-radius: 30px;
}

.b-solid-31 {
  border-style: solid;
  border-width: 31px;
}

.b-radius-31 {
  border-radius: 31px;
}

.b-solid-31-bottom {
  border-bottom-style: solid;
  border-bottom-width: 31px;
}

.b-solid-31-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-31-left {
  border-left-style: solid;
  border-left-width: 31px;
}

.b-solid-31-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-31-right {
  border-right-style: solid;
  border-right-width: 31px;
}

.b-solid-31-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-31-top {
  border-top-style: solid;
  border-top-width: 31px;
}

.b-solid-31-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-31-top-left {
  border-top-left-radius: 31px;
}

.b-radius-31-top-right {
  border-top-right-radius: 31px;
}

.b-radius-31-bottom-left {
  border-bottom-left-radius: 31px;
}

.b-radius-31-bottom-right {
  border-bottom-right-radius: 31px;
}

.b-solid-32 {
  border-style: solid;
  border-width: 32px;
}

.b-radius-32 {
  border-radius: 32px;
}

.b-solid-32-bottom {
  border-bottom-style: solid;
  border-bottom-width: 32px;
}

.b-solid-32-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-32-left {
  border-left-style: solid;
  border-left-width: 32px;
}

.b-solid-32-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-32-right {
  border-right-style: solid;
  border-right-width: 32px;
}

.b-solid-32-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-32-top {
  border-top-style: solid;
  border-top-width: 32px;
}

.b-solid-32-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-32-top-left {
  border-top-left-radius: 32px;
}

.b-radius-32-top-right {
  border-top-right-radius: 32px;
}

.b-radius-32-bottom-left {
  border-bottom-left-radius: 32px;
}

.b-radius-32-bottom-right {
  border-bottom-right-radius: 32px;
}

.b-solid-33 {
  border-style: solid;
  border-width: 33px;
}

.b-radius-33 {
  border-radius: 33px;
}

.b-solid-33-bottom {
  border-bottom-style: solid;
  border-bottom-width: 33px;
}

.b-solid-33-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-33-left {
  border-left-style: solid;
  border-left-width: 33px;
}

.b-solid-33-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-33-right {
  border-right-style: solid;
  border-right-width: 33px;
}

.b-solid-33-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-33-top {
  border-top-style: solid;
  border-top-width: 33px;
}

.b-solid-33-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-33-top-left {
  border-top-left-radius: 33px;
}

.b-radius-33-top-right {
  border-top-right-radius: 33px;
}

.b-radius-33-bottom-left {
  border-bottom-left-radius: 33px;
}

.b-radius-33-bottom-right {
  border-bottom-right-radius: 33px;
}

.b-solid-34 {
  border-style: solid;
  border-width: 34px;
}

.b-radius-34 {
  border-radius: 34px;
}

.b-solid-34-bottom {
  border-bottom-style: solid;
  border-bottom-width: 34px;
}

.b-solid-34-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-34-left {
  border-left-style: solid;
  border-left-width: 34px;
}

.b-solid-34-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-34-right {
  border-right-style: solid;
  border-right-width: 34px;
}

.b-solid-34-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-34-top {
  border-top-style: solid;
  border-top-width: 34px;
}

.b-solid-34-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-34-top-left {
  border-top-left-radius: 34px;
}

.b-radius-34-top-right {
  border-top-right-radius: 34px;
}

.b-radius-34-bottom-left {
  border-bottom-left-radius: 34px;
}

.b-radius-34-bottom-right {
  border-bottom-right-radius: 34px;
}

.b-solid-35 {
  border-style: solid;
  border-width: 35px;
}

.b-radius-35 {
  border-radius: 35px;
}

.b-solid-35-bottom {
  border-bottom-style: solid;
  border-bottom-width: 35px;
}

.b-solid-35-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-35-left {
  border-left-style: solid;
  border-left-width: 35px;
}

.b-solid-35-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-35-right {
  border-right-style: solid;
  border-right-width: 35px;
}

.b-solid-35-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-35-top {
  border-top-style: solid;
  border-top-width: 35px;
}

.b-solid-35-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-35-top-left {
  border-top-left-radius: 35px;
}

.b-radius-35-top-right {
  border-top-right-radius: 35px;
}

.b-radius-35-bottom-left {
  border-bottom-left-radius: 35px;
}

.b-radius-35-bottom-right {
  border-bottom-right-radius: 35px;
}

.b-solid-36 {
  border-style: solid;
  border-width: 36px;
}

.b-radius-36 {
  border-radius: 36px;
}

.b-solid-36-bottom {
  border-bottom-style: solid;
  border-bottom-width: 36px;
}

.b-solid-36-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-36-left {
  border-left-style: solid;
  border-left-width: 36px;
}

.b-solid-36-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-36-right {
  border-right-style: solid;
  border-right-width: 36px;
}

.b-solid-36-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-36-top {
  border-top-style: solid;
  border-top-width: 36px;
}

.b-solid-36-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-36-top-left {
  border-top-left-radius: 36px;
}

.b-radius-36-top-right {
  border-top-right-radius: 36px;
}

.b-radius-36-bottom-left {
  border-bottom-left-radius: 36px;
}

.b-radius-36-bottom-right {
  border-bottom-right-radius: 36px;
}

.b-solid-37 {
  border-style: solid;
  border-width: 37px;
}

.b-radius-37 {
  border-radius: 37px;
}

.b-solid-37-bottom {
  border-bottom-style: solid;
  border-bottom-width: 37px;
}

.b-solid-37-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-37-left {
  border-left-style: solid;
  border-left-width: 37px;
}

.b-solid-37-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-37-right {
  border-right-style: solid;
  border-right-width: 37px;
}

.b-solid-37-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-37-top {
  border-top-style: solid;
  border-top-width: 37px;
}

.b-solid-37-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-37-top-left {
  border-top-left-radius: 37px;
}

.b-radius-37-top-right {
  border-top-right-radius: 37px;
}

.b-radius-37-bottom-left {
  border-bottom-left-radius: 37px;
}

.b-radius-37-bottom-right {
  border-bottom-right-radius: 37px;
}

.b-solid-38 {
  border-style: solid;
  border-width: 38px;
}

.b-radius-38 {
  border-radius: 38px;
}

.b-solid-38-bottom {
  border-bottom-style: solid;
  border-bottom-width: 38px;
}

.b-solid-38-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-38-left {
  border-left-style: solid;
  border-left-width: 38px;
}

.b-solid-38-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-38-right {
  border-right-style: solid;
  border-right-width: 38px;
}

.b-solid-38-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-38-top {
  border-top-style: solid;
  border-top-width: 38px;
}

.b-solid-38-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-38-top-left {
  border-top-left-radius: 38px;
}

.b-radius-38-top-right {
  border-top-right-radius: 38px;
}

.b-radius-38-bottom-left {
  border-bottom-left-radius: 38px;
}

.b-radius-38-bottom-right {
  border-bottom-right-radius: 38px;
}

.b-solid-39 {
  border-style: solid;
  border-width: 39px;
}

.b-radius-39 {
  border-radius: 39px;
}

.b-solid-39-bottom {
  border-bottom-style: solid;
  border-bottom-width: 39px;
}

.b-solid-39-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-39-left {
  border-left-style: solid;
  border-left-width: 39px;
}

.b-solid-39-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-39-right {
  border-right-style: solid;
  border-right-width: 39px;
}

.b-solid-39-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-39-top {
  border-top-style: solid;
  border-top-width: 39px;
}

.b-solid-39-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-39-top-left {
  border-top-left-radius: 39px;
}

.b-radius-39-top-right {
  border-top-right-radius: 39px;
}

.b-radius-39-bottom-left {
  border-bottom-left-radius: 39px;
}

.b-radius-39-bottom-right {
  border-bottom-right-radius: 39px;
}

.b-solid-40 {
  border-style: solid;
  border-width: 40px;
}

.b-radius-40 {
  border-radius: 40px;
}

.b-solid-40-bottom {
  border-bottom-style: solid;
  border-bottom-width: 40px;
}

.b-solid-40-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-40-left {
  border-left-style: solid;
  border-left-width: 40px;
}

.b-solid-40-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-40-right {
  border-right-style: solid;
  border-right-width: 40px;
}

.b-solid-40-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-40-top {
  border-top-style: solid;
  border-top-width: 40px;
}

.b-solid-40-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-40-top-left {
  border-top-left-radius: 40px;
}

.b-radius-40-top-right {
  border-top-right-radius: 40px;
}

.b-radius-40-bottom-left {
  border-bottom-left-radius: 40px;
}

.b-radius-40-bottom-right {
  border-bottom-right-radius: 40px;
}

.b-solid-41 {
  border-style: solid;
  border-width: 41px;
}

.b-radius-41 {
  border-radius: 41px;
}

.b-solid-41-bottom {
  border-bottom-style: solid;
  border-bottom-width: 41px;
}

.b-solid-41-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-41-left {
  border-left-style: solid;
  border-left-width: 41px;
}

.b-solid-41-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-41-right {
  border-right-style: solid;
  border-right-width: 41px;
}

.b-solid-41-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-41-top {
  border-top-style: solid;
  border-top-width: 41px;
}

.b-solid-41-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-41-top-left {
  border-top-left-radius: 41px;
}

.b-radius-41-top-right {
  border-top-right-radius: 41px;
}

.b-radius-41-bottom-left {
  border-bottom-left-radius: 41px;
}

.b-radius-41-bottom-right {
  border-bottom-right-radius: 41px;
}

.b-solid-42 {
  border-style: solid;
  border-width: 42px;
}

.b-radius-42 {
  border-radius: 42px;
}

.b-solid-42-bottom {
  border-bottom-style: solid;
  border-bottom-width: 42px;
}

.b-solid-42-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-42-left {
  border-left-style: solid;
  border-left-width: 42px;
}

.b-solid-42-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-42-right {
  border-right-style: solid;
  border-right-width: 42px;
}

.b-solid-42-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-42-top {
  border-top-style: solid;
  border-top-width: 42px;
}

.b-solid-42-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-42-top-left {
  border-top-left-radius: 42px;
}

.b-radius-42-top-right {
  border-top-right-radius: 42px;
}

.b-radius-42-bottom-left {
  border-bottom-left-radius: 42px;
}

.b-radius-42-bottom-right {
  border-bottom-right-radius: 42px;
}

.b-solid-43 {
  border-style: solid;
  border-width: 43px;
}

.b-radius-43 {
  border-radius: 43px;
}

.b-solid-43-bottom {
  border-bottom-style: solid;
  border-bottom-width: 43px;
}

.b-solid-43-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-43-left {
  border-left-style: solid;
  border-left-width: 43px;
}

.b-solid-43-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-43-right {
  border-right-style: solid;
  border-right-width: 43px;
}

.b-solid-43-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-43-top {
  border-top-style: solid;
  border-top-width: 43px;
}

.b-solid-43-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-43-top-left {
  border-top-left-radius: 43px;
}

.b-radius-43-top-right {
  border-top-right-radius: 43px;
}

.b-radius-43-bottom-left {
  border-bottom-left-radius: 43px;
}

.b-radius-43-bottom-right {
  border-bottom-right-radius: 43px;
}

.b-solid-44 {
  border-style: solid;
  border-width: 44px;
}

.b-radius-44 {
  border-radius: 44px;
}

.b-solid-44-bottom {
  border-bottom-style: solid;
  border-bottom-width: 44px;
}

.b-solid-44-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-44-left {
  border-left-style: solid;
  border-left-width: 44px;
}

.b-solid-44-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-44-right {
  border-right-style: solid;
  border-right-width: 44px;
}

.b-solid-44-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-44-top {
  border-top-style: solid;
  border-top-width: 44px;
}

.b-solid-44-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-44-top-left {
  border-top-left-radius: 44px;
}

.b-radius-44-top-right {
  border-top-right-radius: 44px;
}

.b-radius-44-bottom-left {
  border-bottom-left-radius: 44px;
}

.b-radius-44-bottom-right {
  border-bottom-right-radius: 44px;
}

.b-solid-45 {
  border-style: solid;
  border-width: 45px;
}

.b-radius-45 {
  border-radius: 45px;
}

.b-solid-45-bottom {
  border-bottom-style: solid;
  border-bottom-width: 45px;
}

.b-solid-45-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-45-left {
  border-left-style: solid;
  border-left-width: 45px;
}

.b-solid-45-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-45-right {
  border-right-style: solid;
  border-right-width: 45px;
}

.b-solid-45-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-45-top {
  border-top-style: solid;
  border-top-width: 45px;
}

.b-solid-45-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-45-top-left {
  border-top-left-radius: 45px;
}

.b-radius-45-top-right {
  border-top-right-radius: 45px;
}

.b-radius-45-bottom-left {
  border-bottom-left-radius: 45px;
}

.b-radius-45-bottom-right {
  border-bottom-right-radius: 45px;
}

.b-solid-46 {
  border-style: solid;
  border-width: 46px;
}

.b-radius-46 {
  border-radius: 46px;
}

.b-solid-46-bottom {
  border-bottom-style: solid;
  border-bottom-width: 46px;
}

.b-solid-46-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-46-left {
  border-left-style: solid;
  border-left-width: 46px;
}

.b-solid-46-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-46-right {
  border-right-style: solid;
  border-right-width: 46px;
}

.b-solid-46-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-46-top {
  border-top-style: solid;
  border-top-width: 46px;
}

.b-solid-46-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-46-top-left {
  border-top-left-radius: 46px;
}

.b-radius-46-top-right {
  border-top-right-radius: 46px;
}

.b-radius-46-bottom-left {
  border-bottom-left-radius: 46px;
}

.b-radius-46-bottom-right {
  border-bottom-right-radius: 46px;
}

.b-solid-47 {
  border-style: solid;
  border-width: 47px;
}

.b-radius-47 {
  border-radius: 47px;
}

.b-solid-47-bottom {
  border-bottom-style: solid;
  border-bottom-width: 47px;
}

.b-solid-47-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-47-left {
  border-left-style: solid;
  border-left-width: 47px;
}

.b-solid-47-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-47-right {
  border-right-style: solid;
  border-right-width: 47px;
}

.b-solid-47-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-47-top {
  border-top-style: solid;
  border-top-width: 47px;
}

.b-solid-47-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-47-top-left {
  border-top-left-radius: 47px;
}

.b-radius-47-top-right {
  border-top-right-radius: 47px;
}

.b-radius-47-bottom-left {
  border-bottom-left-radius: 47px;
}

.b-radius-47-bottom-right {
  border-bottom-right-radius: 47px;
}

.b-solid-48 {
  border-style: solid;
  border-width: 48px;
}

.b-radius-48 {
  border-radius: 48px;
}

.b-solid-48-bottom {
  border-bottom-style: solid;
  border-bottom-width: 48px;
}

.b-solid-48-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-48-left {
  border-left-style: solid;
  border-left-width: 48px;
}

.b-solid-48-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-48-right {
  border-right-style: solid;
  border-right-width: 48px;
}

.b-solid-48-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-48-top {
  border-top-style: solid;
  border-top-width: 48px;
}

.b-solid-48-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-48-top-left {
  border-top-left-radius: 48px;
}

.b-radius-48-top-right {
  border-top-right-radius: 48px;
}

.b-radius-48-bottom-left {
  border-bottom-left-radius: 48px;
}

.b-radius-48-bottom-right {
  border-bottom-right-radius: 48px;
}

.b-solid-49 {
  border-style: solid;
  border-width: 49px;
}

.b-radius-49 {
  border-radius: 49px;
}

.b-solid-49-bottom {
  border-bottom-style: solid;
  border-bottom-width: 49px;
}

.b-solid-49-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-49-left {
  border-left-style: solid;
  border-left-width: 49px;
}

.b-solid-49-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-49-right {
  border-right-style: solid;
  border-right-width: 49px;
}

.b-solid-49-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-49-top {
  border-top-style: solid;
  border-top-width: 49px;
}

.b-solid-49-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-49-top-left {
  border-top-left-radius: 49px;
}

.b-radius-49-top-right {
  border-top-right-radius: 49px;
}

.b-radius-49-bottom-left {
  border-bottom-left-radius: 49px;
}

.b-radius-49-bottom-right {
  border-bottom-right-radius: 49px;
}

.b-solid-50 {
  border-style: solid;
  border-width: 50px;
}

.b-radius-50 {
  border-radius: 50px;
}

.b-solid-50-bottom {
  border-bottom-style: solid;
  border-bottom-width: 50px;
}

.b-solid-50-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-50-left {
  border-left-style: solid;
  border-left-width: 50px;
}

.b-solid-50-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-50-right {
  border-right-style: solid;
  border-right-width: 50px;
}

.b-solid-50-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-50-top {
  border-top-style: solid;
  border-top-width: 50px;
}

.b-solid-50-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-50-top-left {
  border-top-left-radius: 50px;
}

.b-radius-50-top-right {
  border-top-right-radius: 50px;
}

.b-radius-50-bottom-left {
  border-bottom-left-radius: 50px;
}

.b-radius-50-bottom-right {
  border-bottom-right-radius: 50px;
}

.b-solid-51 {
  border-style: solid;
  border-width: 51px;
}

.b-radius-51 {
  border-radius: 51px;
}

.b-solid-51-bottom {
  border-bottom-style: solid;
  border-bottom-width: 51px;
}

.b-solid-51-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-51-left {
  border-left-style: solid;
  border-left-width: 51px;
}

.b-solid-51-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-51-right {
  border-right-style: solid;
  border-right-width: 51px;
}

.b-solid-51-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-51-top {
  border-top-style: solid;
  border-top-width: 51px;
}

.b-solid-51-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-51-top-left {
  border-top-left-radius: 51px;
}

.b-radius-51-top-right {
  border-top-right-radius: 51px;
}

.b-radius-51-bottom-left {
  border-bottom-left-radius: 51px;
}

.b-radius-51-bottom-right {
  border-bottom-right-radius: 51px;
}

.b-solid-52 {
  border-style: solid;
  border-width: 52px;
}

.b-radius-52 {
  border-radius: 52px;
}

.b-solid-52-bottom {
  border-bottom-style: solid;
  border-bottom-width: 52px;
}

.b-solid-52-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-52-left {
  border-left-style: solid;
  border-left-width: 52px;
}

.b-solid-52-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-52-right {
  border-right-style: solid;
  border-right-width: 52px;
}

.b-solid-52-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-52-top {
  border-top-style: solid;
  border-top-width: 52px;
}

.b-solid-52-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-52-top-left {
  border-top-left-radius: 52px;
}

.b-radius-52-top-right {
  border-top-right-radius: 52px;
}

.b-radius-52-bottom-left {
  border-bottom-left-radius: 52px;
}

.b-radius-52-bottom-right {
  border-bottom-right-radius: 52px;
}

.b-solid-53 {
  border-style: solid;
  border-width: 53px;
}

.b-radius-53 {
  border-radius: 53px;
}

.b-solid-53-bottom {
  border-bottom-style: solid;
  border-bottom-width: 53px;
}

.b-solid-53-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-53-left {
  border-left-style: solid;
  border-left-width: 53px;
}

.b-solid-53-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-53-right {
  border-right-style: solid;
  border-right-width: 53px;
}

.b-solid-53-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-53-top {
  border-top-style: solid;
  border-top-width: 53px;
}

.b-solid-53-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-53-top-left {
  border-top-left-radius: 53px;
}

.b-radius-53-top-right {
  border-top-right-radius: 53px;
}

.b-radius-53-bottom-left {
  border-bottom-left-radius: 53px;
}

.b-radius-53-bottom-right {
  border-bottom-right-radius: 53px;
}

.b-solid-54 {
  border-style: solid;
  border-width: 54px;
}

.b-radius-54 {
  border-radius: 54px;
}

.b-solid-54-bottom {
  border-bottom-style: solid;
  border-bottom-width: 54px;
}

.b-solid-54-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-54-left {
  border-left-style: solid;
  border-left-width: 54px;
}

.b-solid-54-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-54-right {
  border-right-style: solid;
  border-right-width: 54px;
}

.b-solid-54-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-54-top {
  border-top-style: solid;
  border-top-width: 54px;
}

.b-solid-54-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-54-top-left {
  border-top-left-radius: 54px;
}

.b-radius-54-top-right {
  border-top-right-radius: 54px;
}

.b-radius-54-bottom-left {
  border-bottom-left-radius: 54px;
}

.b-radius-54-bottom-right {
  border-bottom-right-radius: 54px;
}

.b-solid-55 {
  border-style: solid;
  border-width: 55px;
}

.b-radius-55 {
  border-radius: 55px;
}

.b-solid-55-bottom {
  border-bottom-style: solid;
  border-bottom-width: 55px;
}

.b-solid-55-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-55-left {
  border-left-style: solid;
  border-left-width: 55px;
}

.b-solid-55-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-55-right {
  border-right-style: solid;
  border-right-width: 55px;
}

.b-solid-55-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-55-top {
  border-top-style: solid;
  border-top-width: 55px;
}

.b-solid-55-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-55-top-left {
  border-top-left-radius: 55px;
}

.b-radius-55-top-right {
  border-top-right-radius: 55px;
}

.b-radius-55-bottom-left {
  border-bottom-left-radius: 55px;
}

.b-radius-55-bottom-right {
  border-bottom-right-radius: 55px;
}

.b-solid-56 {
  border-style: solid;
  border-width: 56px;
}

.b-radius-56 {
  border-radius: 56px;
}

.b-solid-56-bottom {
  border-bottom-style: solid;
  border-bottom-width: 56px;
}

.b-solid-56-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-56-left {
  border-left-style: solid;
  border-left-width: 56px;
}

.b-solid-56-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-56-right {
  border-right-style: solid;
  border-right-width: 56px;
}

.b-solid-56-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-56-top {
  border-top-style: solid;
  border-top-width: 56px;
}

.b-solid-56-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-56-top-left {
  border-top-left-radius: 56px;
}

.b-radius-56-top-right {
  border-top-right-radius: 56px;
}

.b-radius-56-bottom-left {
  border-bottom-left-radius: 56px;
}

.b-radius-56-bottom-right {
  border-bottom-right-radius: 56px;
}

.b-solid-57 {
  border-style: solid;
  border-width: 57px;
}

.b-radius-57 {
  border-radius: 57px;
}

.b-solid-57-bottom {
  border-bottom-style: solid;
  border-bottom-width: 57px;
}

.b-solid-57-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-57-left {
  border-left-style: solid;
  border-left-width: 57px;
}

.b-solid-57-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-57-right {
  border-right-style: solid;
  border-right-width: 57px;
}

.b-solid-57-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-57-top {
  border-top-style: solid;
  border-top-width: 57px;
}

.b-solid-57-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-57-top-left {
  border-top-left-radius: 57px;
}

.b-radius-57-top-right {
  border-top-right-radius: 57px;
}

.b-radius-57-bottom-left {
  border-bottom-left-radius: 57px;
}

.b-radius-57-bottom-right {
  border-bottom-right-radius: 57px;
}

.b-solid-58 {
  border-style: solid;
  border-width: 58px;
}

.b-radius-58 {
  border-radius: 58px;
}

.b-solid-58-bottom {
  border-bottom-style: solid;
  border-bottom-width: 58px;
}

.b-solid-58-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-58-left {
  border-left-style: solid;
  border-left-width: 58px;
}

.b-solid-58-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-58-right {
  border-right-style: solid;
  border-right-width: 58px;
}

.b-solid-58-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-58-top {
  border-top-style: solid;
  border-top-width: 58px;
}

.b-solid-58-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-58-top-left {
  border-top-left-radius: 58px;
}

.b-radius-58-top-right {
  border-top-right-radius: 58px;
}

.b-radius-58-bottom-left {
  border-bottom-left-radius: 58px;
}

.b-radius-58-bottom-right {
  border-bottom-right-radius: 58px;
}

.b-solid-59 {
  border-style: solid;
  border-width: 59px;
}

.b-radius-59 {
  border-radius: 59px;
}

.b-solid-59-bottom {
  border-bottom-style: solid;
  border-bottom-width: 59px;
}

.b-solid-59-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-59-left {
  border-left-style: solid;
  border-left-width: 59px;
}

.b-solid-59-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-59-right {
  border-right-style: solid;
  border-right-width: 59px;
}

.b-solid-59-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-59-top {
  border-top-style: solid;
  border-top-width: 59px;
}

.b-solid-59-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-59-top-left {
  border-top-left-radius: 59px;
}

.b-radius-59-top-right {
  border-top-right-radius: 59px;
}

.b-radius-59-bottom-left {
  border-bottom-left-radius: 59px;
}

.b-radius-59-bottom-right {
  border-bottom-right-radius: 59px;
}

.b-solid-60 {
  border-style: solid;
  border-width: 60px;
}

.b-radius-60 {
  border-radius: 60px;
}

.b-solid-60-bottom {
  border-bottom-style: solid;
  border-bottom-width: 60px;
}

.b-solid-60-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-60-left {
  border-left-style: solid;
  border-left-width: 60px;
}

.b-solid-60-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-60-right {
  border-right-style: solid;
  border-right-width: 60px;
}

.b-solid-60-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-60-top {
  border-top-style: solid;
  border-top-width: 60px;
}

.b-solid-60-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-60-top-left {
  border-top-left-radius: 60px;
}

.b-radius-60-top-right {
  border-top-right-radius: 60px;
}

.b-radius-60-bottom-left {
  border-bottom-left-radius: 60px;
}

.b-radius-60-bottom-right {
  border-bottom-right-radius: 60px;
}

.b-solid-61 {
  border-style: solid;
  border-width: 61px;
}

.b-radius-61 {
  border-radius: 61px;
}

.b-solid-61-bottom {
  border-bottom-style: solid;
  border-bottom-width: 61px;
}

.b-solid-61-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-61-left {
  border-left-style: solid;
  border-left-width: 61px;
}

.b-solid-61-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-61-right {
  border-right-style: solid;
  border-right-width: 61px;
}

.b-solid-61-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-61-top {
  border-top-style: solid;
  border-top-width: 61px;
}

.b-solid-61-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-61-top-left {
  border-top-left-radius: 61px;
}

.b-radius-61-top-right {
  border-top-right-radius: 61px;
}

.b-radius-61-bottom-left {
  border-bottom-left-radius: 61px;
}

.b-radius-61-bottom-right {
  border-bottom-right-radius: 61px;
}

.b-solid-62 {
  border-style: solid;
  border-width: 62px;
}

.b-radius-62 {
  border-radius: 62px;
}

.b-solid-62-bottom {
  border-bottom-style: solid;
  border-bottom-width: 62px;
}

.b-solid-62-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-62-left {
  border-left-style: solid;
  border-left-width: 62px;
}

.b-solid-62-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-62-right {
  border-right-style: solid;
  border-right-width: 62px;
}

.b-solid-62-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-62-top {
  border-top-style: solid;
  border-top-width: 62px;
}

.b-solid-62-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-62-top-left {
  border-top-left-radius: 62px;
}

.b-radius-62-top-right {
  border-top-right-radius: 62px;
}

.b-radius-62-bottom-left {
  border-bottom-left-radius: 62px;
}

.b-radius-62-bottom-right {
  border-bottom-right-radius: 62px;
}

.b-solid-63 {
  border-style: solid;
  border-width: 63px;
}

.b-radius-63 {
  border-radius: 63px;
}

.b-solid-63-bottom {
  border-bottom-style: solid;
  border-bottom-width: 63px;
}

.b-solid-63-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-63-left {
  border-left-style: solid;
  border-left-width: 63px;
}

.b-solid-63-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-63-right {
  border-right-style: solid;
  border-right-width: 63px;
}

.b-solid-63-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-63-top {
  border-top-style: solid;
  border-top-width: 63px;
}

.b-solid-63-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-63-top-left {
  border-top-left-radius: 63px;
}

.b-radius-63-top-right {
  border-top-right-radius: 63px;
}

.b-radius-63-bottom-left {
  border-bottom-left-radius: 63px;
}

.b-radius-63-bottom-right {
  border-bottom-right-radius: 63px;
}

.b-solid-64 {
  border-style: solid;
  border-width: 64px;
}

.b-radius-64 {
  border-radius: 64px;
}

.b-solid-64-bottom {
  border-bottom-style: solid;
  border-bottom-width: 64px;
}

.b-solid-64-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-64-left {
  border-left-style: solid;
  border-left-width: 64px;
}

.b-solid-64-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-64-right {
  border-right-style: solid;
  border-right-width: 64px;
}

.b-solid-64-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-64-top {
  border-top-style: solid;
  border-top-width: 64px;
}

.b-solid-64-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-64-top-left {
  border-top-left-radius: 64px;
}

.b-radius-64-top-right {
  border-top-right-radius: 64px;
}

.b-radius-64-bottom-left {
  border-bottom-left-radius: 64px;
}

.b-radius-64-bottom-right {
  border-bottom-right-radius: 64px;
}

.b-solid-65 {
  border-style: solid;
  border-width: 65px;
}

.b-radius-65 {
  border-radius: 65px;
}

.b-solid-65-bottom {
  border-bottom-style: solid;
  border-bottom-width: 65px;
}

.b-solid-65-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-65-left {
  border-left-style: solid;
  border-left-width: 65px;
}

.b-solid-65-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-65-right {
  border-right-style: solid;
  border-right-width: 65px;
}

.b-solid-65-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-65-top {
  border-top-style: solid;
  border-top-width: 65px;
}

.b-solid-65-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-65-top-left {
  border-top-left-radius: 65px;
}

.b-radius-65-top-right {
  border-top-right-radius: 65px;
}

.b-radius-65-bottom-left {
  border-bottom-left-radius: 65px;
}

.b-radius-65-bottom-right {
  border-bottom-right-radius: 65px;
}

.b-solid-66 {
  border-style: solid;
  border-width: 66px;
}

.b-radius-66 {
  border-radius: 66px;
}

.b-solid-66-bottom {
  border-bottom-style: solid;
  border-bottom-width: 66px;
}

.b-solid-66-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-66-left {
  border-left-style: solid;
  border-left-width: 66px;
}

.b-solid-66-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-66-right {
  border-right-style: solid;
  border-right-width: 66px;
}

.b-solid-66-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-66-top {
  border-top-style: solid;
  border-top-width: 66px;
}

.b-solid-66-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-66-top-left {
  border-top-left-radius: 66px;
}

.b-radius-66-top-right {
  border-top-right-radius: 66px;
}

.b-radius-66-bottom-left {
  border-bottom-left-radius: 66px;
}

.b-radius-66-bottom-right {
  border-bottom-right-radius: 66px;
}

.b-solid-67 {
  border-style: solid;
  border-width: 67px;
}

.b-radius-67 {
  border-radius: 67px;
}

.b-solid-67-bottom {
  border-bottom-style: solid;
  border-bottom-width: 67px;
}

.b-solid-67-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-67-left {
  border-left-style: solid;
  border-left-width: 67px;
}

.b-solid-67-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-67-right {
  border-right-style: solid;
  border-right-width: 67px;
}

.b-solid-67-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-67-top {
  border-top-style: solid;
  border-top-width: 67px;
}

.b-solid-67-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-67-top-left {
  border-top-left-radius: 67px;
}

.b-radius-67-top-right {
  border-top-right-radius: 67px;
}

.b-radius-67-bottom-left {
  border-bottom-left-radius: 67px;
}

.b-radius-67-bottom-right {
  border-bottom-right-radius: 67px;
}

.b-solid-68 {
  border-style: solid;
  border-width: 68px;
}

.b-radius-68 {
  border-radius: 68px;
}

.b-solid-68-bottom {
  border-bottom-style: solid;
  border-bottom-width: 68px;
}

.b-solid-68-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-68-left {
  border-left-style: solid;
  border-left-width: 68px;
}

.b-solid-68-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-68-right {
  border-right-style: solid;
  border-right-width: 68px;
}

.b-solid-68-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-68-top {
  border-top-style: solid;
  border-top-width: 68px;
}

.b-solid-68-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-68-top-left {
  border-top-left-radius: 68px;
}

.b-radius-68-top-right {
  border-top-right-radius: 68px;
}

.b-radius-68-bottom-left {
  border-bottom-left-radius: 68px;
}

.b-radius-68-bottom-right {
  border-bottom-right-radius: 68px;
}

.b-solid-69 {
  border-style: solid;
  border-width: 69px;
}

.b-radius-69 {
  border-radius: 69px;
}

.b-solid-69-bottom {
  border-bottom-style: solid;
  border-bottom-width: 69px;
}

.b-solid-69-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-69-left {
  border-left-style: solid;
  border-left-width: 69px;
}

.b-solid-69-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-69-right {
  border-right-style: solid;
  border-right-width: 69px;
}

.b-solid-69-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-69-top {
  border-top-style: solid;
  border-top-width: 69px;
}

.b-solid-69-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-69-top-left {
  border-top-left-radius: 69px;
}

.b-radius-69-top-right {
  border-top-right-radius: 69px;
}

.b-radius-69-bottom-left {
  border-bottom-left-radius: 69px;
}

.b-radius-69-bottom-right {
  border-bottom-right-radius: 69px;
}

.b-solid-70 {
  border-style: solid;
  border-width: 70px;
}

.b-radius-70 {
  border-radius: 70px;
}

.b-solid-70-bottom {
  border-bottom-style: solid;
  border-bottom-width: 70px;
}

.b-solid-70-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-70-left {
  border-left-style: solid;
  border-left-width: 70px;
}

.b-solid-70-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-70-right {
  border-right-style: solid;
  border-right-width: 70px;
}

.b-solid-70-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-70-top {
  border-top-style: solid;
  border-top-width: 70px;
}

.b-solid-70-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-70-top-left {
  border-top-left-radius: 70px;
}

.b-radius-70-top-right {
  border-top-right-radius: 70px;
}

.b-radius-70-bottom-left {
  border-bottom-left-radius: 70px;
}

.b-radius-70-bottom-right {
  border-bottom-right-radius: 70px;
}

.b-solid-71 {
  border-style: solid;
  border-width: 71px;
}

.b-radius-71 {
  border-radius: 71px;
}

.b-solid-71-bottom {
  border-bottom-style: solid;
  border-bottom-width: 71px;
}

.b-solid-71-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-71-left {
  border-left-style: solid;
  border-left-width: 71px;
}

.b-solid-71-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-71-right {
  border-right-style: solid;
  border-right-width: 71px;
}

.b-solid-71-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-71-top {
  border-top-style: solid;
  border-top-width: 71px;
}

.b-solid-71-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-71-top-left {
  border-top-left-radius: 71px;
}

.b-radius-71-top-right {
  border-top-right-radius: 71px;
}

.b-radius-71-bottom-left {
  border-bottom-left-radius: 71px;
}

.b-radius-71-bottom-right {
  border-bottom-right-radius: 71px;
}

.b-solid-72 {
  border-style: solid;
  border-width: 72px;
}

.b-radius-72 {
  border-radius: 72px;
}

.b-solid-72-bottom {
  border-bottom-style: solid;
  border-bottom-width: 72px;
}

.b-solid-72-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-72-left {
  border-left-style: solid;
  border-left-width: 72px;
}

.b-solid-72-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-72-right {
  border-right-style: solid;
  border-right-width: 72px;
}

.b-solid-72-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-72-top {
  border-top-style: solid;
  border-top-width: 72px;
}

.b-solid-72-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-72-top-left {
  border-top-left-radius: 72px;
}

.b-radius-72-top-right {
  border-top-right-radius: 72px;
}

.b-radius-72-bottom-left {
  border-bottom-left-radius: 72px;
}

.b-radius-72-bottom-right {
  border-bottom-right-radius: 72px;
}

.b-solid-73 {
  border-style: solid;
  border-width: 73px;
}

.b-radius-73 {
  border-radius: 73px;
}

.b-solid-73-bottom {
  border-bottom-style: solid;
  border-bottom-width: 73px;
}

.b-solid-73-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-73-left {
  border-left-style: solid;
  border-left-width: 73px;
}

.b-solid-73-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-73-right {
  border-right-style: solid;
  border-right-width: 73px;
}

.b-solid-73-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-73-top {
  border-top-style: solid;
  border-top-width: 73px;
}

.b-solid-73-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-73-top-left {
  border-top-left-radius: 73px;
}

.b-radius-73-top-right {
  border-top-right-radius: 73px;
}

.b-radius-73-bottom-left {
  border-bottom-left-radius: 73px;
}

.b-radius-73-bottom-right {
  border-bottom-right-radius: 73px;
}

.b-solid-74 {
  border-style: solid;
  border-width: 74px;
}

.b-radius-74 {
  border-radius: 74px;
}

.b-solid-74-bottom {
  border-bottom-style: solid;
  border-bottom-width: 74px;
}

.b-solid-74-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-74-left {
  border-left-style: solid;
  border-left-width: 74px;
}

.b-solid-74-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-74-right {
  border-right-style: solid;
  border-right-width: 74px;
}

.b-solid-74-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-74-top {
  border-top-style: solid;
  border-top-width: 74px;
}

.b-solid-74-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-74-top-left {
  border-top-left-radius: 74px;
}

.b-radius-74-top-right {
  border-top-right-radius: 74px;
}

.b-radius-74-bottom-left {
  border-bottom-left-radius: 74px;
}

.b-radius-74-bottom-right {
  border-bottom-right-radius: 74px;
}

.b-solid-75 {
  border-style: solid;
  border-width: 75px;
}

.b-radius-75 {
  border-radius: 75px;
}

.b-solid-75-bottom {
  border-bottom-style: solid;
  border-bottom-width: 75px;
}

.b-solid-75-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-75-left {
  border-left-style: solid;
  border-left-width: 75px;
}

.b-solid-75-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-75-right {
  border-right-style: solid;
  border-right-width: 75px;
}

.b-solid-75-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-75-top {
  border-top-style: solid;
  border-top-width: 75px;
}

.b-solid-75-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-75-top-left {
  border-top-left-radius: 75px;
}

.b-radius-75-top-right {
  border-top-right-radius: 75px;
}

.b-radius-75-bottom-left {
  border-bottom-left-radius: 75px;
}

.b-radius-75-bottom-right {
  border-bottom-right-radius: 75px;
}

.b-solid-76 {
  border-style: solid;
  border-width: 76px;
}

.b-radius-76 {
  border-radius: 76px;
}

.b-solid-76-bottom {
  border-bottom-style: solid;
  border-bottom-width: 76px;
}

.b-solid-76-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-76-left {
  border-left-style: solid;
  border-left-width: 76px;
}

.b-solid-76-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-76-right {
  border-right-style: solid;
  border-right-width: 76px;
}

.b-solid-76-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-76-top {
  border-top-style: solid;
  border-top-width: 76px;
}

.b-solid-76-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-76-top-left {
  border-top-left-radius: 76px;
}

.b-radius-76-top-right {
  border-top-right-radius: 76px;
}

.b-radius-76-bottom-left {
  border-bottom-left-radius: 76px;
}

.b-radius-76-bottom-right {
  border-bottom-right-radius: 76px;
}

.b-solid-77 {
  border-style: solid;
  border-width: 77px;
}

.b-radius-77 {
  border-radius: 77px;
}

.b-solid-77-bottom {
  border-bottom-style: solid;
  border-bottom-width: 77px;
}

.b-solid-77-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-77-left {
  border-left-style: solid;
  border-left-width: 77px;
}

.b-solid-77-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-77-right {
  border-right-style: solid;
  border-right-width: 77px;
}

.b-solid-77-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-77-top {
  border-top-style: solid;
  border-top-width: 77px;
}

.b-solid-77-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-77-top-left {
  border-top-left-radius: 77px;
}

.b-radius-77-top-right {
  border-top-right-radius: 77px;
}

.b-radius-77-bottom-left {
  border-bottom-left-radius: 77px;
}

.b-radius-77-bottom-right {
  border-bottom-right-radius: 77px;
}

.b-solid-78 {
  border-style: solid;
  border-width: 78px;
}

.b-radius-78 {
  border-radius: 78px;
}

.b-solid-78-bottom {
  border-bottom-style: solid;
  border-bottom-width: 78px;
}

.b-solid-78-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-78-left {
  border-left-style: solid;
  border-left-width: 78px;
}

.b-solid-78-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-78-right {
  border-right-style: solid;
  border-right-width: 78px;
}

.b-solid-78-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-78-top {
  border-top-style: solid;
  border-top-width: 78px;
}

.b-solid-78-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-78-top-left {
  border-top-left-radius: 78px;
}

.b-radius-78-top-right {
  border-top-right-radius: 78px;
}

.b-radius-78-bottom-left {
  border-bottom-left-radius: 78px;
}

.b-radius-78-bottom-right {
  border-bottom-right-radius: 78px;
}

.b-solid-79 {
  border-style: solid;
  border-width: 79px;
}

.b-radius-79 {
  border-radius: 79px;
}

.b-solid-79-bottom {
  border-bottom-style: solid;
  border-bottom-width: 79px;
}

.b-solid-79-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-79-left {
  border-left-style: solid;
  border-left-width: 79px;
}

.b-solid-79-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-79-right {
  border-right-style: solid;
  border-right-width: 79px;
}

.b-solid-79-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-79-top {
  border-top-style: solid;
  border-top-width: 79px;
}

.b-solid-79-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-79-top-left {
  border-top-left-radius: 79px;
}

.b-radius-79-top-right {
  border-top-right-radius: 79px;
}

.b-radius-79-bottom-left {
  border-bottom-left-radius: 79px;
}

.b-radius-79-bottom-right {
  border-bottom-right-radius: 79px;
}

.b-solid-80 {
  border-style: solid;
  border-width: 80px;
}

.b-radius-80 {
  border-radius: 80px;
}

.b-solid-80-bottom {
  border-bottom-style: solid;
  border-bottom-width: 80px;
}

.b-solid-80-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-80-left {
  border-left-style: solid;
  border-left-width: 80px;
}

.b-solid-80-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-80-right {
  border-right-style: solid;
  border-right-width: 80px;
}

.b-solid-80-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-80-top {
  border-top-style: solid;
  border-top-width: 80px;
}

.b-solid-80-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-80-top-left {
  border-top-left-radius: 80px;
}

.b-radius-80-top-right {
  border-top-right-radius: 80px;
}

.b-radius-80-bottom-left {
  border-bottom-left-radius: 80px;
}

.b-radius-80-bottom-right {
  border-bottom-right-radius: 80px;
}

.b-solid-81 {
  border-style: solid;
  border-width: 81px;
}

.b-radius-81 {
  border-radius: 81px;
}

.b-solid-81-bottom {
  border-bottom-style: solid;
  border-bottom-width: 81px;
}

.b-solid-81-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-81-left {
  border-left-style: solid;
  border-left-width: 81px;
}

.b-solid-81-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-81-right {
  border-right-style: solid;
  border-right-width: 81px;
}

.b-solid-81-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-81-top {
  border-top-style: solid;
  border-top-width: 81px;
}

.b-solid-81-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-81-top-left {
  border-top-left-radius: 81px;
}

.b-radius-81-top-right {
  border-top-right-radius: 81px;
}

.b-radius-81-bottom-left {
  border-bottom-left-radius: 81px;
}

.b-radius-81-bottom-right {
  border-bottom-right-radius: 81px;
}

.b-solid-82 {
  border-style: solid;
  border-width: 82px;
}

.b-radius-82 {
  border-radius: 82px;
}

.b-solid-82-bottom {
  border-bottom-style: solid;
  border-bottom-width: 82px;
}

.b-solid-82-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-82-left {
  border-left-style: solid;
  border-left-width: 82px;
}

.b-solid-82-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-82-right {
  border-right-style: solid;
  border-right-width: 82px;
}

.b-solid-82-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-82-top {
  border-top-style: solid;
  border-top-width: 82px;
}

.b-solid-82-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-82-top-left {
  border-top-left-radius: 82px;
}

.b-radius-82-top-right {
  border-top-right-radius: 82px;
}

.b-radius-82-bottom-left {
  border-bottom-left-radius: 82px;
}

.b-radius-82-bottom-right {
  border-bottom-right-radius: 82px;
}

.b-solid-83 {
  border-style: solid;
  border-width: 83px;
}

.b-radius-83 {
  border-radius: 83px;
}

.b-solid-83-bottom {
  border-bottom-style: solid;
  border-bottom-width: 83px;
}

.b-solid-83-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-83-left {
  border-left-style: solid;
  border-left-width: 83px;
}

.b-solid-83-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-83-right {
  border-right-style: solid;
  border-right-width: 83px;
}

.b-solid-83-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-83-top {
  border-top-style: solid;
  border-top-width: 83px;
}

.b-solid-83-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-83-top-left {
  border-top-left-radius: 83px;
}

.b-radius-83-top-right {
  border-top-right-radius: 83px;
}

.b-radius-83-bottom-left {
  border-bottom-left-radius: 83px;
}

.b-radius-83-bottom-right {
  border-bottom-right-radius: 83px;
}

.b-solid-84 {
  border-style: solid;
  border-width: 84px;
}

.b-radius-84 {
  border-radius: 84px;
}

.b-solid-84-bottom {
  border-bottom-style: solid;
  border-bottom-width: 84px;
}

.b-solid-84-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-84-left {
  border-left-style: solid;
  border-left-width: 84px;
}

.b-solid-84-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-84-right {
  border-right-style: solid;
  border-right-width: 84px;
}

.b-solid-84-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-84-top {
  border-top-style: solid;
  border-top-width: 84px;
}

.b-solid-84-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-84-top-left {
  border-top-left-radius: 84px;
}

.b-radius-84-top-right {
  border-top-right-radius: 84px;
}

.b-radius-84-bottom-left {
  border-bottom-left-radius: 84px;
}

.b-radius-84-bottom-right {
  border-bottom-right-radius: 84px;
}

.b-solid-85 {
  border-style: solid;
  border-width: 85px;
}

.b-radius-85 {
  border-radius: 85px;
}

.b-solid-85-bottom {
  border-bottom-style: solid;
  border-bottom-width: 85px;
}

.b-solid-85-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-85-left {
  border-left-style: solid;
  border-left-width: 85px;
}

.b-solid-85-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-85-right {
  border-right-style: solid;
  border-right-width: 85px;
}

.b-solid-85-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-85-top {
  border-top-style: solid;
  border-top-width: 85px;
}

.b-solid-85-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-85-top-left {
  border-top-left-radius: 85px;
}

.b-radius-85-top-right {
  border-top-right-radius: 85px;
}

.b-radius-85-bottom-left {
  border-bottom-left-radius: 85px;
}

.b-radius-85-bottom-right {
  border-bottom-right-radius: 85px;
}

.b-solid-86 {
  border-style: solid;
  border-width: 86px;
}

.b-radius-86 {
  border-radius: 86px;
}

.b-solid-86-bottom {
  border-bottom-style: solid;
  border-bottom-width: 86px;
}

.b-solid-86-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-86-left {
  border-left-style: solid;
  border-left-width: 86px;
}

.b-solid-86-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-86-right {
  border-right-style: solid;
  border-right-width: 86px;
}

.b-solid-86-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-86-top {
  border-top-style: solid;
  border-top-width: 86px;
}

.b-solid-86-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-86-top-left {
  border-top-left-radius: 86px;
}

.b-radius-86-top-right {
  border-top-right-radius: 86px;
}

.b-radius-86-bottom-left {
  border-bottom-left-radius: 86px;
}

.b-radius-86-bottom-right {
  border-bottom-right-radius: 86px;
}

.b-solid-87 {
  border-style: solid;
  border-width: 87px;
}

.b-radius-87 {
  border-radius: 87px;
}

.b-solid-87-bottom {
  border-bottom-style: solid;
  border-bottom-width: 87px;
}

.b-solid-87-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-87-left {
  border-left-style: solid;
  border-left-width: 87px;
}

.b-solid-87-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-87-right {
  border-right-style: solid;
  border-right-width: 87px;
}

.b-solid-87-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-87-top {
  border-top-style: solid;
  border-top-width: 87px;
}

.b-solid-87-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-87-top-left {
  border-top-left-radius: 87px;
}

.b-radius-87-top-right {
  border-top-right-radius: 87px;
}

.b-radius-87-bottom-left {
  border-bottom-left-radius: 87px;
}

.b-radius-87-bottom-right {
  border-bottom-right-radius: 87px;
}

.b-solid-88 {
  border-style: solid;
  border-width: 88px;
}

.b-radius-88 {
  border-radius: 88px;
}

.b-solid-88-bottom {
  border-bottom-style: solid;
  border-bottom-width: 88px;
}

.b-solid-88-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-88-left {
  border-left-style: solid;
  border-left-width: 88px;
}

.b-solid-88-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-88-right {
  border-right-style: solid;
  border-right-width: 88px;
}

.b-solid-88-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-88-top {
  border-top-style: solid;
  border-top-width: 88px;
}

.b-solid-88-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-88-top-left {
  border-top-left-radius: 88px;
}

.b-radius-88-top-right {
  border-top-right-radius: 88px;
}

.b-radius-88-bottom-left {
  border-bottom-left-radius: 88px;
}

.b-radius-88-bottom-right {
  border-bottom-right-radius: 88px;
}

.b-solid-89 {
  border-style: solid;
  border-width: 89px;
}

.b-radius-89 {
  border-radius: 89px;
}

.b-solid-89-bottom {
  border-bottom-style: solid;
  border-bottom-width: 89px;
}

.b-solid-89-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-89-left {
  border-left-style: solid;
  border-left-width: 89px;
}

.b-solid-89-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-89-right {
  border-right-style: solid;
  border-right-width: 89px;
}

.b-solid-89-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-89-top {
  border-top-style: solid;
  border-top-width: 89px;
}

.b-solid-89-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-89-top-left {
  border-top-left-radius: 89px;
}

.b-radius-89-top-right {
  border-top-right-radius: 89px;
}

.b-radius-89-bottom-left {
  border-bottom-left-radius: 89px;
}

.b-radius-89-bottom-right {
  border-bottom-right-radius: 89px;
}

.b-solid-90 {
  border-style: solid;
  border-width: 90px;
}

.b-radius-90 {
  border-radius: 90px;
}

.b-solid-90-bottom {
  border-bottom-style: solid;
  border-bottom-width: 90px;
}

.b-solid-90-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-90-left {
  border-left-style: solid;
  border-left-width: 90px;
}

.b-solid-90-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-90-right {
  border-right-style: solid;
  border-right-width: 90px;
}

.b-solid-90-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-90-top {
  border-top-style: solid;
  border-top-width: 90px;
}

.b-solid-90-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-90-top-left {
  border-top-left-radius: 90px;
}

.b-radius-90-top-right {
  border-top-right-radius: 90px;
}

.b-radius-90-bottom-left {
  border-bottom-left-radius: 90px;
}

.b-radius-90-bottom-right {
  border-bottom-right-radius: 90px;
}

.b-solid-91 {
  border-style: solid;
  border-width: 91px;
}

.b-radius-91 {
  border-radius: 91px;
}

.b-solid-91-bottom {
  border-bottom-style: solid;
  border-bottom-width: 91px;
}

.b-solid-91-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-91-left {
  border-left-style: solid;
  border-left-width: 91px;
}

.b-solid-91-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-91-right {
  border-right-style: solid;
  border-right-width: 91px;
}

.b-solid-91-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-91-top {
  border-top-style: solid;
  border-top-width: 91px;
}

.b-solid-91-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-91-top-left {
  border-top-left-radius: 91px;
}

.b-radius-91-top-right {
  border-top-right-radius: 91px;
}

.b-radius-91-bottom-left {
  border-bottom-left-radius: 91px;
}

.b-radius-91-bottom-right {
  border-bottom-right-radius: 91px;
}

.b-solid-92 {
  border-style: solid;
  border-width: 92px;
}

.b-radius-92 {
  border-radius: 92px;
}

.b-solid-92-bottom {
  border-bottom-style: solid;
  border-bottom-width: 92px;
}

.b-solid-92-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-92-left {
  border-left-style: solid;
  border-left-width: 92px;
}

.b-solid-92-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-92-right {
  border-right-style: solid;
  border-right-width: 92px;
}

.b-solid-92-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-92-top {
  border-top-style: solid;
  border-top-width: 92px;
}

.b-solid-92-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-92-top-left {
  border-top-left-radius: 92px;
}

.b-radius-92-top-right {
  border-top-right-radius: 92px;
}

.b-radius-92-bottom-left {
  border-bottom-left-radius: 92px;
}

.b-radius-92-bottom-right {
  border-bottom-right-radius: 92px;
}

.b-solid-93 {
  border-style: solid;
  border-width: 93px;
}

.b-radius-93 {
  border-radius: 93px;
}

.b-solid-93-bottom {
  border-bottom-style: solid;
  border-bottom-width: 93px;
}

.b-solid-93-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-93-left {
  border-left-style: solid;
  border-left-width: 93px;
}

.b-solid-93-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-93-right {
  border-right-style: solid;
  border-right-width: 93px;
}

.b-solid-93-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-93-top {
  border-top-style: solid;
  border-top-width: 93px;
}

.b-solid-93-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-93-top-left {
  border-top-left-radius: 93px;
}

.b-radius-93-top-right {
  border-top-right-radius: 93px;
}

.b-radius-93-bottom-left {
  border-bottom-left-radius: 93px;
}

.b-radius-93-bottom-right {
  border-bottom-right-radius: 93px;
}

.b-solid-94 {
  border-style: solid;
  border-width: 94px;
}

.b-radius-94 {
  border-radius: 94px;
}

.b-solid-94-bottom {
  border-bottom-style: solid;
  border-bottom-width: 94px;
}

.b-solid-94-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-94-left {
  border-left-style: solid;
  border-left-width: 94px;
}

.b-solid-94-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-94-right {
  border-right-style: solid;
  border-right-width: 94px;
}

.b-solid-94-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-94-top {
  border-top-style: solid;
  border-top-width: 94px;
}

.b-solid-94-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-94-top-left {
  border-top-left-radius: 94px;
}

.b-radius-94-top-right {
  border-top-right-radius: 94px;
}

.b-radius-94-bottom-left {
  border-bottom-left-radius: 94px;
}

.b-radius-94-bottom-right {
  border-bottom-right-radius: 94px;
}

.b-solid-95 {
  border-style: solid;
  border-width: 95px;
}

.b-radius-95 {
  border-radius: 95px;
}

.b-solid-95-bottom {
  border-bottom-style: solid;
  border-bottom-width: 95px;
}

.b-solid-95-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-95-left {
  border-left-style: solid;
  border-left-width: 95px;
}

.b-solid-95-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-95-right {
  border-right-style: solid;
  border-right-width: 95px;
}

.b-solid-95-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-95-top {
  border-top-style: solid;
  border-top-width: 95px;
}

.b-solid-95-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-95-top-left {
  border-top-left-radius: 95px;
}

.b-radius-95-top-right {
  border-top-right-radius: 95px;
}

.b-radius-95-bottom-left {
  border-bottom-left-radius: 95px;
}

.b-radius-95-bottom-right {
  border-bottom-right-radius: 95px;
}

.b-solid-96 {
  border-style: solid;
  border-width: 96px;
}

.b-radius-96 {
  border-radius: 96px;
}

.b-solid-96-bottom {
  border-bottom-style: solid;
  border-bottom-width: 96px;
}

.b-solid-96-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-96-left {
  border-left-style: solid;
  border-left-width: 96px;
}

.b-solid-96-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-96-right {
  border-right-style: solid;
  border-right-width: 96px;
}

.b-solid-96-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-96-top {
  border-top-style: solid;
  border-top-width: 96px;
}

.b-solid-96-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-96-top-left {
  border-top-left-radius: 96px;
}

.b-radius-96-top-right {
  border-top-right-radius: 96px;
}

.b-radius-96-bottom-left {
  border-bottom-left-radius: 96px;
}

.b-radius-96-bottom-right {
  border-bottom-right-radius: 96px;
}

.b-solid-97 {
  border-style: solid;
  border-width: 97px;
}

.b-radius-97 {
  border-radius: 97px;
}

.b-solid-97-bottom {
  border-bottom-style: solid;
  border-bottom-width: 97px;
}

.b-solid-97-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-97-left {
  border-left-style: solid;
  border-left-width: 97px;
}

.b-solid-97-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-97-right {
  border-right-style: solid;
  border-right-width: 97px;
}

.b-solid-97-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-97-top {
  border-top-style: solid;
  border-top-width: 97px;
}

.b-solid-97-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-97-top-left {
  border-top-left-radius: 97px;
}

.b-radius-97-top-right {
  border-top-right-radius: 97px;
}

.b-radius-97-bottom-left {
  border-bottom-left-radius: 97px;
}

.b-radius-97-bottom-right {
  border-bottom-right-radius: 97px;
}

.b-solid-98 {
  border-style: solid;
  border-width: 98px;
}

.b-radius-98 {
  border-radius: 98px;
}

.b-solid-98-bottom {
  border-bottom-style: solid;
  border-bottom-width: 98px;
}

.b-solid-98-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-98-left {
  border-left-style: solid;
  border-left-width: 98px;
}

.b-solid-98-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-98-right {
  border-right-style: solid;
  border-right-width: 98px;
}

.b-solid-98-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-98-top {
  border-top-style: solid;
  border-top-width: 98px;
}

.b-solid-98-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-98-top-left {
  border-top-left-radius: 98px;
}

.b-radius-98-top-right {
  border-top-right-radius: 98px;
}

.b-radius-98-bottom-left {
  border-bottom-left-radius: 98px;
}

.b-radius-98-bottom-right {
  border-bottom-right-radius: 98px;
}

.b-solid-99 {
  border-style: solid;
  border-width: 99px;
}

.b-radius-99 {
  border-radius: 99px;
}

.b-solid-99-bottom {
  border-bottom-style: solid;
  border-bottom-width: 99px;
}

.b-solid-99-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-99-left {
  border-left-style: solid;
  border-left-width: 99px;
}

.b-solid-99-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-99-right {
  border-right-style: solid;
  border-right-width: 99px;
}

.b-solid-99-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-99-top {
  border-top-style: solid;
  border-top-width: 99px;
}

.b-solid-99-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-99-top-left {
  border-top-left-radius: 99px;
}

.b-radius-99-top-right {
  border-top-right-radius: 99px;
}

.b-radius-99-bottom-left {
  border-bottom-left-radius: 99px;
}

.b-radius-99-bottom-right {
  border-bottom-right-radius: 99px;
}

.b-solid-100 {
  border-style: solid;
  border-width: 100px;
}

.b-radius-100 {
  border-radius: 100px;
}

.b-solid-100-bottom {
  border-bottom-style: solid;
  border-bottom-width: 100px;
}

.b-solid-100-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-100-left {
  border-left-style: solid;
  border-left-width: 100px;
}

.b-solid-100-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-100-right {
  border-right-style: solid;
  border-right-width: 100px;
}

.b-solid-100-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-100-top {
  border-top-style: solid;
  border-top-width: 100px;
}

.b-solid-100-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-100-top-left {
  border-top-left-radius: 100px;
}

.b-radius-100-top-right {
  border-top-right-radius: 100px;
}

.b-radius-100-bottom-left {
  border-bottom-left-radius: 100px;
}

.b-radius-100-bottom-right {
  border-bottom-right-radius: 100px;
}

.b-radius-remove {
  border-radius: 0px;
}

.b-radius-top-left-remove {
  border-top-left-radius: 0;
}

.b-radius-top-right-remove {
  border-top-right-radius: 0;
}

.b-radius-bottom-left-remove {
  border-bottom-left-radius: 0;
}

.b-radius-bottom-right-remove {
  border-bottom-right-radius: 0;
}

.b-solid-remove {
  border-style: inherit;
  border-width: inherit;
}

/* General Theme */
.header {
  box-shadow: 0px 0px 3px #000000;
}

.footer {
  padding-top: 48px;
  padding-bottom: 48px;
}

/* Pages Theme */
.home-seccion-destacado {
  background: linear-gradient(to top, #ffffff 0%, #ffffff 35%, #002a68 35%, #002a68 100%);
}

/* Sections Theme */
.uk-accordion-title::before {
  background-image: none !important;
}

.uk-open > .uk-accordion-title::before {
  background-image: none;
}

.title-acordion .icon-close {
  display: none;
}

.uk-open .title-acordion .icon-close {
  display: block;
}

.uk-open .title-acordion .icon-open {
  display: none;
}

.padding-1 {
  padding: 1px;
}

.padding-1-bottom {
  padding-bottom: 1px;
}

.padding-1-left {
  padding-left: 1px;
}

.padding-1-right {
  padding-right: 1px;
}

.padding-1-top {
  padding-top: 1px;
}

.padding-2 {
  padding: 2px;
}

.padding-2-bottom {
  padding-bottom: 2px;
}

.padding-2-left {
  padding-left: 2px;
}

.padding-2-right {
  padding-right: 2px;
}

.padding-2-top {
  padding-top: 2px;
}

.padding-3 {
  padding: 3px;
}

.padding-3-bottom {
  padding-bottom: 3px;
}

.padding-3-left {
  padding-left: 3px;
}

.padding-3-right {
  padding-right: 3px;
}

.padding-3-top {
  padding-top: 3px;
}

.padding-4 {
  padding: 4px;
}

.padding-4-bottom {
  padding-bottom: 4px;
}

.padding-4-left {
  padding-left: 4px;
}

.padding-4-right {
  padding-right: 4px;
}

.padding-4-top {
  padding-top: 4px;
}

.padding-5 {
  padding: 5px;
}

.padding-5-bottom {
  padding-bottom: 5px;
}

.padding-5-left {
  padding-left: 5px;
}

.padding-5-right {
  padding-right: 5px;
}

.padding-5-top {
  padding-top: 5px;
}

.padding-6 {
  padding: 6px;
}

.padding-6-bottom {
  padding-bottom: 6px;
}

.padding-6-left {
  padding-left: 6px;
}

.padding-6-right {
  padding-right: 6px;
}

.padding-6-top {
  padding-top: 6px;
}

.padding-7 {
  padding: 7px;
}

.padding-7-bottom {
  padding-bottom: 7px;
}

.padding-7-left {
  padding-left: 7px;
}

.padding-7-right {
  padding-right: 7px;
}

.padding-7-top {
  padding-top: 7px;
}

.padding-8 {
  padding: 8px;
}

.padding-8-bottom {
  padding-bottom: 8px;
}

.padding-8-left {
  padding-left: 8px;
}

.padding-8-right {
  padding-right: 8px;
}

.padding-8-top {
  padding-top: 8px;
}

.padding-9 {
  padding: 9px;
}

.padding-9-bottom {
  padding-bottom: 9px;
}

.padding-9-left {
  padding-left: 9px;
}

.padding-9-right {
  padding-right: 9px;
}

.padding-9-top {
  padding-top: 9px;
}

.padding-10 {
  padding: 10px;
}

.padding-10-bottom {
  padding-bottom: 10px;
}

.padding-10-left {
  padding-left: 10px;
}

.padding-10-right {
  padding-right: 10px;
}

.padding-10-top {
  padding-top: 10px;
}

.padding-11 {
  padding: 11px;
}

.padding-11-bottom {
  padding-bottom: 11px;
}

.padding-11-left {
  padding-left: 11px;
}

.padding-11-right {
  padding-right: 11px;
}

.padding-11-top {
  padding-top: 11px;
}

.padding-12 {
  padding: 12px;
}

.padding-12-bottom {
  padding-bottom: 12px;
}

.padding-12-left {
  padding-left: 12px;
}

.padding-12-right {
  padding-right: 12px;
}

.padding-12-top {
  padding-top: 12px;
}

.padding-13 {
  padding: 13px;
}

.padding-13-bottom {
  padding-bottom: 13px;
}

.padding-13-left {
  padding-left: 13px;
}

.padding-13-right {
  padding-right: 13px;
}

.padding-13-top {
  padding-top: 13px;
}

.padding-14 {
  padding: 14px;
}

.padding-14-bottom {
  padding-bottom: 14px;
}

.padding-14-left {
  padding-left: 14px;
}

.padding-14-right {
  padding-right: 14px;
}

.padding-14-top {
  padding-top: 14px;
}

.padding-15 {
  padding: 15px;
}

.padding-15-bottom {
  padding-bottom: 15px;
}

.padding-15-left {
  padding-left: 15px;
}

.padding-15-right {
  padding-right: 15px;
}

.padding-15-top {
  padding-top: 15px;
}

.padding-16 {
  padding: 16px;
}

.padding-16-bottom {
  padding-bottom: 16px;
}

.padding-16-left {
  padding-left: 16px;
}

.padding-16-right {
  padding-right: 16px;
}

.padding-16-top {
  padding-top: 16px;
}

.padding-17 {
  padding: 17px;
}

.padding-17-bottom {
  padding-bottom: 17px;
}

.padding-17-left {
  padding-left: 17px;
}

.padding-17-right {
  padding-right: 17px;
}

.padding-17-top {
  padding-top: 17px;
}

.padding-18 {
  padding: 18px;
}

.padding-18-bottom {
  padding-bottom: 18px;
}

.padding-18-left {
  padding-left: 18px;
}

.padding-18-right {
  padding-right: 18px;
}

.padding-18-top {
  padding-top: 18px;
}

.padding-19 {
  padding: 19px;
}

.padding-19-bottom {
  padding-bottom: 19px;
}

.padding-19-left {
  padding-left: 19px;
}

.padding-19-right {
  padding-right: 19px;
}

.padding-19-top {
  padding-top: 19px;
}

.padding-20 {
  padding: 20px;
}

.padding-20-bottom {
  padding-bottom: 20px;
}

.padding-20-left {
  padding-left: 20px;
}

.padding-20-right {
  padding-right: 20px;
}

.padding-20-top {
  padding-top: 20px;
}

.padding-21 {
  padding: 21px;
}

.padding-21-bottom {
  padding-bottom: 21px;
}

.padding-21-left {
  padding-left: 21px;
}

.padding-21-right {
  padding-right: 21px;
}

.padding-21-top {
  padding-top: 21px;
}

.padding-22 {
  padding: 22px;
}

.padding-22-bottom {
  padding-bottom: 22px;
}

.padding-22-left {
  padding-left: 22px;
}

.padding-22-right {
  padding-right: 22px;
}

.padding-22-top {
  padding-top: 22px;
}

.padding-23 {
  padding: 23px;
}

.padding-23-bottom {
  padding-bottom: 23px;
}

.padding-23-left {
  padding-left: 23px;
}

.padding-23-right {
  padding-right: 23px;
}

.padding-23-top {
  padding-top: 23px;
}

.padding-24 {
  padding: 24px;
}

.padding-24-bottom {
  padding-bottom: 24px;
}

.padding-24-left {
  padding-left: 24px;
}

.padding-24-right {
  padding-right: 24px;
}

.padding-24-top {
  padding-top: 24px;
}

.padding-25 {
  padding: 25px;
}

.padding-25-bottom {
  padding-bottom: 25px;
}

.padding-25-left {
  padding-left: 25px;
}

.padding-25-right {
  padding-right: 25px;
}

.padding-25-top {
  padding-top: 25px;
}

.padding-26 {
  padding: 26px;
}

.padding-26-bottom {
  padding-bottom: 26px;
}

.padding-26-left {
  padding-left: 26px;
}

.padding-26-right {
  padding-right: 26px;
}

.padding-26-top {
  padding-top: 26px;
}

.padding-27 {
  padding: 27px;
}

.padding-27-bottom {
  padding-bottom: 27px;
}

.padding-27-left {
  padding-left: 27px;
}

.padding-27-right {
  padding-right: 27px;
}

.padding-27-top {
  padding-top: 27px;
}

.padding-28 {
  padding: 28px;
}

.padding-28-bottom {
  padding-bottom: 28px;
}

.padding-28-left {
  padding-left: 28px;
}

.padding-28-right {
  padding-right: 28px;
}

.padding-28-top {
  padding-top: 28px;
}

.padding-29 {
  padding: 29px;
}

.padding-29-bottom {
  padding-bottom: 29px;
}

.padding-29-left {
  padding-left: 29px;
}

.padding-29-right {
  padding-right: 29px;
}

.padding-29-top {
  padding-top: 29px;
}

.padding-30 {
  padding: 30px;
}

.padding-30-bottom {
  padding-bottom: 30px;
}

.padding-30-left {
  padding-left: 30px;
}

.padding-30-right {
  padding-right: 30px;
}

.padding-30-top {
  padding-top: 30px;
}

.padding-31 {
  padding: 31px;
}

.padding-31-bottom {
  padding-bottom: 31px;
}

.padding-31-left {
  padding-left: 31px;
}

.padding-31-right {
  padding-right: 31px;
}

.padding-31-top {
  padding-top: 31px;
}

.padding-32 {
  padding: 32px;
}

.padding-32-bottom {
  padding-bottom: 32px;
}

.padding-32-left {
  padding-left: 32px;
}

.padding-32-right {
  padding-right: 32px;
}

.padding-32-top {
  padding-top: 32px;
}

.padding-33 {
  padding: 33px;
}

.padding-33-bottom {
  padding-bottom: 33px;
}

.padding-33-left {
  padding-left: 33px;
}

.padding-33-right {
  padding-right: 33px;
}

.padding-33-top {
  padding-top: 33px;
}

.padding-34 {
  padding: 34px;
}

.padding-34-bottom {
  padding-bottom: 34px;
}

.padding-34-left {
  padding-left: 34px;
}

.padding-34-right {
  padding-right: 34px;
}

.padding-34-top {
  padding-top: 34px;
}

.padding-35 {
  padding: 35px;
}

.padding-35-bottom {
  padding-bottom: 35px;
}

.padding-35-left {
  padding-left: 35px;
}

.padding-35-right {
  padding-right: 35px;
}

.padding-35-top {
  padding-top: 35px;
}

.padding-36 {
  padding: 36px;
}

.padding-36-bottom {
  padding-bottom: 36px;
}

.padding-36-left {
  padding-left: 36px;
}

.padding-36-right {
  padding-right: 36px;
}

.padding-36-top {
  padding-top: 36px;
}

.padding-37 {
  padding: 37px;
}

.padding-37-bottom {
  padding-bottom: 37px;
}

.padding-37-left {
  padding-left: 37px;
}

.padding-37-right {
  padding-right: 37px;
}

.padding-37-top {
  padding-top: 37px;
}

.padding-38 {
  padding: 38px;
}

.padding-38-bottom {
  padding-bottom: 38px;
}

.padding-38-left {
  padding-left: 38px;
}

.padding-38-right {
  padding-right: 38px;
}

.padding-38-top {
  padding-top: 38px;
}

.padding-39 {
  padding: 39px;
}

.padding-39-bottom {
  padding-bottom: 39px;
}

.padding-39-left {
  padding-left: 39px;
}

.padding-39-right {
  padding-right: 39px;
}

.padding-39-top {
  padding-top: 39px;
}

.padding-40 {
  padding: 40px;
}

.padding-40-bottom {
  padding-bottom: 40px;
}

.padding-40-left {
  padding-left: 40px;
}

.padding-40-right {
  padding-right: 40px;
}

.padding-40-top {
  padding-top: 40px;
}

.padding-41 {
  padding: 41px;
}

.padding-41-bottom {
  padding-bottom: 41px;
}

.padding-41-left {
  padding-left: 41px;
}

.padding-41-right {
  padding-right: 41px;
}

.padding-41-top {
  padding-top: 41px;
}

.padding-42 {
  padding: 42px;
}

.padding-42-bottom {
  padding-bottom: 42px;
}

.padding-42-left {
  padding-left: 42px;
}

.padding-42-right {
  padding-right: 42px;
}

.padding-42-top {
  padding-top: 42px;
}

.padding-43 {
  padding: 43px;
}

.padding-43-bottom {
  padding-bottom: 43px;
}

.padding-43-left {
  padding-left: 43px;
}

.padding-43-right {
  padding-right: 43px;
}

.padding-43-top {
  padding-top: 43px;
}

.padding-44 {
  padding: 44px;
}

.padding-44-bottom {
  padding-bottom: 44px;
}

.padding-44-left {
  padding-left: 44px;
}

.padding-44-right {
  padding-right: 44px;
}

.padding-44-top {
  padding-top: 44px;
}

.padding-45 {
  padding: 45px;
}

.padding-45-bottom {
  padding-bottom: 45px;
}

.padding-45-left {
  padding-left: 45px;
}

.padding-45-right {
  padding-right: 45px;
}

.padding-45-top {
  padding-top: 45px;
}

.padding-46 {
  padding: 46px;
}

.padding-46-bottom {
  padding-bottom: 46px;
}

.padding-46-left {
  padding-left: 46px;
}

.padding-46-right {
  padding-right: 46px;
}

.padding-46-top {
  padding-top: 46px;
}

.padding-47 {
  padding: 47px;
}

.padding-47-bottom {
  padding-bottom: 47px;
}

.padding-47-left {
  padding-left: 47px;
}

.padding-47-right {
  padding-right: 47px;
}

.padding-47-top {
  padding-top: 47px;
}

.padding-48 {
  padding: 48px;
}

.padding-48-bottom {
  padding-bottom: 48px;
}

.padding-48-left {
  padding-left: 48px;
}

.padding-48-right {
  padding-right: 48px;
}

.padding-48-top {
  padding-top: 48px;
}

.padding-49 {
  padding: 49px;
}

.padding-49-bottom {
  padding-bottom: 49px;
}

.padding-49-left {
  padding-left: 49px;
}

.padding-49-right {
  padding-right: 49px;
}

.padding-49-top {
  padding-top: 49px;
}

.padding-50 {
  padding: 50px;
}

.padding-50-bottom {
  padding-bottom: 50px;
}

.padding-50-left {
  padding-left: 50px;
}

.padding-50-right {
  padding-right: 50px;
}

.padding-50-top {
  padding-top: 50px;
}

.padding-51 {
  padding: 51px;
}

.padding-51-bottom {
  padding-bottom: 51px;
}

.padding-51-left {
  padding-left: 51px;
}

.padding-51-right {
  padding-right: 51px;
}

.padding-51-top {
  padding-top: 51px;
}

.padding-52 {
  padding: 52px;
}

.padding-52-bottom {
  padding-bottom: 52px;
}

.padding-52-left {
  padding-left: 52px;
}

.padding-52-right {
  padding-right: 52px;
}

.padding-52-top {
  padding-top: 52px;
}

.padding-53 {
  padding: 53px;
}

.padding-53-bottom {
  padding-bottom: 53px;
}

.padding-53-left {
  padding-left: 53px;
}

.padding-53-right {
  padding-right: 53px;
}

.padding-53-top {
  padding-top: 53px;
}

.padding-54 {
  padding: 54px;
}

.padding-54-bottom {
  padding-bottom: 54px;
}

.padding-54-left {
  padding-left: 54px;
}

.padding-54-right {
  padding-right: 54px;
}

.padding-54-top {
  padding-top: 54px;
}

.padding-55 {
  padding: 55px;
}

.padding-55-bottom {
  padding-bottom: 55px;
}

.padding-55-left {
  padding-left: 55px;
}

.padding-55-right {
  padding-right: 55px;
}

.padding-55-top {
  padding-top: 55px;
}

.padding-56 {
  padding: 56px;
}

.padding-56-bottom {
  padding-bottom: 56px;
}

.padding-56-left {
  padding-left: 56px;
}

.padding-56-right {
  padding-right: 56px;
}

.padding-56-top {
  padding-top: 56px;
}

.padding-57 {
  padding: 57px;
}

.padding-57-bottom {
  padding-bottom: 57px;
}

.padding-57-left {
  padding-left: 57px;
}

.padding-57-right {
  padding-right: 57px;
}

.padding-57-top {
  padding-top: 57px;
}

.padding-58 {
  padding: 58px;
}

.padding-58-bottom {
  padding-bottom: 58px;
}

.padding-58-left {
  padding-left: 58px;
}

.padding-58-right {
  padding-right: 58px;
}

.padding-58-top {
  padding-top: 58px;
}

.padding-59 {
  padding: 59px;
}

.padding-59-bottom {
  padding-bottom: 59px;
}

.padding-59-left {
  padding-left: 59px;
}

.padding-59-right {
  padding-right: 59px;
}

.padding-59-top {
  padding-top: 59px;
}

.padding-60 {
  padding: 60px;
}

.padding-60-bottom {
  padding-bottom: 60px;
}

.padding-60-left {
  padding-left: 60px;
}

.padding-60-right {
  padding-right: 60px;
}

.padding-60-top {
  padding-top: 60px;
}

.padding-61 {
  padding: 61px;
}

.padding-61-bottom {
  padding-bottom: 61px;
}

.padding-61-left {
  padding-left: 61px;
}

.padding-61-right {
  padding-right: 61px;
}

.padding-61-top {
  padding-top: 61px;
}

.padding-62 {
  padding: 62px;
}

.padding-62-bottom {
  padding-bottom: 62px;
}

.padding-62-left {
  padding-left: 62px;
}

.padding-62-right {
  padding-right: 62px;
}

.padding-62-top {
  padding-top: 62px;
}

.padding-63 {
  padding: 63px;
}

.padding-63-bottom {
  padding-bottom: 63px;
}

.padding-63-left {
  padding-left: 63px;
}

.padding-63-right {
  padding-right: 63px;
}

.padding-63-top {
  padding-top: 63px;
}

.padding-64 {
  padding: 64px;
}

.padding-64-bottom {
  padding-bottom: 64px;
}

.padding-64-left {
  padding-left: 64px;
}

.padding-64-right {
  padding-right: 64px;
}

.padding-64-top {
  padding-top: 64px;
}

.padding-65 {
  padding: 65px;
}

.padding-65-bottom {
  padding-bottom: 65px;
}

.padding-65-left {
  padding-left: 65px;
}

.padding-65-right {
  padding-right: 65px;
}

.padding-65-top {
  padding-top: 65px;
}

.padding-66 {
  padding: 66px;
}

.padding-66-bottom {
  padding-bottom: 66px;
}

.padding-66-left {
  padding-left: 66px;
}

.padding-66-right {
  padding-right: 66px;
}

.padding-66-top {
  padding-top: 66px;
}

.padding-67 {
  padding: 67px;
}

.padding-67-bottom {
  padding-bottom: 67px;
}

.padding-67-left {
  padding-left: 67px;
}

.padding-67-right {
  padding-right: 67px;
}

.padding-67-top {
  padding-top: 67px;
}

.padding-68 {
  padding: 68px;
}

.padding-68-bottom {
  padding-bottom: 68px;
}

.padding-68-left {
  padding-left: 68px;
}

.padding-68-right {
  padding-right: 68px;
}

.padding-68-top {
  padding-top: 68px;
}

.padding-69 {
  padding: 69px;
}

.padding-69-bottom {
  padding-bottom: 69px;
}

.padding-69-left {
  padding-left: 69px;
}

.padding-69-right {
  padding-right: 69px;
}

.padding-69-top {
  padding-top: 69px;
}

.padding-70 {
  padding: 70px;
}

.padding-70-bottom {
  padding-bottom: 70px;
}

.padding-70-left {
  padding-left: 70px;
}

.padding-70-right {
  padding-right: 70px;
}

.padding-70-top {
  padding-top: 70px;
}

.padding-71 {
  padding: 71px;
}

.padding-71-bottom {
  padding-bottom: 71px;
}

.padding-71-left {
  padding-left: 71px;
}

.padding-71-right {
  padding-right: 71px;
}

.padding-71-top {
  padding-top: 71px;
}

.padding-72 {
  padding: 72px;
}

.padding-72-bottom {
  padding-bottom: 72px;
}

.padding-72-left {
  padding-left: 72px;
}

.padding-72-right {
  padding-right: 72px;
}

.padding-72-top {
  padding-top: 72px;
}

.padding-73 {
  padding: 73px;
}

.padding-73-bottom {
  padding-bottom: 73px;
}

.padding-73-left {
  padding-left: 73px;
}

.padding-73-right {
  padding-right: 73px;
}

.padding-73-top {
  padding-top: 73px;
}

.padding-74 {
  padding: 74px;
}

.padding-74-bottom {
  padding-bottom: 74px;
}

.padding-74-left {
  padding-left: 74px;
}

.padding-74-right {
  padding-right: 74px;
}

.padding-74-top {
  padding-top: 74px;
}

.padding-75 {
  padding: 75px;
}

.padding-75-bottom {
  padding-bottom: 75px;
}

.padding-75-left {
  padding-left: 75px;
}

.padding-75-right {
  padding-right: 75px;
}

.padding-75-top {
  padding-top: 75px;
}

.padding-76 {
  padding: 76px;
}

.padding-76-bottom {
  padding-bottom: 76px;
}

.padding-76-left {
  padding-left: 76px;
}

.padding-76-right {
  padding-right: 76px;
}

.padding-76-top {
  padding-top: 76px;
}

.padding-77 {
  padding: 77px;
}

.padding-77-bottom {
  padding-bottom: 77px;
}

.padding-77-left {
  padding-left: 77px;
}

.padding-77-right {
  padding-right: 77px;
}

.padding-77-top {
  padding-top: 77px;
}

.padding-78 {
  padding: 78px;
}

.padding-78-bottom {
  padding-bottom: 78px;
}

.padding-78-left {
  padding-left: 78px;
}

.padding-78-right {
  padding-right: 78px;
}

.padding-78-top {
  padding-top: 78px;
}

.padding-79 {
  padding: 79px;
}

.padding-79-bottom {
  padding-bottom: 79px;
}

.padding-79-left {
  padding-left: 79px;
}

.padding-79-right {
  padding-right: 79px;
}

.padding-79-top {
  padding-top: 79px;
}

.padding-80 {
  padding: 80px;
}

.padding-80-bottom {
  padding-bottom: 80px;
}

.padding-80-left {
  padding-left: 80px;
}

.padding-80-right {
  padding-right: 80px;
}

.padding-80-top {
  padding-top: 80px;
}

.padding-81 {
  padding: 81px;
}

.padding-81-bottom {
  padding-bottom: 81px;
}

.padding-81-left {
  padding-left: 81px;
}

.padding-81-right {
  padding-right: 81px;
}

.padding-81-top {
  padding-top: 81px;
}

.padding-82 {
  padding: 82px;
}

.padding-82-bottom {
  padding-bottom: 82px;
}

.padding-82-left {
  padding-left: 82px;
}

.padding-82-right {
  padding-right: 82px;
}

.padding-82-top {
  padding-top: 82px;
}

.padding-83 {
  padding: 83px;
}

.padding-83-bottom {
  padding-bottom: 83px;
}

.padding-83-left {
  padding-left: 83px;
}

.padding-83-right {
  padding-right: 83px;
}

.padding-83-top {
  padding-top: 83px;
}

.padding-84 {
  padding: 84px;
}

.padding-84-bottom {
  padding-bottom: 84px;
}

.padding-84-left {
  padding-left: 84px;
}

.padding-84-right {
  padding-right: 84px;
}

.padding-84-top {
  padding-top: 84px;
}

.padding-85 {
  padding: 85px;
}

.padding-85-bottom {
  padding-bottom: 85px;
}

.padding-85-left {
  padding-left: 85px;
}

.padding-85-right {
  padding-right: 85px;
}

.padding-85-top {
  padding-top: 85px;
}

.padding-86 {
  padding: 86px;
}

.padding-86-bottom {
  padding-bottom: 86px;
}

.padding-86-left {
  padding-left: 86px;
}

.padding-86-right {
  padding-right: 86px;
}

.padding-86-top {
  padding-top: 86px;
}

.padding-87 {
  padding: 87px;
}

.padding-87-bottom {
  padding-bottom: 87px;
}

.padding-87-left {
  padding-left: 87px;
}

.padding-87-right {
  padding-right: 87px;
}

.padding-87-top {
  padding-top: 87px;
}

.padding-88 {
  padding: 88px;
}

.padding-88-bottom {
  padding-bottom: 88px;
}

.padding-88-left {
  padding-left: 88px;
}

.padding-88-right {
  padding-right: 88px;
}

.padding-88-top {
  padding-top: 88px;
}

.padding-89 {
  padding: 89px;
}

.padding-89-bottom {
  padding-bottom: 89px;
}

.padding-89-left {
  padding-left: 89px;
}

.padding-89-right {
  padding-right: 89px;
}

.padding-89-top {
  padding-top: 89px;
}

.padding-90 {
  padding: 90px;
}

.padding-90-bottom {
  padding-bottom: 90px;
}

.padding-90-left {
  padding-left: 90px;
}

.padding-90-right {
  padding-right: 90px;
}

.padding-90-top {
  padding-top: 90px;
}

.padding-91 {
  padding: 91px;
}

.padding-91-bottom {
  padding-bottom: 91px;
}

.padding-91-left {
  padding-left: 91px;
}

.padding-91-right {
  padding-right: 91px;
}

.padding-91-top {
  padding-top: 91px;
}

.padding-92 {
  padding: 92px;
}

.padding-92-bottom {
  padding-bottom: 92px;
}

.padding-92-left {
  padding-left: 92px;
}

.padding-92-right {
  padding-right: 92px;
}

.padding-92-top {
  padding-top: 92px;
}

.padding-93 {
  padding: 93px;
}

.padding-93-bottom {
  padding-bottom: 93px;
}

.padding-93-left {
  padding-left: 93px;
}

.padding-93-right {
  padding-right: 93px;
}

.padding-93-top {
  padding-top: 93px;
}

.padding-94 {
  padding: 94px;
}

.padding-94-bottom {
  padding-bottom: 94px;
}

.padding-94-left {
  padding-left: 94px;
}

.padding-94-right {
  padding-right: 94px;
}

.padding-94-top {
  padding-top: 94px;
}

.padding-95 {
  padding: 95px;
}

.padding-95-bottom {
  padding-bottom: 95px;
}

.padding-95-left {
  padding-left: 95px;
}

.padding-95-right {
  padding-right: 95px;
}

.padding-95-top {
  padding-top: 95px;
}

.padding-96 {
  padding: 96px;
}

.padding-96-bottom {
  padding-bottom: 96px;
}

.padding-96-left {
  padding-left: 96px;
}

.padding-96-right {
  padding-right: 96px;
}

.padding-96-top {
  padding-top: 96px;
}

.padding-97 {
  padding: 97px;
}

.padding-97-bottom {
  padding-bottom: 97px;
}

.padding-97-left {
  padding-left: 97px;
}

.padding-97-right {
  padding-right: 97px;
}

.padding-97-top {
  padding-top: 97px;
}

.padding-98 {
  padding: 98px;
}

.padding-98-bottom {
  padding-bottom: 98px;
}

.padding-98-left {
  padding-left: 98px;
}

.padding-98-right {
  padding-right: 98px;
}

.padding-98-top {
  padding-top: 98px;
}

.padding-99 {
  padding: 99px;
}

.padding-99-bottom {
  padding-bottom: 99px;
}

.padding-99-left {
  padding-left: 99px;
}

.padding-99-right {
  padding-right: 99px;
}

.padding-99-top {
  padding-top: 99px;
}

.padding-100 {
  padding: 100px;
}

.padding-100-bottom {
  padding-bottom: 100px;
}

.padding-100-left {
  padding-left: 100px;
}

.padding-100-right {
  padding-right: 100px;
}

.padding-100-top {
  padding-top: 100px;
}

.padding-remove {
  padding: 0;
}

.padding-bottom-remove {
  padding-bottom: 0;
}

.padding-left-remove {
  padding-left: 0;
}

.padding-right-remove {
  padding-right: 0;
}

.padding-top-remove {
  padding-top: 0;
}

.margin-1 {
  margin: 1px;
}

.margin-1-bottom {
  margin-bottom: 1px;
}

.margin-1-left {
  margin-left: 1px;
}

.margin-1-right {
  margin-right: 1px;
}

.margin-1-top {
  margin-top: 1px;
}

.margin-2 {
  margin: 2px;
}

.margin-2-bottom {
  margin-bottom: 2px;
}

.margin-2-left {
  margin-left: 2px;
}

.margin-2-right {
  margin-right: 2px;
}

.margin-2-top {
  margin-top: 2px;
}

.margin-3 {
  margin: 3px;
}

.margin-3-bottom {
  margin-bottom: 3px;
}

.margin-3-left {
  margin-left: 3px;
}

.margin-3-right {
  margin-right: 3px;
}

.margin-3-top {
  margin-top: 3px;
}

.margin-4 {
  margin: 4px;
}

.margin-4-bottom {
  margin-bottom: 4px;
}

.margin-4-left {
  margin-left: 4px;
}

.margin-4-right {
  margin-right: 4px;
}

.margin-4-top {
  margin-top: 4px;
}

.margin-5 {
  margin: 5px;
}

.margin-5-bottom {
  margin-bottom: 5px;
}

.margin-5-left {
  margin-left: 5px;
}

.margin-5-right {
  margin-right: 5px;
}

.margin-5-top {
  margin-top: 5px;
}

.margin-6 {
  margin: 6px;
}

.margin-6-bottom {
  margin-bottom: 6px;
}

.margin-6-left {
  margin-left: 6px;
}

.margin-6-right {
  margin-right: 6px;
}

.margin-6-top {
  margin-top: 6px;
}

.margin-7 {
  margin: 7px;
}

.margin-7-bottom {
  margin-bottom: 7px;
}

.margin-7-left {
  margin-left: 7px;
}

.margin-7-right {
  margin-right: 7px;
}

.margin-7-top {
  margin-top: 7px;
}

.margin-8 {
  margin: 8px;
}

.margin-8-bottom {
  margin-bottom: 8px;
}

.margin-8-left {
  margin-left: 8px;
}

.margin-8-right {
  margin-right: 8px;
}

.margin-8-top {
  margin-top: 8px;
}

.margin-9 {
  margin: 9px;
}

.margin-9-bottom {
  margin-bottom: 9px;
}

.margin-9-left {
  margin-left: 9px;
}

.margin-9-right {
  margin-right: 9px;
}

.margin-9-top {
  margin-top: 9px;
}

.margin-10 {
  margin: 10px;
}

.margin-10-bottom {
  margin-bottom: 10px;
}

.margin-10-left {
  margin-left: 10px;
}

.margin-10-right {
  margin-right: 10px;
}

.margin-10-top {
  margin-top: 10px;
}

.margin-11 {
  margin: 11px;
}

.margin-11-bottom {
  margin-bottom: 11px;
}

.margin-11-left {
  margin-left: 11px;
}

.margin-11-right {
  margin-right: 11px;
}

.margin-11-top {
  margin-top: 11px;
}

.margin-12 {
  margin: 12px;
}

.margin-12-bottom {
  margin-bottom: 12px;
}

.margin-12-left {
  margin-left: 12px;
}

.margin-12-right {
  margin-right: 12px;
}

.margin-12-top {
  margin-top: 12px;
}

.margin-13 {
  margin: 13px;
}

.margin-13-bottom {
  margin-bottom: 13px;
}

.margin-13-left {
  margin-left: 13px;
}

.margin-13-right {
  margin-right: 13px;
}

.margin-13-top {
  margin-top: 13px;
}

.margin-14 {
  margin: 14px;
}

.margin-14-bottom {
  margin-bottom: 14px;
}

.margin-14-left {
  margin-left: 14px;
}

.margin-14-right {
  margin-right: 14px;
}

.margin-14-top {
  margin-top: 14px;
}

.margin-15 {
  margin: 15px;
}

.margin-15-bottom {
  margin-bottom: 15px;
}

.margin-15-left {
  margin-left: 15px;
}

.margin-15-right {
  margin-right: 15px;
}

.margin-15-top {
  margin-top: 15px;
}

.margin-16 {
  margin: 16px;
}

.margin-16-bottom {
  margin-bottom: 16px;
}

.margin-16-left {
  margin-left: 16px;
}

.margin-16-right {
  margin-right: 16px;
}

.margin-16-top {
  margin-top: 16px;
}

.margin-17 {
  margin: 17px;
}

.margin-17-bottom {
  margin-bottom: 17px;
}

.margin-17-left {
  margin-left: 17px;
}

.margin-17-right {
  margin-right: 17px;
}

.margin-17-top {
  margin-top: 17px;
}

.margin-18 {
  margin: 18px;
}

.margin-18-bottom {
  margin-bottom: 18px;
}

.margin-18-left {
  margin-left: 18px;
}

.margin-18-right {
  margin-right: 18px;
}

.margin-18-top {
  margin-top: 18px;
}

.margin-19 {
  margin: 19px;
}

.margin-19-bottom {
  margin-bottom: 19px;
}

.margin-19-left {
  margin-left: 19px;
}

.margin-19-right {
  margin-right: 19px;
}

.margin-19-top {
  margin-top: 19px;
}

.margin-20 {
  margin: 20px;
}

.margin-20-bottom {
  margin-bottom: 20px;
}

.margin-20-left {
  margin-left: 20px;
}

.margin-20-right {
  margin-right: 20px;
}

.margin-20-top {
  margin-top: 20px;
}

.margin-21 {
  margin: 21px;
}

.margin-21-bottom {
  margin-bottom: 21px;
}

.margin-21-left {
  margin-left: 21px;
}

.margin-21-right {
  margin-right: 21px;
}

.margin-21-top {
  margin-top: 21px;
}

.margin-22 {
  margin: 22px;
}

.margin-22-bottom {
  margin-bottom: 22px;
}

.margin-22-left {
  margin-left: 22px;
}

.margin-22-right {
  margin-right: 22px;
}

.margin-22-top {
  margin-top: 22px;
}

.margin-23 {
  margin: 23px;
}

.margin-23-bottom {
  margin-bottom: 23px;
}

.margin-23-left {
  margin-left: 23px;
}

.margin-23-right {
  margin-right: 23px;
}

.margin-23-top {
  margin-top: 23px;
}

.margin-24 {
  margin: 24px;
}

.margin-24-bottom {
  margin-bottom: 24px;
}

.margin-24-left {
  margin-left: 24px;
}

.margin-24-right {
  margin-right: 24px;
}

.margin-24-top {
  margin-top: 24px;
}

.margin-25 {
  margin: 25px;
}

.margin-25-bottom {
  margin-bottom: 25px;
}

.margin-25-left {
  margin-left: 25px;
}

.margin-25-right {
  margin-right: 25px;
}

.margin-25-top {
  margin-top: 25px;
}

.margin-26 {
  margin: 26px;
}

.margin-26-bottom {
  margin-bottom: 26px;
}

.margin-26-left {
  margin-left: 26px;
}

.margin-26-right {
  margin-right: 26px;
}

.margin-26-top {
  margin-top: 26px;
}

.margin-27 {
  margin: 27px;
}

.margin-27-bottom {
  margin-bottom: 27px;
}

.margin-27-left {
  margin-left: 27px;
}

.margin-27-right {
  margin-right: 27px;
}

.margin-27-top {
  margin-top: 27px;
}

.margin-28 {
  margin: 28px;
}

.margin-28-bottom {
  margin-bottom: 28px;
}

.margin-28-left {
  margin-left: 28px;
}

.margin-28-right {
  margin-right: 28px;
}

.margin-28-top {
  margin-top: 28px;
}

.margin-29 {
  margin: 29px;
}

.margin-29-bottom {
  margin-bottom: 29px;
}

.margin-29-left {
  margin-left: 29px;
}

.margin-29-right {
  margin-right: 29px;
}

.margin-29-top {
  margin-top: 29px;
}

.margin-30 {
  margin: 30px;
}

.margin-30-bottom {
  margin-bottom: 30px;
}

.margin-30-left {
  margin-left: 30px;
}

.margin-30-right {
  margin-right: 30px;
}

.margin-30-top {
  margin-top: 30px;
}

.margin-31 {
  margin: 31px;
}

.margin-31-bottom {
  margin-bottom: 31px;
}

.margin-31-left {
  margin-left: 31px;
}

.margin-31-right {
  margin-right: 31px;
}

.margin-31-top {
  margin-top: 31px;
}

.margin-32 {
  margin: 32px;
}

.margin-32-bottom {
  margin-bottom: 32px;
}

.margin-32-left {
  margin-left: 32px;
}

.margin-32-right {
  margin-right: 32px;
}

.margin-32-top {
  margin-top: 32px;
}

.margin-33 {
  margin: 33px;
}

.margin-33-bottom {
  margin-bottom: 33px;
}

.margin-33-left {
  margin-left: 33px;
}

.margin-33-right {
  margin-right: 33px;
}

.margin-33-top {
  margin-top: 33px;
}

.margin-34 {
  margin: 34px;
}

.margin-34-bottom {
  margin-bottom: 34px;
}

.margin-34-left {
  margin-left: 34px;
}

.margin-34-right {
  margin-right: 34px;
}

.margin-34-top {
  margin-top: 34px;
}

.margin-35 {
  margin: 35px;
}

.margin-35-bottom {
  margin-bottom: 35px;
}

.margin-35-left {
  margin-left: 35px;
}

.margin-35-right {
  margin-right: 35px;
}

.margin-35-top {
  margin-top: 35px;
}

.margin-36 {
  margin: 36px;
}

.margin-36-bottom {
  margin-bottom: 36px;
}

.margin-36-left {
  margin-left: 36px;
}

.margin-36-right {
  margin-right: 36px;
}

.margin-36-top {
  margin-top: 36px;
}

.margin-37 {
  margin: 37px;
}

.margin-37-bottom {
  margin-bottom: 37px;
}

.margin-37-left {
  margin-left: 37px;
}

.margin-37-right {
  margin-right: 37px;
}

.margin-37-top {
  margin-top: 37px;
}

.margin-38 {
  margin: 38px;
}

.margin-38-bottom {
  margin-bottom: 38px;
}

.margin-38-left {
  margin-left: 38px;
}

.margin-38-right {
  margin-right: 38px;
}

.margin-38-top {
  margin-top: 38px;
}

.margin-39 {
  margin: 39px;
}

.margin-39-bottom {
  margin-bottom: 39px;
}

.margin-39-left {
  margin-left: 39px;
}

.margin-39-right {
  margin-right: 39px;
}

.margin-39-top {
  margin-top: 39px;
}

.margin-40 {
  margin: 40px;
}

.margin-40-bottom {
  margin-bottom: 40px;
}

.margin-40-left {
  margin-left: 40px;
}

.margin-40-right {
  margin-right: 40px;
}

.margin-40-top {
  margin-top: 40px;
}

.margin-41 {
  margin: 41px;
}

.margin-41-bottom {
  margin-bottom: 41px;
}

.margin-41-left {
  margin-left: 41px;
}

.margin-41-right {
  margin-right: 41px;
}

.margin-41-top {
  margin-top: 41px;
}

.margin-42 {
  margin: 42px;
}

.margin-42-bottom {
  margin-bottom: 42px;
}

.margin-42-left {
  margin-left: 42px;
}

.margin-42-right {
  margin-right: 42px;
}

.margin-42-top {
  margin-top: 42px;
}

.margin-43 {
  margin: 43px;
}

.margin-43-bottom {
  margin-bottom: 43px;
}

.margin-43-left {
  margin-left: 43px;
}

.margin-43-right {
  margin-right: 43px;
}

.margin-43-top {
  margin-top: 43px;
}

.margin-44 {
  margin: 44px;
}

.margin-44-bottom {
  margin-bottom: 44px;
}

.margin-44-left {
  margin-left: 44px;
}

.margin-44-right {
  margin-right: 44px;
}

.margin-44-top {
  margin-top: 44px;
}

.margin-45 {
  margin: 45px;
}

.margin-45-bottom {
  margin-bottom: 45px;
}

.margin-45-left {
  margin-left: 45px;
}

.margin-45-right {
  margin-right: 45px;
}

.margin-45-top {
  margin-top: 45px;
}

.margin-46 {
  margin: 46px;
}

.margin-46-bottom {
  margin-bottom: 46px;
}

.margin-46-left {
  margin-left: 46px;
}

.margin-46-right {
  margin-right: 46px;
}

.margin-46-top {
  margin-top: 46px;
}

.margin-47 {
  margin: 47px;
}

.margin-47-bottom {
  margin-bottom: 47px;
}

.margin-47-left {
  margin-left: 47px;
}

.margin-47-right {
  margin-right: 47px;
}

.margin-47-top {
  margin-top: 47px;
}

.margin-48 {
  margin: 48px;
}

.margin-48-bottom {
  margin-bottom: 48px;
}

.margin-48-left {
  margin-left: 48px;
}

.margin-48-right {
  margin-right: 48px;
}

.margin-48-top {
  margin-top: 48px;
}

.margin-49 {
  margin: 49px;
}

.margin-49-bottom {
  margin-bottom: 49px;
}

.margin-49-left {
  margin-left: 49px;
}

.margin-49-right {
  margin-right: 49px;
}

.margin-49-top {
  margin-top: 49px;
}

.margin-50 {
  margin: 50px;
}

.margin-50-bottom {
  margin-bottom: 50px;
}

.margin-50-left {
  margin-left: 50px;
}

.margin-50-right {
  margin-right: 50px;
}

.margin-50-top {
  margin-top: 50px;
}

.margin-51 {
  margin: 51px;
}

.margin-51-bottom {
  margin-bottom: 51px;
}

.margin-51-left {
  margin-left: 51px;
}

.margin-51-right {
  margin-right: 51px;
}

.margin-51-top {
  margin-top: 51px;
}

.margin-52 {
  margin: 52px;
}

.margin-52-bottom {
  margin-bottom: 52px;
}

.margin-52-left {
  margin-left: 52px;
}

.margin-52-right {
  margin-right: 52px;
}

.margin-52-top {
  margin-top: 52px;
}

.margin-53 {
  margin: 53px;
}

.margin-53-bottom {
  margin-bottom: 53px;
}

.margin-53-left {
  margin-left: 53px;
}

.margin-53-right {
  margin-right: 53px;
}

.margin-53-top {
  margin-top: 53px;
}

.margin-54 {
  margin: 54px;
}

.margin-54-bottom {
  margin-bottom: 54px;
}

.margin-54-left {
  margin-left: 54px;
}

.margin-54-right {
  margin-right: 54px;
}

.margin-54-top {
  margin-top: 54px;
}

.margin-55 {
  margin: 55px;
}

.margin-55-bottom {
  margin-bottom: 55px;
}

.margin-55-left {
  margin-left: 55px;
}

.margin-55-right {
  margin-right: 55px;
}

.margin-55-top {
  margin-top: 55px;
}

.margin-56 {
  margin: 56px;
}

.margin-56-bottom {
  margin-bottom: 56px;
}

.margin-56-left {
  margin-left: 56px;
}

.margin-56-right {
  margin-right: 56px;
}

.margin-56-top {
  margin-top: 56px;
}

.margin-57 {
  margin: 57px;
}

.margin-57-bottom {
  margin-bottom: 57px;
}

.margin-57-left {
  margin-left: 57px;
}

.margin-57-right {
  margin-right: 57px;
}

.margin-57-top {
  margin-top: 57px;
}

.margin-58 {
  margin: 58px;
}

.margin-58-bottom {
  margin-bottom: 58px;
}

.margin-58-left {
  margin-left: 58px;
}

.margin-58-right {
  margin-right: 58px;
}

.margin-58-top {
  margin-top: 58px;
}

.margin-59 {
  margin: 59px;
}

.margin-59-bottom {
  margin-bottom: 59px;
}

.margin-59-left {
  margin-left: 59px;
}

.margin-59-right {
  margin-right: 59px;
}

.margin-59-top {
  margin-top: 59px;
}

.margin-60 {
  margin: 60px;
}

.margin-60-bottom {
  margin-bottom: 60px;
}

.margin-60-left {
  margin-left: 60px;
}

.margin-60-right {
  margin-right: 60px;
}

.margin-60-top {
  margin-top: 60px;
}

.margin-61 {
  margin: 61px;
}

.margin-61-bottom {
  margin-bottom: 61px;
}

.margin-61-left {
  margin-left: 61px;
}

.margin-61-right {
  margin-right: 61px;
}

.margin-61-top {
  margin-top: 61px;
}

.margin-62 {
  margin: 62px;
}

.margin-62-bottom {
  margin-bottom: 62px;
}

.margin-62-left {
  margin-left: 62px;
}

.margin-62-right {
  margin-right: 62px;
}

.margin-62-top {
  margin-top: 62px;
}

.margin-63 {
  margin: 63px;
}

.margin-63-bottom {
  margin-bottom: 63px;
}

.margin-63-left {
  margin-left: 63px;
}

.margin-63-right {
  margin-right: 63px;
}

.margin-63-top {
  margin-top: 63px;
}

.margin-64 {
  margin: 64px;
}

.margin-64-bottom {
  margin-bottom: 64px;
}

.margin-64-left {
  margin-left: 64px;
}

.margin-64-right {
  margin-right: 64px;
}

.margin-64-top {
  margin-top: 64px;
}

.margin-65 {
  margin: 65px;
}

.margin-65-bottom {
  margin-bottom: 65px;
}

.margin-65-left {
  margin-left: 65px;
}

.margin-65-right {
  margin-right: 65px;
}

.margin-65-top {
  margin-top: 65px;
}

.margin-66 {
  margin: 66px;
}

.margin-66-bottom {
  margin-bottom: 66px;
}

.margin-66-left {
  margin-left: 66px;
}

.margin-66-right {
  margin-right: 66px;
}

.margin-66-top {
  margin-top: 66px;
}

.margin-67 {
  margin: 67px;
}

.margin-67-bottom {
  margin-bottom: 67px;
}

.margin-67-left {
  margin-left: 67px;
}

.margin-67-right {
  margin-right: 67px;
}

.margin-67-top {
  margin-top: 67px;
}

.margin-68 {
  margin: 68px;
}

.margin-68-bottom {
  margin-bottom: 68px;
}

.margin-68-left {
  margin-left: 68px;
}

.margin-68-right {
  margin-right: 68px;
}

.margin-68-top {
  margin-top: 68px;
}

.margin-69 {
  margin: 69px;
}

.margin-69-bottom {
  margin-bottom: 69px;
}

.margin-69-left {
  margin-left: 69px;
}

.margin-69-right {
  margin-right: 69px;
}

.margin-69-top {
  margin-top: 69px;
}

.margin-70 {
  margin: 70px;
}

.margin-70-bottom {
  margin-bottom: 70px;
}

.margin-70-left {
  margin-left: 70px;
}

.margin-70-right {
  margin-right: 70px;
}

.margin-70-top {
  margin-top: 70px;
}

.margin-71 {
  margin: 71px;
}

.margin-71-bottom {
  margin-bottom: 71px;
}

.margin-71-left {
  margin-left: 71px;
}

.margin-71-right {
  margin-right: 71px;
}

.margin-71-top {
  margin-top: 71px;
}

.margin-72 {
  margin: 72px;
}

.margin-72-bottom {
  margin-bottom: 72px;
}

.margin-72-left {
  margin-left: 72px;
}

.margin-72-right {
  margin-right: 72px;
}

.margin-72-top {
  margin-top: 72px;
}

.margin-73 {
  margin: 73px;
}

.margin-73-bottom {
  margin-bottom: 73px;
}

.margin-73-left {
  margin-left: 73px;
}

.margin-73-right {
  margin-right: 73px;
}

.margin-73-top {
  margin-top: 73px;
}

.margin-74 {
  margin: 74px;
}

.margin-74-bottom {
  margin-bottom: 74px;
}

.margin-74-left {
  margin-left: 74px;
}

.margin-74-right {
  margin-right: 74px;
}

.margin-74-top {
  margin-top: 74px;
}

.margin-75 {
  margin: 75px;
}

.margin-75-bottom {
  margin-bottom: 75px;
}

.margin-75-left {
  margin-left: 75px;
}

.margin-75-right {
  margin-right: 75px;
}

.margin-75-top {
  margin-top: 75px;
}

.margin-76 {
  margin: 76px;
}

.margin-76-bottom {
  margin-bottom: 76px;
}

.margin-76-left {
  margin-left: 76px;
}

.margin-76-right {
  margin-right: 76px;
}

.margin-76-top {
  margin-top: 76px;
}

.margin-77 {
  margin: 77px;
}

.margin-77-bottom {
  margin-bottom: 77px;
}

.margin-77-left {
  margin-left: 77px;
}

.margin-77-right {
  margin-right: 77px;
}

.margin-77-top {
  margin-top: 77px;
}

.margin-78 {
  margin: 78px;
}

.margin-78-bottom {
  margin-bottom: 78px;
}

.margin-78-left {
  margin-left: 78px;
}

.margin-78-right {
  margin-right: 78px;
}

.margin-78-top {
  margin-top: 78px;
}

.margin-79 {
  margin: 79px;
}

.margin-79-bottom {
  margin-bottom: 79px;
}

.margin-79-left {
  margin-left: 79px;
}

.margin-79-right {
  margin-right: 79px;
}

.margin-79-top {
  margin-top: 79px;
}

.margin-80 {
  margin: 80px;
}

.margin-80-bottom {
  margin-bottom: 80px;
}

.margin-80-left {
  margin-left: 80px;
}

.margin-80-right {
  margin-right: 80px;
}

.margin-80-top {
  margin-top: 80px;
}

.margin-81 {
  margin: 81px;
}

.margin-81-bottom {
  margin-bottom: 81px;
}

.margin-81-left {
  margin-left: 81px;
}

.margin-81-right {
  margin-right: 81px;
}

.margin-81-top {
  margin-top: 81px;
}

.margin-82 {
  margin: 82px;
}

.margin-82-bottom {
  margin-bottom: 82px;
}

.margin-82-left {
  margin-left: 82px;
}

.margin-82-right {
  margin-right: 82px;
}

.margin-82-top {
  margin-top: 82px;
}

.margin-83 {
  margin: 83px;
}

.margin-83-bottom {
  margin-bottom: 83px;
}

.margin-83-left {
  margin-left: 83px;
}

.margin-83-right {
  margin-right: 83px;
}

.margin-83-top {
  margin-top: 83px;
}

.margin-84 {
  margin: 84px;
}

.margin-84-bottom {
  margin-bottom: 84px;
}

.margin-84-left {
  margin-left: 84px;
}

.margin-84-right {
  margin-right: 84px;
}

.margin-84-top {
  margin-top: 84px;
}

.margin-85 {
  margin: 85px;
}

.margin-85-bottom {
  margin-bottom: 85px;
}

.margin-85-left {
  margin-left: 85px;
}

.margin-85-right {
  margin-right: 85px;
}

.margin-85-top {
  margin-top: 85px;
}

.margin-86 {
  margin: 86px;
}

.margin-86-bottom {
  margin-bottom: 86px;
}

.margin-86-left {
  margin-left: 86px;
}

.margin-86-right {
  margin-right: 86px;
}

.margin-86-top {
  margin-top: 86px;
}

.margin-87 {
  margin: 87px;
}

.margin-87-bottom {
  margin-bottom: 87px;
}

.margin-87-left {
  margin-left: 87px;
}

.margin-87-right {
  margin-right: 87px;
}

.margin-87-top {
  margin-top: 87px;
}

.margin-88 {
  margin: 88px;
}

.margin-88-bottom {
  margin-bottom: 88px;
}

.margin-88-left {
  margin-left: 88px;
}

.margin-88-right {
  margin-right: 88px;
}

.margin-88-top {
  margin-top: 88px;
}

.margin-89 {
  margin: 89px;
}

.margin-89-bottom {
  margin-bottom: 89px;
}

.margin-89-left {
  margin-left: 89px;
}

.margin-89-right {
  margin-right: 89px;
}

.margin-89-top {
  margin-top: 89px;
}

.margin-90 {
  margin: 90px;
}

.margin-90-bottom {
  margin-bottom: 90px;
}

.margin-90-left {
  margin-left: 90px;
}

.margin-90-right {
  margin-right: 90px;
}

.margin-90-top {
  margin-top: 90px;
}

.margin-91 {
  margin: 91px;
}

.margin-91-bottom {
  margin-bottom: 91px;
}

.margin-91-left {
  margin-left: 91px;
}

.margin-91-right {
  margin-right: 91px;
}

.margin-91-top {
  margin-top: 91px;
}

.margin-92 {
  margin: 92px;
}

.margin-92-bottom {
  margin-bottom: 92px;
}

.margin-92-left {
  margin-left: 92px;
}

.margin-92-right {
  margin-right: 92px;
}

.margin-92-top {
  margin-top: 92px;
}

.margin-93 {
  margin: 93px;
}

.margin-93-bottom {
  margin-bottom: 93px;
}

.margin-93-left {
  margin-left: 93px;
}

.margin-93-right {
  margin-right: 93px;
}

.margin-93-top {
  margin-top: 93px;
}

.margin-94 {
  margin: 94px;
}

.margin-94-bottom {
  margin-bottom: 94px;
}

.margin-94-left {
  margin-left: 94px;
}

.margin-94-right {
  margin-right: 94px;
}

.margin-94-top {
  margin-top: 94px;
}

.margin-95 {
  margin: 95px;
}

.margin-95-bottom {
  margin-bottom: 95px;
}

.margin-95-left {
  margin-left: 95px;
}

.margin-95-right {
  margin-right: 95px;
}

.margin-95-top {
  margin-top: 95px;
}

.margin-96 {
  margin: 96px;
}

.margin-96-bottom {
  margin-bottom: 96px;
}

.margin-96-left {
  margin-left: 96px;
}

.margin-96-right {
  margin-right: 96px;
}

.margin-96-top {
  margin-top: 96px;
}

.margin-97 {
  margin: 97px;
}

.margin-97-bottom {
  margin-bottom: 97px;
}

.margin-97-left {
  margin-left: 97px;
}

.margin-97-right {
  margin-right: 97px;
}

.margin-97-top {
  margin-top: 97px;
}

.margin-98 {
  margin: 98px;
}

.margin-98-bottom {
  margin-bottom: 98px;
}

.margin-98-left {
  margin-left: 98px;
}

.margin-98-right {
  margin-right: 98px;
}

.margin-98-top {
  margin-top: 98px;
}

.margin-99 {
  margin: 99px;
}

.margin-99-bottom {
  margin-bottom: 99px;
}

.margin-99-left {
  margin-left: 99px;
}

.margin-99-right {
  margin-right: 99px;
}

.margin-99-top {
  margin-top: 99px;
}

.margin-100 {
  margin: 100px;
}

.margin-100-bottom {
  margin-bottom: 100px;
}

.margin-100-left {
  margin-left: 100px;
}

.margin-100-right {
  margin-right: 100px;
}

.margin-100-top {
  margin-top: 100px;
}

.margin-remove {
  margin: 0;
}

.margin-bottom-remove {
  margin-bottom: 0;
}

.margin-left-remove {
  margin-left: 0;
}

.margin-right-remove {
  margin-right: 0;
}

.margin-top-remove {
  margin-top: 0;
}

.border-radius-1 {
  border-radius: 1px;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-9 {
  border-radius: 9px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-11 {
  border-radius: 11px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-13 {
  border-radius: 13px;
}

.border-radius-14 {
  border-radius: 14px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-17 {
  border-radius: 17px;
}

.border-radius-18 {
  border-radius: 18px;
}

.border-radius-19 {
  border-radius: 19px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-21 {
  border-radius: 21px;
}

.border-radius-22 {
  border-radius: 22px;
}

.border-radius-23 {
  border-radius: 23px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-26 {
  border-radius: 26px;
}

.border-radius-27 {
  border-radius: 27px;
}

.border-radius-28 {
  border-radius: 28px;
}

.border-radius-29 {
  border-radius: 29px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-31 {
  border-radius: 31px;
}

.border-radius-32 {
  border-radius: 32px;
}

.border-radius-33 {
  border-radius: 33px;
}

.border-radius-34 {
  border-radius: 34px;
}

.border-radius-35 {
  border-radius: 35px;
}

.border-radius-36 {
  border-radius: 36px;
}

.border-radius-37 {
  border-radius: 37px;
}

.border-radius-38 {
  border-radius: 38px;
}

.border-radius-39 {
  border-radius: 39px;
}

.border-radius-40 {
  border-radius: 40px;
}

.border-radius-41 {
  border-radius: 41px;
}

.border-radius-42 {
  border-radius: 42px;
}

.border-radius-43 {
  border-radius: 43px;
}

.border-radius-44 {
  border-radius: 44px;
}

.border-radius-45 {
  border-radius: 45px;
}

.border-radius-46 {
  border-radius: 46px;
}

.border-radius-47 {
  border-radius: 47px;
}

.border-radius-48 {
  border-radius: 48px;
}

.border-radius-49 {
  border-radius: 49px;
}

.border-radius-50 {
  border-radius: 50px;
}

.border-radius-51 {
  border-radius: 51px;
}

.border-radius-52 {
  border-radius: 52px;
}

.border-radius-53 {
  border-radius: 53px;
}

.border-radius-54 {
  border-radius: 54px;
}

.border-radius-55 {
  border-radius: 55px;
}

.border-radius-56 {
  border-radius: 56px;
}

.border-radius-57 {
  border-radius: 57px;
}

.border-radius-58 {
  border-radius: 58px;
}

.border-radius-59 {
  border-radius: 59px;
}

.border-radius-60 {
  border-radius: 60px;
}

.border-radius-61 {
  border-radius: 61px;
}

.border-radius-62 {
  border-radius: 62px;
}

.border-radius-63 {
  border-radius: 63px;
}

.border-radius-64 {
  border-radius: 64px;
}

.border-radius-65 {
  border-radius: 65px;
}

.border-radius-66 {
  border-radius: 66px;
}

.border-radius-67 {
  border-radius: 67px;
}

.border-radius-68 {
  border-radius: 68px;
}

.border-radius-69 {
  border-radius: 69px;
}

.border-radius-70 {
  border-radius: 70px;
}

.border-radius-71 {
  border-radius: 71px;
}

.border-radius-72 {
  border-radius: 72px;
}

.border-radius-73 {
  border-radius: 73px;
}

.border-radius-74 {
  border-radius: 74px;
}

.border-radius-75 {
  border-radius: 75px;
}

.border-radius-76 {
  border-radius: 76px;
}

.border-radius-77 {
  border-radius: 77px;
}

.border-radius-78 {
  border-radius: 78px;
}

.border-radius-79 {
  border-radius: 79px;
}

.border-radius-80 {
  border-radius: 80px;
}

.border-radius-81 {
  border-radius: 81px;
}

.border-radius-82 {
  border-radius: 82px;
}

.border-radius-83 {
  border-radius: 83px;
}

.border-radius-84 {
  border-radius: 84px;
}

.border-radius-85 {
  border-radius: 85px;
}

.border-radius-86 {
  border-radius: 86px;
}

.border-radius-87 {
  border-radius: 87px;
}

.border-radius-88 {
  border-radius: 88px;
}

.border-radius-89 {
  border-radius: 89px;
}

.border-radius-90 {
  border-radius: 90px;
}

.border-radius-91 {
  border-radius: 91px;
}

.border-radius-92 {
  border-radius: 92px;
}

.border-radius-93 {
  border-radius: 93px;
}

.border-radius-94 {
  border-radius: 94px;
}

.border-radius-95 {
  border-radius: 95px;
}

.border-radius-96 {
  border-radius: 96px;
}

.border-radius-97 {
  border-radius: 97px;
}

.border-radius-98 {
  border-radius: 98px;
}

.border-radius-99 {
  border-radius: 99px;
}

.border-radius-100 {
  border-radius: 100px;
}

.b-solid-1 {
  border-style: solid;
  border-width: 1px;
}

.b-radius-1 {
  border-radius: 1px;
}

.b-solid-1-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.b-solid-1-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-1-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.b-solid-1-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-1-right {
  border-right-style: solid;
  border-right-width: 1px;
}

.b-solid-1-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-1-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.b-solid-1-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-1-top-left {
  border-top-left-radius: 1px;
}

.b-radius-1-top-right {
  border-top-right-radius: 1px;
}

.b-radius-1-bottom-left {
  border-bottom-left-radius: 1px;
}

.b-radius-1-bottom-right {
  border-bottom-right-radius: 1px;
}

.b-solid-2 {
  border-style: solid;
  border-width: 2px;
}

.b-radius-2 {
  border-radius: 2px;
}

.b-solid-2-bottom {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.b-solid-2-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-2-left {
  border-left-style: solid;
  border-left-width: 2px;
}

.b-solid-2-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-2-right {
  border-right-style: solid;
  border-right-width: 2px;
}

.b-solid-2-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-2-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.b-solid-2-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-2-top-left {
  border-top-left-radius: 2px;
}

.b-radius-2-top-right {
  border-top-right-radius: 2px;
}

.b-radius-2-bottom-left {
  border-bottom-left-radius: 2px;
}

.b-radius-2-bottom-right {
  border-bottom-right-radius: 2px;
}

.b-solid-3 {
  border-style: solid;
  border-width: 3px;
}

.b-radius-3 {
  border-radius: 3px;
}

.b-solid-3-bottom {
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.b-solid-3-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-3-left {
  border-left-style: solid;
  border-left-width: 3px;
}

.b-solid-3-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-3-right {
  border-right-style: solid;
  border-right-width: 3px;
}

.b-solid-3-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-3-top {
  border-top-style: solid;
  border-top-width: 3px;
}

.b-solid-3-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-3-top-left {
  border-top-left-radius: 3px;
}

.b-radius-3-top-right {
  border-top-right-radius: 3px;
}

.b-radius-3-bottom-left {
  border-bottom-left-radius: 3px;
}

.b-radius-3-bottom-right {
  border-bottom-right-radius: 3px;
}

.b-solid-4 {
  border-style: solid;
  border-width: 4px;
}

.b-radius-4 {
  border-radius: 4px;
}

.b-solid-4-bottom {
  border-bottom-style: solid;
  border-bottom-width: 4px;
}

.b-solid-4-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-4-left {
  border-left-style: solid;
  border-left-width: 4px;
}

.b-solid-4-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-4-right {
  border-right-style: solid;
  border-right-width: 4px;
}

.b-solid-4-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-4-top {
  border-top-style: solid;
  border-top-width: 4px;
}

.b-solid-4-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-4-top-left {
  border-top-left-radius: 4px;
}

.b-radius-4-top-right {
  border-top-right-radius: 4px;
}

.b-radius-4-bottom-left {
  border-bottom-left-radius: 4px;
}

.b-radius-4-bottom-right {
  border-bottom-right-radius: 4px;
}

.b-solid-5 {
  border-style: solid;
  border-width: 5px;
}

.b-radius-5 {
  border-radius: 5px;
}

.b-solid-5-bottom {
  border-bottom-style: solid;
  border-bottom-width: 5px;
}

.b-solid-5-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-5-left {
  border-left-style: solid;
  border-left-width: 5px;
}

.b-solid-5-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-5-right {
  border-right-style: solid;
  border-right-width: 5px;
}

.b-solid-5-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-5-top {
  border-top-style: solid;
  border-top-width: 5px;
}

.b-solid-5-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-5-top-left {
  border-top-left-radius: 5px;
}

.b-radius-5-top-right {
  border-top-right-radius: 5px;
}

.b-radius-5-bottom-left {
  border-bottom-left-radius: 5px;
}

.b-radius-5-bottom-right {
  border-bottom-right-radius: 5px;
}

.b-solid-6 {
  border-style: solid;
  border-width: 6px;
}

.b-radius-6 {
  border-radius: 6px;
}

.b-solid-6-bottom {
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.b-solid-6-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-6-left {
  border-left-style: solid;
  border-left-width: 6px;
}

.b-solid-6-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-6-right {
  border-right-style: solid;
  border-right-width: 6px;
}

.b-solid-6-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-6-top {
  border-top-style: solid;
  border-top-width: 6px;
}

.b-solid-6-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-6-top-left {
  border-top-left-radius: 6px;
}

.b-radius-6-top-right {
  border-top-right-radius: 6px;
}

.b-radius-6-bottom-left {
  border-bottom-left-radius: 6px;
}

.b-radius-6-bottom-right {
  border-bottom-right-radius: 6px;
}

.b-solid-7 {
  border-style: solid;
  border-width: 7px;
}

.b-radius-7 {
  border-radius: 7px;
}

.b-solid-7-bottom {
  border-bottom-style: solid;
  border-bottom-width: 7px;
}

.b-solid-7-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-7-left {
  border-left-style: solid;
  border-left-width: 7px;
}

.b-solid-7-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-7-right {
  border-right-style: solid;
  border-right-width: 7px;
}

.b-solid-7-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-7-top {
  border-top-style: solid;
  border-top-width: 7px;
}

.b-solid-7-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-7-top-left {
  border-top-left-radius: 7px;
}

.b-radius-7-top-right {
  border-top-right-radius: 7px;
}

.b-radius-7-bottom-left {
  border-bottom-left-radius: 7px;
}

.b-radius-7-bottom-right {
  border-bottom-right-radius: 7px;
}

.b-solid-8 {
  border-style: solid;
  border-width: 8px;
}

.b-radius-8 {
  border-radius: 8px;
}

.b-solid-8-bottom {
  border-bottom-style: solid;
  border-bottom-width: 8px;
}

.b-solid-8-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-8-left {
  border-left-style: solid;
  border-left-width: 8px;
}

.b-solid-8-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-8-right {
  border-right-style: solid;
  border-right-width: 8px;
}

.b-solid-8-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-8-top {
  border-top-style: solid;
  border-top-width: 8px;
}

.b-solid-8-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-8-top-left {
  border-top-left-radius: 8px;
}

.b-radius-8-top-right {
  border-top-right-radius: 8px;
}

.b-radius-8-bottom-left {
  border-bottom-left-radius: 8px;
}

.b-radius-8-bottom-right {
  border-bottom-right-radius: 8px;
}

.b-solid-9 {
  border-style: solid;
  border-width: 9px;
}

.b-radius-9 {
  border-radius: 9px;
}

.b-solid-9-bottom {
  border-bottom-style: solid;
  border-bottom-width: 9px;
}

.b-solid-9-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-9-left {
  border-left-style: solid;
  border-left-width: 9px;
}

.b-solid-9-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-9-right {
  border-right-style: solid;
  border-right-width: 9px;
}

.b-solid-9-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-9-top {
  border-top-style: solid;
  border-top-width: 9px;
}

.b-solid-9-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-9-top-left {
  border-top-left-radius: 9px;
}

.b-radius-9-top-right {
  border-top-right-radius: 9px;
}

.b-radius-9-bottom-left {
  border-bottom-left-radius: 9px;
}

.b-radius-9-bottom-right {
  border-bottom-right-radius: 9px;
}

.b-solid-10 {
  border-style: solid;
  border-width: 10px;
}

.b-radius-10 {
  border-radius: 10px;
}

.b-solid-10-bottom {
  border-bottom-style: solid;
  border-bottom-width: 10px;
}

.b-solid-10-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-10-left {
  border-left-style: solid;
  border-left-width: 10px;
}

.b-solid-10-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-10-right {
  border-right-style: solid;
  border-right-width: 10px;
}

.b-solid-10-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-10-top {
  border-top-style: solid;
  border-top-width: 10px;
}

.b-solid-10-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-10-top-left {
  border-top-left-radius: 10px;
}

.b-radius-10-top-right {
  border-top-right-radius: 10px;
}

.b-radius-10-bottom-left {
  border-bottom-left-radius: 10px;
}

.b-radius-10-bottom-right {
  border-bottom-right-radius: 10px;
}

.b-solid-11 {
  border-style: solid;
  border-width: 11px;
}

.b-radius-11 {
  border-radius: 11px;
}

.b-solid-11-bottom {
  border-bottom-style: solid;
  border-bottom-width: 11px;
}

.b-solid-11-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-11-left {
  border-left-style: solid;
  border-left-width: 11px;
}

.b-solid-11-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-11-right {
  border-right-style: solid;
  border-right-width: 11px;
}

.b-solid-11-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-11-top {
  border-top-style: solid;
  border-top-width: 11px;
}

.b-solid-11-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-11-top-left {
  border-top-left-radius: 11px;
}

.b-radius-11-top-right {
  border-top-right-radius: 11px;
}

.b-radius-11-bottom-left {
  border-bottom-left-radius: 11px;
}

.b-radius-11-bottom-right {
  border-bottom-right-radius: 11px;
}

.b-solid-12 {
  border-style: solid;
  border-width: 12px;
}

.b-radius-12 {
  border-radius: 12px;
}

.b-solid-12-bottom {
  border-bottom-style: solid;
  border-bottom-width: 12px;
}

.b-solid-12-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-12-left {
  border-left-style: solid;
  border-left-width: 12px;
}

.b-solid-12-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-12-right {
  border-right-style: solid;
  border-right-width: 12px;
}

.b-solid-12-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-12-top {
  border-top-style: solid;
  border-top-width: 12px;
}

.b-solid-12-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-12-top-left {
  border-top-left-radius: 12px;
}

.b-radius-12-top-right {
  border-top-right-radius: 12px;
}

.b-radius-12-bottom-left {
  border-bottom-left-radius: 12px;
}

.b-radius-12-bottom-right {
  border-bottom-right-radius: 12px;
}

.b-solid-13 {
  border-style: solid;
  border-width: 13px;
}

.b-radius-13 {
  border-radius: 13px;
}

.b-solid-13-bottom {
  border-bottom-style: solid;
  border-bottom-width: 13px;
}

.b-solid-13-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-13-left {
  border-left-style: solid;
  border-left-width: 13px;
}

.b-solid-13-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-13-right {
  border-right-style: solid;
  border-right-width: 13px;
}

.b-solid-13-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-13-top {
  border-top-style: solid;
  border-top-width: 13px;
}

.b-solid-13-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-13-top-left {
  border-top-left-radius: 13px;
}

.b-radius-13-top-right {
  border-top-right-radius: 13px;
}

.b-radius-13-bottom-left {
  border-bottom-left-radius: 13px;
}

.b-radius-13-bottom-right {
  border-bottom-right-radius: 13px;
}

.b-solid-14 {
  border-style: solid;
  border-width: 14px;
}

.b-radius-14 {
  border-radius: 14px;
}

.b-solid-14-bottom {
  border-bottom-style: solid;
  border-bottom-width: 14px;
}

.b-solid-14-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-14-left {
  border-left-style: solid;
  border-left-width: 14px;
}

.b-solid-14-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-14-right {
  border-right-style: solid;
  border-right-width: 14px;
}

.b-solid-14-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-14-top {
  border-top-style: solid;
  border-top-width: 14px;
}

.b-solid-14-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-14-top-left {
  border-top-left-radius: 14px;
}

.b-radius-14-top-right {
  border-top-right-radius: 14px;
}

.b-radius-14-bottom-left {
  border-bottom-left-radius: 14px;
}

.b-radius-14-bottom-right {
  border-bottom-right-radius: 14px;
}

.b-solid-15 {
  border-style: solid;
  border-width: 15px;
}

.b-radius-15 {
  border-radius: 15px;
}

.b-solid-15-bottom {
  border-bottom-style: solid;
  border-bottom-width: 15px;
}

.b-solid-15-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-15-left {
  border-left-style: solid;
  border-left-width: 15px;
}

.b-solid-15-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-15-right {
  border-right-style: solid;
  border-right-width: 15px;
}

.b-solid-15-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-15-top {
  border-top-style: solid;
  border-top-width: 15px;
}

.b-solid-15-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-15-top-left {
  border-top-left-radius: 15px;
}

.b-radius-15-top-right {
  border-top-right-radius: 15px;
}

.b-radius-15-bottom-left {
  border-bottom-left-radius: 15px;
}

.b-radius-15-bottom-right {
  border-bottom-right-radius: 15px;
}

.b-solid-16 {
  border-style: solid;
  border-width: 16px;
}

.b-radius-16 {
  border-radius: 16px;
}

.b-solid-16-bottom {
  border-bottom-style: solid;
  border-bottom-width: 16px;
}

.b-solid-16-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-16-left {
  border-left-style: solid;
  border-left-width: 16px;
}

.b-solid-16-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-16-right {
  border-right-style: solid;
  border-right-width: 16px;
}

.b-solid-16-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-16-top {
  border-top-style: solid;
  border-top-width: 16px;
}

.b-solid-16-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-16-top-left {
  border-top-left-radius: 16px;
}

.b-radius-16-top-right {
  border-top-right-radius: 16px;
}

.b-radius-16-bottom-left {
  border-bottom-left-radius: 16px;
}

.b-radius-16-bottom-right {
  border-bottom-right-radius: 16px;
}

.b-solid-17 {
  border-style: solid;
  border-width: 17px;
}

.b-radius-17 {
  border-radius: 17px;
}

.b-solid-17-bottom {
  border-bottom-style: solid;
  border-bottom-width: 17px;
}

.b-solid-17-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-17-left {
  border-left-style: solid;
  border-left-width: 17px;
}

.b-solid-17-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-17-right {
  border-right-style: solid;
  border-right-width: 17px;
}

.b-solid-17-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-17-top {
  border-top-style: solid;
  border-top-width: 17px;
}

.b-solid-17-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-17-top-left {
  border-top-left-radius: 17px;
}

.b-radius-17-top-right {
  border-top-right-radius: 17px;
}

.b-radius-17-bottom-left {
  border-bottom-left-radius: 17px;
}

.b-radius-17-bottom-right {
  border-bottom-right-radius: 17px;
}

.b-solid-18 {
  border-style: solid;
  border-width: 18px;
}

.b-radius-18 {
  border-radius: 18px;
}

.b-solid-18-bottom {
  border-bottom-style: solid;
  border-bottom-width: 18px;
}

.b-solid-18-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-18-left {
  border-left-style: solid;
  border-left-width: 18px;
}

.b-solid-18-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-18-right {
  border-right-style: solid;
  border-right-width: 18px;
}

.b-solid-18-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-18-top {
  border-top-style: solid;
  border-top-width: 18px;
}

.b-solid-18-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-18-top-left {
  border-top-left-radius: 18px;
}

.b-radius-18-top-right {
  border-top-right-radius: 18px;
}

.b-radius-18-bottom-left {
  border-bottom-left-radius: 18px;
}

.b-radius-18-bottom-right {
  border-bottom-right-radius: 18px;
}

.b-solid-19 {
  border-style: solid;
  border-width: 19px;
}

.b-radius-19 {
  border-radius: 19px;
}

.b-solid-19-bottom {
  border-bottom-style: solid;
  border-bottom-width: 19px;
}

.b-solid-19-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-19-left {
  border-left-style: solid;
  border-left-width: 19px;
}

.b-solid-19-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-19-right {
  border-right-style: solid;
  border-right-width: 19px;
}

.b-solid-19-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-19-top {
  border-top-style: solid;
  border-top-width: 19px;
}

.b-solid-19-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-19-top-left {
  border-top-left-radius: 19px;
}

.b-radius-19-top-right {
  border-top-right-radius: 19px;
}

.b-radius-19-bottom-left {
  border-bottom-left-radius: 19px;
}

.b-radius-19-bottom-right {
  border-bottom-right-radius: 19px;
}

.b-solid-20 {
  border-style: solid;
  border-width: 20px;
}

.b-radius-20 {
  border-radius: 20px;
}

.b-solid-20-bottom {
  border-bottom-style: solid;
  border-bottom-width: 20px;
}

.b-solid-20-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-20-left {
  border-left-style: solid;
  border-left-width: 20px;
}

.b-solid-20-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-20-right {
  border-right-style: solid;
  border-right-width: 20px;
}

.b-solid-20-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-20-top {
  border-top-style: solid;
  border-top-width: 20px;
}

.b-solid-20-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-20-top-left {
  border-top-left-radius: 20px;
}

.b-radius-20-top-right {
  border-top-right-radius: 20px;
}

.b-radius-20-bottom-left {
  border-bottom-left-radius: 20px;
}

.b-radius-20-bottom-right {
  border-bottom-right-radius: 20px;
}

.b-solid-21 {
  border-style: solid;
  border-width: 21px;
}

.b-radius-21 {
  border-radius: 21px;
}

.b-solid-21-bottom {
  border-bottom-style: solid;
  border-bottom-width: 21px;
}

.b-solid-21-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-21-left {
  border-left-style: solid;
  border-left-width: 21px;
}

.b-solid-21-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-21-right {
  border-right-style: solid;
  border-right-width: 21px;
}

.b-solid-21-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-21-top {
  border-top-style: solid;
  border-top-width: 21px;
}

.b-solid-21-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-21-top-left {
  border-top-left-radius: 21px;
}

.b-radius-21-top-right {
  border-top-right-radius: 21px;
}

.b-radius-21-bottom-left {
  border-bottom-left-radius: 21px;
}

.b-radius-21-bottom-right {
  border-bottom-right-radius: 21px;
}

.b-solid-22 {
  border-style: solid;
  border-width: 22px;
}

.b-radius-22 {
  border-radius: 22px;
}

.b-solid-22-bottom {
  border-bottom-style: solid;
  border-bottom-width: 22px;
}

.b-solid-22-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-22-left {
  border-left-style: solid;
  border-left-width: 22px;
}

.b-solid-22-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-22-right {
  border-right-style: solid;
  border-right-width: 22px;
}

.b-solid-22-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-22-top {
  border-top-style: solid;
  border-top-width: 22px;
}

.b-solid-22-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-22-top-left {
  border-top-left-radius: 22px;
}

.b-radius-22-top-right {
  border-top-right-radius: 22px;
}

.b-radius-22-bottom-left {
  border-bottom-left-radius: 22px;
}

.b-radius-22-bottom-right {
  border-bottom-right-radius: 22px;
}

.b-solid-23 {
  border-style: solid;
  border-width: 23px;
}

.b-radius-23 {
  border-radius: 23px;
}

.b-solid-23-bottom {
  border-bottom-style: solid;
  border-bottom-width: 23px;
}

.b-solid-23-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-23-left {
  border-left-style: solid;
  border-left-width: 23px;
}

.b-solid-23-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-23-right {
  border-right-style: solid;
  border-right-width: 23px;
}

.b-solid-23-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-23-top {
  border-top-style: solid;
  border-top-width: 23px;
}

.b-solid-23-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-23-top-left {
  border-top-left-radius: 23px;
}

.b-radius-23-top-right {
  border-top-right-radius: 23px;
}

.b-radius-23-bottom-left {
  border-bottom-left-radius: 23px;
}

.b-radius-23-bottom-right {
  border-bottom-right-radius: 23px;
}

.b-solid-24 {
  border-style: solid;
  border-width: 24px;
}

.b-radius-24 {
  border-radius: 24px;
}

.b-solid-24-bottom {
  border-bottom-style: solid;
  border-bottom-width: 24px;
}

.b-solid-24-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-24-left {
  border-left-style: solid;
  border-left-width: 24px;
}

.b-solid-24-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-24-right {
  border-right-style: solid;
  border-right-width: 24px;
}

.b-solid-24-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-24-top {
  border-top-style: solid;
  border-top-width: 24px;
}

.b-solid-24-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-24-top-left {
  border-top-left-radius: 24px;
}

.b-radius-24-top-right {
  border-top-right-radius: 24px;
}

.b-radius-24-bottom-left {
  border-bottom-left-radius: 24px;
}

.b-radius-24-bottom-right {
  border-bottom-right-radius: 24px;
}

.b-solid-25 {
  border-style: solid;
  border-width: 25px;
}

.b-radius-25 {
  border-radius: 25px;
}

.b-solid-25-bottom {
  border-bottom-style: solid;
  border-bottom-width: 25px;
}

.b-solid-25-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-25-left {
  border-left-style: solid;
  border-left-width: 25px;
}

.b-solid-25-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-25-right {
  border-right-style: solid;
  border-right-width: 25px;
}

.b-solid-25-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-25-top {
  border-top-style: solid;
  border-top-width: 25px;
}

.b-solid-25-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-25-top-left {
  border-top-left-radius: 25px;
}

.b-radius-25-top-right {
  border-top-right-radius: 25px;
}

.b-radius-25-bottom-left {
  border-bottom-left-radius: 25px;
}

.b-radius-25-bottom-right {
  border-bottom-right-radius: 25px;
}

.b-solid-26 {
  border-style: solid;
  border-width: 26px;
}

.b-radius-26 {
  border-radius: 26px;
}

.b-solid-26-bottom {
  border-bottom-style: solid;
  border-bottom-width: 26px;
}

.b-solid-26-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-26-left {
  border-left-style: solid;
  border-left-width: 26px;
}

.b-solid-26-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-26-right {
  border-right-style: solid;
  border-right-width: 26px;
}

.b-solid-26-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-26-top {
  border-top-style: solid;
  border-top-width: 26px;
}

.b-solid-26-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-26-top-left {
  border-top-left-radius: 26px;
}

.b-radius-26-top-right {
  border-top-right-radius: 26px;
}

.b-radius-26-bottom-left {
  border-bottom-left-radius: 26px;
}

.b-radius-26-bottom-right {
  border-bottom-right-radius: 26px;
}

.b-solid-27 {
  border-style: solid;
  border-width: 27px;
}

.b-radius-27 {
  border-radius: 27px;
}

.b-solid-27-bottom {
  border-bottom-style: solid;
  border-bottom-width: 27px;
}

.b-solid-27-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-27-left {
  border-left-style: solid;
  border-left-width: 27px;
}

.b-solid-27-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-27-right {
  border-right-style: solid;
  border-right-width: 27px;
}

.b-solid-27-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-27-top {
  border-top-style: solid;
  border-top-width: 27px;
}

.b-solid-27-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-27-top-left {
  border-top-left-radius: 27px;
}

.b-radius-27-top-right {
  border-top-right-radius: 27px;
}

.b-radius-27-bottom-left {
  border-bottom-left-radius: 27px;
}

.b-radius-27-bottom-right {
  border-bottom-right-radius: 27px;
}

.b-solid-28 {
  border-style: solid;
  border-width: 28px;
}

.b-radius-28 {
  border-radius: 28px;
}

.b-solid-28-bottom {
  border-bottom-style: solid;
  border-bottom-width: 28px;
}

.b-solid-28-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-28-left {
  border-left-style: solid;
  border-left-width: 28px;
}

.b-solid-28-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-28-right {
  border-right-style: solid;
  border-right-width: 28px;
}

.b-solid-28-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-28-top {
  border-top-style: solid;
  border-top-width: 28px;
}

.b-solid-28-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-28-top-left {
  border-top-left-radius: 28px;
}

.b-radius-28-top-right {
  border-top-right-radius: 28px;
}

.b-radius-28-bottom-left {
  border-bottom-left-radius: 28px;
}

.b-radius-28-bottom-right {
  border-bottom-right-radius: 28px;
}

.b-solid-29 {
  border-style: solid;
  border-width: 29px;
}

.b-radius-29 {
  border-radius: 29px;
}

.b-solid-29-bottom {
  border-bottom-style: solid;
  border-bottom-width: 29px;
}

.b-solid-29-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-29-left {
  border-left-style: solid;
  border-left-width: 29px;
}

.b-solid-29-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-29-right {
  border-right-style: solid;
  border-right-width: 29px;
}

.b-solid-29-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-29-top {
  border-top-style: solid;
  border-top-width: 29px;
}

.b-solid-29-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-29-top-left {
  border-top-left-radius: 29px;
}

.b-radius-29-top-right {
  border-top-right-radius: 29px;
}

.b-radius-29-bottom-left {
  border-bottom-left-radius: 29px;
}

.b-radius-29-bottom-right {
  border-bottom-right-radius: 29px;
}

.b-solid-30 {
  border-style: solid;
  border-width: 30px;
}

.b-radius-30 {
  border-radius: 30px;
}

.b-solid-30-bottom {
  border-bottom-style: solid;
  border-bottom-width: 30px;
}

.b-solid-30-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-30-left {
  border-left-style: solid;
  border-left-width: 30px;
}

.b-solid-30-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-30-right {
  border-right-style: solid;
  border-right-width: 30px;
}

.b-solid-30-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-30-top {
  border-top-style: solid;
  border-top-width: 30px;
}

.b-solid-30-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-30-top-left {
  border-top-left-radius: 30px;
}

.b-radius-30-top-right {
  border-top-right-radius: 30px;
}

.b-radius-30-bottom-left {
  border-bottom-left-radius: 30px;
}

.b-radius-30-bottom-right {
  border-bottom-right-radius: 30px;
}

.b-solid-31 {
  border-style: solid;
  border-width: 31px;
}

.b-radius-31 {
  border-radius: 31px;
}

.b-solid-31-bottom {
  border-bottom-style: solid;
  border-bottom-width: 31px;
}

.b-solid-31-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-31-left {
  border-left-style: solid;
  border-left-width: 31px;
}

.b-solid-31-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-31-right {
  border-right-style: solid;
  border-right-width: 31px;
}

.b-solid-31-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-31-top {
  border-top-style: solid;
  border-top-width: 31px;
}

.b-solid-31-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-31-top-left {
  border-top-left-radius: 31px;
}

.b-radius-31-top-right {
  border-top-right-radius: 31px;
}

.b-radius-31-bottom-left {
  border-bottom-left-radius: 31px;
}

.b-radius-31-bottom-right {
  border-bottom-right-radius: 31px;
}

.b-solid-32 {
  border-style: solid;
  border-width: 32px;
}

.b-radius-32 {
  border-radius: 32px;
}

.b-solid-32-bottom {
  border-bottom-style: solid;
  border-bottom-width: 32px;
}

.b-solid-32-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-32-left {
  border-left-style: solid;
  border-left-width: 32px;
}

.b-solid-32-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-32-right {
  border-right-style: solid;
  border-right-width: 32px;
}

.b-solid-32-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-32-top {
  border-top-style: solid;
  border-top-width: 32px;
}

.b-solid-32-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-32-top-left {
  border-top-left-radius: 32px;
}

.b-radius-32-top-right {
  border-top-right-radius: 32px;
}

.b-radius-32-bottom-left {
  border-bottom-left-radius: 32px;
}

.b-radius-32-bottom-right {
  border-bottom-right-radius: 32px;
}

.b-solid-33 {
  border-style: solid;
  border-width: 33px;
}

.b-radius-33 {
  border-radius: 33px;
}

.b-solid-33-bottom {
  border-bottom-style: solid;
  border-bottom-width: 33px;
}

.b-solid-33-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-33-left {
  border-left-style: solid;
  border-left-width: 33px;
}

.b-solid-33-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-33-right {
  border-right-style: solid;
  border-right-width: 33px;
}

.b-solid-33-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-33-top {
  border-top-style: solid;
  border-top-width: 33px;
}

.b-solid-33-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-33-top-left {
  border-top-left-radius: 33px;
}

.b-radius-33-top-right {
  border-top-right-radius: 33px;
}

.b-radius-33-bottom-left {
  border-bottom-left-radius: 33px;
}

.b-radius-33-bottom-right {
  border-bottom-right-radius: 33px;
}

.b-solid-34 {
  border-style: solid;
  border-width: 34px;
}

.b-radius-34 {
  border-radius: 34px;
}

.b-solid-34-bottom {
  border-bottom-style: solid;
  border-bottom-width: 34px;
}

.b-solid-34-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-34-left {
  border-left-style: solid;
  border-left-width: 34px;
}

.b-solid-34-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-34-right {
  border-right-style: solid;
  border-right-width: 34px;
}

.b-solid-34-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-34-top {
  border-top-style: solid;
  border-top-width: 34px;
}

.b-solid-34-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-34-top-left {
  border-top-left-radius: 34px;
}

.b-radius-34-top-right {
  border-top-right-radius: 34px;
}

.b-radius-34-bottom-left {
  border-bottom-left-radius: 34px;
}

.b-radius-34-bottom-right {
  border-bottom-right-radius: 34px;
}

.b-solid-35 {
  border-style: solid;
  border-width: 35px;
}

.b-radius-35 {
  border-radius: 35px;
}

.b-solid-35-bottom {
  border-bottom-style: solid;
  border-bottom-width: 35px;
}

.b-solid-35-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-35-left {
  border-left-style: solid;
  border-left-width: 35px;
}

.b-solid-35-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-35-right {
  border-right-style: solid;
  border-right-width: 35px;
}

.b-solid-35-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-35-top {
  border-top-style: solid;
  border-top-width: 35px;
}

.b-solid-35-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-35-top-left {
  border-top-left-radius: 35px;
}

.b-radius-35-top-right {
  border-top-right-radius: 35px;
}

.b-radius-35-bottom-left {
  border-bottom-left-radius: 35px;
}

.b-radius-35-bottom-right {
  border-bottom-right-radius: 35px;
}

.b-solid-36 {
  border-style: solid;
  border-width: 36px;
}

.b-radius-36 {
  border-radius: 36px;
}

.b-solid-36-bottom {
  border-bottom-style: solid;
  border-bottom-width: 36px;
}

.b-solid-36-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-36-left {
  border-left-style: solid;
  border-left-width: 36px;
}

.b-solid-36-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-36-right {
  border-right-style: solid;
  border-right-width: 36px;
}

.b-solid-36-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-36-top {
  border-top-style: solid;
  border-top-width: 36px;
}

.b-solid-36-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-36-top-left {
  border-top-left-radius: 36px;
}

.b-radius-36-top-right {
  border-top-right-radius: 36px;
}

.b-radius-36-bottom-left {
  border-bottom-left-radius: 36px;
}

.b-radius-36-bottom-right {
  border-bottom-right-radius: 36px;
}

.b-solid-37 {
  border-style: solid;
  border-width: 37px;
}

.b-radius-37 {
  border-radius: 37px;
}

.b-solid-37-bottom {
  border-bottom-style: solid;
  border-bottom-width: 37px;
}

.b-solid-37-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-37-left {
  border-left-style: solid;
  border-left-width: 37px;
}

.b-solid-37-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-37-right {
  border-right-style: solid;
  border-right-width: 37px;
}

.b-solid-37-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-37-top {
  border-top-style: solid;
  border-top-width: 37px;
}

.b-solid-37-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-37-top-left {
  border-top-left-radius: 37px;
}

.b-radius-37-top-right {
  border-top-right-radius: 37px;
}

.b-radius-37-bottom-left {
  border-bottom-left-radius: 37px;
}

.b-radius-37-bottom-right {
  border-bottom-right-radius: 37px;
}

.b-solid-38 {
  border-style: solid;
  border-width: 38px;
}

.b-radius-38 {
  border-radius: 38px;
}

.b-solid-38-bottom {
  border-bottom-style: solid;
  border-bottom-width: 38px;
}

.b-solid-38-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-38-left {
  border-left-style: solid;
  border-left-width: 38px;
}

.b-solid-38-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-38-right {
  border-right-style: solid;
  border-right-width: 38px;
}

.b-solid-38-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-38-top {
  border-top-style: solid;
  border-top-width: 38px;
}

.b-solid-38-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-38-top-left {
  border-top-left-radius: 38px;
}

.b-radius-38-top-right {
  border-top-right-radius: 38px;
}

.b-radius-38-bottom-left {
  border-bottom-left-radius: 38px;
}

.b-radius-38-bottom-right {
  border-bottom-right-radius: 38px;
}

.b-solid-39 {
  border-style: solid;
  border-width: 39px;
}

.b-radius-39 {
  border-radius: 39px;
}

.b-solid-39-bottom {
  border-bottom-style: solid;
  border-bottom-width: 39px;
}

.b-solid-39-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-39-left {
  border-left-style: solid;
  border-left-width: 39px;
}

.b-solid-39-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-39-right {
  border-right-style: solid;
  border-right-width: 39px;
}

.b-solid-39-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-39-top {
  border-top-style: solid;
  border-top-width: 39px;
}

.b-solid-39-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-39-top-left {
  border-top-left-radius: 39px;
}

.b-radius-39-top-right {
  border-top-right-radius: 39px;
}

.b-radius-39-bottom-left {
  border-bottom-left-radius: 39px;
}

.b-radius-39-bottom-right {
  border-bottom-right-radius: 39px;
}

.b-solid-40 {
  border-style: solid;
  border-width: 40px;
}

.b-radius-40 {
  border-radius: 40px;
}

.b-solid-40-bottom {
  border-bottom-style: solid;
  border-bottom-width: 40px;
}

.b-solid-40-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-40-left {
  border-left-style: solid;
  border-left-width: 40px;
}

.b-solid-40-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-40-right {
  border-right-style: solid;
  border-right-width: 40px;
}

.b-solid-40-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-40-top {
  border-top-style: solid;
  border-top-width: 40px;
}

.b-solid-40-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-40-top-left {
  border-top-left-radius: 40px;
}

.b-radius-40-top-right {
  border-top-right-radius: 40px;
}

.b-radius-40-bottom-left {
  border-bottom-left-radius: 40px;
}

.b-radius-40-bottom-right {
  border-bottom-right-radius: 40px;
}

.b-solid-41 {
  border-style: solid;
  border-width: 41px;
}

.b-radius-41 {
  border-radius: 41px;
}

.b-solid-41-bottom {
  border-bottom-style: solid;
  border-bottom-width: 41px;
}

.b-solid-41-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-41-left {
  border-left-style: solid;
  border-left-width: 41px;
}

.b-solid-41-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-41-right {
  border-right-style: solid;
  border-right-width: 41px;
}

.b-solid-41-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-41-top {
  border-top-style: solid;
  border-top-width: 41px;
}

.b-solid-41-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-41-top-left {
  border-top-left-radius: 41px;
}

.b-radius-41-top-right {
  border-top-right-radius: 41px;
}

.b-radius-41-bottom-left {
  border-bottom-left-radius: 41px;
}

.b-radius-41-bottom-right {
  border-bottom-right-radius: 41px;
}

.b-solid-42 {
  border-style: solid;
  border-width: 42px;
}

.b-radius-42 {
  border-radius: 42px;
}

.b-solid-42-bottom {
  border-bottom-style: solid;
  border-bottom-width: 42px;
}

.b-solid-42-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-42-left {
  border-left-style: solid;
  border-left-width: 42px;
}

.b-solid-42-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-42-right {
  border-right-style: solid;
  border-right-width: 42px;
}

.b-solid-42-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-42-top {
  border-top-style: solid;
  border-top-width: 42px;
}

.b-solid-42-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-42-top-left {
  border-top-left-radius: 42px;
}

.b-radius-42-top-right {
  border-top-right-radius: 42px;
}

.b-radius-42-bottom-left {
  border-bottom-left-radius: 42px;
}

.b-radius-42-bottom-right {
  border-bottom-right-radius: 42px;
}

.b-solid-43 {
  border-style: solid;
  border-width: 43px;
}

.b-radius-43 {
  border-radius: 43px;
}

.b-solid-43-bottom {
  border-bottom-style: solid;
  border-bottom-width: 43px;
}

.b-solid-43-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-43-left {
  border-left-style: solid;
  border-left-width: 43px;
}

.b-solid-43-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-43-right {
  border-right-style: solid;
  border-right-width: 43px;
}

.b-solid-43-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-43-top {
  border-top-style: solid;
  border-top-width: 43px;
}

.b-solid-43-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-43-top-left {
  border-top-left-radius: 43px;
}

.b-radius-43-top-right {
  border-top-right-radius: 43px;
}

.b-radius-43-bottom-left {
  border-bottom-left-radius: 43px;
}

.b-radius-43-bottom-right {
  border-bottom-right-radius: 43px;
}

.b-solid-44 {
  border-style: solid;
  border-width: 44px;
}

.b-radius-44 {
  border-radius: 44px;
}

.b-solid-44-bottom {
  border-bottom-style: solid;
  border-bottom-width: 44px;
}

.b-solid-44-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-44-left {
  border-left-style: solid;
  border-left-width: 44px;
}

.b-solid-44-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-44-right {
  border-right-style: solid;
  border-right-width: 44px;
}

.b-solid-44-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-44-top {
  border-top-style: solid;
  border-top-width: 44px;
}

.b-solid-44-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-44-top-left {
  border-top-left-radius: 44px;
}

.b-radius-44-top-right {
  border-top-right-radius: 44px;
}

.b-radius-44-bottom-left {
  border-bottom-left-radius: 44px;
}

.b-radius-44-bottom-right {
  border-bottom-right-radius: 44px;
}

.b-solid-45 {
  border-style: solid;
  border-width: 45px;
}

.b-radius-45 {
  border-radius: 45px;
}

.b-solid-45-bottom {
  border-bottom-style: solid;
  border-bottom-width: 45px;
}

.b-solid-45-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-45-left {
  border-left-style: solid;
  border-left-width: 45px;
}

.b-solid-45-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-45-right {
  border-right-style: solid;
  border-right-width: 45px;
}

.b-solid-45-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-45-top {
  border-top-style: solid;
  border-top-width: 45px;
}

.b-solid-45-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-45-top-left {
  border-top-left-radius: 45px;
}

.b-radius-45-top-right {
  border-top-right-radius: 45px;
}

.b-radius-45-bottom-left {
  border-bottom-left-radius: 45px;
}

.b-radius-45-bottom-right {
  border-bottom-right-radius: 45px;
}

.b-solid-46 {
  border-style: solid;
  border-width: 46px;
}

.b-radius-46 {
  border-radius: 46px;
}

.b-solid-46-bottom {
  border-bottom-style: solid;
  border-bottom-width: 46px;
}

.b-solid-46-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-46-left {
  border-left-style: solid;
  border-left-width: 46px;
}

.b-solid-46-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-46-right {
  border-right-style: solid;
  border-right-width: 46px;
}

.b-solid-46-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-46-top {
  border-top-style: solid;
  border-top-width: 46px;
}

.b-solid-46-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-46-top-left {
  border-top-left-radius: 46px;
}

.b-radius-46-top-right {
  border-top-right-radius: 46px;
}

.b-radius-46-bottom-left {
  border-bottom-left-radius: 46px;
}

.b-radius-46-bottom-right {
  border-bottom-right-radius: 46px;
}

.b-solid-47 {
  border-style: solid;
  border-width: 47px;
}

.b-radius-47 {
  border-radius: 47px;
}

.b-solid-47-bottom {
  border-bottom-style: solid;
  border-bottom-width: 47px;
}

.b-solid-47-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-47-left {
  border-left-style: solid;
  border-left-width: 47px;
}

.b-solid-47-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-47-right {
  border-right-style: solid;
  border-right-width: 47px;
}

.b-solid-47-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-47-top {
  border-top-style: solid;
  border-top-width: 47px;
}

.b-solid-47-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-47-top-left {
  border-top-left-radius: 47px;
}

.b-radius-47-top-right {
  border-top-right-radius: 47px;
}

.b-radius-47-bottom-left {
  border-bottom-left-radius: 47px;
}

.b-radius-47-bottom-right {
  border-bottom-right-radius: 47px;
}

.b-solid-48 {
  border-style: solid;
  border-width: 48px;
}

.b-radius-48 {
  border-radius: 48px;
}

.b-solid-48-bottom {
  border-bottom-style: solid;
  border-bottom-width: 48px;
}

.b-solid-48-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-48-left {
  border-left-style: solid;
  border-left-width: 48px;
}

.b-solid-48-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-48-right {
  border-right-style: solid;
  border-right-width: 48px;
}

.b-solid-48-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-48-top {
  border-top-style: solid;
  border-top-width: 48px;
}

.b-solid-48-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-48-top-left {
  border-top-left-radius: 48px;
}

.b-radius-48-top-right {
  border-top-right-radius: 48px;
}

.b-radius-48-bottom-left {
  border-bottom-left-radius: 48px;
}

.b-radius-48-bottom-right {
  border-bottom-right-radius: 48px;
}

.b-solid-49 {
  border-style: solid;
  border-width: 49px;
}

.b-radius-49 {
  border-radius: 49px;
}

.b-solid-49-bottom {
  border-bottom-style: solid;
  border-bottom-width: 49px;
}

.b-solid-49-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-49-left {
  border-left-style: solid;
  border-left-width: 49px;
}

.b-solid-49-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-49-right {
  border-right-style: solid;
  border-right-width: 49px;
}

.b-solid-49-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-49-top {
  border-top-style: solid;
  border-top-width: 49px;
}

.b-solid-49-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-49-top-left {
  border-top-left-radius: 49px;
}

.b-radius-49-top-right {
  border-top-right-radius: 49px;
}

.b-radius-49-bottom-left {
  border-bottom-left-radius: 49px;
}

.b-radius-49-bottom-right {
  border-bottom-right-radius: 49px;
}

.b-solid-50 {
  border-style: solid;
  border-width: 50px;
}

.b-radius-50 {
  border-radius: 50px;
}

.b-solid-50-bottom {
  border-bottom-style: solid;
  border-bottom-width: 50px;
}

.b-solid-50-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-50-left {
  border-left-style: solid;
  border-left-width: 50px;
}

.b-solid-50-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-50-right {
  border-right-style: solid;
  border-right-width: 50px;
}

.b-solid-50-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-50-top {
  border-top-style: solid;
  border-top-width: 50px;
}

.b-solid-50-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-50-top-left {
  border-top-left-radius: 50px;
}

.b-radius-50-top-right {
  border-top-right-radius: 50px;
}

.b-radius-50-bottom-left {
  border-bottom-left-radius: 50px;
}

.b-radius-50-bottom-right {
  border-bottom-right-radius: 50px;
}

.b-solid-51 {
  border-style: solid;
  border-width: 51px;
}

.b-radius-51 {
  border-radius: 51px;
}

.b-solid-51-bottom {
  border-bottom-style: solid;
  border-bottom-width: 51px;
}

.b-solid-51-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-51-left {
  border-left-style: solid;
  border-left-width: 51px;
}

.b-solid-51-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-51-right {
  border-right-style: solid;
  border-right-width: 51px;
}

.b-solid-51-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-51-top {
  border-top-style: solid;
  border-top-width: 51px;
}

.b-solid-51-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-51-top-left {
  border-top-left-radius: 51px;
}

.b-radius-51-top-right {
  border-top-right-radius: 51px;
}

.b-radius-51-bottom-left {
  border-bottom-left-radius: 51px;
}

.b-radius-51-bottom-right {
  border-bottom-right-radius: 51px;
}

.b-solid-52 {
  border-style: solid;
  border-width: 52px;
}

.b-radius-52 {
  border-radius: 52px;
}

.b-solid-52-bottom {
  border-bottom-style: solid;
  border-bottom-width: 52px;
}

.b-solid-52-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-52-left {
  border-left-style: solid;
  border-left-width: 52px;
}

.b-solid-52-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-52-right {
  border-right-style: solid;
  border-right-width: 52px;
}

.b-solid-52-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-52-top {
  border-top-style: solid;
  border-top-width: 52px;
}

.b-solid-52-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-52-top-left {
  border-top-left-radius: 52px;
}

.b-radius-52-top-right {
  border-top-right-radius: 52px;
}

.b-radius-52-bottom-left {
  border-bottom-left-radius: 52px;
}

.b-radius-52-bottom-right {
  border-bottom-right-radius: 52px;
}

.b-solid-53 {
  border-style: solid;
  border-width: 53px;
}

.b-radius-53 {
  border-radius: 53px;
}

.b-solid-53-bottom {
  border-bottom-style: solid;
  border-bottom-width: 53px;
}

.b-solid-53-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-53-left {
  border-left-style: solid;
  border-left-width: 53px;
}

.b-solid-53-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-53-right {
  border-right-style: solid;
  border-right-width: 53px;
}

.b-solid-53-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-53-top {
  border-top-style: solid;
  border-top-width: 53px;
}

.b-solid-53-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-53-top-left {
  border-top-left-radius: 53px;
}

.b-radius-53-top-right {
  border-top-right-radius: 53px;
}

.b-radius-53-bottom-left {
  border-bottom-left-radius: 53px;
}

.b-radius-53-bottom-right {
  border-bottom-right-radius: 53px;
}

.b-solid-54 {
  border-style: solid;
  border-width: 54px;
}

.b-radius-54 {
  border-radius: 54px;
}

.b-solid-54-bottom {
  border-bottom-style: solid;
  border-bottom-width: 54px;
}

.b-solid-54-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-54-left {
  border-left-style: solid;
  border-left-width: 54px;
}

.b-solid-54-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-54-right {
  border-right-style: solid;
  border-right-width: 54px;
}

.b-solid-54-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-54-top {
  border-top-style: solid;
  border-top-width: 54px;
}

.b-solid-54-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-54-top-left {
  border-top-left-radius: 54px;
}

.b-radius-54-top-right {
  border-top-right-radius: 54px;
}

.b-radius-54-bottom-left {
  border-bottom-left-radius: 54px;
}

.b-radius-54-bottom-right {
  border-bottom-right-radius: 54px;
}

.b-solid-55 {
  border-style: solid;
  border-width: 55px;
}

.b-radius-55 {
  border-radius: 55px;
}

.b-solid-55-bottom {
  border-bottom-style: solid;
  border-bottom-width: 55px;
}

.b-solid-55-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-55-left {
  border-left-style: solid;
  border-left-width: 55px;
}

.b-solid-55-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-55-right {
  border-right-style: solid;
  border-right-width: 55px;
}

.b-solid-55-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-55-top {
  border-top-style: solid;
  border-top-width: 55px;
}

.b-solid-55-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-55-top-left {
  border-top-left-radius: 55px;
}

.b-radius-55-top-right {
  border-top-right-radius: 55px;
}

.b-radius-55-bottom-left {
  border-bottom-left-radius: 55px;
}

.b-radius-55-bottom-right {
  border-bottom-right-radius: 55px;
}

.b-solid-56 {
  border-style: solid;
  border-width: 56px;
}

.b-radius-56 {
  border-radius: 56px;
}

.b-solid-56-bottom {
  border-bottom-style: solid;
  border-bottom-width: 56px;
}

.b-solid-56-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-56-left {
  border-left-style: solid;
  border-left-width: 56px;
}

.b-solid-56-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-56-right {
  border-right-style: solid;
  border-right-width: 56px;
}

.b-solid-56-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-56-top {
  border-top-style: solid;
  border-top-width: 56px;
}

.b-solid-56-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-56-top-left {
  border-top-left-radius: 56px;
}

.b-radius-56-top-right {
  border-top-right-radius: 56px;
}

.b-radius-56-bottom-left {
  border-bottom-left-radius: 56px;
}

.b-radius-56-bottom-right {
  border-bottom-right-radius: 56px;
}

.b-solid-57 {
  border-style: solid;
  border-width: 57px;
}

.b-radius-57 {
  border-radius: 57px;
}

.b-solid-57-bottom {
  border-bottom-style: solid;
  border-bottom-width: 57px;
}

.b-solid-57-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-57-left {
  border-left-style: solid;
  border-left-width: 57px;
}

.b-solid-57-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-57-right {
  border-right-style: solid;
  border-right-width: 57px;
}

.b-solid-57-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-57-top {
  border-top-style: solid;
  border-top-width: 57px;
}

.b-solid-57-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-57-top-left {
  border-top-left-radius: 57px;
}

.b-radius-57-top-right {
  border-top-right-radius: 57px;
}

.b-radius-57-bottom-left {
  border-bottom-left-radius: 57px;
}

.b-radius-57-bottom-right {
  border-bottom-right-radius: 57px;
}

.b-solid-58 {
  border-style: solid;
  border-width: 58px;
}

.b-radius-58 {
  border-radius: 58px;
}

.b-solid-58-bottom {
  border-bottom-style: solid;
  border-bottom-width: 58px;
}

.b-solid-58-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-58-left {
  border-left-style: solid;
  border-left-width: 58px;
}

.b-solid-58-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-58-right {
  border-right-style: solid;
  border-right-width: 58px;
}

.b-solid-58-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-58-top {
  border-top-style: solid;
  border-top-width: 58px;
}

.b-solid-58-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-58-top-left {
  border-top-left-radius: 58px;
}

.b-radius-58-top-right {
  border-top-right-radius: 58px;
}

.b-radius-58-bottom-left {
  border-bottom-left-radius: 58px;
}

.b-radius-58-bottom-right {
  border-bottom-right-radius: 58px;
}

.b-solid-59 {
  border-style: solid;
  border-width: 59px;
}

.b-radius-59 {
  border-radius: 59px;
}

.b-solid-59-bottom {
  border-bottom-style: solid;
  border-bottom-width: 59px;
}

.b-solid-59-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-59-left {
  border-left-style: solid;
  border-left-width: 59px;
}

.b-solid-59-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-59-right {
  border-right-style: solid;
  border-right-width: 59px;
}

.b-solid-59-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-59-top {
  border-top-style: solid;
  border-top-width: 59px;
}

.b-solid-59-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-59-top-left {
  border-top-left-radius: 59px;
}

.b-radius-59-top-right {
  border-top-right-radius: 59px;
}

.b-radius-59-bottom-left {
  border-bottom-left-radius: 59px;
}

.b-radius-59-bottom-right {
  border-bottom-right-radius: 59px;
}

.b-solid-60 {
  border-style: solid;
  border-width: 60px;
}

.b-radius-60 {
  border-radius: 60px;
}

.b-solid-60-bottom {
  border-bottom-style: solid;
  border-bottom-width: 60px;
}

.b-solid-60-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-60-left {
  border-left-style: solid;
  border-left-width: 60px;
}

.b-solid-60-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-60-right {
  border-right-style: solid;
  border-right-width: 60px;
}

.b-solid-60-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-60-top {
  border-top-style: solid;
  border-top-width: 60px;
}

.b-solid-60-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-60-top-left {
  border-top-left-radius: 60px;
}

.b-radius-60-top-right {
  border-top-right-radius: 60px;
}

.b-radius-60-bottom-left {
  border-bottom-left-radius: 60px;
}

.b-radius-60-bottom-right {
  border-bottom-right-radius: 60px;
}

.b-solid-61 {
  border-style: solid;
  border-width: 61px;
}

.b-radius-61 {
  border-radius: 61px;
}

.b-solid-61-bottom {
  border-bottom-style: solid;
  border-bottom-width: 61px;
}

.b-solid-61-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-61-left {
  border-left-style: solid;
  border-left-width: 61px;
}

.b-solid-61-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-61-right {
  border-right-style: solid;
  border-right-width: 61px;
}

.b-solid-61-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-61-top {
  border-top-style: solid;
  border-top-width: 61px;
}

.b-solid-61-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-61-top-left {
  border-top-left-radius: 61px;
}

.b-radius-61-top-right {
  border-top-right-radius: 61px;
}

.b-radius-61-bottom-left {
  border-bottom-left-radius: 61px;
}

.b-radius-61-bottom-right {
  border-bottom-right-radius: 61px;
}

.b-solid-62 {
  border-style: solid;
  border-width: 62px;
}

.b-radius-62 {
  border-radius: 62px;
}

.b-solid-62-bottom {
  border-bottom-style: solid;
  border-bottom-width: 62px;
}

.b-solid-62-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-62-left {
  border-left-style: solid;
  border-left-width: 62px;
}

.b-solid-62-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-62-right {
  border-right-style: solid;
  border-right-width: 62px;
}

.b-solid-62-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-62-top {
  border-top-style: solid;
  border-top-width: 62px;
}

.b-solid-62-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-62-top-left {
  border-top-left-radius: 62px;
}

.b-radius-62-top-right {
  border-top-right-radius: 62px;
}

.b-radius-62-bottom-left {
  border-bottom-left-radius: 62px;
}

.b-radius-62-bottom-right {
  border-bottom-right-radius: 62px;
}

.b-solid-63 {
  border-style: solid;
  border-width: 63px;
}

.b-radius-63 {
  border-radius: 63px;
}

.b-solid-63-bottom {
  border-bottom-style: solid;
  border-bottom-width: 63px;
}

.b-solid-63-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-63-left {
  border-left-style: solid;
  border-left-width: 63px;
}

.b-solid-63-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-63-right {
  border-right-style: solid;
  border-right-width: 63px;
}

.b-solid-63-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-63-top {
  border-top-style: solid;
  border-top-width: 63px;
}

.b-solid-63-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-63-top-left {
  border-top-left-radius: 63px;
}

.b-radius-63-top-right {
  border-top-right-radius: 63px;
}

.b-radius-63-bottom-left {
  border-bottom-left-radius: 63px;
}

.b-radius-63-bottom-right {
  border-bottom-right-radius: 63px;
}

.b-solid-64 {
  border-style: solid;
  border-width: 64px;
}

.b-radius-64 {
  border-radius: 64px;
}

.b-solid-64-bottom {
  border-bottom-style: solid;
  border-bottom-width: 64px;
}

.b-solid-64-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-64-left {
  border-left-style: solid;
  border-left-width: 64px;
}

.b-solid-64-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-64-right {
  border-right-style: solid;
  border-right-width: 64px;
}

.b-solid-64-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-64-top {
  border-top-style: solid;
  border-top-width: 64px;
}

.b-solid-64-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-64-top-left {
  border-top-left-radius: 64px;
}

.b-radius-64-top-right {
  border-top-right-radius: 64px;
}

.b-radius-64-bottom-left {
  border-bottom-left-radius: 64px;
}

.b-radius-64-bottom-right {
  border-bottom-right-radius: 64px;
}

.b-solid-65 {
  border-style: solid;
  border-width: 65px;
}

.b-radius-65 {
  border-radius: 65px;
}

.b-solid-65-bottom {
  border-bottom-style: solid;
  border-bottom-width: 65px;
}

.b-solid-65-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-65-left {
  border-left-style: solid;
  border-left-width: 65px;
}

.b-solid-65-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-65-right {
  border-right-style: solid;
  border-right-width: 65px;
}

.b-solid-65-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-65-top {
  border-top-style: solid;
  border-top-width: 65px;
}

.b-solid-65-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-65-top-left {
  border-top-left-radius: 65px;
}

.b-radius-65-top-right {
  border-top-right-radius: 65px;
}

.b-radius-65-bottom-left {
  border-bottom-left-radius: 65px;
}

.b-radius-65-bottom-right {
  border-bottom-right-radius: 65px;
}

.b-solid-66 {
  border-style: solid;
  border-width: 66px;
}

.b-radius-66 {
  border-radius: 66px;
}

.b-solid-66-bottom {
  border-bottom-style: solid;
  border-bottom-width: 66px;
}

.b-solid-66-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-66-left {
  border-left-style: solid;
  border-left-width: 66px;
}

.b-solid-66-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-66-right {
  border-right-style: solid;
  border-right-width: 66px;
}

.b-solid-66-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-66-top {
  border-top-style: solid;
  border-top-width: 66px;
}

.b-solid-66-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-66-top-left {
  border-top-left-radius: 66px;
}

.b-radius-66-top-right {
  border-top-right-radius: 66px;
}

.b-radius-66-bottom-left {
  border-bottom-left-radius: 66px;
}

.b-radius-66-bottom-right {
  border-bottom-right-radius: 66px;
}

.b-solid-67 {
  border-style: solid;
  border-width: 67px;
}

.b-radius-67 {
  border-radius: 67px;
}

.b-solid-67-bottom {
  border-bottom-style: solid;
  border-bottom-width: 67px;
}

.b-solid-67-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-67-left {
  border-left-style: solid;
  border-left-width: 67px;
}

.b-solid-67-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-67-right {
  border-right-style: solid;
  border-right-width: 67px;
}

.b-solid-67-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-67-top {
  border-top-style: solid;
  border-top-width: 67px;
}

.b-solid-67-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-67-top-left {
  border-top-left-radius: 67px;
}

.b-radius-67-top-right {
  border-top-right-radius: 67px;
}

.b-radius-67-bottom-left {
  border-bottom-left-radius: 67px;
}

.b-radius-67-bottom-right {
  border-bottom-right-radius: 67px;
}

.b-solid-68 {
  border-style: solid;
  border-width: 68px;
}

.b-radius-68 {
  border-radius: 68px;
}

.b-solid-68-bottom {
  border-bottom-style: solid;
  border-bottom-width: 68px;
}

.b-solid-68-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-68-left {
  border-left-style: solid;
  border-left-width: 68px;
}

.b-solid-68-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-68-right {
  border-right-style: solid;
  border-right-width: 68px;
}

.b-solid-68-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-68-top {
  border-top-style: solid;
  border-top-width: 68px;
}

.b-solid-68-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-68-top-left {
  border-top-left-radius: 68px;
}

.b-radius-68-top-right {
  border-top-right-radius: 68px;
}

.b-radius-68-bottom-left {
  border-bottom-left-radius: 68px;
}

.b-radius-68-bottom-right {
  border-bottom-right-radius: 68px;
}

.b-solid-69 {
  border-style: solid;
  border-width: 69px;
}

.b-radius-69 {
  border-radius: 69px;
}

.b-solid-69-bottom {
  border-bottom-style: solid;
  border-bottom-width: 69px;
}

.b-solid-69-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-69-left {
  border-left-style: solid;
  border-left-width: 69px;
}

.b-solid-69-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-69-right {
  border-right-style: solid;
  border-right-width: 69px;
}

.b-solid-69-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-69-top {
  border-top-style: solid;
  border-top-width: 69px;
}

.b-solid-69-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-69-top-left {
  border-top-left-radius: 69px;
}

.b-radius-69-top-right {
  border-top-right-radius: 69px;
}

.b-radius-69-bottom-left {
  border-bottom-left-radius: 69px;
}

.b-radius-69-bottom-right {
  border-bottom-right-radius: 69px;
}

.b-solid-70 {
  border-style: solid;
  border-width: 70px;
}

.b-radius-70 {
  border-radius: 70px;
}

.b-solid-70-bottom {
  border-bottom-style: solid;
  border-bottom-width: 70px;
}

.b-solid-70-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-70-left {
  border-left-style: solid;
  border-left-width: 70px;
}

.b-solid-70-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-70-right {
  border-right-style: solid;
  border-right-width: 70px;
}

.b-solid-70-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-70-top {
  border-top-style: solid;
  border-top-width: 70px;
}

.b-solid-70-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-70-top-left {
  border-top-left-radius: 70px;
}

.b-radius-70-top-right {
  border-top-right-radius: 70px;
}

.b-radius-70-bottom-left {
  border-bottom-left-radius: 70px;
}

.b-radius-70-bottom-right {
  border-bottom-right-radius: 70px;
}

.b-solid-71 {
  border-style: solid;
  border-width: 71px;
}

.b-radius-71 {
  border-radius: 71px;
}

.b-solid-71-bottom {
  border-bottom-style: solid;
  border-bottom-width: 71px;
}

.b-solid-71-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-71-left {
  border-left-style: solid;
  border-left-width: 71px;
}

.b-solid-71-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-71-right {
  border-right-style: solid;
  border-right-width: 71px;
}

.b-solid-71-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-71-top {
  border-top-style: solid;
  border-top-width: 71px;
}

.b-solid-71-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-71-top-left {
  border-top-left-radius: 71px;
}

.b-radius-71-top-right {
  border-top-right-radius: 71px;
}

.b-radius-71-bottom-left {
  border-bottom-left-radius: 71px;
}

.b-radius-71-bottom-right {
  border-bottom-right-radius: 71px;
}

.b-solid-72 {
  border-style: solid;
  border-width: 72px;
}

.b-radius-72 {
  border-radius: 72px;
}

.b-solid-72-bottom {
  border-bottom-style: solid;
  border-bottom-width: 72px;
}

.b-solid-72-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-72-left {
  border-left-style: solid;
  border-left-width: 72px;
}

.b-solid-72-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-72-right {
  border-right-style: solid;
  border-right-width: 72px;
}

.b-solid-72-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-72-top {
  border-top-style: solid;
  border-top-width: 72px;
}

.b-solid-72-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-72-top-left {
  border-top-left-radius: 72px;
}

.b-radius-72-top-right {
  border-top-right-radius: 72px;
}

.b-radius-72-bottom-left {
  border-bottom-left-radius: 72px;
}

.b-radius-72-bottom-right {
  border-bottom-right-radius: 72px;
}

.b-solid-73 {
  border-style: solid;
  border-width: 73px;
}

.b-radius-73 {
  border-radius: 73px;
}

.b-solid-73-bottom {
  border-bottom-style: solid;
  border-bottom-width: 73px;
}

.b-solid-73-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-73-left {
  border-left-style: solid;
  border-left-width: 73px;
}

.b-solid-73-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-73-right {
  border-right-style: solid;
  border-right-width: 73px;
}

.b-solid-73-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-73-top {
  border-top-style: solid;
  border-top-width: 73px;
}

.b-solid-73-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-73-top-left {
  border-top-left-radius: 73px;
}

.b-radius-73-top-right {
  border-top-right-radius: 73px;
}

.b-radius-73-bottom-left {
  border-bottom-left-radius: 73px;
}

.b-radius-73-bottom-right {
  border-bottom-right-radius: 73px;
}

.b-solid-74 {
  border-style: solid;
  border-width: 74px;
}

.b-radius-74 {
  border-radius: 74px;
}

.b-solid-74-bottom {
  border-bottom-style: solid;
  border-bottom-width: 74px;
}

.b-solid-74-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-74-left {
  border-left-style: solid;
  border-left-width: 74px;
}

.b-solid-74-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-74-right {
  border-right-style: solid;
  border-right-width: 74px;
}

.b-solid-74-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-74-top {
  border-top-style: solid;
  border-top-width: 74px;
}

.b-solid-74-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-74-top-left {
  border-top-left-radius: 74px;
}

.b-radius-74-top-right {
  border-top-right-radius: 74px;
}

.b-radius-74-bottom-left {
  border-bottom-left-radius: 74px;
}

.b-radius-74-bottom-right {
  border-bottom-right-radius: 74px;
}

.b-solid-75 {
  border-style: solid;
  border-width: 75px;
}

.b-radius-75 {
  border-radius: 75px;
}

.b-solid-75-bottom {
  border-bottom-style: solid;
  border-bottom-width: 75px;
}

.b-solid-75-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-75-left {
  border-left-style: solid;
  border-left-width: 75px;
}

.b-solid-75-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-75-right {
  border-right-style: solid;
  border-right-width: 75px;
}

.b-solid-75-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-75-top {
  border-top-style: solid;
  border-top-width: 75px;
}

.b-solid-75-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-75-top-left {
  border-top-left-radius: 75px;
}

.b-radius-75-top-right {
  border-top-right-radius: 75px;
}

.b-radius-75-bottom-left {
  border-bottom-left-radius: 75px;
}

.b-radius-75-bottom-right {
  border-bottom-right-radius: 75px;
}

.b-solid-76 {
  border-style: solid;
  border-width: 76px;
}

.b-radius-76 {
  border-radius: 76px;
}

.b-solid-76-bottom {
  border-bottom-style: solid;
  border-bottom-width: 76px;
}

.b-solid-76-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-76-left {
  border-left-style: solid;
  border-left-width: 76px;
}

.b-solid-76-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-76-right {
  border-right-style: solid;
  border-right-width: 76px;
}

.b-solid-76-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-76-top {
  border-top-style: solid;
  border-top-width: 76px;
}

.b-solid-76-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-76-top-left {
  border-top-left-radius: 76px;
}

.b-radius-76-top-right {
  border-top-right-radius: 76px;
}

.b-radius-76-bottom-left {
  border-bottom-left-radius: 76px;
}

.b-radius-76-bottom-right {
  border-bottom-right-radius: 76px;
}

.b-solid-77 {
  border-style: solid;
  border-width: 77px;
}

.b-radius-77 {
  border-radius: 77px;
}

.b-solid-77-bottom {
  border-bottom-style: solid;
  border-bottom-width: 77px;
}

.b-solid-77-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-77-left {
  border-left-style: solid;
  border-left-width: 77px;
}

.b-solid-77-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-77-right {
  border-right-style: solid;
  border-right-width: 77px;
}

.b-solid-77-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-77-top {
  border-top-style: solid;
  border-top-width: 77px;
}

.b-solid-77-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-77-top-left {
  border-top-left-radius: 77px;
}

.b-radius-77-top-right {
  border-top-right-radius: 77px;
}

.b-radius-77-bottom-left {
  border-bottom-left-radius: 77px;
}

.b-radius-77-bottom-right {
  border-bottom-right-radius: 77px;
}

.b-solid-78 {
  border-style: solid;
  border-width: 78px;
}

.b-radius-78 {
  border-radius: 78px;
}

.b-solid-78-bottom {
  border-bottom-style: solid;
  border-bottom-width: 78px;
}

.b-solid-78-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-78-left {
  border-left-style: solid;
  border-left-width: 78px;
}

.b-solid-78-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-78-right {
  border-right-style: solid;
  border-right-width: 78px;
}

.b-solid-78-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-78-top {
  border-top-style: solid;
  border-top-width: 78px;
}

.b-solid-78-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-78-top-left {
  border-top-left-radius: 78px;
}

.b-radius-78-top-right {
  border-top-right-radius: 78px;
}

.b-radius-78-bottom-left {
  border-bottom-left-radius: 78px;
}

.b-radius-78-bottom-right {
  border-bottom-right-radius: 78px;
}

.b-solid-79 {
  border-style: solid;
  border-width: 79px;
}

.b-radius-79 {
  border-radius: 79px;
}

.b-solid-79-bottom {
  border-bottom-style: solid;
  border-bottom-width: 79px;
}

.b-solid-79-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-79-left {
  border-left-style: solid;
  border-left-width: 79px;
}

.b-solid-79-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-79-right {
  border-right-style: solid;
  border-right-width: 79px;
}

.b-solid-79-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-79-top {
  border-top-style: solid;
  border-top-width: 79px;
}

.b-solid-79-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-79-top-left {
  border-top-left-radius: 79px;
}

.b-radius-79-top-right {
  border-top-right-radius: 79px;
}

.b-radius-79-bottom-left {
  border-bottom-left-radius: 79px;
}

.b-radius-79-bottom-right {
  border-bottom-right-radius: 79px;
}

.b-solid-80 {
  border-style: solid;
  border-width: 80px;
}

.b-radius-80 {
  border-radius: 80px;
}

.b-solid-80-bottom {
  border-bottom-style: solid;
  border-bottom-width: 80px;
}

.b-solid-80-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-80-left {
  border-left-style: solid;
  border-left-width: 80px;
}

.b-solid-80-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-80-right {
  border-right-style: solid;
  border-right-width: 80px;
}

.b-solid-80-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-80-top {
  border-top-style: solid;
  border-top-width: 80px;
}

.b-solid-80-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-80-top-left {
  border-top-left-radius: 80px;
}

.b-radius-80-top-right {
  border-top-right-radius: 80px;
}

.b-radius-80-bottom-left {
  border-bottom-left-radius: 80px;
}

.b-radius-80-bottom-right {
  border-bottom-right-radius: 80px;
}

.b-solid-81 {
  border-style: solid;
  border-width: 81px;
}

.b-radius-81 {
  border-radius: 81px;
}

.b-solid-81-bottom {
  border-bottom-style: solid;
  border-bottom-width: 81px;
}

.b-solid-81-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-81-left {
  border-left-style: solid;
  border-left-width: 81px;
}

.b-solid-81-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-81-right {
  border-right-style: solid;
  border-right-width: 81px;
}

.b-solid-81-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-81-top {
  border-top-style: solid;
  border-top-width: 81px;
}

.b-solid-81-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-81-top-left {
  border-top-left-radius: 81px;
}

.b-radius-81-top-right {
  border-top-right-radius: 81px;
}

.b-radius-81-bottom-left {
  border-bottom-left-radius: 81px;
}

.b-radius-81-bottom-right {
  border-bottom-right-radius: 81px;
}

.b-solid-82 {
  border-style: solid;
  border-width: 82px;
}

.b-radius-82 {
  border-radius: 82px;
}

.b-solid-82-bottom {
  border-bottom-style: solid;
  border-bottom-width: 82px;
}

.b-solid-82-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-82-left {
  border-left-style: solid;
  border-left-width: 82px;
}

.b-solid-82-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-82-right {
  border-right-style: solid;
  border-right-width: 82px;
}

.b-solid-82-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-82-top {
  border-top-style: solid;
  border-top-width: 82px;
}

.b-solid-82-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-82-top-left {
  border-top-left-radius: 82px;
}

.b-radius-82-top-right {
  border-top-right-radius: 82px;
}

.b-radius-82-bottom-left {
  border-bottom-left-radius: 82px;
}

.b-radius-82-bottom-right {
  border-bottom-right-radius: 82px;
}

.b-solid-83 {
  border-style: solid;
  border-width: 83px;
}

.b-radius-83 {
  border-radius: 83px;
}

.b-solid-83-bottom {
  border-bottom-style: solid;
  border-bottom-width: 83px;
}

.b-solid-83-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-83-left {
  border-left-style: solid;
  border-left-width: 83px;
}

.b-solid-83-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-83-right {
  border-right-style: solid;
  border-right-width: 83px;
}

.b-solid-83-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-83-top {
  border-top-style: solid;
  border-top-width: 83px;
}

.b-solid-83-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-83-top-left {
  border-top-left-radius: 83px;
}

.b-radius-83-top-right {
  border-top-right-radius: 83px;
}

.b-radius-83-bottom-left {
  border-bottom-left-radius: 83px;
}

.b-radius-83-bottom-right {
  border-bottom-right-radius: 83px;
}

.b-solid-84 {
  border-style: solid;
  border-width: 84px;
}

.b-radius-84 {
  border-radius: 84px;
}

.b-solid-84-bottom {
  border-bottom-style: solid;
  border-bottom-width: 84px;
}

.b-solid-84-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-84-left {
  border-left-style: solid;
  border-left-width: 84px;
}

.b-solid-84-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-84-right {
  border-right-style: solid;
  border-right-width: 84px;
}

.b-solid-84-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-84-top {
  border-top-style: solid;
  border-top-width: 84px;
}

.b-solid-84-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-84-top-left {
  border-top-left-radius: 84px;
}

.b-radius-84-top-right {
  border-top-right-radius: 84px;
}

.b-radius-84-bottom-left {
  border-bottom-left-radius: 84px;
}

.b-radius-84-bottom-right {
  border-bottom-right-radius: 84px;
}

.b-solid-85 {
  border-style: solid;
  border-width: 85px;
}

.b-radius-85 {
  border-radius: 85px;
}

.b-solid-85-bottom {
  border-bottom-style: solid;
  border-bottom-width: 85px;
}

.b-solid-85-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-85-left {
  border-left-style: solid;
  border-left-width: 85px;
}

.b-solid-85-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-85-right {
  border-right-style: solid;
  border-right-width: 85px;
}

.b-solid-85-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-85-top {
  border-top-style: solid;
  border-top-width: 85px;
}

.b-solid-85-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-85-top-left {
  border-top-left-radius: 85px;
}

.b-radius-85-top-right {
  border-top-right-radius: 85px;
}

.b-radius-85-bottom-left {
  border-bottom-left-radius: 85px;
}

.b-radius-85-bottom-right {
  border-bottom-right-radius: 85px;
}

.b-solid-86 {
  border-style: solid;
  border-width: 86px;
}

.b-radius-86 {
  border-radius: 86px;
}

.b-solid-86-bottom {
  border-bottom-style: solid;
  border-bottom-width: 86px;
}

.b-solid-86-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-86-left {
  border-left-style: solid;
  border-left-width: 86px;
}

.b-solid-86-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-86-right {
  border-right-style: solid;
  border-right-width: 86px;
}

.b-solid-86-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-86-top {
  border-top-style: solid;
  border-top-width: 86px;
}

.b-solid-86-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-86-top-left {
  border-top-left-radius: 86px;
}

.b-radius-86-top-right {
  border-top-right-radius: 86px;
}

.b-radius-86-bottom-left {
  border-bottom-left-radius: 86px;
}

.b-radius-86-bottom-right {
  border-bottom-right-radius: 86px;
}

.b-solid-87 {
  border-style: solid;
  border-width: 87px;
}

.b-radius-87 {
  border-radius: 87px;
}

.b-solid-87-bottom {
  border-bottom-style: solid;
  border-bottom-width: 87px;
}

.b-solid-87-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-87-left {
  border-left-style: solid;
  border-left-width: 87px;
}

.b-solid-87-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-87-right {
  border-right-style: solid;
  border-right-width: 87px;
}

.b-solid-87-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-87-top {
  border-top-style: solid;
  border-top-width: 87px;
}

.b-solid-87-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-87-top-left {
  border-top-left-radius: 87px;
}

.b-radius-87-top-right {
  border-top-right-radius: 87px;
}

.b-radius-87-bottom-left {
  border-bottom-left-radius: 87px;
}

.b-radius-87-bottom-right {
  border-bottom-right-radius: 87px;
}

.b-solid-88 {
  border-style: solid;
  border-width: 88px;
}

.b-radius-88 {
  border-radius: 88px;
}

.b-solid-88-bottom {
  border-bottom-style: solid;
  border-bottom-width: 88px;
}

.b-solid-88-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-88-left {
  border-left-style: solid;
  border-left-width: 88px;
}

.b-solid-88-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-88-right {
  border-right-style: solid;
  border-right-width: 88px;
}

.b-solid-88-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-88-top {
  border-top-style: solid;
  border-top-width: 88px;
}

.b-solid-88-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-88-top-left {
  border-top-left-radius: 88px;
}

.b-radius-88-top-right {
  border-top-right-radius: 88px;
}

.b-radius-88-bottom-left {
  border-bottom-left-radius: 88px;
}

.b-radius-88-bottom-right {
  border-bottom-right-radius: 88px;
}

.b-solid-89 {
  border-style: solid;
  border-width: 89px;
}

.b-radius-89 {
  border-radius: 89px;
}

.b-solid-89-bottom {
  border-bottom-style: solid;
  border-bottom-width: 89px;
}

.b-solid-89-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-89-left {
  border-left-style: solid;
  border-left-width: 89px;
}

.b-solid-89-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-89-right {
  border-right-style: solid;
  border-right-width: 89px;
}

.b-solid-89-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-89-top {
  border-top-style: solid;
  border-top-width: 89px;
}

.b-solid-89-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-89-top-left {
  border-top-left-radius: 89px;
}

.b-radius-89-top-right {
  border-top-right-radius: 89px;
}

.b-radius-89-bottom-left {
  border-bottom-left-radius: 89px;
}

.b-radius-89-bottom-right {
  border-bottom-right-radius: 89px;
}

.b-solid-90 {
  border-style: solid;
  border-width: 90px;
}

.b-radius-90 {
  border-radius: 90px;
}

.b-solid-90-bottom {
  border-bottom-style: solid;
  border-bottom-width: 90px;
}

.b-solid-90-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-90-left {
  border-left-style: solid;
  border-left-width: 90px;
}

.b-solid-90-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-90-right {
  border-right-style: solid;
  border-right-width: 90px;
}

.b-solid-90-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-90-top {
  border-top-style: solid;
  border-top-width: 90px;
}

.b-solid-90-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-90-top-left {
  border-top-left-radius: 90px;
}

.b-radius-90-top-right {
  border-top-right-radius: 90px;
}

.b-radius-90-bottom-left {
  border-bottom-left-radius: 90px;
}

.b-radius-90-bottom-right {
  border-bottom-right-radius: 90px;
}

.b-solid-91 {
  border-style: solid;
  border-width: 91px;
}

.b-radius-91 {
  border-radius: 91px;
}

.b-solid-91-bottom {
  border-bottom-style: solid;
  border-bottom-width: 91px;
}

.b-solid-91-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-91-left {
  border-left-style: solid;
  border-left-width: 91px;
}

.b-solid-91-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-91-right {
  border-right-style: solid;
  border-right-width: 91px;
}

.b-solid-91-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-91-top {
  border-top-style: solid;
  border-top-width: 91px;
}

.b-solid-91-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-91-top-left {
  border-top-left-radius: 91px;
}

.b-radius-91-top-right {
  border-top-right-radius: 91px;
}

.b-radius-91-bottom-left {
  border-bottom-left-radius: 91px;
}

.b-radius-91-bottom-right {
  border-bottom-right-radius: 91px;
}

.b-solid-92 {
  border-style: solid;
  border-width: 92px;
}

.b-radius-92 {
  border-radius: 92px;
}

.b-solid-92-bottom {
  border-bottom-style: solid;
  border-bottom-width: 92px;
}

.b-solid-92-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-92-left {
  border-left-style: solid;
  border-left-width: 92px;
}

.b-solid-92-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-92-right {
  border-right-style: solid;
  border-right-width: 92px;
}

.b-solid-92-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-92-top {
  border-top-style: solid;
  border-top-width: 92px;
}

.b-solid-92-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-92-top-left {
  border-top-left-radius: 92px;
}

.b-radius-92-top-right {
  border-top-right-radius: 92px;
}

.b-radius-92-bottom-left {
  border-bottom-left-radius: 92px;
}

.b-radius-92-bottom-right {
  border-bottom-right-radius: 92px;
}

.b-solid-93 {
  border-style: solid;
  border-width: 93px;
}

.b-radius-93 {
  border-radius: 93px;
}

.b-solid-93-bottom {
  border-bottom-style: solid;
  border-bottom-width: 93px;
}

.b-solid-93-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-93-left {
  border-left-style: solid;
  border-left-width: 93px;
}

.b-solid-93-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-93-right {
  border-right-style: solid;
  border-right-width: 93px;
}

.b-solid-93-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-93-top {
  border-top-style: solid;
  border-top-width: 93px;
}

.b-solid-93-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-93-top-left {
  border-top-left-radius: 93px;
}

.b-radius-93-top-right {
  border-top-right-radius: 93px;
}

.b-radius-93-bottom-left {
  border-bottom-left-radius: 93px;
}

.b-radius-93-bottom-right {
  border-bottom-right-radius: 93px;
}

.b-solid-94 {
  border-style: solid;
  border-width: 94px;
}

.b-radius-94 {
  border-radius: 94px;
}

.b-solid-94-bottom {
  border-bottom-style: solid;
  border-bottom-width: 94px;
}

.b-solid-94-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-94-left {
  border-left-style: solid;
  border-left-width: 94px;
}

.b-solid-94-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-94-right {
  border-right-style: solid;
  border-right-width: 94px;
}

.b-solid-94-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-94-top {
  border-top-style: solid;
  border-top-width: 94px;
}

.b-solid-94-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-94-top-left {
  border-top-left-radius: 94px;
}

.b-radius-94-top-right {
  border-top-right-radius: 94px;
}

.b-radius-94-bottom-left {
  border-bottom-left-radius: 94px;
}

.b-radius-94-bottom-right {
  border-bottom-right-radius: 94px;
}

.b-solid-95 {
  border-style: solid;
  border-width: 95px;
}

.b-radius-95 {
  border-radius: 95px;
}

.b-solid-95-bottom {
  border-bottom-style: solid;
  border-bottom-width: 95px;
}

.b-solid-95-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-95-left {
  border-left-style: solid;
  border-left-width: 95px;
}

.b-solid-95-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-95-right {
  border-right-style: solid;
  border-right-width: 95px;
}

.b-solid-95-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-95-top {
  border-top-style: solid;
  border-top-width: 95px;
}

.b-solid-95-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-95-top-left {
  border-top-left-radius: 95px;
}

.b-radius-95-top-right {
  border-top-right-radius: 95px;
}

.b-radius-95-bottom-left {
  border-bottom-left-radius: 95px;
}

.b-radius-95-bottom-right {
  border-bottom-right-radius: 95px;
}

.b-solid-96 {
  border-style: solid;
  border-width: 96px;
}

.b-radius-96 {
  border-radius: 96px;
}

.b-solid-96-bottom {
  border-bottom-style: solid;
  border-bottom-width: 96px;
}

.b-solid-96-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-96-left {
  border-left-style: solid;
  border-left-width: 96px;
}

.b-solid-96-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-96-right {
  border-right-style: solid;
  border-right-width: 96px;
}

.b-solid-96-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-96-top {
  border-top-style: solid;
  border-top-width: 96px;
}

.b-solid-96-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-96-top-left {
  border-top-left-radius: 96px;
}

.b-radius-96-top-right {
  border-top-right-radius: 96px;
}

.b-radius-96-bottom-left {
  border-bottom-left-radius: 96px;
}

.b-radius-96-bottom-right {
  border-bottom-right-radius: 96px;
}

.b-solid-97 {
  border-style: solid;
  border-width: 97px;
}

.b-radius-97 {
  border-radius: 97px;
}

.b-solid-97-bottom {
  border-bottom-style: solid;
  border-bottom-width: 97px;
}

.b-solid-97-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-97-left {
  border-left-style: solid;
  border-left-width: 97px;
}

.b-solid-97-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-97-right {
  border-right-style: solid;
  border-right-width: 97px;
}

.b-solid-97-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-97-top {
  border-top-style: solid;
  border-top-width: 97px;
}

.b-solid-97-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-97-top-left {
  border-top-left-radius: 97px;
}

.b-radius-97-top-right {
  border-top-right-radius: 97px;
}

.b-radius-97-bottom-left {
  border-bottom-left-radius: 97px;
}

.b-radius-97-bottom-right {
  border-bottom-right-radius: 97px;
}

.b-solid-98 {
  border-style: solid;
  border-width: 98px;
}

.b-radius-98 {
  border-radius: 98px;
}

.b-solid-98-bottom {
  border-bottom-style: solid;
  border-bottom-width: 98px;
}

.b-solid-98-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-98-left {
  border-left-style: solid;
  border-left-width: 98px;
}

.b-solid-98-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-98-right {
  border-right-style: solid;
  border-right-width: 98px;
}

.b-solid-98-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-98-top {
  border-top-style: solid;
  border-top-width: 98px;
}

.b-solid-98-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-98-top-left {
  border-top-left-radius: 98px;
}

.b-radius-98-top-right {
  border-top-right-radius: 98px;
}

.b-radius-98-bottom-left {
  border-bottom-left-radius: 98px;
}

.b-radius-98-bottom-right {
  border-bottom-right-radius: 98px;
}

.b-solid-99 {
  border-style: solid;
  border-width: 99px;
}

.b-radius-99 {
  border-radius: 99px;
}

.b-solid-99-bottom {
  border-bottom-style: solid;
  border-bottom-width: 99px;
}

.b-solid-99-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-99-left {
  border-left-style: solid;
  border-left-width: 99px;
}

.b-solid-99-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-99-right {
  border-right-style: solid;
  border-right-width: 99px;
}

.b-solid-99-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-99-top {
  border-top-style: solid;
  border-top-width: 99px;
}

.b-solid-99-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-99-top-left {
  border-top-left-radius: 99px;
}

.b-radius-99-top-right {
  border-top-right-radius: 99px;
}

.b-radius-99-bottom-left {
  border-bottom-left-radius: 99px;
}

.b-radius-99-bottom-right {
  border-bottom-right-radius: 99px;
}

.b-solid-100 {
  border-style: solid;
  border-width: 100px;
}

.b-radius-100 {
  border-radius: 100px;
}

.b-solid-100-bottom {
  border-bottom-style: solid;
  border-bottom-width: 100px;
}

.b-solid-100-bottom-remove {
  border-bottom-style: inherit;
  border-bottom-width: inherit;
}

.b-solid-100-left {
  border-left-style: solid;
  border-left-width: 100px;
}

.b-solid-100-left-remove {
  border-left-style: inherit;
  border-left-width: inherit;
}

.b-solid-100-right {
  border-right-style: solid;
  border-right-width: 100px;
}

.b-solid-100-right-remove {
  border-right-style: inherit;
  border-right-width: inherit;
}

.b-solid-100-top {
  border-top-style: solid;
  border-top-width: 100px;
}

.b-solid-100-top-remove {
  border-top-style: inherit;
  border-top-width: inherit;
}

.b-radius-100-top-left {
  border-top-left-radius: 100px;
}

.b-radius-100-top-right {
  border-top-right-radius: 100px;
}

.b-radius-100-bottom-left {
  border-bottom-left-radius: 100px;
}

.b-radius-100-bottom-right {
  border-bottom-right-radius: 100px;
}

/* Libraries Components */
/* External Components */
.uk-navbar-nav.uk-navbar-menu-top > li > a {
  min-height: inherit;
  padding: 5px 0;
}

.uk-navbar-nav > li > a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: initial;
}
.uk-navbar-nav > li > a:hover {
  color: #ffffff;
}
.uk-navbar-nav > li > a:active {
  color: #ffffff;
}
.uk-navbar-nav > li > a[aria-expanded=true] {
  color: #ffffff;
}

.uk-dropdown-nav > li > a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.uk-dropdown-nav > li > a:hover {
  color: #ffffff;
}

.uk-grid-divider-pc-pink.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before,
.uk-grid-divider-pc-pink.uk-grid-divider > :not(.uk-first-column)::before {
  border-color: #ff1784;
  border-width: 3px;
}

.uk-grid-divider-pc-pink {
  border-color: #ff1784;
  border-width: 3px;
}

.uk-dotnav--pc-navy li a {
  border: none;
  background-color: #002a68;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-navy li.uk-active a {
  border: none;
  background-color: #002a68;
}
.uk-dotnav--pc-pink li a {
  border: none;
  background-color: #ff1784;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-pink li.uk-active a {
  border: none;
  background-color: #ff1784;
}
.uk-dotnav--pc-sky-blue li a {
  border: none;
  background-color: #0090d3;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-sky-blue li.uk-active a {
  border: none;
  background-color: #0090d3;
}
.uk-dotnav--pc-white li a {
  border: none;
  background-color: white;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-white li.uk-active a {
  border: none;
  background-color: white;
}
.uk-dotnav--pc-grey-1 li a {
  border: none;
  background-color: #333333;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-1 li.uk-active a {
  border: none;
  background-color: #333333;
}
.uk-dotnav--pc-grey-2 li a {
  border: none;
  background-color: #888888;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-2 li.uk-active a {
  border: none;
  background-color: #888888;
}
.uk-dotnav--pc-grey-3 li a {
  border: none;
  background-color: #e5e5e5;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-pc-grey-3 li.uk-active a {
  border: none;
  background-color: #e5e5e5;
}
.uk-dotnav--com-green li a {
  border: none;
  background-color: #66af30;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-green li.uk-active a {
  border: none;
  background-color: #66af30;
}
.uk-dotnav--com-cherry li a {
  border: none;
  background-color: #8d1c50;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-cherry li.uk-active a {
  border: none;
  background-color: #8d1c50;
}
.uk-dotnav--com-red li a {
  border: none;
  background-color: #e20613;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-red li.uk-active a {
  border: none;
  background-color: #e20613;
}
.uk-dotnav--com-mint li a {
  border: none;
  background-color: #00999b;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-mint li.uk-active a {
  border: none;
  background-color: #00999b;
}
.uk-dotnav--com-blue li a {
  border: none;
  background-color: #004b9b;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-blue li.uk-active a {
  border: none;
  background-color: #004b9b;
}
.uk-dotnav--com-orange li a {
  border: none;
  background-color: #e14504;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-orange li.uk-active a {
  border: none;
  background-color: #e14504;
}
.uk-dotnav--com-gold li a {
  border: none;
  background-color: #f29100;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-gold li.uk-active a {
  border: none;
  background-color: #f29100;
}
.uk-dotnav--com-yellow li a {
  border: none;
  background-color: #ffda27;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-com-yellow li.uk-active a {
  border: none;
  background-color: #ffda27;
}
.uk-dotnav--sc-navy-2 li a {
  border: none;
  background-color: #011c43;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-navy-2 li.uk-active a {
  border: none;
  background-color: #011c43;
}
.uk-dotnav--sc-pink-2 li a {
  border: none;
  background-color: #a92462;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-pink-2 li.uk-active a {
  border: none;
  background-color: #a92462;
}
.uk-dotnav--sc-sky-blue-2 li a {
  border: none;
  background-color: #0277a0;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-2 li.uk-active a {
  border: none;
  background-color: #0277a0;
}
.uk-dotnav--sc-sky-blue-3 li a {
  border: none;
  background-color: #bce0f1;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-3 li.uk-active a {
  border: none;
  background-color: #bce0f1;
}
.uk-dotnav--sc-sky-blue-4 li a {
  border: none;
  background-color: #e5f4fb;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-sky-blue-4 li.uk-active a {
  border: none;
  background-color: #e5f4fb;
}
.uk-dotnav--sc-grey-4 li a {
  border: none;
  background-color: #666666;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-4 li.uk-active a {
  border: none;
  background-color: #666666;
}
.uk-dotnav--sc-grey-5 li a {
  border: none;
  background-color: #f6f6f6;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-5 li.uk-active a {
  border: none;
  background-color: #f6f6f6;
}
.uk-dotnav--sc-grey-6 li a {
  border: none;
  background-color: #eeeeee;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-6 li.uk-active a {
  border: none;
  background-color: #eeeeee;
}
.uk-dotnav--sc-grey-7 li a {
  border: none;
  background-color: #fcfcfc;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-7 li.uk-active a {
  border: none;
  background-color: #fcfcfc;
}
.uk-dotnav--sc-grey-8 li a {
  border: none;
  background-color: #f1efec;
  width: 15px;
  height: 15px;
}
.uk-dotnav--active-sc-grey-8 li.uk-active a {
  border: none;
  background-color: #f1efec;
}

/* Internal Components */
ul {
  list-style-image: url("/images/site/svg/icono-flecha.svg");
}

.sw-main-menu-offcanvas__container {
  background-color: #002a68 !important;
}
.sw-main-menu-offcanvas__item {
  color: #ffffff !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.sw-main-menu-offcanvas__item, .sw-main-menu-offcanvas__item:hover {
  border-style: none;
  border-width: none;
}
.sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item {
  border-left: 2px solid #0090d3 !important;
  padding: 5px 0 5px 10px !important;
  transition: border-left 250ms;
  font-size: 14px;
}
.sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item:hover, .sw-main-menu-offcanvas__container-submenu .sw-main-menu-offcanvas__item:focus {
  border-left: 2px solid #ff1784 !important;
}

.userdata {
  background-color: #002a68;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

.login-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.form-group {
  margin-bottom: 10px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input[type=text],
.form-group input[type=password] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.form-group i {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: #ff1784;
  border: none;
}

.remember-me {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.forgot-questions {
  margin-top: 10px;
  text-align: left;
}

.forgot-questions p {
  margin: 5px 0;
}

.controls {
  background-color: #fff;
  color: #002A68;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  width: 100%;
}

a {
  color: white;
}

.forgot-questions a {
  text-decoration: none;
}

.paginacio_custom li.active a {
  background: #1e87f0;
  color: #333333;
}

.uk-pagination > * > * {
  color: #333333;
}

input[type=text] {
  border: none;
  border-radius: 5px;
  min-height: 40px;
  padding: 2px 10px;
  font-family: "Open Sans";
}

textarea {
  border: none;
  border-radius: 5px;
  min-height: 40px;
  padding: 2px 10px;
  font-family: "Open Sans";
}

.control-label-checkbox {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  display: inline-block;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px !important;
  height: 16px !important;
  border: 0.15em solid currentColor;
  border-radius: 5px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type=checkbox].large {
  width: 1.15em;
  height: 1.15em;
}

input[type=checkbox]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #002a68;
  background-color: CanvasText;
}

input[type=checkbox].check-pc-navy::before {
  box-shadow: inset 1em 1em #002a68;
}

input[type=checkbox].check-pc-pink::before {
  box-shadow: inset 1em 1em #ff1784;
}

input[type=checkbox]:checked::before {
  transform: scale(1);
}

.button-primary {
  padding: 8px 50px;
  background-color: #ffffff;
  color: #002a68;
  border: 2px solid #ff1784;
  border-radius: 5px;
  font-weight: bold;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: background 300ms;
}
.button-primary:focus, .button-primary:hover {
  background: #eeeeee;
  border: 2px solid #ff1784;
}

:root {
  --gutter-large: 24px;
  --gutter-medium: 16px;
  --gutter-small: 8px;
  --brand-color-gray: #f6f6f5;
}

.sw-horizontal-acordion-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
.sw-horizontal-acordion-panel {
  background-size: 352px 424px;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: var(--gutter-medium);
  position: relative;
  transition: all 700ms ease-in;
  max-width: 150px;
}
.sw-horizontal-acordion-panel.sw-active {
  flex: 3;
  max-width: 352px;
}
.sw-horizontal-acordion-title {
  opacity: 0;
  position: absolute;
  transition: all 500ms;
}
.sw-horizontal-acordion-title.sw-active {
  opacity: 1;
}

.registro::after {
  content: "*";
  color: #ff1784;
}
.registro .star {
  display: none;
}

.box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(/images/Iconos/icono-flecha-preguntas.svg);
  background-repeat: no-repeat;
  background-position: 97% 51%;
  background-size: 25px;
}

.extrainput input, .extrainput select {
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  border: 0 none;
  padding: 0 10px;
  color: #666666;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 25px;
  font-size: 16px;
}
.extrainput input[type=radio] + label:before {
  content: "";
  background: #f6f6f6;
  border-radius: 100%;
  border: 1px solid #ff1784;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -2px !important;
  margin-right: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.extrainput input[type=radio]:checked + label:before {
  background-color: #002a68;
  box-shadow: inset 0 0 0 4px #f6f6f6;
}
.extrainput input[type=radio] + label:before {
  top: 1px;
}
.extrainput input[type=radio] {
  position: absolute;
  opacity: 0;
}
.extrainput .radio {
  border: 0px;
  padding: 0;
  margin-top: 15px;
  font-weight: 500;
}
.extrainput .radio label {
  margin-right: 15px;
}

.buscador .inputbox {
  border: 2px solid #ff1784;
  padding: 7px;
}
.buscador .inputbox:focus {
  border: 2px solid #ff1784;
}
.buscador .button {
  background-color: #ff1784;
  color: #ffffff;
  padding: 7px;
  border: 2px solid #ff1784;
  margin-left: -4px;
}

.redes__top {
  z-index: 10;
  top: 4px;
}
@media (min-width: 640px) {
  .redes__top {
    top: 15px;
  }
}

.remove-18-top {
  top: -18px;
}

.lin_lateral:before {
  content: "";
  height: 100%;
  display: block;
  width: 1px;
  background: #ff1784;
  position: absolute;
  left: -35px;
}

.lin_lateraR:after {
  content: "";
  height: 100%;
  display: block;
  width: 1px;
  background: #ff1784;
  position: absolute;
  right: -35px;
  top: 0;
  z-index: 0;
}

.titulo__modoneh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
  color: #002a68;
  font-family: "Open Sans Condensed ExtraBold";
}
.titulo__modoneh2:after {
  background-color: #002a68;
  content: "";
  width: 95%;
  height: 3px;
  display: block;
  margin-top: 15px;
  position: relative;
  left: -45px;
}

.titulo__modtwoh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
  text-align: center;
  font-family: "Open Sans Condensed ExtraBold";
  color: #002a68;
}

.titulo__modtreeh2 {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
  padding-left: 45px;
}
.titulo__modtreeh2:after {
  background-color: #002a68;
  content: "";
  width: 460px;
  max-width: 100%;
  height: 3px;
  display: block;
  margin-top: 15px;
  position: relative;
  left: -45px;
}

.remove__border_bottom:last-child {
  border-bottom: 0px;
}

.mejorespracticas--title:before {
  content: "";
}

.remove_bottom-20 {
  margin-bottom: -20px;
}

.white_deg {
  border-color: rgba(255, 255, 255, 0.3803921569);
}

.titulo__h2__interna {
  font-size: 28px;
  font-weight: 800;
  text-align: left;
  color: #002a68;
  font-family: "Open Sans Condensed ExtraBold";
}
.titulo__h2__interna:after {
  background-color: #ff1784;
  content: "";
  width: 188px;
  height: 6px;
  display: block;
  margin-top: 14px;
  position: relative;
}

.uk-modal-iframe-dialog {
  position: relative;
  padding-bottom: 50.625%; /* 16:9 */
  width: 90%;
  height: 0;
}
@media (min-width: 1200px) {
  .uk-modal-iframe-dialog {
    padding-bottom: 675px; /* 16:9 */
    width: 1200px !important;
  }
}
.uk-modal-iframe-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.level1 .uk-accordion-title {
  background-color: #F1EFEC;
  border-bottom: 1px solid;
  border-radius: 0;
  font-weight: 400;
  padding-left: 0px;
}
.level1 svg {
  color: #ff1784;
}
.level1 > :nth-child(n+2) {
  margin-top: 2px;
}

.level2 .uk-accordion-title {
  background-color: #ffffff;
  border-bottom: 0px;
  border-radius: 30px;
  font-weight: 700;
  padding-left: 30px;
}
.level2 svg {
  color: #666666;
}