.uk-dotnav {

	@each $name, $color in $colors {

		&--#{$name} li a {
			border: none;
			background-color: rgba($color, 1);
			width: 15px;
			height: 15px;
		}

		&--active-#{$name} li.uk-active a {
			border: none;
			background-color: rgba($color, 1);
		}

	}

}
