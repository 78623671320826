.redes__top{

	z-index: 10;
	top: 4px;

	@include mq-s {
		top:15px;
	}

}

.remove-18-top{
	top:-18px;
}

