:root {
  --gutter-large: 24px;
  --gutter-medium: 16px;
  --gutter-small: 8px;
  --brand-color-gray: #f6f6f5;
}

.sw-horizontal-acordion {
	&-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
 		margin: 0 auto;
	}

	&-panel {
		background-size: 352px 424px;
		background-position: center;
		background-repeat: no-repeat;
		height: 400px;
		border-radius: 5px;
		cursor: pointer;
		flex: 1;
		margin: var(--gutter-medium);
		position: relative;
		transition: all 700ms ease-in;
		max-width: 150px;
	}

	&-panel.sw-active {
		flex: 3;
		max-width: 352px;
	}

	&-title {
		opacity: 0;
		position: absolute;
		transition: all 500ms;
	}

	&-title.sw-active {
		opacity: 1;
	}
}
