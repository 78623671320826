
.lin_lateral{
	&:before{
		content: '';
		height: 100%;
    display: block;
    width: 1px;
    background: map-get($colors, 'pc-pink');
    position: absolute;
    left: -35px;

	}

}


.lin_lateraR{
	&:after{
		content: '';
		height: 100%;
    display: block;
    width: 1px;
    background: map-get($colors, 'pc-pink');
    position: absolute;
    right: -35px;
		top:0;
		z-index: 0;

	}

}

