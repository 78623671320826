.uk-modal-iframe {
	&-dialog {
		position: relative;
		padding-bottom: calc(56.25% * 0.90); /* 16:9 */
		width: 90%;
		height: 0;

		@include mq-l {
			padding-bottom: 675px; /* 16:9 */
			width: 1200px !important;
		}
	}

	&-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
