.registro{
	&::after{
		content: '*';
		color: map-get($colors, 'pc-pink');
	}
	.star{
		display: none;
	}



}


.box {


	select{

		-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(/images/Iconos/icono-flecha-preguntas.svg);
    background-repeat: no-repeat;
    background-position: 97% 51%;
    background-size: 25px;

	}
}

.extrainput{
	input, select{
		background-color: map-get($colors, "pc-white");
		max-width: 100%;
    width: 100%;
    border: 0 none;
    padding: 0 10px;

    color: map-get($colors, 'sc-grey-4');
    border: 1px solid map-get($colors, 'pc-gray-3');
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;
		height: 40px;
    vertical-align: middle;
    display: inline-block;
		margin-top: 6px;
    margin-bottom: 25px;
		font-size: 16px;
	}


	input[type=radio] + label:before {
    content: "";
    background: map-get($colors, 'sc-grey-5');
    border-radius: 100%;
    border: 1px solid map-get($colors, 'pc-pink');
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    position: relative;
		top: -2px !important;
    margin-right: 8px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

input[type=radio]:checked + label:before {
	background-color: map-get($colors, 'pc-navy');
	box-shadow: inset 0 0 0 4px map-get($colors, 'sc-grey-5');
}
input[type=radio] + label:before {
  top: 1px;
}
input[type=radio] {
	position: absolute;
	opacity: 0;
}
.radio{
	border: 0px;
	padding: 0;
	margin-top: 15px;
	font-weight: 500;
	label{
		margin-right: 15px;
	}

}

}
