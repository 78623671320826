.sw-main-menu-offcanvas {

	&__container {
		background-color: map-get($colors, 'pc-navy') !important;
	}

	&__item {
		color: map-get($colors, 'pc-white') !important;
		text-transform: none !important;
		font-weight: map-get($weights, 'semibold') !important;
		font-size: 16px !important;
		&,
		&:hover {
			border-style: none;
			border-width: none;
		}
	}

	&__container-submenu &__item {
		border-left: 2px solid map-get($colors, 'pc-sky-blue') !important;
		padding: 5px 0 5px 10px !important;
		transition: border-left 250ms;
		font-size: 14px;

		&:hover,
		&:focus {
			border-left: 2px solid map-get($colors, 'pc-pink') !important;
		}
	}

}
