.home-seccion-destacado {
	background: linear-gradient(
    to top,
    map-get($colors, 'pc-white') 0%,
    map-get($colors, 'pc-white') 35%,
		map-get($colors, 'pc-navy') 35%,
    map-get($colors, 'pc-navy') 100%
  );
}

