
@for $i from $minSize through $maxSize {
	.padding-#{$i} {
		padding: #{$i+'px'};
	}
	@each $side in $sides {
		.padding-#{$i}-#{$side} {
			padding-#{$side}:#{$i+'px'};

		}
	}
}
.padding-remove {
    padding: 0;
}
@each $side in $sides {
    .padding-#{$side}-remove {
        padding-#{$side}:0;
    }
}

@for $i from $minSize through $maxSize {
	.margin-#{$i} {
		margin: #{$i+'px'};
	}
	@each $side in $sides {
		.margin-#{$i}-#{$side} {
			margin-#{$side}:#{$i+'px'};

		}
	}
}
.margin-remove {
    margin: 0;
}
@each $side in $sides {
    .margin-#{$side}-remove {
        margin-#{$side}:0;
    }
}

@for $i from $minSize through $maxSize {
	.border-radius-#{$i}{
		border-radius:#{$i+'px'};
	}

}

@for $i from $minSize through $maxSize {
	.b-solid-#{$i} {
		border-style: solid;
		border-width: #{$i+'px'};
	}
	.b-radius-#{$i}{
		border-radius:#{$i+'px'};
	}
	@each $side in $sides {
		.b-solid-#{$i}-#{$side} {
			border-#{$side}-style: solid;
			border-#{$side}-width: #{$i+'px'};
		}

		.b-solid-#{$i}-#{$side}-remove {
			border-#{$side}-style: inherit;
			border-#{$side}-width: inherit;
		}
	}
	@each $border-side in $border-sides {
    .b-radius-#{$i}-#{$border-side} {
        border-#{$border-side}-radius:  #{$i+'px'};;
    }
	}
}
