.mecanismos_img
	{
		min-height: 170px;
    background-position: center;
		background-size: cover;
	}
.mecanismos_degradado
	{
		background: transparent linear-gradient(180deg, #EEEEEE00 0%, #EEEEEE 45%, #EEEEEE 72%, #EEEEEE 100%) 0% 0% no-repeat padding-box;
		opacity: 1;
		height: 32px;
		bottom: 30px;
	}
	.mecanismos_degradado_p
		{

			display: block;

			max-width: 100%;
			height: 75px;

			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
