@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import './Fuentes_Condensed/Condensed.scss';
@import './Fuentes_Condensed_extrabold/Condensed_extrabold.scss';
@import './Fuentes_Condensed_regular/Condensed_regular.scss';

html,
body {
  font: map-get($weights, 'medium') 16px map-get($fonts, 'opensans'), sans-serif;
}

@mixin font-get($font) {
	@if map-has-key($fonts, $font) {
		font-family: map-get($fonts, $font), serif, sans-serif;
	}
}

.f-opensans,
.f-open-sans {
	@include font-get('opensans');
}

.f-CondensedBold,
.f-Condensed-Bold,
.f-CondensedBold,
.f-Condensedbold {
	@include font-get('condensedbold');
}

.f-CondensedExtrabold,
.f-Condensed-Extrabold,
.f-CondensedExtrabold,
.f-Condensedextrabold {
	@include font-get('condensedextrabold');
}

.f-CondensedLight,
.f-Condensed-Light,
.f-Condensedlight {
	@include font-get('condensedlight');
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3,
h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6 {
  @include font-get('opensans');
	line-height: 1.2;
  text-align: center;
}

h1, .uk-h1,
h2, .uk-h2,
h3, .uk-h3 {
	font-size: 22px;
	font-weight: map-get($weights, 'bold');

	@include mq-m {
		font-size: 24px;
	}
}

h3, .uk-h3 {
	font-size: 20px;

}

h4, .uk-h4,
h5, .uk-h5,
h6, .uk-h6 {
	font-size: 18px;
	font-weight: map-get($weights, 'semibold');
}

@each $weight-name, $weight in $weights {

	.fw-#{$weight-name}, .fw-#{$weight} {
    font-weight: $weight;
  }

  .fwi-#{$weight-name}, .fwi-#{$weight} {
    font-weight: $weight;
    font-style: italic;
  }

}

@for $size from $minSize through $maxSize {

	.fs-#{$size} {
    font-size: #{$size+'px'};
  }

  @include mq-xs {
    .fs-#{$size}\@sx {
      font-size: #{$size+'px'};
    }
  }

  @include mq-s {
    .fs-#{$size}\@s {
      font-size: #{$size+'px'};
    }
  }

  @include mq-m {
    .fs-#{$size}\@m {
      font-size: #{$size+'px'};
    }
  }

  @include mq-l {
    .fs-#{$size}\@l {
      font-size: #{$size+'px'};
    }
  }

  @include mq-xl {
    .fs-#{$size}\@xl {
      font-size: #{$size+'px'};
    }
  }

}
