.buscador{
	.inputbox{
		border: 2px solid map-get($colors, 'pc-pink');
    padding: 7px;

		&:focus{
			border: 2px solid map-get($colors, 'pc-pink');
		}
	}
	.button{
		background-color: map-get($colors, 'pc-pink');
		color: map-get($colors, 'pc-white');
		padding: 7px;
		border: 2px solid map-get($colors, 'pc-pink');
		margin-left: -4px;
	}
}
