.anexo {
  counter-reset: numero_inv;
  margin: 0 auto;
}
.anexo_num span:after {
  counter-increment: numero_inv;
  content: counter(numero_inv, decimal);
  color: #fff;
  font-size: 18px;
  margin-left: 5px;
  min-width: 53px;
  font-weight: 800;
}
