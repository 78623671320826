@font-face {
	font-family: "Open Sans Condensed ExtraBold";
	src: url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.eot");
	src: url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.eot?#iefix")format("embedded-opentype"),
	url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.woff2")format("woff2"),
	url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.woff")format("woff"),
	url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.ttf")format("truetype"),
	url("https://db.onlinewebfonts.com/t/7c0a49b715e157b11d0bca4df4247716.svg#Open Sans Condensed ExtraBold")format("svg");

}

// @mixin font-get($font) {
// 	@if map-has-key($fonts, $font) {
// 		font-family: map-get($fonts, $font), 'Condensedbold';
// 	}
// }



