.uk-accordion-title::before{
	background-image: none!important;
}
.uk-open > .uk-accordion-title::before{
	background-image: none;
}
// .title-acordion{

// }
// .uk-open  .title-acordion{
//  background-image: url("/images/Iconos/icono-flecha-preguntas-collapse.svg");
//  background:no-repeat;
// }
.title-acordion .icon-close{
	display: none;
}
.uk-open .title-acordion .icon-close{
	display: block;
}
.uk-open .title-acordion .icon-open{
	display: none;
}
