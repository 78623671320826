.list_preve:before{
	content:'';
	width: 6px;
	height: 6px;
	background: #333;
	border-radius: 25px;
	position: absolute;
	left: 26px;
	top: 8px;
}
