.paginacio_custom{
    & li.active a {
        background: #1e87f0;
        color: #333333;
    }
}

.uk-pagination > * > * {
    color: #333333;
}
