.button-primary {
	padding: 8px 50px;
	background-color: map-get($colors, "pc-white");
	color: map-get($colors, "pc-navy");
	border: 2px solid map-get($colors, "pc-pink");
	border-radius: 5px;
	font-weight: bold;
	display: block;
	text-align: center;
	cursor: pointer;
	transition: background 300ms;

	&:focus,
	&:hover {
		background: map-get($colors, "sc-grey-6");
		border: 2px solid map-get($colors, "pc-pink");
	}
}
